var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pl-6 pr-6 pt-0 pb-6" },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "#fafafa" } },
        [_c("v-toolbar-title", [_vm._v("Output Set Groups")])],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "pa-4",
          staticStyle: { border: "1px solid #ccc" },
          attrs: { flat: "", "max-height": "300" }
        },
        [
          _vm.store.output_set.set_id !== null
            ? _c(
                "section",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-2",
                      attrs: { justify: "center", align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              items: _vm.outputs,
                              "item-text": "label",
                              "item-value": "set_id"
                            },
                            model: {
                              value: _vm.store.output_set.set_id,
                              callback: function($$v) {
                                _vm.$set(_vm.store.output_set, "set_id", $$v)
                              },
                              expression: "store.output_set.set_id"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              readonly: "",
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Begin Date",
                              value: _vm.beginDate
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              readonly: "",
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "End Date",
                              value: _vm.endDate
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              readonly: "",
                              outlined: "",
                              dense: "",
                              background: "",
                              prefix: "$",
                              label: "Fee Amount",
                              value: _vm.feeAmount
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "section",
                { staticClass: "pt-6" },
                [
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c("h1", [_vm._v("No Output Set")])
                  ])
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }