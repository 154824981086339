<template>
  <v-container>
    <section class="pa-6">
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar dark flat color="#1C3144">
              <v-toolbar-title>Reports</v-toolbar-title>
            </v-toolbar>
            <v-data-table :height="tableSize" :items-per-page="100" :items.sync="reports" :headers="reportHeaders"
              hide-default-footer no-data-text="Reports Currently Not Available" :loading="reportsLoading"
              loading-text="Loading Reports...Please Wait." class="table" fixed-header dense @click:row="getParameters">
              <template v-slot:footer>
                <v-toolbar flat>
                  <v-spacer />
                  <v-btn depressed color="#fff" height="50"
                    @click="metabase('https://reports.iprosystems.com/collection/40')">
                    <v-img max-height="50" max-width="50" src="../assets/metabase-logo.png" alt="Metabase Logo" />
                    <h1 style="color:#0d68a5; font-weight:300">Reports</h1>
                  </v-btn>
                  <v-btn depressed color="#fff" height="50"
                    @click="metabase('https://reports.iprosystems.com/collection/49')">
                    <v-img max-height="50" max-width="50" src="../assets/metabase-logo.png" alt="Metabase Logo" />
                    <h1 style="color:#0d68a5; font-weight:300">Exports</h1>
                  </v-btn>
                  <v-spacer />
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-toolbar dark flat color="#1C3144">
              <v-toolbar-title>Available Reports</v-toolbar-title>
              <v-spacer />
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon class="mx-1" @click="getAvailableReports">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-toolbar>
            <v-data-table :height="tableSize - 64" :items-per-page="1000" :items="reportsAvailable"
              :headers="availableReportsHeaders" no-data-text="No Finished Reports Available" sort-by="execution_time"
              sort-desc hide-default-footer :loading="queueLoading" item-key="id" class="table" fixed-header dense
              @click:row="downloadLink">

              <template v-slot:top>
                <v-toolbar>
                  <v-col xs="12" sm="10" md="10" lg="8">
                    <v-text-field class="mt-7" outlined dense background-color="white"
                      prepend-inner-icon="mdi-filter-outline" label="Filter Reports" />
                  </v-col>
                </v-toolbar>
                <v-divider />
              </template>

              <template v-slot:[`item.report_name`]="{ item }">
                {{item.report_name}}
                {{'invoice_number' in item ? `&bull; Invoice #${item.invoice_number}` : 'batch_number' in item ? `&bull; Invoice Batch #${item.batch_number}` : 'AdGroupName' in item ? `&bull; ${item.AdGroupName}` : ''}}
              </template>

              <template v-slot:[`item.execution_time`]="{ item }">
                <span>{{formatTime(item.execution_time)}}</span>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-icon v-if="item.status === 'Complete'" color="#00b24a">mdi-checkbox-marked-circle-outline</v-icon>
                <v-icon v-if="item.status === 'Running'" color="#f9a825">mdi-clock-outline</v-icon>
                <v-icon v-if="item.status === 'Failed'" color="red">mdi-alert-outline</v-icon>
              </template>

              <template v-slot:footer>
                <v-toolbar flat>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <ReportBuilder :key="componentKey" v-on:submit="notify($event)" :reportParameters="reportParameters" :reportDimensions="reportDimensions"
      @refresh-queue="getAvailableReports" :value.sync="modal" :report="report" @add="addParameterValue" @submit="submitReport"/>
  </v-container>
</template>

<script>
import { polling } from '@/mixins/polling'
import { notification } from '@/mixins/notification'

export default {
  name: 'Dashboard',

  components: {
    ReportBuilder: () => import('@/components/reporting/reportBuilder')
  },

  mixins: [polling, notification],

  data () {
    return {
      adGroups: [],
      modal: false,
      queueLoading: true,
      report: {},
      reportDimensions: [],
      reportParameters: [],
      reportParameterValues: [],
      reports: [],
      reportsAvailable: [],
      reportsLoading: true,
      show: true,
      tableSize: '',
      windowHeight: '',
      componentKey: ''
    }
  },

  computed: {
    filteredReports () {
      return [
        '4',
        '14',
        '15',
        '66',
        '77',
        '79',
        '80',
        '111',
        '112',
        '113',
        '116',
        '117',
        '118',
        '119',
        '120',
        '121',
        '122',
        '123',
        '124',
        '125',
        '129',
        // '163',
        // '164',
        '166'
      ]
    },

    reportDisplay () {
      return this.moment().subtract(7, 'days')
    },
    reportHeaders () {
      return [
        { text: 'Report #', sortable: true, class: 'black-text', value: 'name' }
      ]
    },
    availableReportsHeaders () {
      return [
        { text: 'Report Name', sortable: true, class: 'black-text', value: 'report_name' },
        // { text: 'Ad Group', sortable: true, class: 'black-text', value: 'AdGroupName' },
        { text: 'Submit Time', sortable: true, class: 'black-text', value: 'execution_time' },
        { text: 'Status', sortable: true, class: 'black-text', value: 'status' }
      ]
    }
  },

  watch: {
    windowHeight: {
      handler () {
        this.tableSize = this.windowHeight - 300
      }
    },

    modal: {
      handler () {
        this.componentKey += 1
        if (!this.modal) {
          this.report = {}
          this.reportParameterValues = []
        }
      }
    }
  },

  created () {
    this.$store.commit('set_snackbar', [])
    this.tableSize = this.windowHeight - 300
    window.addEventListener('resize', this.resize)
    this.resize()
    this.getReports()
    this.getAvailableReports()
    this.pollingMethod = this.getAvailableReports
  },

  methods: {
    resize () {
      this.windowHeight = window.innerHeight
    },

    formatTime (date) {
      return date ? this.moment.utc(date).local().format('MMM D - h:mm a') : ''
    },

    async getReports () {
      try {
        const data = await this.$reports.getReports()
        this.reports = data.filter(report => this.filteredReports.includes(report.id))
        this.reports.push({
          id: '1',
          name: 'TPS Report'
        })
      } catch {
        this.notify('error', 'Error Retrieving Reports')
      } finally {
        this.reportsLoading = false
      }
    },

    async getAvailableReports () {
      try {
        const data = await this.$reports.getQueue()
        this.reportsAvailable = data.filter(report => report.execution_time >= this.moment().isoWeekday(1).format('YYYY-MM-DD'))
      } catch {
        this.notify('error', 'Error Retrieving Report Queue')
        clearInterval(this.polling)      
      } finally {
        this.queueLoading = false
      }
    },

    async getParameters(report) {
      this.report = report
      if (report.id == 166) {
        try {
          await this.$reports.submitReport(report.id)
          this.notify('success', 'Report Successfully Submitted')
        } catch {
          this.notify('error', 'Invalid Report Parameters')
        }
      } else if (report.id == 1) {
          this.reportParameters = [
            {
              name: 'secret',
              display_name: 'Enter Name',
              data_type: 'text'
            }
          ]
          this.modal = true
      } else {
        try {
          const { parameters } = await this.$reports.getParameters(report.id)
          this.reportParameters = parameters
          const parameterWithDimensions = this.reportParameters.find(parameter => parameter.data_type == 'dimension')
          if (parameterWithDimensions) {
            this.reportDimensions = await this.$reports.getDimensions(parameterWithDimensions.field_id)
          }
          this.modal = true
        } catch {
          this.notify('error', 'Error Retrieving Report Parameters')
        }
      }
    },

    async getDimensions (parameter) {
      try {
        const data = await this.$reports.getDimensions(parameter.field_id)
        return data
      } catch {
        this.notify('error', 'Error Retrieving Parameter Options')
      }
    },

    addParameterValue (value) {
      const parameterExists = this.reportParameterValues.some(parameter => parameter.parameterName == value.parameterName)
      if (parameterExists) {
        this.reportParameterValues.map(parameter => {
          if (parameter.parameterName == value.parameterName) {
            parameter.parameterValue = value.parameterValue
          }
        })
      } else {
        this.reportParameterValues.push(value)
      }
    },

    async submitReport () {
      const easterEggReport = this.report.id == 1
      if (!easterEggReport) {
        if (this.reportParameters.length === this.reportParameterValues.length) {
          try {
            let reportQueryParameters = new URLSearchParams()
            this.reportParameterValues.map(rpv => {
              reportQueryParameters.append(rpv.parameterName, rpv.parameterValue)
            })
            await this.$reports.submitReport(this.report.id, reportQueryParameters)
            this.notify('success', 'Report Submitted Successfully')
          } catch {
            this.notify('error', 'Invalid Report Parameters')
          } finally {
            this.getAvailableReports()
            this.modal = false
          }
        } else {
          this.notify('error', 'Missing Report Parameters')
        }
      } else {
        const secretAnswerCorrect = this.reportParameterValues.some(value => value.parameterValue == 'Peter Gibbons')
        if (secretAnswerCorrect) {
          this.notify('success', 'Report Submitted Successfully')
          setTimeout(() => {
            var win = window.open('http://d2agz7p8m5udbi.cloudfront.net/', '_blank')
            win.focus()
          }, 2000);
          this.modal = false
        } else {
          this.notify('error', 'Invalid Report Parameters')
          this.reportParameterValues = []
        }
      }
    },

    metabase (url) {
      var win = window.open(url, '_blank')
      win.focus()
    },

    downloadLink (report) {
      if (report.status === 'Complete') {
        var win = window.open(report.link)
        win.focus()
      }
    }
  }
}
</script>
