var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c("v-data-table", {
        staticClass: "table",
        attrs: {
          height: _vm.tableSize,
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.shuttles,
          expanded: _vm.expanded,
          search: _vm.search,
          "items-per-page": _vm.pageSize,
          "single-select": _vm.singleSelect,
          "loader-height": "2",
          color: "#fff",
          "loading-text": "Loading Shuttles... Please wait",
          "no-results-text": "No Matching Shuttles Found",
          "no-data-text": "No Shuttles Invoices",
          "fixed-header": "",
          "hide-default-footer": "",
          "show-expand": "",
          "single-expand": _vm.singleExpand,
          "item-key": "shuttleId",
          dense: ""
        },
        on: {
          "update:items": function($event) {
            _vm.shuttles = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { cursor: "default" },
                      attrs: { flat: "", color: "#fafafa" }
                    },
                    [
                      _c("v-toolbar-title", { staticClass: "title" }, [
                        _vm._v("Shuttle Status")
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" }
                      }),
                      _c(
                        "v-col",
                        { attrs: { md: "4", lg: "3", xl: "3" } },
                        [
                          _c("v-autocomplete", {
                            staticClass: "mt-7",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              "prepend-inner-icon": "mdi-filter-outline",
                              label: "Select User",
                              "item-text": "",
                              items: _vm.users
                            },
                            model: {
                              value: _vm.selectedUser,
                              callback: function($$v) {
                                _vm.selectedUser = $$v
                              },
                              expression: "selectedUser"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3", lg: "2", xl: "2" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-7",
                            attrs: {
                              "background-color": "#fff",
                              label: "Filter By Contract ID",
                              "prepend-inner-icon": "mdi-filter-outline",
                              outlined: "",
                              dense: "",
                              autocomplete: "off"
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3", lg: "2", xl: "2" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-7",
                            attrs: {
                              "background-color": "#fff",
                              "prepend-inner-icon": "mdi-calendar",
                              label: "Shuttle Date Filter",
                              placeholder: "M/D/YYYY",
                              autocomplete: "off",
                              dense: "",
                              outlined: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { small: "", icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.getShuttlesByUser(
                                              _vm.selectedUser,
                                              _vm.shuttleDate
                                            )
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v("mdi-magnify")])],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.shuttleDate,
                              callback: function($$v) {
                                _vm.shuttleDate = $$v
                              },
                              expression: "shuttleDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider")
                ]
              },
              proxy: true
            },
            {
              key: "item.submitTime",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(_vm._s(_vm.formatSubmitTime(item.submitTime)))
                  ])
                ]
              }
            },
            {
              key: "item.shuttleStatus",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(item.totalComplete) +
                      " of " +
                      _vm._s(item.totalGroups) +
                      " Completed "
                  )
                ]
              }
            },
            {
              key: "item.totalFailed",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-chip",
                    {
                      staticClass: "white--text",
                      attrs: {
                        color: item.totalFailed > 0 ? "#D32F2F" : "#00B24A"
                      }
                    },
                    [_vm._v(" " + _vm._s(item.totalFailed) + " ")]
                  )
                ]
              }
            },
            {
              key: "item.details",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.getShuttleDetails(item.shuttleId)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { dense: "" } }, [
                        _vm._v("mdi-text-box-search-outline")
                      ])
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.shuttleType === "Ad"
                    ? _c(
                        "td",
                        {
                          staticStyle: { padding: "0px" },
                          attrs: { colspan: "12" }
                        },
                        [
                          _c("v-data-table", {
                            staticClass: "table",
                            attrs: {
                              "items-per-page": _vm.pageSize,
                              items: item.adGroups,
                              headers: _vm.adHeaders,
                              height: "350",
                              "hide-default-footer": "",
                              "fixed-header": "",
                              dense: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.payload.retail",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.payload.retCt) +
                                          " @ " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              item.payload.retail
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.payload.adFee",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              item.payload.adFee
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.status",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      item.status === "Complete"
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-6",
                                                              attrs: {
                                                                color: "#00b24a"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-check-all"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Complete")])]
                                          )
                                        : item.status === "Pending"
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-6",
                                                              attrs: {
                                                                color: "#f9a825"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-clock-outline"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Pending")])]
                                          )
                                        : item.status === "Failed"
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                color: "#d32f2f",
                                                top: ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-6",
                                                              attrs: {
                                                                icon: "",
                                                                color: "#d32f2f"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.showErrors(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  dense: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-alert-outline"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Failed")])]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  item.shuttleType === "TPR"
                    ? _c(
                        "td",
                        {
                          staticStyle: { padding: "0px" },
                          attrs: { colspan: "12" }
                        },
                        [
                          _c("v-data-table", {
                            staticClass: "table",
                            attrs: {
                              "items-per-page": _vm.pageSize,
                              items: item.adGroups,
                              headers: _vm.tprHeaders,
                              "sort-by": ["adGroup", "payload.dealBeginDate"],
                              "group-by": "adGroup",
                              height: "510",
                              "hide-default-footer": "",
                              "fixed-header": "",
                              dense: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "group.header",
                                  fn: function(ref) {
                                    var group = ref.group
                                    var isOpen = ref.isOpen
                                    var toggle = ref.toggle
                                    var headers = ref.headers
                                    return [
                                      _c(
                                        "td",
                                        {
                                          attrs: { colspan: headers.length },
                                          on: { click: toggle }
                                        },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "pr-5",
                                                  attrs: { right: "" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      isOpen
                                                        ? "mdi-minus"
                                                        : "mdi-plus"
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" " + _vm._s(group) + " ")
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.payload.regSRP",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.payload.regSRPCount) +
                                          " @ " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              item.payload.regSRP
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.payload.TPR_SRP",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.payload.TPR_SRPCount) +
                                          " @ " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              item.payload.TPR_SRP
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.dates",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDate(
                                              item.payload.dealBeginDate
                                            )
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm.formatDate(
                                              item.payload.dealEndDate
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.status",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      item.status === "Complete"
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-6",
                                                              attrs: {
                                                                color: "#00b24a"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-check-all"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Complete")])]
                                          )
                                        : item.status === "Pending"
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-6",
                                                              attrs: {
                                                                color: "#f9a825"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-clock-outline"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Pending")])]
                                          )
                                        : item.status === "Failed"
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-6",
                                                              attrs: {
                                                                icon: "",
                                                                color: "#d32f2f"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.showErrors(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  dense: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-alert-outline"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Failed")])]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "#fafafa" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _vm.selected.length > 0
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text mb-2",
                                      attrs: { color: "#D32F2F" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.shuttleDelete = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(" Remove"),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { dense: "" }
                                        },
                                        [_vm._v("mdi-trash-can-outline")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { justify: "center", persistent: "", "max-width": "300" },
          model: {
            value: _vm.shuttleDelete,
            callback: function($$v) {
              _vm.shuttleDelete = $$v
            },
            expression: "shuttleDelete"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "#fafafa" } },
            [
              _c(
                "v-container",
                { staticClass: "ma-0 pa-6" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { "x-large": "", color: "orange" } },
                        [_vm._v("mdi-alert-circle-outline")]
                      )
                    ],
                    1
                  ),
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c("h3", [_vm._v("Are You Sure?")])
                  ]),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "elevation-1",
                          attrs: { height: "40" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.shuttleDelete = false
                            }
                          }
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text elevation-1 ml-2",
                          attrs: { height: "40", color: "#0c67a5" },
                          on: { click: _vm.removeShuttle }
                        },
                        [_vm._v("Yes")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ErrorModal", {
        attrs: { failureReason: _vm.failureReason },
        model: {
          value: _vm.errorModal,
          callback: function($$v) {
            _vm.errorModal = $$v
          },
          expression: "errorModal"
        }
      }),
      _vm.contract !== {}
        ? _c("ShuttleDetail", {
            attrs: { contract: _vm.contract, expanded: _vm.expanded },
            model: {
              value: _vm.shuttleDetail,
              callback: function($$v) {
                _vm.shuttleDetail = $$v
              },
              expression: "shuttleDetail"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }