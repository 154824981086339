<template>
  <v-container class="ma-0">
    <v-data-table :height="tableSize" :loading="loading" :headers="headers" :items.sync="contracts"
      :items-per-page="pageSize" :search="search" loader-height="2" color="#fff" class="table"
      loading-text="Loading Contracts... Please Wait" no-data-text="No Matching Contracts Found"
      no-results-text="No Matching Contracts Found" fixed-header hide-default-footer dense @click:row="editContract">

      <template v-slot:top>
        <v-toolbar flat color="#fafafa" style="cursor: default;">
          <v-toolbar-title class="ml-2 title" @click="getContracts">Contracts</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-col cols="2">
            <v-text-field class="mt-7" background-color="#fff" max-width="200" @keydown.enter="getContract" outlined
              dense label="Contract ID" prepend-inner-icon="mdi-magnify" placeholder="Find By Contract ID" single-lin
              v-model.number="contractID" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field class="mt-7" background-color="#fff" label="Contract Name"
              placeholder="Filter By Contract Name" prepend-inner-icon="mdi-filter-outline" outlined dense
              v-model="search" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn :to="{ path: '/new-contract'}" v-on="on" height="40" color="#0D68A5" class="white--text ml-2"
                style="background: #0D68A5;">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create New Contract</span>
          </v-tooltip>
        </v-toolbar>
        <v-divider></v-divider>
      </template>

      <template v-slot:[`item.AdDate`]="{ item }">
        <span>{{formatDates(item.AdDate)}}</span>
      </template>
      <template v-slot:[`item.TPRBeginDate`]="{ item }">
        <span>{{formatDates(item.TPRBeginDate)}} - {{formatDates(item.TPREndDate)}}</span>
      </template>

      <template v-slot:footer>
        <v-divider/>
        <v-toolbar flat color="#fafafa">
          <v-row align="center">
            <v-spacer />
            <v-col cols="2" style="max-width:150px;">
              <v-select class="mt-6" dense :items="pageSizes" label="Items Per Page" select v-model="pageSize"
                :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
              </v-select>
            </v-col>
            <v-btn icon large @click="previous()" :disabled="disablePrevious" class="mx-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <small>Page {{page}}</small>
            <v-btn icon large @click="next()" :disabled="disableNext" class="mx-2">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-snackbar v-model="notification" :timeout="timeout" :color="type" top>
      <v-icon left>
        {{(this.type === 'success') ? 'mdi-checkbox-marked-circle-outline' : 'mdi-close-circle-outline'}}
      </v-icon>{{text}}
    </v-snackbar>
  </v-container>
</template>

<script>
// Third-Party
import { debounce } from 'lodash'
// Mixins
import { tableSettings } from '@/mixins/table'

export default {
  name: 'ContractList',

  mixins: [tableSettings],

  data () {
    return {
      disablePrevious: true,
      disableNext: false,
      loading: true,
      notification: false,
      timeout: 3000,
      text: '',
      type: '',
      search: '',
      contracts: [],
      contractID: ''
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Contract ID', align: 'center', sortable: true, filterable: true, value: 'ContractID', class: 'black--text' },
        { text: 'Contract Name', align: 'left', sortable: true, filterable: true, value: 'Name', class: 'black--text' },
        { text: 'Contract Type', align: 'center', sortable: true, filterable: false, value: 'ContractType.Description', class: 'black--text' },
        { text: 'Ad Date', align: 'center', sortable: true, filterable: false, value: 'AdDate', class: 'black--text' },
        { text: 'TPR Begin - TPR End', align: 'center', sortable: true, filterable: false, value: 'TPRBeginDate', class: 'black--text' }
      ]
    }
  },

  watch: {
    pageSize: {
      deep: true,
      handler () {
        this.contracts = []
        this.loading = true
        this.page = 1
        this.getContracts()
      }
    },

    contractID: debounce(function () {
      this.getContract()
    }, 1000)
  },

  created () {
    this.nonTableHeight = 245
    this.getContracts()
    localStorage.removeItem('adman_contractID')
    localStorage.removeItem('adman_dept_code')
    // this.$root.$on('delete-parent', this.notify('success', 'Contract Successfully Deleted'))
  },

  methods: {
    notify (type, text) {
      this.type = type
      this.text = text
      this.notification = true
    },

    newContract () {
      this.$router.push({ path: 'new-contract' })
    },

    async getContracts () {
      this.contractID = ''
      const start = (this.page - 1) * this.pageSize
      const end = this.pageSize
      await this.$contracts.getContracts(start, end)
        .then(response => {
          this.contracts = response
          if (this.contracts.length < this.pageSize) {
            this.disableNext = true
          } else {
            this.disableNext = false
          }
          if (start === 0) {
            this.disablePrevious = true
          } else {
            this.disablePrevious = false
          }
          this.loading = false
        })
    },

    getContract () {
      this.$contracts.getContract(this.contractID)
        .then(response => {
          const contractID = response.ContractID
          const items = response.ContractItems
          this.$router.push({ name: 'ContractEdit', params: { id: contractID, items: items }, query: { id: contractID } })
        }).catch(() => {
          if (this.contractID) {
            this.notify('warning', 'Contract ID Not Found')
          }
        })
    },

    previous () {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      this.getContracts()
    },

    next () {
      this.page = this.page + 1
      this.getContracts()
    },

    editContract (contract) {
      this.$router.push({ name: 'ContractEdit', params: { id: contract.ContractID }, query: { id: contract.ContractID } })
    },

    formatDates (date) {
      return date ? this.moment(date).format(this.$config.date_display) : ''
    }
  }
}
</script>
<style scoped>
.table >>> table {
  cursor: pointer;
}
</style>
