<template>
  <v-container class="ma-0">
    <section class="px-4 mb-0">
      <v-toolbar flat color="#fafafa" style="cursor:default;">
        <v-btn :to="{ path: '/contracts'}" text color="#0c67a5">
          <v-icon large left color="#0c67a5">mdi-chevron-left</v-icon>Contracts
        </v-btn>
        <v-spacer />
        <span v-if="hasContractChanged">
          <v-icon color="orange" left>mdi-alert-circle-outline</v-icon>
          <small class="orange--text">Contract information has been changed. Please update.</small>
        </span>
        <v-spacer />
        <v-radio-group class="mt-6" v-model="contract.ContractScope" row>
          <v-radio color="#0c67a5" v-for="s in scopes" :key="s.value" :label="`${s.label}`" :value="s.value"></v-radio>
        </v-radio-group>
        <v-divider vertical inset class="mx-4" />
        <b>
          <p class="mt-4">{{(isExistingContract) ? `ID: ${contract.ContractID}` : 'New Contract'}}</p>
        </b>
      </v-toolbar>
      <v-row dense justify="center">
        <v-col cols="4">
          <v-text-field :rules="[v => !!v || 'Contract Name is required']" v-model="contract.Name"
            background-color="#fff" label="Contract Name" autocomplete="off" dense outlined required />
        </v-col>
        <v-col cols="2">
          <v-autocomplete auto-select-first :rules="[v => !!v || 'Contract Type is required']" :items="contractTypes"
            v-model="contract.ContractType.ID" background-color="#fff" label="Contract Type" item-text="description"
            item-value="id" autocomplete="off" dense outlined @change="contractTypeChange"/>
        </v-col>
        <v-col cols="3">
          <v-autocomplete auto-select-first background-color="#fff" dense outlined label="Ad Group" :items="adGroups"
            :rules="[v => !!v || 'Ad Group is required']" item-text="description" item-value="id"
            @change="adGroupSelect" v-model="contract.AdGroup.ID" autocomplete="off" />
        </v-col>
        <v-col cols="3">
          <v-autocomplete auto-select-first background-color="#fff" dense outlined label="Base Supplier"
            :items="suppliers" :rules="[v => !!v || 'Base Supplier Name is required']" item-text="description"
            item-value="id" v-model="contract.Supplier.ID" autocomplete="off" @change="supplierSelect" />
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col cols="2">
          <v-autocomplete auto-select-first background-color="#fff" dense outlined label="Department"
            item-text="name" item-value="id" :rules="[v => !!v || 'Department is required']" :items="departments"
            v-model="contract.Department.ID" autocomplete="off" @change="departmentSelect"/>
        </v-col>
        <v-col cols="4">
          <v-autocomplete auto-select-first background-color="#fff" dense outlined label="Manufacturer"
            item-text="description" :rules="[v => !!v || 'Manufacturer is required']" :items="manufacturers"
            item-value="id" v-model="contract.Manufacturer.ID" autocomplete="off" />
        </v-col>
        <v-col cols="2" style="max-width:15%;">
          <v-autocomplete auto-select-first :value="formattedAdDate" @change="selectAdDate" background-color="#fff"
            prepend-inner-icon="mdi-calendar" label="Ad Date" autocomplete="off" dense outlined :items="adDates">
            <template v-slot:append v-if="contract.AdDate !== null">
              <v-icon @click="clear('adDate')" tabindex="-1" class="clear">mdi-close</v-icon>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2" style="max-width:15%;">
          <v-text-field background-color="#fff" dense outlined label="TPR Begin Date" hint="M/D/YYYY format"
            @change="setTPRBeginDate" prepend-inner-icon="mdi-calendar" :value="formattedTPRBeginDate"
            autocomplete="off">
            <template v-slot:append v-if="contract.TPRBeginDate !== null">
              <v-icon @click="clear('beginDate')" tabindex="-1" class="clear">mdi-close</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="2" style="max-width:15%;">
          <v-text-field :value="formattedTPREndDate" @change="setTPREndDate" background-color="#fff"
            label="TPR End Date" hint="M/D/YYYY format" prepend-inner-icon="mdi-calendar" dense outlined
            autocomplete="off">
            <template v-slot:append v-if="contract.TPREndDate !== null">
              <v-icon @click="clear('endDate')" tabindex="-1" class="clear">mdi-close</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn :disabled="disabledMultiDates" large icon class="pr-0"
                @click="setComponent('AdditionalTprDates')">
                <v-icon>mdi-calendar-multiple</v-icon>
              </v-btn>
              <v-badge v-on="on" :content="contract.TPRDates.length" class="pl-0 mb-2" :value="contract.TPRDates.length" color="#0c67a5" />
            </template>
            <span>Additional Dates</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field v-model="contract.TPRLump" @change="setTPRLump" background-color="#fff" class="input"
            label="TPR Lump" placeholder="0.00" type="number" min="0" step="0.01" prefix="$" autocomplete="off" dense
            outlined>
          </v-text-field>
        </v-col>
        <v-col cols="5">
          <v-text-field v-model="contract.InternalComment" label="Internal Notes" autocomplete="off" rows="1"
            class="input" background-color="#fff" outlined dense>
          </v-text-field>
        </v-col>
        <v-col cols="6" align="right">
          <!-- <v-btn v-if="isExistingContract" height="40" color="red" class="white--text" @click="confirm('Delete', 'Contract')">
            Delete
            <v-icon right>mdi-trash-can-outline</v-icon>
          </v-btn> -->
          <v-btn v-if="isExistingContract" height="40" color="orange" class="white--text ml-2" @click="confirm('Copy', 'Contract')">
            Copy
            <v-icon right>mdi-content-duplicate</v-icon>
          </v-btn>
          <v-btn :disabled="disabled" height="40" color="green" class="white--text ml-2" @click="saveContract"
            :class="hasContractChanged ? 'updateRequired ml-2' : 'white--text ml-2'" :elevation="hasContractChanged ? '12' : '2'">
            {{(isExistingContract) ? 'Update' : 'Save'}}
            <v-icon right>{{(isExistingContract) ? 'mdi-update' : 'mdi-checkbox-marked-circle-outline'}}</v-icon>
          </v-btn>
          <v-menu v-if="contractItems.length" :offset-y="offsetY">
            <template v-slot:activator="{ on }">
              <v-btn :disabled="hasContractChanged" v-on="on" height="40" color="#D8542F" class="white--text ml-2">
                Shuttle
                <v-icon right>mdi-transfer-right</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="contract.AdDate" @click="setComponent('ShuttleToAd')">
                <v-list-item-title>Items to Ad</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="contract.TPRBeginDate && contract.TPRBeginDate" @click="setComponent('ShuttleToTpr')">
                <v-list-item-title>Items to TPR</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </section>
    <v-divider />

    <v-data-table :expanded.sync="expanded" :headers="headers" :height="tableSize" :items-per-page="pageSize"
      :items.sync="contractItems" :loading="loading" loading-text="Loading Contract Items...Please Wait" :search="search"
      :sort-by="tableSort" show-select class="table" dense fixed-header hide-default-footer item-key="Item.OrderCode"
      no-results-text="No Matching Items Found" style="cursor:default;" v-model="selected">

      <template v-slot:[`item.Item.CaseCost`]="{ item }">
        <span>{{formatCurrency(item.Item.CaseCost)}}</span>
      </template>

      <template v-slot:[`item.Item.Status`]="{ item }">
        <span :class="item.Item.Status === 'Active' ? 'green--text' : 'red--text'">
          {{item.Item.Status}}
        </span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small icon @click="setComponent('ContractItemEdit', item)">
              <v-icon medium class="edit">mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small icon>
              <v-icon class="copy" @click="setComponent('ContractItemCopy', item)">mdi-content-duplicate</v-icon>
            </v-btn>
          </template>
          <span>Copy</span>
        </v-tooltip>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pl-12">
          <small><strong>Case Cost:</strong> {{formatCurrency(item.Item.CaseCost)}}</small>
          <v-divider class="mx-3" vertical />
          <small><strong>AMAP:</strong> {{formatCurrency(item.AMAP)}}</small>
          <v-divider class="mx-3" vertical />
          <small><strong>EBA:</strong> {{formatCurrency(item.EBA)}}</small>
          <v-divider class="mx-3" vertical />
          <small><strong>TPR BB:</strong> {{formatCurrency(item.TPRBB)}}</small>
          <v-divider class="mx-3" vertical />
          <small><strong>Ad BB:</strong> {{formatCurrency(item.AdBB)}}</small>
          <v-divider class="mx-3" vertical />
          <small><strong>Ad Scan:</strong> {{formatCurrency(item.AdScan)}}</small>
          <v-divider class="mx-3" vertical />
          <small><strong>TPR Scan:</strong> {{formatCurrency(item.TPRScan)}}</small>
          <v-divider class="mx-3" vertical />
          <small><strong>ePay Ad:</strong> {{formatCurrency(item.ePayAd)}}</small>
          <v-divider class="mx-3" vertical />
          <small><strong>ePay TPR:</strong> {{formatCurrency(item.ePayTPR)}}</small>
          <v-divider class="mx-3" vertical />
          <small><strong>Compete:</strong> {{formatCurrency(item.Compete)}}</small>
          <v-divider class="mx-3" vertical />
          <small><strong>PCAF:</strong> {{formatCurrency(item.PCAF)}}</small>
          <v-divider class="mx-3" vertical />
          <small><strong>Deal ID:</strong> {{item.DealID}}</small>
        </td>
      </template>

    </v-data-table>

    <v-divider />

    <v-toolbar flat color="#fafafa" class="pt-3">
      <v-row dense>
        <v-col cols="2">
          <v-select outlined dense background-color="#fff" label="Sort By Asc" :items="sortOptions"
            :menu-props="{ top: true, offsetY: true, maxHeight: 500 }" item-text="label" item-value="value"
            @change="changeSort" prepend-inner-icon="mdi-sort-ascending" />
        </v-col>
        <v-col cols="3" align="left">
          <v-text-field v-model="search" background-color="#fff" label="Search for Contract Item"
            prepend-inner-icon="mdi-magnify" autocomplete="off" outlined dense />
        </v-col>
        <v-spacer />
        <v-col cols="6" align="right">
          <v-menu v-if="selected.length" top :offset-y="offset">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" height="40" color="#E0E0E0">Selected ({{selected.length}})</v-btn>
            </template>
            <v-list>
              <v-list-item class="ml-1" style="color:green !important;" @click="setComponent('ContractItemEdit')">
                <v-list-item-title>
                  Update
                  <v-icon right color="green">mdi-update</v-icon>
                </v-list-item-title>
              </v-list-item>
              <v-list-item style="color:red !important;" @click="confirm('Delete', 'Item')">
                <v-list-item-title>
                  Remove
                  <v-icon right color="red">mdi-playlist-remove</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn :disabled="!isExistingContract" height="40" color="#0c67a5" class="white--text ml-2"
            @click="setComponent('ContractItemAdd')">
            Add Items<v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <Component :contract="contract" :getContract="getContract" :is="component" :isBulkEdit="isBulkEdit"
      :saveContract="saveContract" :selected="selected" :selectedItem="selectedItem" :suppliers="suppliers"
      :value.sync="modal" @clear-dates="clearTPRDates" />

    <ConfirmDialog :action="action" :copy="copyContract" :remove="remove" :selected="selected"
      :selectedItem="selectedItem" :type="type" :value.sync="confirmDialog" />

  </v-container>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'

export default {
  name: 'ContractView',

  components: {
    AdditionalTprDates: () => import('@/components/contracts/additional-tpr-dates'),
    ContractItemAdd: () => import('@/components/contracts/contract-item-add'),
    ContractItemEdit: () => import('@/components/contracts/contract-item-edit'),
    ContractItemCopy: () => import('@/components/contracts/contract-item-copy'),
    ShuttleToAd: () => import('@/components/contracts/shuttle-to-ad'),
    ShuttleToTpr: () => import('@/components/contracts/shuttle-to-tpr'),
    ConfirmDialog: () => import('@/components/shared/confirm-dialog')
  },

  mixins: [tableSettings, notification],

  data () {
    return {
      action: '',
      component: '',
      adDates: [],
      adGroups: [],
      confirmDialog: false,
      contract: {
        AdDate: null,
        AdGroup: { ID: 'ALL' },
        Supplier: { ID: 1 },
        ContractID: '',
        ContractScope: 1,
        ContractType: { ID: 2 },
        Department: { ID: '' },
        Manufacturer: { ID: '' },
        Name: '',
        TPRDates: [],
        TPRBeginDate: null,
        TPREndDate: null,
        TPRLump: null
      },
      contractID: '',
      contractTypes: [],
      departments: [],
      expanded: [],
      contractItems: [],
      loading: true,
      manufacturers: [],
      modal: false,
      offset: true,
      offsetY: true,
      pageSize: 500,
      search: '',
      selected: [],
      selectedItem: {},
      hasContractChanged: false,
      shuttleItem: {},
      supplierChange: false,
      suppliers: [],
      tableSort: '',
      type: ''
    }
  },

  computed: {
    formattedAdDate () {
      return this.contract.AdDate ? this.moment(this.contract.AdDate).format(this.$config.date_display) : ''
    },

    formattedTPRBeginDate () {
      return this.contract.TPRBeginDate ? this.moment(this.contract.TPRBeginDate).format(this.$config.date_display) : ''
    },

    formattedTPREndDate () {
      return this.contract.TPREndDate ? this.moment(this.contract.TPREndDate).format(this.$config.date_display) : ''
    },

    disabledMultiDates () {
      if (this.contract.TPRBeginDate === null && this.contract.TPREndDate === null) {
        return true
      } else if ((this.contract.TPRBeginDate !== null && this.contract.TPREndDate === null) || (this.contract.TPRBeginDate === null && this.contract.TPREndDate !== null)) {
        return true
      } else {
        return false
      }
    },

    disabled () {
      if ((this.contract.AdDate !== null) || (this.contract.TPRBeginDate !== null && this.contract.TPREndDate !== null)) {
        return false
      } else {
        return true
      }
    },

    headers () {
      return [
        { text: 'Item Code', align: 'left', sortable: true, filterable: true, value: 'Item.OrderCode', class: 'black--text' },
        { text: 'Pack', align: 'left', sortable: true, filterable: true, value: 'Item.Pack', class: 'black--text' },
        { text: 'Size', align: 'left', sortable: true, filterable: true, value: 'Item.Size', class: 'black--text' },
        { text: 'UPC', align: 'left', sortable: true, filterable: true, value: 'Item.UPC', class: 'black--text' },
        { text: 'Item Description', align: 'left', sortable: true, filterable: true, value: 'Item.Description', class: 'black--text', width: '40%' },
        { text: 'Item Status', align: 'left', sortable: true, filterable: true, value: 'Item.Status', class: 'black--text' },
        { text: 'Actions', sortable: false, filterable: true, align: 'center', value: 'action', class: 'black--text' }
      ]
    },

    isBulkEdit () {
      return this.selected.length > 0
    },

    isExistingContract () {
      return ('id' in this.$route.query)
    },

    scopes () {
      return [
        { label: 'Consolidated', value: 1 },
        { label: 'Shared', value: 2 },
        { label: 'Single', value: 3 }
      ]
    },

    sortOptions () {
      return [
        { label: 'Case Cost', value: 'Item.CaseCost' },
        { label: 'AMAP', value: 'AMAP' },
        { label: 'TPR BB', value: 'TPRBB' },
        { label: 'Ad BB', value: 'AdBB' },
        { label: 'Ad Scan', value: 'AdScan' },
        { label: 'TPR Scan', value: 'TPRScan' },
        { label: 'ePay Ad', value: 'ePayAd' },
        { label: 'ePay TPR', value: 'ePayTPR' },
        { label: 'Compete', value: 'Compete' },
        { label: 'PCAF', value: 'PCAF' }
      ]
    }
  },

  watch: {
    contract: {
      deep: true,
      handler (newValue, oldValue) {
        newValue !== oldValue ? this.hasContractChanged = true : this.hasContractChanged = false
        localStorage.setItem('adman_contractID', JSON.stringify(this.contract.ContractID))
        localStorage.setItem('adman_dept_name', JSON.stringify(this.contract.Department.Name))
      }
    },

    modal: {
      handler (value) {
        if (!value) {
          this.selectedItem = {}
        }
      }
    }
  },

  async created () {
    this.nonTableHeight = 405
    if (this.isExistingContract) {
      localStorage.getItem('adman_contractID')
        ? this.contract.ContractID = JSON.parse(localStorage.getItem('adman_contractID'))
        : this.contract.ContractID = this.$route.params.id
      this.getContract()
    } else {
      this.getContractOptions()
      this.loading = false
    }
  },

  methods: {
    setComponent (component, item) {
      if (item) { this.selectedItem = item }
      this.component = component
      this.modal = true
    },

    confirm (action, type) {
      this.action = action
      this.type = type
      this.confirmDialog = true
    },

    clearTPRDates () {
      this.contract.TPRDates = []
      this.saveContract()
    },

    setTPRLump (value) {
      if (value !== null) {
        this.contract.TPRLump = ((value * 100) / 100).toFixed(2)
      }
    },

    clear (value) {
      switch (value) {
        case 'adDate':
          this.contract.AdDate = null
          break
        case 'beginDate':
          this.contract.TPRBeginDate = null
          break
        case 'endDate':
          this.contract.TPREndDate = null
          break
      }
    },

    selectAdDate (date) {
      if (!date) return null
      this.contract.AdDate = this.moment(date, 'M/D/YYYY').format('YYYY-MM-DD')
    },

    setTPRBeginDate (date) {
      if (!date) return null
      this.contract.TPRBeginDate = this.moment(date, 'M/D/YYYY').format('YYYY-MM-DD')
    },

    setTPREndDate (date) {
      if (!date) return null
      this.contract.TPREndDate = this.moment(date, 'M/D/YYYY').format('YYYY-MM-DD')
    },

    allowedAdDates: val => ![1, 2, 3, 4, 5, 6].includes(new Date(val).getDay()),

    allowedTPRBegin: val => ![0, 1, 2, 3, 4, 5].includes(new Date(val).getDay()),

    allowedTPREnd: val => ![0, 1, 2, 3, 4, 6].includes(new Date(val).getDay()),

    changeSort (value) {
      this.tableSort = value
    },

    formatCurrency (value) {
      return '$' + parseFloat(value).toFixed(2)
    },

    contractTypeChange () {
      if (this.contract.ContractType.ID === 7) {
        this.contract.AdGroup.ID = 'ALL'
        this.contract.Supplier.ID = 7
        this.contract.ContractScope = 2
      } else {
        this.contract.Supplier.ID = 1
      }
      this.supplierSelect()
    },

    adGroupSelect (id) {
      this.adGroups.forEach(adGroup => {
        if (id === adGroup.id) {
          this.contract.Supplier.ID = adGroup.supplierCode
        }
      })
    },

    departmentSelect () {
      this.departments.forEach(department => {
        if (this.contract.Department.ID === department.id) {
          this.contract.Department = {
            Code: department.code,
            ID: department.id,
            Name: department.name
          }
        }
      })
    },

    async supplierSelect () {
      this.contract.AdGroup.ID = 'ALL'
      if (this.contract.Supplier.ID === 7) {
        this.contract.ContractScope = 2
      }
      const arr = []
      await this.$contracts.getDepartments(this.contract.Supplier.ID)
        .then(response => {
          if (this.isExistingContract) {
            this.departments = response
            this.departments.forEach(department => {
              if (department.name === JSON.parse(localStorage.getItem('adman_dept_name'))) {
                this.contract.Department = {
                  Code: department.code,
                  ID: department.id,
                  Name: department.name
                }
                arr.push(department)
              }
            })
            if (!arr.length) {
              this.contract.Department = {}
              this.notify('error', 'Department Not Available')
            }
          } else {
            this.departments = response
          }
        })
    },

    async getContractOptions () {
      const adDates = this.$contracts.getAdDates()
      const adGroups = this.$contracts.getAdGroups()
      const contractTypes = this.$contracts.getContractTypes()
      const departments = this.$contracts.getDepartments(this.isExistingContract ? this.contract.Supplier.ID : 1)
      const manufacturers = this.$contracts.getManufacturers()
      const suppliers = this.$contracts.getSuppliers()

      await Promise.all([adDates, adGroups, contractTypes, departments, manufacturers, suppliers])
        .then(response => {
          this.adDates = response[0]
          this.adGroups = response[1]
          this.contractTypes = response[2]
          this.departments = response[3]
          this.manufacturers = response[4]
          this.suppliers = response[5]
        })
    },

    async getContract () {
      await this.$contracts.getContract(this.contract.ContractID)
        .then(response => {
          this.contract = response
          this.contractItems = response.ContractItems
          this.expanded = this.contractItems
          this.selected = []
          this.loading = false
          this.getContractOptions()
          localStorage.setItem('adman_dept_name', JSON.stringify(response.Department.Name))
        }).catch(err => {
          console.log(err)
        })
      this.hasContractChanged = false
    },

    async saveContract () {
      if (this.isExistingContract) {
        await this.$contracts.updateContract(this.contract)
          .then(() => {
            this.notify('success', 'Contract Updated')
          }).catch(err => {
            try {
              this.notify('error', `${err.response.data.failure_reason}`)
            } catch {
              this.notify('error', 'TPR Not Updated')
            }
          })
        this.getContract(this.contract.ContractID)
      } else {
        await this.$contracts.saveContract(this.contract)
          .then(response => {
            this.contract.ContractID = response.ContractID
            this.$router.replace({ query: { id: this.contract.ContractID } })
            this.notify('success', 'Contract Saved')
          }).catch(err => {
            try {
              this.notify('error', `${err.response.data.failure_reason}`)
            } catch {
              this.notify('error', 'TPR Not Updated')
            }
          })
      }
    },

    async copyContract () {
      const errors = []
      const promises = []
      delete this.contract.ContractID
      this.contract.Name = `${this.contract.Name} - Copy`
      await this.$contracts.saveContract(this.contract)
        .then(async (response) => {
          this.contract.ContractID = response.ContractID
          this.contractItems.forEach(item => {
            item.ContractID = this.contract.ContractID
            promises.push(
              this.$contracts.saveItem(item)
                .then().catch(() => {
                  errors.push(`${item.Item.OrderCode} - ${item.Item.Description} Not Copied`)
                })
            )
          })
        }).catch(err => {
          try {
            this.notify('error', err.response.data.error)
          } catch {
            this.notify('error', 'Contract Copy Failed')
          }
        })
      await Promise.allSettled(promises)
      if (errors?.length) {
        this.showErrors(errors)
      } else {
        this.notify('success', 'Contract Successfully Copied')
      }
      this.$router.push({ name: 'ContractEdit', query: { id: this.contract.ContractID }, params: { id: this.contract.ContractID } })
      this.getContract(this.contract.ContractID)
    },

    async remove () {
      if (this.type === 'Contract') {
        await this.$contracts.deleteContract(this.contract)
          .then(() => {
            setTimeout(() => {
              this.notify('success', 'Contract Successfully Deleted')
              this.$router.push({ path: '/contracts' })
            }, 1000)
          }).catch(() => {
            this.notify('error', `Contract ${this.contract.ContractID} Not Deleted`)
          })
      } else if (this.type === 'Item' && this.selected.length) {
        const promises = []
        const errors = []
        this.selected.forEach(contractItem => {
          promises.push(
            this.$contracts.deleteItem(contractItem)
              .then().catch(() => {
                errors.push(`${contractItem.Item.OrderCode} Not Removed`)
              })
          )
        })
        await Promise.allSettled(promises)
        this.getContract()
        errors.length ? this.showErrors(errors) : this.notify('success', `${this.selected.length} Items Removed`)
      } else {
        this.$contracts.deleteItem(this.selectedItem)
          .then(() => {
            this.modal = false
            this.notify('success', 'Item Removed')
          }).catch(() => {
            this.notify('error', 'Unable to Remove Item')
          })
        this.getContract()
      }
    }
  }
}
</script>

<style scoped>
.updateRequired {
  /* box-shadow: 0px 0px 5px 3px rgb(0, 216, 0) !important; */
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
0% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
}

70% {
  transform: scale(1.05);
  box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
}

100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
}

.table >>> tbody tr.v-data-table__expanded__content {
  background: #eeeeee;
  box-shadow: none;
  cursor: default;
}

.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
  height: 25px;
  font-size: 15px;
}

small {
  font-size: 85%;
}

</style>
