export const tableSettings = {
  data () {
    return {
      containerWidth: 0,
      loading: false,
      page: 1,
      pageSize: 50,
      pageSizes: [50, 100, 250],
      nonTableHeight: 425,
      serverItemsLength: 0,
      showDialog: false,
      tableSize: 0,
      windowHeight: 0,
      disablePrevious: true,
      disableNext: false
    }
  },

  watch: {
    nonTableHeight: {
      deep: 'true',
      handler (newValue) {
        this.tableSize = this.windowHeight - newValue
      }
    },

    windowHeight: {
      handler () {
        this.tableSize = this.windowHeight - this.nonTableHeight
      }
    }
  },

  created () {
    this.tableSize = this.windowHeight - this.nonTableHeight
    window.addEventListener('resize', this.resize)
    this.resize()
  },

  methods: {
    resize () {
      this.windowHeight = window.innerHeight
    }
  }
}
