import { store } from '../../store/index'
import { Auth } from 'aws-amplify'

// Initial "state"
const state = {
  loaded: false,
  valid: false
}

const getters = {
  valid (state) {
    return state.valid
  }
}

const mutations = {
  set_valid (state, payload) {
    state.valid = payload
  }
}

const actions = {
  async valid (context) {
    const user = store.state.user
    if (Object.entries(user).length === 0 && user.constructor === Object) {
      const valid = await Auth.currentAuthenticatedUser({ bypassCache: false })
        .then(response => {
          context.dispatch('load', response, { root: true })
          context.commit('set_valid', true)
          return true
        })
        .catch(() => {
          context.commit('set_valid', false)
          return false
        })
      return valid
    } else {
      context.dispatch('load', user, { root: true })
      context.commit('set_valid', true)
      return true
    }
  },

  load (context, user) {
    context.commit('set_user', user, { root: true })

    if (user.signInUserSession.accessToken.payload['cognito:groups'].includes('AdMan_Admin')) {
      context.commit('set_admin', true, { root: true })
    }
    if (user.signInUserSession.accessToken.payload['cognito:groups'].includes('AdMan_Billing')) {
      context.commit('set_billing_user', true, { root: true })
    }

    if (user.signInUserSession.accessToken.payload['cognito:groups'].includes('AdMan_TPR_Bulk')) {
      context.commit('set_tpr_bulk_user', true, { root: true })
    }

    context.dispatch('setLoaded', true, { root: true })
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
