import { base } from './base'
// import { store } from '@/store/index'

export default class contracts {
  static async getContracts (start, end) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: `/contracts/getContractList/${start}/${end}`,
      method: 'GET'
    })
  }

  static async getContract (contractID) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: `/contracts/contract/${contractID}`,
      method: 'GET'
    })
  }

  static async getContractTypes () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/contractTypes',
      method: 'GET'
    })
  }

  static async saveContract (saveContract) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/contract',
      method: 'POST',
      data: saveContract
    })
  }

  static async updateContract (updateContract) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/contract',
      method: 'PUT',
      data: updateContract
    })
  }

  static async deleteContract (deleteContract) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/contract',
      method: 'DELETE',
      data: deleteContract
    })
  }

  static async getByItemCode (itemCode, supplierCode) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: `/items/${itemCode}/${supplierCode}`,
      method: 'GET'
    })
  }

  static async getProductLinkGroup (itemCode, supplierCode) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: `/productLinkGroup/${itemCode}/${supplierCode}`,
      method: 'GET'
    })
  }

  static async saveItem (saveItem) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/contracts/item',
      method: 'POST',
      data: saveItem
    })
  }

  static async updateItem (updateItem) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/contracts/item',
      method: 'PUT',
      data: updateItem
    })
  }

  static async deleteItem (deleteItem) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/contracts/item',
      method: 'DELETE',
      data: deleteItem
    })
  }

  static async getSuppliers () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/suppliers',
      method: 'GET'
    })
  }

  static async getAdGroups () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/adGroups',
      method: 'GET'
    })
  }

  static async getDepartments (supID) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: `/departments/${supID}`,
      method: 'GET'
    })
  }

  static async getManufacturers () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/manufacturers',
      method: 'GET'
    })
  }

  static async getAdDates () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/ad_dates',
      method: 'GET'
    })
  }
}
