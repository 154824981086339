var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "section",
        { staticClass: "pa-6" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dark: "", flat: "", color: "#1C3144" } },
                        [_c("v-toolbar-title", [_vm._v("Reports")])],
                        1
                      ),
                      _c("v-data-table", {
                        staticClass: "table",
                        attrs: {
                          height: _vm.tableSize,
                          "items-per-page": 100,
                          items: _vm.reports,
                          headers: _vm.reportHeaders,
                          "hide-default-footer": "",
                          "no-data-text": "Reports Currently Not Available",
                          loading: _vm.reportsLoading,
                          "loading-text": "Loading Reports...Please Wait.",
                          "fixed-header": "",
                          dense: ""
                        },
                        on: {
                          "update:items": function($event) {
                            _vm.reports = $event
                          },
                          "click:row": _vm.getParameters
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "footer",
                            fn: function() {
                              return [
                                _c(
                                  "v-toolbar",
                                  { attrs: { flat: "" } },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          depressed: "",
                                          color: "#fff",
                                          height: "50"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.metabase(
                                              "https://reports.iprosystems.com/collection/40"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            "max-height": "50",
                                            "max-width": "50",
                                            src: require("../assets/metabase-logo.png"),
                                            alt: "Metabase Logo"
                                          }
                                        }),
                                        _c(
                                          "h1",
                                          {
                                            staticStyle: {
                                              color: "#0d68a5",
                                              "font-weight": "300"
                                            }
                                          },
                                          [_vm._v("Reports")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          depressed: "",
                                          color: "#fff",
                                          height: "50"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.metabase(
                                              "https://reports.iprosystems.com/collection/49"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            "max-height": "50",
                                            "max-width": "50",
                                            src: require("../assets/metabase-logo.png"),
                                            alt: "Metabase Logo"
                                          }
                                        }),
                                        _c(
                                          "h1",
                                          {
                                            staticStyle: {
                                              color: "#0d68a5",
                                              "font-weight": "300"
                                            }
                                          },
                                          [_vm._v("Exports")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-spacer")
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dark: "", flat: "", color: "#1C3144" } },
                        [
                          _c("v-toolbar-title", [_vm._v("Available Reports")]),
                          _c("v-spacer"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "mx-1",
                                            attrs: { icon: "" },
                                            on: {
                                              click: _vm.getAvailableReports
                                            }
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("mdi-refresh")])],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_c("span", [_vm._v("Refresh")])]
                          )
                        ],
                        1
                      ),
                      _c("v-data-table", {
                        staticClass: "table",
                        attrs: {
                          height: _vm.tableSize - 64,
                          "items-per-page": 1000,
                          items: _vm.reportsAvailable,
                          headers: _vm.availableReportsHeaders,
                          "no-data-text": "No Finished Reports Available",
                          "sort-by": "execution_time",
                          "sort-desc": "",
                          "hide-default-footer": "",
                          loading: _vm.queueLoading,
                          "item-key": "id",
                          "fixed-header": "",
                          dense: ""
                        },
                        on: { "click:row": _vm.downloadLink },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "top",
                              fn: function() {
                                return [
                                  _c(
                                    "v-toolbar",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            xs: "12",
                                            sm: "10",
                                            md: "10",
                                            lg: "8"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mt-7",
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              "background-color": "white",
                                              "prepend-inner-icon":
                                                "mdi-filter-outline",
                                              label: "Filter Reports"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider")
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "item.report_name",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.report_name) +
                                      " " +
                                      _vm._s(
                                        "invoice_number" in item
                                          ? "• Invoice #" + item.invoice_number
                                          : "batch_number" in item
                                          ? "• Invoice Batch #" +
                                            item.batch_number
                                          : "AdGroupName" in item
                                          ? "• " + item.AdGroupName
                                          : ""
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.execution_time",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatTime(item.execution_time)
                                      )
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item.status",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.status === "Complete"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "#00b24a" } },
                                        [
                                          _vm._v(
                                            "mdi-checkbox-marked-circle-outline"
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  item.status === "Running"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "#f9a825" } },
                                        [_vm._v("mdi-clock-outline")]
                                      )
                                    : _vm._e(),
                                  item.status === "Failed"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "red" } },
                                        [_vm._v("mdi-alert-outline")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "footer",
                              fn: function() {
                                return [
                                  _c("v-toolbar", { attrs: { flat: "" } })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ReportBuilder", {
        key: _vm.componentKey,
        attrs: {
          reportParameters: _vm.reportParameters,
          reportDimensions: _vm.reportDimensions,
          value: _vm.modal,
          report: _vm.report
        },
        on: {
          submit: [
            function($event) {
              return _vm.notify($event)
            },
            _vm.submitReport
          ],
          "refresh-queue": _vm.getAvailableReports,
          "update:value": function($event) {
            _vm.modal = $event
          },
          add: _vm.addParameterValue
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }