<template>
    <v-container class="ma-0">
        <AccrualDetail
            :value.sync="accrualDetail"
            :selectedAccrual="selectedAccrual"
            :ads="ads"
            :manufacturers="manufacturers"
            :suppliers="suppliers"
            :getAccruals="getAccruals"
            :formatCurrency="formatCurrency"
            :toggle="toggle"
            :edit="edit"
        />

        <v-data-table
            sort-by="adId"
            :height="tableSize"
            :loading="loading"
            :headers.sync="headers"
            :items.sync="accruals"
            :items-per-page="pageSize"
            :expanded="expanded"
            :search="search"
            loader-height="2"
            color="#fff"
            class="table"
            fixed-header
            hide-default-footer
            dense
            loading-text="Loading Accruals... Please Wait"
            no-results-text="No Matching Accruals Found"
            no-data-text="Enter Accrual ID To View Existing Accruals"
            @click:row="openAccrualModal"
        >
            <template v-slot:top>
                <v-toolbar flat color="#fafafa" style="cursor:default;">
                    <v-toolbar-title class="ml-2 title"
                        >Meat-Accruals</v-toolbar-title
                    >
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-col cols="2">
                        <v-text-field
                            class="mt-7"
                            background-color="#fff"
                            label="Filter Accruals"
                            @keyup.enter="getAccrualById"
                            prepend-inner-icon="mdi-filter-outline"
                            autocomplete="off"
                            dense
                            outlined
                            v-model="search"
                            clearable
                        >
                        </v-text-field>
                    </v-col>
                    <v-menu :offset-y="offset">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                color="#0D68A5"
                                height="40"
                                class="white--text ml-2"
                                style="background: #0D68A5;"
                            >
                                Accrual<v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="openAccrualModal('Standard')">
                                <v-list-item-title>Standard</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click="openAccrualModal('Non-Standard')"
                            >
                                <v-list-item-title
                                    >Non-Standard</v-list-item-title
                                >
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-spacer />
                    <v-toolbar-title
                        class="ml-2 title"
                        :class="{ active: toggle === 'standard' }"
                        @click="toggle = 'standard'"
                    >
                        Standard
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical />
                    <v-toolbar-title
                        class="title mr-4"
                        :class="{ active: toggle === 'non-standard' }"
                        @click="toggle = 'non-standard'"
                    >
                        Non-Standard
                    </v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>
            </template>

            <template v-slot:[`item.beginning_date`]="{ item }">
                <span>{{ formatDates(item.beginning_date) }}</span>
            </template>

            <template v-slot:[`item.ending_date`]="{ item }">
                <span>{{ formatDates(item.ending_date) }}</span>
            </template>

            <template v-slot:[`item.billing_date`]="{ item }">
                <span>{{ formatDates(item.billing_date) }}</span>
            </template>

            <template v-slot:footer>
                <v-divider></v-divider>
                <v-toolbar flat color="#fafafa">
                    <v-row align="center">
                        <v-spacer />
                        <v-col cols="2" style="max-width:150px;">
                            <v-select
                                class="mt-6"
                                dense
                                :items="pageSizes"
                                label="Items Per Page"
                                select
                                v-model="pageSize"
                                @change="getAccruals(toggle)"
                                :menu-props="{
                                    top: true,
                                    offsetY: true,
                                    maxHeight: 500,
                                }"
                            >
                            </v-select>
                        </v-col>
                        <v-btn
                            icon
                            large
                            @click="previous()"
                            :disabled="disablePrevious"
                            class="mx-2"
                        >
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <small>Page {{ page }}</small>
                        <v-btn
                            icon
                            large
                            @click="next()"
                            :disabled="disableNext"
                            class="mx-2"
                        >
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-row>
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import accruals from "../axios/accruals/endpoints";
import ads from "../axios/adPlanner/endpoints";
import contracts from "../axios/contractManager/endpoints";
import AccrualDetail from "../components/meatAccrual/accrualDetail";
import moment from "moment";

export default {
    name: "Accruals",
    data() {
        return {
            selectedAccrual: {},
            accrualDetail: false,
            accrualItemCopy: false,
            alert: false,
            disableNext: false,
            disablePrevious: true,
            edit: false,
            editAccrual: false,
            item: {},
            loading: false,
            newAccrual: false,
            notification: false,
            offset: true,
            page: 1,
            pageSize: 50,
            pageSizes: [50, 100, 250],
            search: "",
            tableSize: "",
            toggle: "standard",
            windowHeight: "",
            ads: [],
            manufacturers: [],
            suppliers: [],
            accruals: [],
            accrualItems: [],
            expanded: [],
            headers: [],
            stdHeaders: [
                {
                    text: "",
                    sortable: false,
                    filterable: false,
                    value: "",
                },
                {
                    text: "Accrual ID",
                    sortable: true,
                    filterable: true,
                    value: "id",
                    class: "black--text",
                },
                {
                    text: "Accrual Type",
                    sortable: true,
                    filterable: true,
                    value: "accrual_type",
                    class: "black--text",
                },
                {
                    text: "Ad Name",
                    sortable: true,
                    filterable: true,
                    value: "ad_name",
                    class: "black--text",
                },
            ],
            nonStdHeaders: [
                {
                    text: "",
                    sortable: false,
                    filterable: false,
                    value: "",
                },
                {
                    text: "Accrual ID",
                    sortable: true,
                    filterable: true,
                    value: "id",
                    class: "black--text",
                },
                {
                    text: "Accrual Type",
                    sortable: true,
                    filterable: true,
                    value: "accrual_type",
                    class: "black--text",
                },
                {
                    text: "Supplier",
                    sortable: true,
                    filterable: true,
                    value: "supplier.name",
                    class: "black--text",
                },
                {
                    text: "Begin Date",
                    sortable: true,
                    filterable: true,
                    value: "beginning_date",
                    class: "black--text",
                },
                {
                    text: "End Date",
                    sortable: true,
                    filterable: true,
                    value: "ending_date",
                    class: "black--text",
                },
                {
                    text: "Billing Date",
                    sortable: true,
                    filterable: true,
                    value: "billing_date",
                    class: "black--text",
                },
                {
                    text: "",
                    value: "data-table-expand",
                },
            ],
        };
    },
    watch: {
        toggle: {
            handler(value) {
                if (value === "standard") {
                    this.headers = this.stdHeaders;
                    this.page = 1;
                    this.getAccruals(value);
                } else if (value === "non-standard") {
                    this.headers = this.nonStdHeaders;
                    this.page = 1;
                    this.getAccruals(value);
                } else if (value === "Standard") {
                    this.headers = this.stdHeaders;
                } else if (value === "Non-Standard") {
                    this.headers = this.nonStdHeaders;
                }
            },
        },
        search: {
            handler(value) {
                if (!value) {
                    this.headers = this.stdHeaders;
                    this.toggle = "standard";
                }
            },
        },
        accrualDetail: {
            handler(value) {
                if (!value) {
                    this.selectedAccrual = {};
                    this.accrualHTML = "";
                    this.edit = false;
                }
            },
        },
        windowHeight: {
            handler() {
                this.tableSize = this.windowHeight - 245;
            },
        },
    },
    components: {
        AccrualDetail,
    },
    created() {
        this.headers = this.stdHeaders;
        // this.tableSize = this.windowHeight - 215
        window.addEventListener("resize", this.resize);
        this.resize();
        this.getAccruals("standard");
        this.getAds();
        this.getManufacturers();
        this.getSuppliers();
    },
    methods: {
        resize() {
            this.windowHeight = window.innerHeight;
        },
        formatCurrency(value) {
            return (
                "$" +
                parseFloat(value)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            );
        },
        previous() {
            this.page = this.page - 1;
            if (this.page < 1) {
                this.page = 1;
            }
            this.getAccruals(this.toggle);
        },
        next() {
            this.page = this.page + 1;
            this.getAccruals(this.toggle);
        },
        formatDates(date) {
            return date ? moment(date).format("MM/DD/YYYY") : "";
        },
        getAds() {
            var adGroup = "";
            var adDate = "";
            ads.getAds(0, 1500, adGroup, adDate).then((response) => {
                this.ads = response;
            });
        },
        getManufacturers() {
            contracts.getManufacturers().then((response) => {
                this.manufacturers = response;
            });
        },
        getSuppliers() {
            contracts.getSuppliers().then((response) => {
                this.suppliers = response;
            });
        },
        openAccrualModal(accrual) {
            if (typeof accrual !== "string") {
                this.selectedAccrual = accrual;
                this.edit = true;
                this.accrualDetail = true;
            } else {
                this.accrualDetail = true;
                this.selectedAccrual.accrual_type = accrual;
            }
        },
        async getAccrualById() {
            var accrual = {};
            if (this.search) {
                await accruals.getAccrualById(this.search).then((response) => {
                    this.toggle = response.accrual_type;
                    accrual = response;
                });
                this.accruals = [];
                this.accruals.push(accrual);
            }
        },
        async getAccruals(type) {
            // this.accruals = []
            this.loading = true;
            const start = (this.page - 1) * this.pageSize;
            const end = this.pageSize;
            await accruals.getAccruals(type, start, end).then((response) => {
                this.accruals = response;
                if (this.accruals.length < this.pageSize) {
                    this.disableNext = true;
                } else {
                    this.disableNext = false;
                }
                if (start === 0) {
                    this.disablePrevious = true;
                } else {
                    this.disablePrevious = false;
                }
                this.loading = false;
            });
        },
    },
};
</script>

<style scoped>
.active {
    color: #0d68a5;
}
</style>
