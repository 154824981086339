var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c(
        "v-toolbar",
        {
          staticStyle: { cursor: "default" },
          attrs: { flat: "", color: "#fafafa" }
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "ml-2 mt-4 title", on: { click: _vm.getAds } },
                [_vm._v("Ads")]
              ),
              _c("v-divider", {
                staticClass: "mx-4",
                attrs: { inset: "", vertical: "" }
              }),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-autocomplete", {
                    staticClass: "mt-2",
                    attrs: {
                      "auto-select-first": "",
                      items: _vm.adGroups,
                      "item-text": "description",
                      "item-value": "id",
                      "background-color": "#fff",
                      label: "Ad Group Filter",
                      placeholder: "Select Ad Group",
                      autocomplete: "off",
                      outlined: "",
                      dense: "",
                      clearable: "",
                      "prepend-inner-icon": "mdi-filter-outline"
                    },
                    on: { change: _vm.getAds },
                    model: {
                      value: _vm.adGroup,
                      callback: function($$v) {
                        _vm.adGroup = $$v
                      },
                      expression: "adGroup"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      label: "Ad Date Filter",
                      placeholder: "Enter Ad Date",
                      autocomplete: "off",
                      clearable: "",
                      "prepend-inner-icon": "mdi-filter-outline"
                    },
                    model: {
                      value: _vm.adDate,
                      callback: function($$v) {
                        _vm.adDate = $$v
                      },
                      expression: "adDate"
                    }
                  })
                ],
                1
              ),
              _c("v-divider", {
                staticClass: "mx-4",
                attrs: { inset: "", vertical: "" }
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "white--text mt-3 ml-2",
                                attrs: { color: "#0D68A5", height: "40" },
                                on: {
                                  click: function($event) {
                                    return _vm.setComponent("AdEdit")
                                  }
                                }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Create New Ad")])]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        staticClass: "table",
        staticStyle: { cursor: "default" },
        attrs: {
          "sort-by": "adId",
          height: _vm.tableSize,
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.ads,
          "items-per-page": _vm.pageSize,
          "loader-height": "2",
          "show-select": "",
          "item-key": "adId",
          color: "#fff",
          "fixed-header": "",
          "hide-default-footer": "",
          dense: "",
          "loading-text": "Loading Ads... Please Wait",
          "no-results-text": "No Matching Ads Found",
          "no-data-text": "No Matching Ads Found"
        },
        on: {
          "update:items": function($event) {
            _vm.ads = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "span",
                    { staticClass: "noWrap" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: { icon: "", small: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.setComponent(
                                                "AdEdit",
                                                item
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", { staticClass: "edit" }, [
                                          _vm._v("mdi-square-edit-outline")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Edit Ad")])]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: { small: "", icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.setComponent(
                                                "AdCopy",
                                                item
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-content-duplicate")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Copy Ad")])]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.adDate",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [_vm._v(_vm._s(_vm.formatDates(item.adDate)))])
                ]
              }
            },
            {
              key: "item.dateCreated",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(_vm._s(_vm.formatDates(item.dateCreated)))
                  ])
                ]
              }
            },
            {
              key: "footer",
              fn: function() {
                return [_c("v-divider")]
              },
              proxy: true
            },
            {
              key: "item.readyForUpload",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.setComponent(
                                            "A2Ready",
                                            item
                                          )
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: item.readyForUpload
                                            ? "green"
                                            : "red"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.readyForUpload
                                                ? "mdi-checkbox-marked-circle-outline"
                                                : "mdi-close"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v("Change Status")])]
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "#fafafa" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _vm.selected.length
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { height: "40", color: "#0c67a5" },
                          on: {
                            click: function($event) {
                              return _vm.setComponent("FeatureManagement")
                            }
                          }
                        },
                        [
                          _vm._v(" Manage Features"),
                          _c(
                            "v-icon",
                            { staticClass: "ml-1", attrs: { color: "#fff" } },
                            [_vm._v("mdi-square-edit-outline")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-spacer"),
              _vm.selected.length
                ? _c("v-col", { attrs: { cols: "2", align: "center" } }, [
                    _c("i", [
                      _c("p", { staticClass: "blue--text" }, [
                        _vm._v(_vm._s(_vm.selected.length) + " Selected")
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-col",
                { staticStyle: { "max-width": "150px" }, attrs: { cols: "2" } },
                [
                  _c("v-select", {
                    staticClass: "mt-6",
                    attrs: {
                      dense: "",
                      items: _vm.pageSizes,
                      label: "Items Per Page",
                      select: "",
                      "menu-props": { top: true, offsetY: true, maxHeight: 500 }
                    },
                    model: {
                      value: _vm.pageSize,
                      callback: function($$v) {
                        _vm.pageSize = $$v
                      },
                      expression: "pageSize"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { icon: "", large: "", disabled: _vm.disablePrevious },
                  on: {
                    click: function($event) {
                      return _vm.previous()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { icon: "", large: "", disabled: _vm.disableNext },
                  on: {
                    click: function($event) {
                      return _vm.next()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(_vm.component, {
        key: _vm.componentKey,
        tag: "Component",
        attrs: {
          value: _vm.modal,
          selectedAd: _vm.selectedAd,
          selectedAds: _vm.selected,
          getAds: _vm.getAds,
          adGroup: _vm.adGroup,
          adDate: _vm.adDate
        },
        on: {
          "update:value": function($event) {
            _vm.modal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }