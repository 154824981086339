import { base } from './base'

export default class limiter {
  static async getLimiterConfig (config) {
    return base({
      url: `/throttle_settings/${config}`,
      method: 'GET'
    })
  }
}
