var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-6" },
    [
      _c(
        "v-row",
        { staticClass: "mb-12", attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-textarea", {
                attrs: {
                  align: "center",
                  outlined: "",
                  dense: "",
                  "background-color": "#fff",
                  label: "Comments",
                  rows: "3"
                },
                model: {
                  value: _vm.store.comments,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "comments", $$v)
                  },
                  expression: "store.comments"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }