<template>
  <v-container class="ma-0 pa-0" fluid v-resize="onResize">
    <v-data-table :headers="headers" :height="tableHeight" :items="items" :loading="loading" :no-data-text="
      this.items.length ? 'Enter Order Code' : 'No Matching Items Found'" :items-per-page="20"
      @click:row="viewItem" dense fixed-header hide-default-footer
      item-key="item_code" v-model="selected" style="cursor:pointer;">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Price Book Maintenance</v-toolbar-title>
          <v-divider vertical inset class="mx-4" />
          <v-row dense>
            <v-col md="4" lg="3" xl="2">
              <v-text-field class="mt-2" label="Order Code" outlined dense background-color="white" v-model="itemSearch"
                prepend-inner-icon="mdi-magnify" autocomplete="off" clearable />
            </v-col>
            <v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="mt-2 mx-2 white--text" color="#0D68A5" height="38"
                    @click="component='PriceBookItem', modal=true">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>DSD Item</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider />
      </template>

      <template v-slot:[`item.case_cost`]="{ item }">
        ${{ item.case_cost | format }}
      </template>

      <template v-slot:[`item.allowance`]="{ item }">
        ${{ item.allowance | format }}
      </template>

      <template v-slot:[`item.srp`]="{ item }">
        {{ item.srp_count }} @ ${{ item.srp | format }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-menu :disabled="item.dsd" offset-y>
          <template v-slot:activator="{ on }">
            <v-chip v-on="on" class="mr-4" small dark :color="item.status == 'A' ? 'green' : 'red'">
              <span class="text-capitalize"
                style="display: inline-block; margin: 0 auto; width: 90px; text-align: center">
                <span>{{ item.status | status }}</span>
              </span>
            </v-chip>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item :disabled="item.status === status.id" v-for="(status, index) in statuses" :key="index"
                @click="saveItem(item, status)">
                <v-list-item-title>{{ status.text}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
    <v-divider/>
    <Modal
      :departments="departments"
      :is="component"
      :key="modalKey"
      :selected="selected"
      :selectedItem="selectedItem"
      :suppliers="suppliers"
      :value.sync="modal"
      @getDepartments="getDepartmentsBySupplier"
      @create="createItem"
      @delete="deleteItem"
      @save="saveItem"
      @refresh="getPriceBookItems"
      :dsdVendors="dsdVendors"
    />

  </v-container>
</template>

<script>
import { debounce } from 'lodash'
import { notification } from '@/mixins/notification'

export default {
  name: 'PriceBook',

  filters: {
    status: function (value) {
      if (value == 'A') return 'Active'
      if (value == 'D') return 'Discontinued'
    },
    format: function (value) {
      if (!value) return '0.00'
      return parseFloat(value).toFixed(2)
    }
  },

  mixins: [notification],

  components: {
    PriceBookItem: () => import('@/components/maintenance/priceBook/priceBookItem'),
    MultiSelect: () => import('@/components/maintenance/priceBook/priceBookItem')
  },

  data() {
    return {
      confirmStatus: false,
      component: '',
      departments: [],
      dsdVendors: [],
      itemEdit: false,
      items: [],
      itemSearch: '',
      loading: false,
      modal: false,
      modalKey: '',
      selected: [],
      selectedItem: {},
      suppliers: [],
      tableHeight: ''
    }
  },

  computed: {
    headers() {
      return [{
          text: '',
          align: 'start',
          sortable: true,
          filterable: true,
          width: '1%'
        },
        {
          text: 'Order Code',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'black--text',
          value: 'order_code',
          width: 'auto'
        },
        {
          text: 'Supplier Name',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'black--text',
          value: 'supplier.name',
          width: 'auto'
        },
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'black--text',
          value: 'description',
          width: 'auto'
        },
        {
          text: 'Pack',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'black--text',
          value: 'pack',
          width: 'auto'
        },
        {
          text: 'Size',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'black--text',
          value: 'size',
          width: 'auto'
        },
        {
          text: 'UPC',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'black--text',
          value: 'upc',
          width: 'auto'
        },
        {
          text: 'Department',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'black--text',
          value: 'department.name',
          width: 'auto'
        },
        {
          text: 'Case cost',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'black--text',
          value: 'case_cost',
          width: 'auto'
        },
        {
          text: 'Allowance',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'black--text',
          value: 'allowance',
          width: 'auto'
        },
        {
          text: 'SRP',
          align: 'center',
          sortable: true,
          filterable: true,
          class: 'black--text',
          value: 'srp',
          width: 'auto'
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          filterable: true,
          class: 'black--text',
          value: 'status',
          width: 'auto'
        }
      ]
    },
    statuses() {
      return [{
          text: 'Active',
          id: 'A'
        },
        {
          text: 'Discontinued',
          id: 'D'
        }
      ]
    }
  },

  watch: {
    itemSearch: {
      handler: debounce(function (value) {
        if (value) {
          this.loading = true
          this.getPriceBookItems()
        } else {
          this.items = []
        }
      }, 500)
    },

    modal: {
      immediate: true,
      handler (value) {
        this.modalKey += 1
        if (!value) {
          this.selectedItem = {}
        }
      }
    }
  },

  created() {
    this.tableHeight = window.innerHeight - 223
    this.getSuppliers()
    this.getDSDVendors()
  },

  methods: {
    onResize() {
      this.tableHeight = window.innerHeight - 223
    },

    getRows() {
      const numCols = 5
      const rows = this.fields.reduce((acc, curr, index) => {
        const i = index
        const row = Math.floor(i / numCols)
        const mod = i % numCols
        if (mod === 0) {
          acc.push([])
        }
        acc[row].push(curr)
        return acc
      }, [])
      return rows
    },

    async getDSDVendors () {
      const response = await this.$maintenance.getDSDVendors()
      this.dsdVendors = this._.sortBy(response, 'code')
    },

    async getSuppliers() {
      const response = await this.$contracts.getSuppliers()
      this.suppliers = response
    },

    async getDepartmentsBySupplier(suppierCode) {
      const response = await this.$contracts.getDepartments(suppierCode)
      this.departments = response
    },

    async getPriceBookItems() {
      try {
        const response = await this.$maintenance.getPriceBookItem(this.itemSearch)
        this.items = response
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    viewItem (item) {
      if (item.dsd) {
        this.selectedItem=item
        this.component='PriceBookItem'
        this.modal=true
      }
    },

    async createItem (item) {
      try {
        await this.$maintenance.createPriceBookItem(item)
        this.notify('success', 'Item Created Successfully')
        this.modal = false
      } catch (e) {
        this.notify('error', 'Error Creating Item.')
      } finally {
        this.itemSearch = item.order_code
        this.getPriceBookItems()
      }
    },

    async saveItem (item, status) {
      if (!item.dsd) item.status = status.id
      try {
        await this.$maintenance.savePriceBookItem(item)
        this.notify('success', 'Item Saved Successfully')
        this.modal = false
      } catch (e) {
        this.notify('error', 'Error Saving Item.')
      } finally {
        this.getPriceBookItems()
      }
    },

    async deleteItem (item) {
      try {
        await this.$maintenance.deletePriceBookItem(item)
        this.notify('success', 'Item Deleted Successfully')
      } catch (e) {
        this.notify('error', 'Error Deleting Item')
      } finally {
        this.getPriceBookItems()
        this.modal = false
      }
    }
  }
}
</script>
