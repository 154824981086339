<template>
  <v-container class="ma-0 pa-6">
    <v-row justify="center" class="mb-4">
      <v-col cols="3">
        <small>
          The date the account closed. Entering a date here will close this store to all further activity,
          including the importing of movement data.
        </small>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-menu :close-on-content-click="false" :nudge-right="8" :nudge-top="35" transition="scale-transition" offset-y
        max-width="290px" min-width="290px">
        <template v-slot:activator="{ on }">
          <v-col cols="3">
            <v-text-field background-color="#fff" dense outlined label="Termination Date" @change="setTerminationDate"
              :value="terminationDate" autocomplete="off">
              <template v-slot:prepend-inner>
                <v-icon v-on="on" class="calendar">mdi-calendar</v-icon>
              </template>
              <template v-slot:append v-if="store.account_closed_date !== null">
                <v-icon @click="clearTerminationDate" tabindex="-1" class="clear">mdi-close</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </template>
        <v-date-picker color="#033f63" no-title show-current="false"
          v-model="store.account_closed_date" autocomplete="off">
        </v-date-picker>
      </v-menu>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  name: 'TerminateAccount',
  data () {
    return {
      offset: false,
      showCurrent: true
    }
  },
  props: [
    'store'
  ],
  computed: {
    terminationDate () {
      return this.store.account_closed_date ? moment(this.store.account_closed_date).format('MM/DD/YYYY') : ''
    }
  },
  methods: {
    clearTerminationDate () {
      this.store.account_closed_date = null
    },
    setTerminationDate (value) {
      if (value !== '') {
        this.store.account_closed_date = moment(value).format('YYYY-MM-DD')
      } else {
        this.store.account_closed_date = null
      }
    }
  }
}
</script>
