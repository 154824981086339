<template>
  <v-container class="ma-0">
    <v-data-table :height="tableSize" :loading="loading" :headers="headers" :items.sync="shuttles" :expanded="expanded"
      :search="search" :items-per-page="pageSize" :single-select="singleSelect" v-model="selected" loader-height="2"
      color="#fff" class="table" loading-text="Loading Shuttles... Please wait"
      no-results-text="No Matching Shuttles Found" no-data-text="No Shuttles Invoices" fixed-header hide-default-footer
      show-expand :single-expand="singleExpand" item-key="shuttleId" dense>
      <template v-slot:top>
        <v-toolbar flat color="#fafafa" style="cursor:default">
          <v-toolbar-title class="title">Shuttle Status</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-col md="4" lg="3" xl="3">
            <v-autocomplete outlined dense background-color="#fff" prepend-inner-icon="mdi-filter-outline" label="Select User" class="mt-7"
              item-text="" :items="users" v-model="selectedUser"/>
          </v-col>
          <v-col md="3" lg="2" xl="2">
            <v-text-field background-color="#fff" class="mt-7" label="Filter By Contract ID" prepend-inner-icon="mdi-filter-outline"
              outlined dense v-model="search" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col md="3" lg="2" xl="2">
            <v-text-field v-model="shuttleDate" class="mt-7" background-color="#fff" prepend-inner-icon="mdi-calendar"
              label="Shuttle Date Filter" placeholder="M/D/YYYY" autocomplete="off" dense outlined>
              <template v-slot:append>
                <v-btn small icon @click="getShuttlesByUser(selectedUser, shuttleDate)">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-toolbar>
        <v-divider></v-divider>
      </template>

      <template v-slot:[`item.submitTime`]="{ item }">
        <span>{{formatSubmitTime(item.submitTime)}}</span>
      </template>

      <template v-slot:[`item.shuttleStatus`]="{ item }">
        {{ item.totalComplete }} of {{ item.totalGroups }} Completed
      </template>

      <template v-slot:[`item.totalFailed`]="{ item }">
        <v-chip :color="item.totalFailed > 0 ? '#D32F2F' : '#00B24A'" class="white--text" >
          {{ item.totalFailed }}
        </v-chip>
      </template>

      <template v-slot:[`item.details`]="{ item }">
        <v-btn icon @click="getShuttleDetails(item.shuttleId)">
          <v-icon dense>mdi-text-box-search-outline</v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td v-if="item.shuttleType === 'Ad'" colspan="12" style="padding:0px;">
          <v-data-table :items-per-page="pageSize" :items="item.adGroups" :headers="adHeaders" height="350"
            class="table" hide-default-footer fixed-header dense>

          <template v-slot:[`item.payload.retail`]="{ item }">
            {{item.payload.retCt}} @ {{formatCurrency(item.payload.retail)}}
          </template>

          <template v-slot:[`item.payload.adFee`]="{ item }">
            {{formatCurrency(item.payload.adFee)}}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip v-if="item.status === 'Complete'" top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-6" color="#00b24a">mdi-check-all</v-icon>
              </template>
              <span>Complete</span>
            </v-tooltip>
            <v-tooltip v-else-if="item.status === 'Pending'" top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-6" color="#f9a825">mdi-clock-outline</v-icon>
              </template>
              <span>Pending</span>
            </v-tooltip>
            <v-tooltip v-else-if="item.status === 'Failed'" color="#d32f2f" top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="mr-6" color="#d32f2f" @click="showErrors(item)">
                  <v-icon dense>mdi-alert-outline</v-icon>
                </v-btn>
              </template>
              <span>Failed</span>
            </v-tooltip>
          </template>

          </v-data-table>
        </td>

        <td v-if="item.shuttleType === 'TPR'" colspan="12" style="padding:0px;">
          <v-data-table :items-per-page="pageSize" :items="item.adGroups" :headers="tprHeaders"
            :sort-by="['adGroup', 'payload.dealBeginDate']" group-by="adGroup" height="510" class="table"
            hide-default-footer fixed-header dense>

          <template v-slot:[`group.header`]="{ group, isOpen, toggle, headers }">
            <td :colspan="headers.length" @click="toggle">
              <span>
                <v-icon class="pr-5" right>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon> {{group}}
              </span>
            </td>
          </template>

          <template v-slot:[`item.payload.regSRP`]="{ item }">
            {{item.payload.regSRPCount}} @ {{formatCurrency(item.payload.regSRP)}}
          </template>

          <template v-slot:[`item.payload.TPR_SRP`]="{ item }">
            {{item.payload.TPR_SRPCount}} @ {{formatCurrency(item.payload.TPR_SRP)}}
          </template>

          <template v-slot:[`item.dates`]="{ item }">
            {{formatDate(item.payload.dealBeginDate)}} - {{formatDate(item.payload.dealEndDate)}}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip v-if="item.status === 'Complete'" top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-6" color="#00b24a">mdi-check-all</v-icon>
              </template>
              <span>Complete</span>
            </v-tooltip>
            <v-tooltip v-else-if="item.status === 'Pending'" top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-6" color="#f9a825">mdi-clock-outline</v-icon>
              </template>
              <span>Pending</span>
            </v-tooltip>
            <v-tooltip v-else-if="item.status === 'Failed'" top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="mr-6" color="#d32f2f" @click="showErrors(item)">
                  <v-icon dense>mdi-alert-outline</v-icon>
                </v-btn>
              </template>
              <span>Failed</span>
            </v-tooltip>
          </template>

          </v-data-table>
        </td>
      </template>

      <template v-slot:footer>
        <v-divider></v-divider>
        <v-toolbar flat color="#fafafa">
          <v-row>
            <v-col cols="2">
              <v-btn v-if="selected.length > 0" color="#D32F2F" class="white--text mb-2" @click.stop="shuttleDelete=true">
                Remove<v-icon class="ml-1" dense>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

    </v-data-table>

    <v-dialog justify="center" v-model="shuttleDelete" persistent max-width="300">
        <v-card color=#fafafa>
          <v-container class="ma-0 pa-6">
            <v-row justify="center">
              <v-icon x-large color="orange">mdi-alert-circle-outline</v-icon>
            </v-row>
            <v-row justify="center">
              <h3>Are You Sure?</h3>
            </v-row>
            <v-row justify="center">
              <v-btn height="40" class="elevation-1" @click.stop="shuttleDelete=false">No</v-btn>
              <v-btn class="white--text elevation-1 ml-2" height="40" color="#0c67a5" @click="removeShuttle">Yes</v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

    <ErrorModal v-model="errorModal" :failureReason="failureReason" />
    <ShuttleDetail v-if="contract !== {}" v-model="shuttleDetail" :contract="contract" :expanded="expanded"/>

  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ShuttleStatus',

  components: {
    ErrorModal: () => import('@/components/shuttle/failureReason'),
    ShuttleDetail: () => import('@/components/shuttle/shuttleDetail')
  },

  data () {
    return {
      pageSize: 500,
      pageSizes: [50, 100, 200],
      page: 1,
      shuttleDelete: false,
      failureModal: false,
      shuttleDetail: false,
      errorModal: false,
      contract: {},
      failureReason: [],
      singleSelect: false,
      singleExpand: true,
      disablePrevious: true,
      disableNext: true,
      loading: true,
      refresh: false,
      timer: '',
      selectedUser: '',
      shuttleDate: '',
      windowHeight: '',
      tableSize: '',
      search: '',
      toggle: 'ads',
      statusCount: [],
      users: [],
      selected: [],
      headers: [
        {},
        { text: 'Submit Time', align: 'left', sortable: true, filterable: false, value: 'submitTime', class: 'black--text' },
        { text: 'Contract ID', align: 'left', sortable: true, filterable: true, value: 'contractId', class: 'black--text' },
        { text: 'Shuttle Type', align: 'left', sortable: true, filterable: false, value: 'shuttleType', class: 'black--text' },
        { text: 'Shuttle Status', align: 'center', sortable: false, filterable: false, value: 'shuttleStatus', class: 'black--text' },
        { text: 'Shuttle Errors', align: 'center', sortable: false, filterable: false, value: 'totalFailed', class: 'black--text' },
        { text: 'Contract Details', align: 'center', sortable: false, filterable: false, value: 'details', class: 'black--text' },
        { value: 'data-table-expand' }
      ],
      adHeaders: [
        {},
        { text: 'Ad Group', align: 'left', sortable: true, value: 'adGroup', class: 'black--text' },
        { text: 'Ad Retail', align: 'left', sortable: true, value: 'payload.retail', class: 'black--text' },
        { text: 'Ad Fee', align: 'left', sortable: true, value: 'payload.adFee', class: 'black--text' },
        { text: 'Ad Group Status', sortable: true, align: 'center', value: 'status', class: 'black--text' }
      ],
      tprHeaders: [
        {},
        // { text: 'Ad Group', align: 'right', sortable: true, value: 'adGroup', class: 'black--text' },
        { text: 'Reg SRP', align: 'right', sortable: true, value: 'payload.regSRP', class: 'black--text' },
        { text: 'TPR Retail', align: 'right', sortable: true, value: 'payload.TPR_SRP', class: 'black--text' },
        { text: 'TPR Date Range', sortable: true, align: 'center', value: 'dates', class: 'black--text' },
        { text: 'TPR Group Status', sortable: true, align: 'center', value: 'status', class: 'black--text' }
      ],
      adShuttles: [],
      tprShuttles: [],
      shuttles: [],
      expanded: []
    }
  },

  computed: {
    ...mapState(['user'])
  },

  watch: {
    selectedUser: {
      handler (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.shuttles = []
          this.loading = true
          this.getShuttlesByUser()
        }
      }
    },

    expanded: {
      deep: true,
      handler (value) {
        if (value[0].shuttleType === 'Ad') {
          this.shuttleHeaders = [
            {
              text: 'Ad Group',
              sortable: true,
              value: 'adGroup',
              class: 'black--text'
            }
          ]
        } else {
          this.shuttleHeaders = []
        }
      }
    },

    toggle: {
      handler (value) {
        if (value === 'ads') {
          this.headers = this.adHeaders
          this.shuttles = this.adShuttles
        } else {
          this.headers = this.tprHeaders
          this.shuttles = this.tprShuttles
        }
      }
    },

    windowHeight: {
      handler () {
        this.tableSize = this.windowHeight - 245
      }
    }
  },

  async created () {
    window.addEventListener('resize', this.resize)
    this.resize()
    this.getShuttleUsers()
    this.shuttleDate = this.moment().format('M/D/YYYY')
    this.timer = setInterval(this.getStatus, 5000)
  },

  methods: {
    showErrors (item) {
      this.failureReason = []
      this.failureReason = item.failureReason
      this.errorModal = true
    },

    resize () {
      this.windowHeight = window.innerHeight
    },

    formatCurrency (value) {
      return '$' + parseFloat(value).toFixed(2)
    },

    formatSubmitTime (date) {
      return date ? this.moment.utc(date).local().format('MMM D - h:mm a') : ''
    },

    formatDate (date) {
      return date ? this.moment(date).format('MM/DD/YYYY') : ''
    },

    async getShuttleUsers () {
      await this.$shuttles.getShuttleUsers()
        .then(response => {
          this.users = response
          this.selectedUser = this.user.username
          this.getShuttlesByUser()
        })
    },

    async getShuttlesByUser (user, shuttleDate) {
      if (!user) user = this.selectedUser
      if (!shuttleDate) this.shuttleDate = this.moment().format('M/D/YYYY')
      // if (!shuttleDate) this.shuttleDate = moment().format('YYYY-MM-DD')
      await this.$shuttles.getShuttlesByUser(user, this.moment(this.shuttleDate, ['M/D/YY', 'M/D/YYYY']).format('YYYY-MM-DD')).then(response => {
        response.forEach(shuttle => {
          shuttle.totalComplete = 0
          shuttle.totalFailed = 0
          shuttle.totalGroups = shuttle.adGroups.length
          shuttle.adGroups.forEach(adGroup => {
            if (adGroup.status === 'Failed') {
              if (adGroup.failureReason !== null) {
                adGroup.failureReason = adGroup.failureReason.split(',')
                shuttle.totalFailed++
              }
            } else if (adGroup.status === 'Complete') {
              shuttle.totalComplete++
            }
          })
        })
        this.shuttles = response
        this.loading = false
        this.$emit('shuttleUpdate', response)
      })
    },

    expandAll () { this.$data.expanded = this.$data.contract.ContractItems },

    getShuttleDetails (shuttleId) {
      this.$shuttles.getShuttleDetails(shuttleId).then(response => {
        this.contract = response
        this.contract.ContractType = response.ContractType.Description
        this.contract.AdGroup = response.AdGroup.Description
        this.contract.Supplier = response.Supplier.Name
        this.contract.Department = response.Department.Name
        this.contract.Manufacturer = response.Manufacturer.Name
        this.expandAll()
        this.shuttleDetail = true
      })
    },

    async removeShuttle () {
      var promises = []
      this.selected.forEach(shuttle => {
        promises.push(this.$contracts.removeShuttle(shuttle.shuttleId))
      })
      await Promise.all(promises)
      this.getStatus()
      this.search = ''
      this.remove = false
      this.selected = []
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
tr.v-data-table__selected {
  background: #7d92f5 !important;
}
</style>
