<template>
  <v-container class="ma-0">
    <v-data-table
      :height="tableSize"
      :headers="batchHeaders"
      :items="batches"
      :expanded.sync="expanded"
      :items-per-page="pageSize"
      :search="filter"
      :loading="loading"
      v-model="selected"
      loader-height="2"
      loading-text="Loading Invoices...Please wait'"
      no-data-text="No Matching Invoice Batches"
      item-key="batchNumber"
      class="table"
      single-expand
      show-expand
      hide-default-footer
      fixed-header
      dense>
      <template v-slot:top>
        <!-- <v-container class="ma-0 pa-0" fluid> -->
        <v-toolbar flat color="#fafafa" style="cursor: default">
          <v-row dense align="center">
            <v-toolbar-title class="ml-2 title"> Invoices </v-toolbar-title>
            <v-divider class="mx-4" inset vertical />
            <v-col sm="2" md="2" lg="2" xl="2">
              <v-text-field
                background-color="#fff"
                dense
                hide-details
                outlined
                label="Filter Batches"
                prepend-inner-icon="mdi-filter-outline"
                v-model="filter">
              </v-text-field>
            </v-col>
            <v-menu
              v-if="toggle == 'Posted'"
              :value="searchMenu"
              bottom
              offset-y
              :nudge-left="100"
              :close-on-content-click="false">
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  text
                  @click="searchMenu = true"
                  class="text-capitalize pr-1 mx-2">
                  <v-icon left>mdi-magnify</v-icon>
                  Search
                  <v-badge
                    inline
                    class="ml-2"
                    color="orange"
                    :content="filterCount"
                    :value="showFilterBadge" />
                  <v-icon left>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card width="350" class="grey lighten-5">
                <v-card-title>Search By</v-card-title>
                <v-card-text>
                  <v-row
                    justify="center"
                    v-for="(field, idx) in searchFields"
                    :key="idx"
                    style="height: 55px">
                    <v-col align="center" style="width: 375px">
                      <v-text-field
                        v-if="field.type === 'text'"
                        clearable
                        @click:clear="searchParams[`${field.value}`] = ''"
                        v-model="searchParams[`${field.value}`]"
                        outlined
                        solo
                        dense
                        background-color="input"
                        :label="field.text" />
                      <v-autocomplete
                        v-else
                        outlined
                        solo
                        dense
                        clearable
                        background-color="white"
                        :label="field.text"
                        :items="batchTypeList"
                        item-text="description"
                        item-value="id"
                        v-model="searchParams[`${field.value}`]" />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text class="text-capitalize" @click="clearFilterCache">
                    Clear
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="text-capitalize mr-2"
                    :disabled="searchDisabled"
                    @click="getBatches">
                    Search
                  </v-btn>
                </v-card-actions>
                <v-divider />
              </v-card>
            </v-menu>
            <v-divider class="mx-4" inset vertical />
            <v-menu offset-y :nudge-left="30">
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="!billingUser"
                  v-on="on"
                  height="40"
                  color="#0D68A5"
                  class="white--text">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click=";(component = 'InvoiceBillingTypes'), (modal = true)">
                  <v-list-item-title>All Types</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click=";(component = 'InvoiceManual'), (modal = true)">
                  <v-list-item-title>Manual Invoice</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer />
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="getBatches(toggle)">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
            <v-toolbar-title
              class="ml-2 title"
              :class="{ active: toggle === 'Pending' }"
              @click="toggle = 'Pending'">
              Pending
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical />
            <v-toolbar-title
              class="title mr-4"
              :class="{ active: toggle === 'Posted' }"
              @click="toggle = 'Posted'">
              Posted
            </v-toolbar-title>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
      </template>

      <template v-slot:[`item.run_date`]="{ item }">
        <span>{{ formatDates(item.run_date) }}</span>
      </template>

      <template v-slot:[`item.begin_date`]="{ item }">
        <span
          >{{ formatDates(item.begin_date) }} -
          {{ formatDates(item.end_date) }}
        </span>
      </template>

      <template v-slot:[`item.billing_type`]="{ item }">
        <span v-if="item.billing_type === 'ManInv'">Manual</span>
        <span v-if="item.billing_type === 'Grocery'">
          Grocery, Frozen, Dairy
        </span>
        <span v-if="item.billing_type === 'MeatAccrual'">Meat Accrual</span>
        <span v-if="item.billing_type === 'Meat'">Perishables</span>
        <span v-if="item.billing_type === 'AdScans'">Ad Scans</span>
        <span v-if="item.billing_type === 'TPRScans'">TPR Scans</span>
        <span v-if="item.billing_type === 'VMC_BB'">VMC Billbacks</span>
        <span v-if="item.billing_type === 'VMC_Scans'">VMC Scans</span>
      </template>

      <!-- <template v-slot:[`item.invoice_total`]="{ item }">
        <v-menu offset-y open-on-hover :close-on-content-click="false">
          <template v-slot:activator="{ on }">
              <span v-if="checkTotals(item.invoice_total, item.credit_total)" v-on=on>{{formatCurrency(item.invoice_total)}}</span>
              <span v-else class='font-weight-bold red--text' v-on=on>{{formatCurrency(item.invoice_total)}}</span>
          </template>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                Invoice Total: {{formatCurrency(item.invoice_total)}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                Credit Total: {{formatCurrency(item.credit_total)}}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template> -->

      <template v-slot:[`item.invoice_total`]="{ item }">
        <span v-if="item.billing_type === 'ManInv'">{{
          formatCurrency(item.invoice_total)
        }}</span>
        <span v-else>
          <span v-if="checkTotals(item.invoice_total, item.credit_total)">
            {{ formatCurrency(item.invoice_total) }}/{{
              formatCurrency(item.credit_total)
            }}
          </span>
          <span v-else class="font-weight-bold red--text">
            {{ formatCurrency(item.invoice_total) }}/{{
              formatCurrency(item.credit_total)
            }}
          </span>
        </span>
      </template>

      <template #[`item.status`]="{ item }">
        <td v-if="item.status !== 'Posted'">
          <v-chip
            v-if="item.status !== 'Failed'"
            small
            class="ml-10"
            dark
            :color="getColor(item.status)">
            <span
              class="text-capitalize"
              style="
                display: inline-block;
                margin: 0 auto;
                width: 70px;
                text-align: center;
              ">
              {{ item.status }}
            </span>
          </v-chip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-chip
                v-if="item.status === 'Failed'"
                v-on="on"
                small
                class="ml-10"
                dark
                :color="getColor(item.status)">
                <span
                  class="text-capitalize"
                  style="
                    display: inline-block;
                    margin: 0 auto;
                    width: 70px;
                    text-align: center;
                  ">
                  {{ item.status }}
                </span>
              </v-chip>
            </template>
            <span>{{ item.failure_reason }}</span>
          </v-tooltip>
        </td>
      </template>

      <template v-slot:[`item.data-table-expand`]="{ item }">
        <v-btn
          v-if="item.status !== 'Failed' && item.status !== 'Running'"
          icon
          @click="!expanded.length ? getInvoices(item) : (expanded = [])">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          v-if="
            toggle === 'Pending' ||
            (toggle === 'Posted'  )
          "
          offset-y
          :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              :disabled="!billingUser"
              v-for="(action, index) in batchActions"
              :key="index"
              link
              @click="
                ;(type = action.value),
                  (selectedBatch = item),
                  (component = 'InvoiceActions'),
                  (modal = true)
              ">
              <v-list-item-icon>
                <v-icon>{{ action.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ action.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="toggle === 'Posted' && item.billing_type !== 'ManInv'"
              @click="
                ;(type = 'send'),
                  (selectedBatch = item),
                  (component = 'InvoiceActions'),
                  (modal = true)
              "
              link>
              <v-list-item-icon>
                <v-icon>mdi-email-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Send </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="toggle === 'Pending' | item.billing_type == 'ManInv'"
              @click="runReportByBatch(item)"
              link>
              <v-list-item-icon>
                <v-icon>mdi-file-clock-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Invoice Batch Report </v-list-item-title>
            </v-list-item>
            <v-list-item @click="runInvoiceSummaryReport(item)" link>
              <v-list-item-icon>
                <v-icon>mdi-file-clock-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title> End Sheet Report </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="item.billing_type !== 'ManInv'"
              @click="
                ;(reportId = 163),
                  (selectedBatch = item),
                  (component = 'RetailCreditStores'),
                  (modal = true)
              ">
              <v-list-item-icon>
                <v-icon>mdi-file-clock-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Output Retail Credits </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <v-divider />
        <v-toolbar flat color="#fafafa">
          <v-row align="center">
            <v-spacer />
            <v-col cols="2" style="max-width: 150px">
              <v-select
                class="mt-6"
                dense
                :items="pageSizes"
                label="Items Per Page"
                select
                v-model="pageSize"
                :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
              </v-select>
            </v-col>
            <v-btn
              icon
              large
              @click="previous()"
              :disabled="disablePrevious"
              class="mx-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <small>Page {{ page }}</small>
            <v-btn
              icon
              large
              @click="next()"
              :disabled="disableNext"
              class="mx-2">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td colspan="12" style="padding: 0px">
          <!-- <v-toolbar v-if="item.billing_type !== 'ManInv'" flat>
            <v-toolbar-title class="ml-2 title">
              Vendor Invoices
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical />
            <v-col cols="3">
              <v-text-field class="mt-7" outlined dense background-color="#fff" label="Filter Vendor Invoices"
                prepend-inner-icon="mdi-filter-outline" v-model="invoiceFilter" />
            </v-col>
          </v-toolbar> -->

          <v-divider />

          <v-data-table
            :items-per-page="500"
            :items="item.invoices"
            :headers="invoiceHeaders"
            height="500"
            class="table"
            hide-default-footer
            fixed-header
            dense
            :search="invoiceFilter">
            <template v-slot:[`item.manufacturer`]="{ item }">
              <span style="max-width: 400px">{{ item.manufacturer }}</span>
            </template>

            <template v-slot:[`item.email_address`]="{ item }">
              <span
                :class="item.email_address ? '' : 'font-weight-bold red--text'">
                {{
                  item.email_address ? item.email_address : 'No Email Provided'
                }}
              </span>
            </template>

            <template v-slot:[`item.total`]="{ item }">
              <span>{{ formatCurrency(item.total) }}</span>
            </template>

            <template v-slot:[`item.amount_paid`]="{ item }">
              <span v-if="item.status === 'Posted'">
                {{ formatCurrency(item.amount_paid) }}</span
              >
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu
                offset-y
                bottom
                :nudge-left="40"
                :close-on-content-click="false"
                ref="parentMenuRef">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-if="item.billing_type === 'ManInv' || toggle === 'Posted'"
                    @click="
                      ;(selectedBatch = item),
                        (component =
                          toggle === 'Pending' && item.billing_type === 'ManInv'
                            ? 'InvoiceManual'
                            : 'InvoiceVendorEdit'),
                        (modal = true),
                        ($refs.parentMenuRef.isActive = false)
                    ">
                    <v-list-item-icon>
                      <v-icon>mdi-square-edit-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="mr-6"> Edit </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="
                      ;(component = 'InvoicePreview'),
                        (selectedBatch = item),
                        (modal = true),
                        ($refs.parentMenuRef.isActive = false)
                    ">
                    <v-list-item-icon>
                      <v-icon>mdi-text-box-search-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> View </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="
                      ;(type = 'delete'),
                        (component = 'InvoiceActions'),
                        (selectedBatch = item),
                        (modal = true),
                        ($refs.parentMenuRef.isActive = false)
                    ">
                    <v-list-item-icon>
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Delete </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="toggle === 'Posted'"
                    @click="
                      ;(component = 'InvoicePayments'),
                        (selectedBatch = item),
                        (modal = true),
                        ($refs.parentMenuRef.isActive = false)
                    ">
                    <v-list-item-icon>
                      <v-icon>mdi-cash-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Payments </v-list-item-title>
                  </v-list-item>
                  <v-list-group
                    v-if="toggle === 'Posted'"
                    :value="false"
                    prepend-icon="mdi-file-clock-outline">
                    <template v-slot:activator>
                      <v-list-item-title>Reports</v-list-item-title>
                    </template>
                    <v-list-item>
                      <v-list-item-title> Invoice Report </v-list-item-title>
                      <v-btn
                        icon
                        @click.stop="exportFile('pdf', item)"
                        :loading="loadingPDF">
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click.stop="exportFile('xlsx', item)"
                        :loading="loadingExcel">
                        <v-icon>mdi-microsoft-excel</v-icon>
                      </v-btn>
                    </v-list-item>
                    <v-list-item
                      v-if="item.billing_type !== 'ManInv'"
                      @click="runReportByInvoice(item, 164)">
                      <v-list-item-title> Retail Credit </v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <v-divider />
        </td>
      </template>
    </v-data-table>

    <Component
      :getBatches="getBatches"
      :is="component"
      :key="componentKey"
      :reportId="reportId"
      :selectedBatch="selectedBatch"
      :toggle="toggle"
      :type="type"
      :value.sync="modal"
      @deleteBatch="deleteInvoiceBatch"
      @deleteInvoice="deleteInvoice"
      @dialog="invoiceReportAlert = true"
      @post="postInvoiceBatch"
      @send="emailInvoiceBatch"
      @refresh="getBatches"
      @restart="restartInvoiceBatch" />

    <InvoiceReportAlert :value.sync="invoiceReportAlert" />

    <!-- <ConfirmationModal :value.sync="confirmationModal" :type="type" :selectedBatch="selectedBatch"
      :deleteInvoiceBatch="deleteInvoiceBatch" :postInvoiceBatch="postInvoiceBatch"
      :restartInvoiceBatch="restartInvoiceBatch" /> -->
  </v-container>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'
import { mapState } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'Invoices',
  mixins: [tableSettings, notification],
  components: {
    InvoiceActions: () => import('@/components/invoicing/invoice-actions'),
    InvoiceBillingTypes: () =>
      import('@/components/invoicing/invoice-billing-types'),
    InvoiceManual: () => import('@/components/invoicing/InvoiceManual'),
    InvoicePreview: () => import('@/components/invoicing/invoice-preview'),
    InvoiceReportAlert: () =>
      import('@/components/invoicing/invoice-report-alert'),
    RetailCreditStores: () =>
      import('@/components/invoicing/retail-credit-stores'),
    InvoiceVendorEdit: () =>
      import('@/components/invoicing/invoice-vendor-edit'),
    InvoicePayments: () => import('@/components/invoicing/invoice-payments')
  },
  data() {
    return {
      batches: [],
      batchMenu: false,
      component: '',
      componentKey: 0,
      disableNext: false,
      disablePrevious: true,
      expanded: [],
      expandedRow: {},
      filter: '',
      searchParams: {},
      searchMenu: false,
      filterLabel: 'Filter Pending Batches',
      invoiceAll: false,
      invoiceDetail: false,
      invoiceFilter: '',
      invoiceManual: false,
      invoiceMenu: false,
      invoiceReportAlert: false,
      loading: true,
      loadingExcel: false,
      loadingPDF: false,
      modal: false,
      offset: true,
      page: 1,
      pageSize: 50,
      reportId: '',
      search: '',
      searchDate: '',
      searchBatchType: '',
      selected: [],
      selectedBatch: {},
      singleSelect: false,
      toggle: 'Pending',
      type: ''
    }
  },
  computed: {
    ...mapState(['billingUser']),
    searchFields() {
      return [
        {
          text: 'Invoice Number',
          value: 'invoice_number',
          type: 'text'
        },
        {
          text: 'Week Begin Date',
          value: 'begin_date',
          type: 'text'
        },
        {
          text: 'Batch Type',
          value: 'batch_type',
          type: 'select'
        }
      ]
    },
    batchActions() {
      if (this.toggle === 'Pending') {
        return [
          { text: 'Restart', icon: 'mdi-restart', value: 'restart' },
          { text: 'Delete', icon: 'mdi-trash-can-outline', value: 'delete' },
          { text: 'Post', icon: 'mdi-text-box-check-outline', value: 'post' }
        ]
      } else {
        return []
      }
    },
    invoiceActions() {
      return [
        {
          text: 'Edit',
          icon: 'mdi-square-edit-outline',
          component:
            this.toggle === 'Pending' ? 'InvoiceManual' : 'InvoiceVendorEdit'
        },
        { text: 'View', icon: 'mdi-eye-outline', component: 'InvoicePreview' },
        {
          text: 'Payments',
          icon: 'mdi-cash-multiple',
          component: 'InvoicePayments'
        }
      ]
    },
    batchReports() {
      return [{ text: 'Output Retail Credits', id: '163' }]
    },
    invoiceReports() {
      return [{ text: 'Retail Credit', id: '164' }]
    },
    batchHeaders() {
      return [
        { sortable: false },
        {
          text: this.toggle === 'Pending' ? 'User' : '',
          sortable: this.toggle === 'Pending',
          filterable: true,
          value: 'user_id',
          class: 'black--text'
        },
        {
          text: 'Batch Number',
          sortable: true,
          filterable: true,
          value: 'batchNumber',
          class: 'black--text'
        },
        {
          text: 'Run Date',
          sortable: true,
          filterable: true,
          value: 'run_date',
          class: 'black--text'
        },
        {
          text: 'Begin Date - End Date',
          align: 'center',
          sortable: true,
          filterable: true,
          value: 'begin_date',
          class: 'black--text'
        },
        {
          text: 'Billing Type',
          sortable: true,
          filterable: true,
          value: 'billing_type',
          class: 'black--text'
        },
        {
          text: 'Comment',
          sortable: true,
          filterable: true,
          value: 'comment',
          class: 'black--text'
        },
        {
          text: 'Invoice/Credit',
          sortable: true,
          filterable: false,
          value: 'invoice_total',
          class: 'black--text'
        },
        {
          text: this.toggle === 'Pending' ? 'Status' : '',
          align: 'center',
          sortable: this.toggle === 'Pending',
          filterable: true,
          value: 'status',
          class: 'black--text',
          width: '12%'
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          filterable: false,
          value: 'actions',
          class: 'black--text'
        },
        { value: 'data-table-expand', align: 'end' }
      ]
    },
    invoiceHeaders() {
      return [
        { sortable: false },
        {
          text: this.toggle === 'Posted' ? 'Invoice Number' : '',
          align: 'left',
          sortable: true,
          filterable: true,
          value: this.toggle === 'Posted' ? 'invoice_number' : '',
          class: 'black--text'
        },
        { sortable: false },
        { sortable: false },
        {
          text: 'Vendor Email',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'email_address',
          class: 'black--text'
        },
        {
          text: 'Vendor',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'manufacturer',
          class: 'black--text'
        },
        {
          text: 'Grand Totals',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'total',
          class: 'black--text'
        },
        {
          text: this.toggle === 'Posted' ? 'Amount Paid' : '',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'amount_paid',
          class: 'black--text'
        },
        {
          text: 'Actions',
          align: 'left',
          sortable: false,
          filterable: false,
          value: 'actions',
          class: 'black--text'
        }
      ]
    },
    batchTypeList() {
      return [
        { id: 'Grocery', description: 'Grocery' },
        { id: 'Meat', description: 'Perishables' },
        { id: 'MeatAccrual', description: 'Meat Accruals' },
        { id: 'AdScans', description: 'Ad Scans' },
        { id: 'TPRScans', description: 'TPR Scans' },
        { id: 'VMC_BB', description: 'VMC Billbacks' },
        { id: 'VMC_Scans', description: 'VMC Scans' },
        { id: 'ManInv', description: 'Manual Invoice' }
      ]
    },
    batchDisabled() {
      return !this.searchDate
    },
    invoiceDateFormatted() {
      return this.searchParams.begin_date
        ? this.moment().format(this.$config.date_display)
        : ''
    },
    showFilterBadge() {
      return (
        Object.values(this.searchParams).length > 0 ||
        this.status === 'DISABLED' ||
        this.showBrokers === false
      )
    },
    filterCount() {
      let filterCount = ''
      if (this.showFilterBadge) {
        filterCount = Object.values(this.searchParams).length
      }
      return filterCount
    },
    searchDisabled() {
      let isDisabled = true
      if (this.searchParams.invoice_number || (this.searchParams.begin_date && this.searchParams.batch_type))
        isDisabled = false
      return isDisabled
    }
  },
  watch: {
    search: {
      handler: debounce(function () {
        this.getBatches()
      }, 500)
    },
    searchParams: {
      handler(newValue) {
        if (Object.values(newValue).length > 0) {
          sessionStorage.setItem(
            'invoice_search_params',
            JSON.stringify(newValue)
          )
          if (Object.values(newValue).every(value => !value || value == '')) {
            this.searchParams = {}
            sessionStorage.removeItem('invoice_search_params')
          }
        } else {
          this.getBatches()
        }
      },
      deep: true
    },
    expanded: {
      handler() {
        if (this.expanded.length) {
          this.expandedRow = this.expanded[0]
        }
      }
    },
    modal: {
      immediate: true,
      handler(value) {
        this.componentKey += 1
        if (!value) {
          this.selectedBatch = {}
        }
      }
    },
    toggle: {
      handler() {
        this.loading = true
        this.batches = []
        this.search = ''
        this.invoiceFilter = ''
        this.page = 1
        this.getBatches()
      }
    },
    pageSize: {
      handler() {
        this.batches = []
        this.selected = []
        this.loading = true
        this.page = 1
        this.getBatches()
      }
    }
  },
  created() {
    this.getBatches()
    this.nonTableHeight = 245
    if (sessionStorage.invoice_search_params) {
      this.searchParams = JSON.parse(sessionStorage.invoice_search_params)
    }
  },
  methods: {
    clearFilterCache() {
      this.searchParams = {}
      sessionStorage.removeItem('invoice_search_params')
      this.searchMenu = false
    },
    itemRow() {
      return this.expanded[0].invoices.map(i => !i.email_address)
        ? 'red white--text'
        : ''
    },
    getColor(status) {
      switch (status) {
        case 'Running':
          return 'amber darken-1'
        case 'Complete':
          return 'green'
        case 'Failed':
          return 'red'
      }
    },
    checkTotals(invoice, credit) {
      if (Math.abs(invoice - credit) > 2) {
        return false
      } else {
        return true
      }
    },
    async getInvoices(batch) {
      this.invoicesLoading = true
      await this.$invoicing
        .getInvoicesByBatch(
          batch.batchNumber || batch.batch_number,
          this.toggle
        )
        .then(response => {
          this.expanded.push(batch)
          this.expanded[0].invoices = response
          this.loading = false
        })
        .catch(() => {
          console.log('error')
        })
    },
    viewInvoice(invoice) {
      this.selectedBatch = invoice
      this.invoiceDetail = true
    },
    formatDates(date) {
      return date ? this.moment(date).format('MM/DD/YYYY') : ''
    },
    formatCurrency(value) {
      return (
        '$' +
        parseFloat(value)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      )
    },
    async getBatches() {
      const start = (this.page - 1) * this.pageSize
      const end = this.pageSize

      const searchParams = Object.assign({}, this.searchParams)
      if (searchParams.begin_date) {
        searchParams.begin_date = this.moment(this.searchParams.begin_date, [
          'MM/DD/YY',
          'MM/DD/YYYY'
        ]).format(this.$config.date_format)
      }
      try {
        const response = await this.$invoicing.getBatches(
          start,
          end,
          this.toggle,
          searchParams
        )
        this.batches = response
        this.searchParams.invoice_number ? (this.expanded = this.batches) : (this.expanded = [])

        this.batches.length < this.pageSize
          ? (this.disableNext = true)
          : (this.disableNext = false)

        start === 0
          ? (this.disablePrevious = true)
          : (this.disablePrevious = false)
      } catch (error) {
        this.notify('error', 'Unable to load invoices')
      } finally {
        this.loading = false
        this.searchMenu = false
      }
    },
    batchSearchRules() {
      const rules = []

      if (!this.searchDate) rules.push('Must also specify a begin date')

      return rules
    },
    previous() {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      this.getBatches()
    },
    next() {
      this.page = this.page + 1
      this.getBatches()
    },
    async runReportByBatch(item) {
      const reportParams = { batch_number: item.batchNumber }
      await this.$reports.submitReport(129, reportParams).then(() => {
        this.invoiceReportAlert = true
      })
    },
    async runReportByInvoice(item, reportID) {
      const params = { invoice_number: item.invoice_number }
      await this.$reports.submitReport(reportID, params).then(() => {
        this.invoiceReportAlert = true
      })
    },
    async runInvoiceSummaryReport(item) {
      const reportParams = { batch_number: item.batchNumber }
      await this.$reports.submitReport(169, reportParams).then(() => {
        this.invoiceReportAlert = true
      })
    },
    async exportFile(fileType, item) {
      this.btnLoading = true
      let contentType
      let reportId
      if (fileType === 'xlsx') {
        this.loadingExcel = true
        contentType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        reportId = 161
      } else if (fileType === 'pdf') {
        this.loadingPDF = true
        contentType = 'application/pdf'
        reportId = 129
      }
      await this.$reports
        .exportFile(fileType, reportId, item.invoice_number)
        .then(response => {
          var blob = new Blob([response], { type: contentType })
          var objectUrl = URL.createObjectURL(blob)
          var anchor = document.createElement('a')
          anchor.download = `${item.invoice_number}.${fileType}`
          anchor.href = objectUrl
          anchor.click()
        })
        .catch(err => {
          console.log(err)
        })
      this.loadingExcel = false
      this.loadingPDF = false
    },
    async deleteInvoice(invoice) {
      await this.$invoicing
        .deleteInvoice(invoice.invoice_number)
        .then(() => {
          this.notify('success', 'Successfully Deleted Invoice')
        })
        .catch(() => {
          this.notify('error', 'Failed to Delete Invoice')
        })
      const batch = invoice
      this.getInvoices(batch)
    },
    async deleteInvoiceBatch(batch) {
      await this.$invoicing
        .deleteBatch(batch.batchNumber)
        .then(() => {
          this.notify('success', 'Successfully Deleted Invoice Batch')
        })
        .catch(() => {
          this.notify('error', 'Failed to Delete Invoice Batch')
        })
      this.getBatches()
    },
    async restartInvoiceBatch(batch) {
      await this.$invoicing
        .restartBatch(batch.batchNumber)
        .then(() => {
          this.notify('success', 'Successfully Restarted Invoice Batch')
        })
        .catch(() => {
          this.notify('error', 'Failed to Restart Invoice Batch')
        })
      this.getBatches()
    },
    async postInvoiceBatch(batch) {
      await this.$invoicing
        .postInvoice(batch.batchNumber)
        .then(() => {
          this.notify('success', 'Successfully Posted Invoice Batch')
        })
        .catch(() => {
          this.notify('error', 'Failed to Post Invoice Batch')
        })
      this.getBatches()
    },
    async emailInvoiceBatch(batch) {
      await this.$invoicing
        .emailInvoice(batch.batchNumber)
        .then(() => {
          this.notify('success', 'Successfully Submitted Emails for Batch')
        })
        .catch(() => {
          this.notify('error', 'Failed to Send Emails')
        })
      this.getBatches()
    }
  }
}
</script>
<style scoped>
@import '../assets/styles/global.css';
.active {
  color: #0d68a5;
}

.table >>> .table {
  cursor: pointer;
}

.table >>> tbody tr.v-data-table__expanded.v-data-table__expanded__row {
  background: #eeeeee;
  /* color: #fff; */
}

.table >>> tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
