var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pl-6 pr-6 pb-6 pt-4" },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "#fafafa", flat: "" } },
        [_c("v-toolbar-title", [_vm._v("Exclusions")])],
        1
      ),
      _c(
        "v-card",
        { staticStyle: { border: "1px solid #ccc" }, attrs: { flat: "" } },
        [
          _c(
            "v-row",
            { staticClass: "ml-6", attrs: { justify: "start" } },
            [
              _c("v-checkbox", {
                staticClass: "mr-12",
                attrs: { readonly: "", label: "Exclude New Items?" },
                model: {
                  value: _vm.store.exclusions.new_items,
                  callback: function($$v) {
                    _vm.$set(_vm.store.exclusions, "new_items", $$v)
                  },
                  expression: "store.exclusions.new_items"
                }
              }),
              _c("v-checkbox", {
                staticClass: "mr-12",
                attrs: { readonly: "", label: "Exclude from ad scan billing?" },
                model: {
                  value: _vm.store.exclusions.ad_scan_billing,
                  callback: function($$v) {
                    _vm.$set(_vm.store.exclusions, "ad_scan_billing", $$v)
                  },
                  expression: "store.exclusions.ad_scan_billing"
                }
              }),
              _c("v-checkbox", {
                staticClass: "mr-12",
                attrs: {
                  readonly: "",
                  label: "Exclude from ad-billback billing?"
                },
                model: {
                  value: _vm.store.exclusions.ad_billback_billing,
                  callback: function($$v) {
                    _vm.$set(_vm.store.exclusions, "ad_billback_billing", $$v)
                  },
                  expression: "store.exclusions.ad_billback_billing"
                }
              })
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ml-6", attrs: { justify: "start" } },
            [
              _c("v-checkbox", {
                staticClass: "mr-12",
                attrs: { readonly: "", label: "Exclude from Ad-Free Dist" },
                model: {
                  value: _vm.store.exclusions.ad_fee_dist,
                  callback: function($$v) {
                    _vm.$set(_vm.store.exclusions, "ad_fee_dist", $$v)
                  },
                  expression: "store.exclusions.ad_fee_dist"
                }
              }),
              _c("v-checkbox", {
                staticClass: "mr-12",
                attrs: { readonly: "", label: "Is scan adata being provided?" },
                model: {
                  value: _vm.store.exclusions.scan_data,
                  callback: function($$v) {
                    _vm.$set(_vm.store.exclusions, "scan_data", $$v)
                  },
                  expression: "store.exclusions.scan_data"
                }
              }),
              _c("v-checkbox", {
                staticClass: "mr-12",
                attrs: { readonly: "", label: "Non-Compliance Exclude" },
                model: {
                  value: _vm.store.exclusions.non_compliance,
                  callback: function($$v) {
                    _vm.$set(_vm.store.exclusions, "non_compliance", $$v)
                  },
                  expression: "store.exclusions.non_compliance"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-toolbar",
        { attrs: { color: "#fafafa", flat: "" } },
        [_c("v-toolbar-title", [_vm._v("Inclusions")])],
        1
      ),
      _c(
        "v-card",
        { staticStyle: { border: "1px solid #ccc" }, attrs: { flat: "" } },
        [
          _c(
            "v-row",
            { staticClass: "ml-6", attrs: { justify: "start" } },
            [
              _c("v-checkbox", {
                staticClass: "mr-12",
                attrs: {
                  readonly: "",
                  label: "Include this store in billing?"
                },
                model: {
                  value: _vm.store.exclusions.include_store_billing,
                  callback: function($$v) {
                    _vm.$set(_vm.store.exclusions, "include_store_billing", $$v)
                  },
                  expression: "store.exclusions.include_store_billing"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-toolbar",
        { attrs: { color: "#fafafa", flat: "" } },
        [_c("v-toolbar-title", [_vm._v("Other Settings")])],
        1
      ),
      _c(
        "v-card",
        { staticStyle: { border: "1px solid #ccc" }, attrs: { flat: "" } },
        [
          _c(
            "v-row",
            { staticClass: "ml-6", attrs: { justify: "start" } },
            [
              _c("v-checkbox", {
                staticClass: "mr-8",
                attrs: {
                  readonly: "",
                  label: "Exclude from admin fee?",
                  "true-value": "Y",
                  "false-value": "N"
                },
                model: {
                  value: _vm.store.exclusions.admin_fee,
                  callback: function($$v) {
                    _vm.$set(_vm.store.exclusions, "admin_fee", $$v)
                  },
                  expression: "store.exclusions.admin_fee"
                }
              }),
              _c("v-checkbox", {
                staticClass: "mr-8",
                attrs: {
                  readonly: "",
                  label: "Is this store open 24 hours?",
                  "true-value": "Y",
                  "false-value": "N"
                },
                model: {
                  value: _vm.store.exclusions.open24hours,
                  callback: function($$v) {
                    _vm.$set(_vm.store.exclusions, "open24hours", $$v)
                  },
                  expression: "store.exclusions.open24hours"
                }
              }),
              _c("v-checkbox", {
                staticClass: "mr-8",
                attrs: {
                  readonly: "",
                  label: "Closed on Sunday?",
                  "true-value": "Y",
                  "false-value": "N"
                },
                model: {
                  value: _vm.store.exclusions.closed_sunday,
                  callback: function($$v) {
                    _vm.$set(_vm.store.exclusions, "closed_sunday", $$v)
                  },
                  expression: "store.exclusions.closed_sunday"
                }
              }),
              _c("v-checkbox", {
                staticClass: "mr-8",
                attrs: {
                  readonly: "",
                  "true-value": "Y",
                  "false-value": "N",
                  label: "Does this store run a loyalty program?"
                },
                model: {
                  value: _vm.store.exclusions.ad_fee_dist,
                  callback: function($$v) {
                    _vm.$set(_vm.store.exclusions, "ad_fee_dist", $$v)
                  },
                  expression: "store.exclusions.ad_fee_dist"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }