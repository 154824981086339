var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c("v-data-table", {
        staticClass: "table",
        attrs: {
          height: _vm.tableSize,
          items: _vm.adGroups,
          headers: _vm.headers,
          "items-per-page": _vm.pageSize,
          loading: _vm.loading,
          "loader-height": "2",
          search: _vm.search,
          "hide-default-footer": "",
          "fixed-header": "",
          dense: "",
          "item-key": "id",
          "loading-text": "Loading... Please wait"
        },
        on: {
          "update:items": function($event) {
            _vm.adGroups = $event
          },
          "click:row": _vm.editAdGroup
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "toolbar",
                      attrs: { flat: "", color: "#fafafa" }
                    },
                    [
                      _c("v-toolbar-title", [_vm._v("Ad Group Maintenance")]),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" }
                      }),
                      _c(
                        "v-col",
                        { staticClass: "mt-6", attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "background-color": "#fff",
                              "prepend-inner-icon": "mdi-filter-outline",
                              label: "Filter Ad Groups"
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider")
                ]
              },
              proxy: true
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c("v-divider"),
                  _c("v-toolbar", { attrs: { flat: "", color: "#fafafa" } })
                ]
              },
              proxy: true
            },
            {
              key: "item.active",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.active === true
                    ? _c("span", { staticClass: "ml-1" }, [_vm._v("Yes")])
                    : _c("span", { staticClass: "ml-1" }, [_vm._v("No")])
                ]
              }
            },
            {
              key: "item.ad_begin_day",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [_vm._v(_vm._s(_vm.format(item.ad_begin_day)))])
                ]
              }
            },
            {
              key: "item.tpr_begin_day",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [_vm._v(_vm._s(_vm.format(item.tpr_begin_day)))])
                ]
              }
            },
            {
              key: "item.tpr_end_day",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [_vm._v(_vm._s(_vm.format(item.tpr_end_day)))])
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "1000" },
          model: {
            value: _vm.adGroupModal,
            callback: function($$v) {
              _vm.adGroupModal = $$v
            },
            expression: "adGroupModal"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "#fafafa" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "#1C3144", dark: "", flat: "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  !_vm.edit
                    ? _c("v-toolbar-title", [_vm._v("New Ad Group Setup")])
                    : _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.adGroup.ad_group_desc))
                      ])
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "ma-0 pa-6" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "Supplier",
                              "item-text": "description",
                              "item-value": "id",
                              items: _vm.suppliers
                            },
                            model: {
                              value: _vm.adGroup.sup_code,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "sup_code", $$v)
                              },
                              expression: "adGroup.sup_code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "Code"
                            },
                            model: {
                              value: _vm.adGroup.ad_group_code,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "ad_group_code", $$v)
                              },
                              expression: "adGroup.ad_group_code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "Ad Group Name"
                            },
                            model: {
                              value: _vm.adGroup.ad_group_desc,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "ad_group_desc", $$v)
                              },
                              expression: "adGroup.ad_group_desc"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              "item-text": "day",
                              "item-value": "number",
                              autocomplete: "off",
                              items: _vm.adDays,
                              label: "Ad Begin Day"
                            },
                            model: {
                              value: _vm.adGroup.ad_begin_day,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "ad_begin_day", $$v)
                              },
                              expression: "adGroup.ad_begin_day"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              readonly: "",
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "TPR Begin Day",
                              items: _vm.tprDays,
                              "item-text": "day",
                              "item-value": "number"
                            },
                            model: {
                              value: _vm.adGroup.tpr_begin_day,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "tpr_begin_day", $$v)
                              },
                              expression: "adGroup.tpr_begin_day"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              readonly: "",
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "TPR End Day",
                              items: _vm.tprDays,
                              "item-text": "day",
                              "item-value": "number"
                            },
                            model: {
                              value: _vm.adGroup.tpr_end_day,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "tpr_end_day", $$v)
                              },
                              expression: "adGroup.tpr_end_day"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "TPR Group"
                            },
                            model: {
                              value: _vm.adGroup.tpr_group,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "tpr_group", $$v)
                              },
                              expression: "adGroup.tpr_group"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "Index"
                            },
                            model: {
                              value: _vm.adGroup.ad_group_index,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "ad_group_index", $$v)
                              },
                              expression: "adGroup.ad_group_index"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "Sequence"
                            },
                            model: {
                              value: _vm.adGroup.sequence,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "sequence", $$v)
                              },
                              expression: "adGroup.sequence"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "Special Purpose"
                            },
                            model: {
                              value: _vm.adGroup.special_purpose,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "special_purpose", $$v)
                              },
                              expression: "adGroup.special_purpose"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "Freight Amount"
                            },
                            on: { change: _vm.setFreightAmount },
                            model: {
                              value: _vm.adGroup.freight_amount,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "freight_amount", $$v)
                              },
                              expression: "adGroup.freight_amount"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "GP Calc Method"
                            },
                            model: {
                              value: _vm.adGroup.gp_calc_method,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "gp_calc_method", $$v)
                              },
                              expression: "adGroup.gp_calc_method"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "TPR SRP Calc Method"
                            },
                            model: {
                              value: _vm.adGroup.tpr_srp_calc_method,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adGroup,
                                  "tpr_srp_calc_method",
                                  $$v
                                )
                              },
                              expression: "adGroup.tpr_srp_calc_method"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              autocomplete: "off",
                              label: "Weekly Admin Fee",
                              prefix: "$"
                            },
                            on: { change: _vm.setWeeklyAdminFee },
                            model: {
                              value: _vm.adGroup.weekly_admin_fee,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "weekly_admin_fee", $$v)
                              },
                              expression: "adGroup.weekly_admin_fee"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Receive Ads" },
                            model: {
                              value: _vm.adGroup.receive_ads,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "receive_ads", $$v)
                              },
                              expression: "adGroup.receive_ads"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Receive TPRs" },
                            model: {
                              value: _vm.adGroup.receive_tprs,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "receive_tprs", $$v)
                              },
                              expression: "adGroup.receive_tprs"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "TPR Billing" },
                            model: {
                              value: _vm.adGroup.tpr_billing,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "tpr_billing", $$v)
                              },
                              expression: "adGroup.tpr_billing"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "TPR Maintenance" },
                            model: {
                              value: _vm.adGroup.tpr_maint,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "tpr_maint", $$v)
                              },
                              expression: "adGroup.tpr_maint"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Exclude TPR Meat" },
                            model: {
                              value: _vm.adGroup.tpr_exclude_tpr_meat,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adGroup,
                                  "tpr_exclude_tpr_meat",
                                  $$v
                                )
                              },
                              expression: "adGroup.tpr_exclude_tpr_meat"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "No Display" },
                            model: {
                              value: _vm.adGroup.no_display,
                              callback: function($$v) {
                                _vm.$set(_vm.adGroup, "no_display", $$v)
                              },
                              expression: "adGroup.no_display"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "#fafafa" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { height: "40", color: "#00B24A" },
                      on: { click: _vm.saveAdGroup }
                    },
                    [
                      !_vm.edit
                        ? _c(
                            "span",
                            [
                              _vm._v("Save"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-checkbox-marked-circle-outline")]
                              )
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _vm._v("Update"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-update")]
                              )
                            ],
                            1
                          )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          staticClass: "notification",
          attrs: { timeout: _vm.timeout, color: "#00B24A", top: "" },
          model: {
            value: _vm.notification,
            callback: function($$v) {
              _vm.notification = $$v
            },
            expression: "notification"
          }
        },
        [_vm._v(" " + _vm._s(_vm.text) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }