import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import auth from './modules/store.auth'

// Endpoints
import ads from '@/axios/ad-planner-endpoints'

Vue.use(Vuex)

const namespaced = true
const modules = { auth }

const strict = false

const state = {
  admin: false,
  billingUser: false,
  loaded: false,
  tprBulkUser: false,
  user: {},
  snackbar: {},
  adgroups: []
}

const mutations = {
  set_user (state, payload) {
    state.user = payload
  },
  set_admin (state, payload) {
    state.admin = payload
  },
  set_billing_user (state, payload) {
    state.billingUser = payload
  },
  set_tpr_bulk_user (state, payload) {
    state.tprBulkUser = payload
  },
  set_loaded (state, payload) {
    state.loaded = payload
  },
  set_adgroups (state, payload) {
    state.adgroups = payload
  },
  set_snackbar (state, snackbar) {
    state.snackbar = snackbar
  },
}

const actions = {
  setLoaded (context, payload) {
    context.commit('set_loaded', payload)
  },

  getAllAdGroups (context) {
    ads.getAdGroups()
      .then(response => {
        context.commit('set_adgroups', response)
      })
  },

  setSnackbar ({commit}, snackbar) {
    snackbar.showing = true
    snackbar.timeout = 3000
    snackbar.icon = snackbar.status === 'success' ? 'mdi-checkbox-marked-circle-outline' : 'mdi-alert-circle-outline'
    snackbar.color = snackbar.status === 'success' ? 'success' : 'orange'
    commit('set_snackbar', snackbar)
  },
}

const getters = {
  user (userState) {
    return userState.user
  },
  loaded (userState) {
    return userState.loaded
  }
}

export const store = new Vuex.Store({
  state,
  strict,
  actions,
  modules,
  getters,
  mutations,
  namespaced
})
