<template>
  <v-container class="ma-0">
    <v-data-table
      :height="tableSize"
      :items.sync="vendorCodes"
      :headers="headers"
      :items-per-page="pageSize"
      :loading="loading"
      :search="search"
      loader-height="2"
      hide-default-footer
      fixed-header
      dense
      item-key="id"
      class="table"
      loading-text="Loading... Please wait"
      @click:row="editVendorCode"
    >

    <template v-slot:top>
      <v-toolbar flat color="#fafafa" class="toolbar">
      <v-toolbar-title>AWG Vendor Code Maintenance</v-toolbar-title>
      <v-divider class="mx-4" inset vertical/>
      <v-col cols="3" class="mt-6">
        <v-text-field dense outlined background-color="#fff" v-model="search"
          prepend-inner-icon="mdi-filter-outline" label ="Filter Vendor Codes" />
      </v-col>
      </v-toolbar>
      <v-divider/>
    </template>

    <template v-slot:footer>
      <v-divider></v-divider>
      <v-toolbar flat color="#fafafa">
      </v-toolbar>
    </template>

    </v-data-table>

    <v-dialog v-model="vendorCodeModal" persistent width="600">
    <v-card color="#fafafa">
      <v-toolbar color="#1C3144" dark flat>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="!edit">New Vendor Code Setup</v-toolbar-title>
        <v-toolbar-title v-else>{{vendorCode.name}}</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-title>ID: {{vendorCode.id}}</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row class="mt-6">
          <v-col cols="3" class="mb-12">
            <v-text-field readonly outlined dense background-color="#fff" label="Code" v-model="vendorCode.code"/>
          </v-col>
          <v-col cols="9">
            <v-text-field readonly outlined dense background-color="#fff" label="Name" v-model="vendorCode.name"/>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="1" class="mb-12">
            <v-checkbox readonly label="Hide" v-model="vendorCode.hide"/>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-toolbar flat color="#fafafa">
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
  </v-dialog>

  <v-snackbar v-model="notification" :timeout="timeout" color="#00B24A" top class="notification">
    {{ text }}
  </v-snackbar>

  </v-container>
</template>

<script>
import maintenance from '../../axios/maintenance/endpoints'
import { store } from '@/store/index'

export default {
  name: 'VendorCodes',
  data () {
    return {
      new: false,
      edit: false,
      vendorCode: {},
      vendorCodeModal: false,
      pageSize: 5000,
      loading: true,
      notification: false,
      text: '',
      timeout: 2000,
      tableSize: '',
      windowHeight: '',
      search: '',
      headers: [
        {
          text: '',
          sortable: false,
          fitlerable: false,
          value: ''
        },
        {
          text: 'Code',
          sortable: true,
          fitlerable: true,
          value: 'code'
        },
        {
          text: 'Name',
          sortable: true,
          fitlerable: true,
          value: 'name'
        }
      ],
      vendorCodes: []
    }
  },
  watch: {
    windowHeight: {
      handler () {
        this.tableSize = this.windowHeight - 225
      }
    }
  },
  created () {
    if (!store.state.userGroups.includes('AdMan_Admin')) {
      this.$router.push({ path: '/' })
    }
    this.tableSize = this.windowHeight - 225
    window.addEventListener('resize', this.resize)
    this.resize()

    this.loading = true
    this.getVendorCodes()
  },
  methods: {
    resize () {
      this.windowHeight = window.innerHeight
    },
    close () {
      this.edit = false
      this.new = false
      this.vendorCodeModal = false
      this.vendorCode = {}
    },
    editVendorCode (vendorCode) {
      this.vendorCode = vendorCode
      this.edit = true
      this.vendorCodeModal = true
    },
    getVendorCodes () {
      this.$maintenance.getVendorCodes().then(response => {
        this.vendorCodes = response
        this.loading = false
      })
    },
    saveVendorCode () {
      maintenance.updateVendorCode(this.store).then(response => {
        this.text = response.status
        this.notification = true
        this.getStores()
      }).catch(err => {
        console.log(err)
      })
      this.vendorCodeModal = false
      this.vendorCode = {}
      this.new = false
      this.edit = false
    }
  }
}
</script>
