<template>
  <v-container class="ma-0 pa-6">
    <v-row justify="center">
      <v-col cols="3">
        <v-autocomplete readonly :items="adGroups" item-text="description" item-value="id" background-color="#fff" dense outlined
          label="Ad Group" v-model="store.groups.ad_group"/>
      </v-col>
      <v-col cols="3">
        <v-autocomplete readonly :items="subGroups" item-text="description" item-value="id" background-color="#fff" dense outlined
          label="Sub-Group" v-model="store.groups.sub_group"/>
      </v-col>
      <v-col cols="3">
        <v-autocomplete readonly :items="ownerGroups" item-text="name" item-value="id" background-color="#fff" dense outlined
          label="Owner Group" v-model="store.groups.owner_group"/>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="3">
        <v-autocomplete readonly :items="catMgmtGroups" item-text="description" item-value="group" background-color="#fff" dense outlined
          label="Category Management Group" v-model="store.groups.category_management_group"/>
      </v-col>
      <v-col cols="3">
        <v-autocomplete readonly :items="vmcAdGroups" item-text="description" item-value="index" background-color="#fff" dense outlined
          label="VMC Ad Group" v-model="store.groups.vmc_ad_group"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import maintenance from '../../axios/maintenance/endpoints'
import ads from '../../axios/adPlanner/endpoints'

export default {
  name: 'GroupAssigments',
  data () {
    return {
      adGroups: [],
      ownerGroups: [],
      subGroups: [],
      catMgmtGroups: [
        { group: 'A', description: 'A.C. Nielsen' },
        { group: 'I', description: 'IRI' },
        { group: 'AI', description: 'Both Programs' }
      ],
      vmcAdGroups: []
    }
  },
  props: [
    'store'
  ],
  created () {
    this.getAdGroups()
    this.getOwnerGroups()
    this.getSubGroups()
  },
  methods: {
    getAdGroups () {
      ads.getAdGroups().then(response => {
        this.adGroups = response
        this.vmcAdGroups = response
      })
    },
    getOwnerGroups () {
      maintenance.getOwnerGroups().then(response => {
        this.ownerGroups = response
      })
    },
    getSubGroups () {
      maintenance.getSubGroups().then(response => {
        this.subGroups = response
      })
    }
  }
}
</script>
