var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "700" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [_c("v-toolbar-title", [_vm._v("Errors")])],
            1
          ),
          _c(
            "v-container",
            {
              staticClass: "ma-0 pa-6",
              staticStyle: { "max-height": "200px", overflow: "auto" }
            },
            _vm._l(_vm.errors, function(error) {
              return _c("ul", { key: error }, [
                _c("li", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(error))
                ])
              ])
            }),
            0
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "#fafafa" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "#0c67a5", text: "" },
                  on: {
                    click: function($event) {
                      _vm.show = false
                    }
                  }
                },
                [_vm._v("Okay")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }