<template>
  <v-container class="ma-0">
    <v-toolbar flat color="#fafafa">
      <v-row dense class="pt-2">
        <v-toolbar-title class="ml-3 mt-2 title">Recap Additions</v-toolbar-title>
        <v-divider vertical inset class="mx-4" />
        <v-col>
          <v-autocomplete prepend-inner-icon="mdi-filter-outline" outlined dense background-color="#fff" label="Year" :items="years" item-text="description"
            item-value="id" :menu-props="{ maxHeight: 215 }" v-model="filters.year" />
        </v-col>
        <v-col>
          <v-autocomplete prepend-inner-icon="mdi-filter-outline" outlined dense background-color="#fff" label="Period" :items="periods" item-text="description"
            item-value="id" :menu-props="{ maxHeight: 215 }" v-model="filters.period"/>
        </v-col>
        <v-col>
          <v-autocomplete prepend-inner-icon="mdi-filter-outline" outlined dense background-color="#fff" label="Component" :items="components" item-text="description"
            item-value="id" :menu-props="{ maxHeight: 215 }" v-model="filters.component" />
        </v-col>
        <v-col>
          <v-text-field prepend-inner-icon="mdi-filter-outline" outlined dense background-color="#fff" label="Store #"
            v-model="filters.store_id" />
        </v-col>
        <v-col>
          <v-text-field prepend-inner-icon="mdi-filter-outline" outlined dense background-color="#fff" label="Comment"
            v-model="filters.comment" />
        </v-col>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="!billingUser" v-on="on" class="white--text mx-2 mt-1" height="40" color="#0D68A5" @click="modal=true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Create Recap Addition</span>
        </v-tooltip>
      </v-row>
    </v-toolbar>
    <v-divider />
    <v-data-table :items="items" :headers="headers" :height="tableSize" class="table" :items-per-page="pageSize" :loading="loading"
      loader-height="2" :search="search" loading-text="Loading Recap Additions...Please wait" dense hide-default-footer
      fixed-header>

      <template v-slot:[`item.amount`]="{ item }">
        {{formatCurrency(item.amount)}}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="!billingUser" v-on="on" icon
              @click="selectedRecap=item, modal=true">
              <v-icon class="edit">mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>

    </v-data-table>
    <v-divider />
    <v-toolbar flat color="#fafafa">
      <v-row align="center">
        <v-spacer/>
        <v-spacer/>
        <v-col v-if="filters" cols="2">
          <i>
            <p class="blue--text">{{items.length}} Results</p>
          </i>
        </v-col>
        <v-spacer />
        <v-col cols="2" style="max-width:150px;">
          <v-select class="mt-6" dense :items="pageSizes" label="Items Per Page" select v-model="pageSize"
            :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
          </v-select>
        </v-col>
        <v-btn icon large @click="previous()" :disabled="disablePrevious" class="mx-2">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <small>Page {{page}}</small>
        <v-btn icon large @click="next()" :disabled="disableNext" class="mx-2">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-toolbar>

    <RecapAdditionModal
      :key="componentKey"
      :value.sync="modal"
      :selectedRecap="selectedRecap"
      :components="components"
      :scopes="scopes"
      :periods="periods"
      :years="years"
      :stores="stores"
      :adGroups="adGroups"
      :getRecapAdditions="getRecapAdditions"
    />

  </v-container>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { debounce } from 'lodash'

export default {
  name: 'RecapAdditionList',

  mixins: [tableSettings],

  components: {
    RecapAdditionModal: () => import('@/components/recaps/recap-addition-modal')
  },

  data () {
    return {
      components: [],
      componentKey: 0,
      filters: {},
      items: [],
      loading: true,
      modal: false,
      search: '',
      selectedRecap: {},
      stores: [],
      adGroups: [],
      pageSize: 100,
      pageSizes: [100, 250, 500]
    }
  },

  computed: {
    billingUser () {
      return this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'].includes('AdMan_Billing')
    },

    headers () {
      return [
        { sortable: false, width: '0%' },
        { text: 'Year', align: 'start', sortable: true, filterable: true, class: 'black--text', value: 'year', width: '6%' },
        { text: 'Period', align: 'start', sortable: true, filterable: true, class: 'black--text', value: 'period', width: '6%' },
        { text: 'Recap Component', align: 'start', sortable: true, filterable: true, class: 'black--text', value: 'component.description', width: '11%' },
        { text: 'Ad Group', align: 'start', sortable: true, filterable: true, class: 'black--text', value: 'ad_group.description' },
        { text: 'Store Name', align: 'start', sortable: true, filterable: true, class: 'black--text', value: 'store.name' },
        { text: 'Amount', align: 'start', sortable: true, filterable: true, class: 'black--text', value: 'amount' },
        { text: 'Comment', align: 'start', sortable: true, filterable: true, class: 'black--text', value: 'comment', width: '30%' },
        { text: 'Actions', sortable: false, filterable: false, class: 'black--text', value: 'actions' }
      ]
    },

    scopes () {
      return [
        { description: 'Ad Group', id: 'Ad Group' },
        { description: 'Store', id: 'Store' }
      ]
    },

    periods () {
      return [
        { description: 'Period 1', id: 1 },
        { description: 'Period 2', id: 2 },
        { description: 'Period 3', id: 3 },
        { description: 'Period 4', id: 4 },
        { description: 'Period 5', id: 5 },
        { description: 'Period 6', id: 6 },
        { description: 'Period 7', id: 7 },
        { description: 'Period 8', id: 8 },
        { description: 'Period 9', id: 9 },
        { description: 'Period 10', id: 10 },
        { description: 'Period 11', id: 11 },
        { description: 'Period 12', id: 12 },
        { description: 'Period 13', id: 13 }
      ]
    },

    years () {
      const years = []
      const dateStart = this.moment().subtract(3, 'y')
      const dateEnd = this.moment().add(3, 'y')
      while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'))
        dateStart.add(1, 'year')
      }
      return years
    },

    disableNextPage () {
      return this.items.length < this.pageSize
    },

    disablePreviousPage () {
      return this.page === 1
    }
  },

  watch: {
    modal: {
      handler (value) {
        this.componentKey += 1
        if (!value) {
          this.selectedRecap = {}
          this.getRecapAdditions()
        }
      }
    },

    filters: {
      deep: true,
      immediate: true,
      handler: debounce(function (value) {
        for (const key in value) {
          if (value[key] === null || value[key] === '') {
            delete value[key]
          }
        }
        this.getRecapAdditions()
      }, 500)
    }
  },

  created () {
    this.nonTableHeight = 245
    this.getRecapAdditions()
    this.getRecapComponents()
    this.getStores()
    this.getAdGroups()
  },

  methods: {
    previous () {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      this.getRecapAdditions()
    },

    next () {
      this.page = this.page + 1
      this.getRecapAdditions()
    },

    checkValues () {
      for (const property in this.filters) {
        if (this.filters[property] === null) {
          delete this.filters[property]
        }
      }
    },

    formatCurrency (amount) {
      if (amount < 0) {
        const negativeAmount = amount.toString()
        return `(${negativeAmount.substring(0, 1)}$${parseFloat(negativeAmount.substring(1, negativeAmount.length)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`
      } else {
        return `$${parseFloat(amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
      }
    },

    modifyRecap (recap) {
      this.selectedRecap = recap
      this.modal = true
    },

    getStores () {
      this.stores = []
      this.$recaps.getStores()
        .then(response => {
          this.stores = response
          this.loading = false
        })
    },

    getAdGroups () {
      this.$ads.getAdGroups()
        .then(response => {
          this.adGroups = response.filter(adGroup => adGroup.id !== 'ALL')
        })
    },

    getRecapAdditions () {
      this.loading = true
      const start = (this.page - 1) * this.pageSize
      const end = this.pageSize
      var arr = []
      for (const property in this.filters) {
        arr.push(`${property}=${this.filters[property]}`)
      }
      const filters = arr.join('&')

      this.$recaps.getRecapAdditions(start, end, filters)
        .then(response => {
          this.items = response
          this.loading = false
        })
    },

    getRecapComponents () {
      this.$recaps.getRecapComponents()
        .then(response => {
          this.components = response
        })
    }
  }
}
</script>
