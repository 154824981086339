<template>
  <v-container class="ma-0 pl-6 pr-6 pt-0 pb-6">
    <v-toolbar flat color="#fafafa">
      <v-toolbar-title>Output Set Groups</v-toolbar-title>
    </v-toolbar>
    <v-card style="border:1px solid #ccc;" flat class="pa-4" max-height="300">
      <section v-if="store.output_set.set_id !== null">
        <v-row justify="center" align="center" class="mb-2">
          <v-col cols="3">
            <v-autocomplete outlined dense background-color="#fff" :items="outputs" item-text="label"
              item-value="set_id" v-model="store.output_set.set_id" />
          </v-col>
          <v-col cols="3">
            <v-text-field readonly outlined dense background-color="#fff" label="Begin Date" :value="beginDate" />
          </v-col>
          <v-col cols="3">
            <v-text-field readonly outlined dense background-color="#fff" label="End Date" :value="endDate" />
          </v-col>
          <v-col cols="2">
            <v-text-field readonly outlined dense background prefix="$" label="Fee Amount" :value="feeAmount" />
          </v-col>
        </v-row>
      </section>
      <section v-else class="pt-6">
        <v-row justify="center">
          <h1>No Output Set</h1>
        </v-row>
      </section>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  name: 'OutputSetMgmt',
  data () {
    return {
      outputs: [
        { label: 'Produce Reporting Fee', set_id: 1 },
        { label: 'Retailer Analytics Subscription', set_id: 2 },
        { label: 'Revionics Data Feed', set_id: 3 },
        { label: 'Cooke\'s Data Feed', set_id: 4 },
        { label: 'Category Management Fee', set_id: 5 },
        { label: 'G & W Bissel Report', set_id: 6 },
        { label: 'Park City Data Feed', set_id: 7 }
      ],
      items: [],
      headers: []
    }
  },
  props: [
    'store'
  ],
  computed: {
    beginDate () {
      return this.store.output_set.begin_date ? moment(this.store.output_set.begin_date).format('MM/DD/YYYY') : ''
    },
    endDate () {
      return this.store.output_set.end_date ? moment(this.store.output_set.end_date).format('MM/DD/YYYY') : ''
    },
    feeAmount () {
      return (this.store.output_set.fee_amount * -1).toFixed(2)
    }
  },
  methods: {
    resize () {
      this.windowHeight = window.innerHeight
    }
  }
}
</script>
<style scoped>
.groups {
  overflow-y: scroll;
}
</style>
