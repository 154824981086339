export const notification = {
  methods: {
    notify(type, message) {
      this.$store.dispatch('setSnackbar', { status: type, text: message })
      this.showLoader('', false)
    },

    showLoader (message, show) {
      const payload = {
        message: message,
        show: show
      }
      this.$root.$emit('loader', payload)
    },

    showErrors (payload) {
      this.$root.$emit('show-errors', payload)
    }
  }
}
