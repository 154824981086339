<template>
  <v-container class="ma-0">
    <v-data-table
      :height="tableSize"
      :items.sync="subGroups"
      :headers="headers"
      :items-per-page="pageSize"
      :loading="loading"
      :search="search"
      loader-height="2"
      hide-default-footer
      fixed-header
      dense
      item-key="id"
      class="table"
      loading-text="Loading... Please wait"
      @click:row="editSubGroup"
    >

    <template v-slot:top>
      <v-toolbar flat color="#fafafa" class="toolbar">
      <v-toolbar-title>Sub-Group Maintenance</v-toolbar-title>
      <v-divider class="mx-4" inset vertical/>
      <v-col cols="3" class="mt-6">
        <v-text-field dense outlined background-color="#fff" v-model="search"
          prepend-inner-icon="mdi-filter-outline" label ="Filter Sub-Groups" />
      </v-col>
      </v-toolbar>
      <v-divider/>
    </template>

    <template v-slot:item.meat_billing_exclude="{ item }">
      <span v-if="item.meat_billing_exclude === false" class="ml-12">No</span>
      <span v-else class="ml-12">Yes</span>
    </template>

    <template v-slot:item.inactive="{ item }">
      <span v-if="item.inactive === false" class="ml-1">Yes</span>
      <span v-else class="ml-1">No</span>
    </template>

    <template v-slot:item.rebate_deduction_pct="{ item }">
      {{formatRebateDeduction(item.rebate_deduction_pct)}} %
    </template>

    <template v-slot:footer>
      <v-divider></v-divider>
      <v-toolbar flat color="#fafafa">
      </v-toolbar>
    </template>

  </v-data-table>

  <v-dialog v-model="subGroupModal" persistent width="600">
    <v-card color="#fafafa">
      <v-toolbar color="#1C3144" dark flat>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="!edit">New Sub-Group Setup</v-toolbar-title>
          <v-toolbar-title v-else>{{subGroup.description}}</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row>
          <v-col cols="3">
            <v-text-field outlined dense background-color="#fff" label="Sub Code" v-model="subGroup.code"/>
          </v-col>
          <v-col cols="9">
            <v-text-field outlined dense background-color="#fff" label="Description" v-model="subGroup.description"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-autocomplete outlined dense background-color="#fff" label="Rebate Deduction" :items="deductions" item-text="label" item-value="value" v-model="subGroup.rebate_deduction_pct"/>
          </v-col>
          <v-col cols="8">
            <v-autocomplete outlined dense background-color="#fff" label="Managing Office" :items="officeCodes" item-text="title" item-value="id"
              v-model="subGroup.office_code"/>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="5">
            <v-checkbox label="Exclude from Meat Billing" v-model="subGroup.exclude_from_meat_billing"/>
          </v-col>
          <v-col cols="4">
            <v-checkbox label="Inactive" v-model="subGroup.inactive"/>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-toolbar flat color="#fafafa">
        <v-spacer></v-spacer>
        <v-btn height="40" color="#00B24A" class="white--text" @click="saveSubGroup">
          <span v-if="!edit">Save<v-icon class="ml-1" dense>mdi-checkbox-marked-circle-outline</v-icon></span>
          <span v-else>Update<v-icon class="ml-1" dense>mdi-update</v-icon></span>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>

  <v-snackbar outlined v-model="notification" :timeout="timeout" color="#00B24A" top class="notification">
    {{ text }}<v-icon color="#fff">mdi-check-circle</v-icon>
  </v-snackbar>
  </v-container>
</template>

<script>
import maintenance from '../../axios/maintenance/endpoints'
import { store } from '@/store/index'

export default {
  name: 'SubGroups',
  data () {
    return {
      edit: false,
      new: false,
      subGroup: {},
      subGroupModal: false,
      pageSize: 200,
      loading: true,
      notification: false,
      text: '',
      timeout: 2000,
      tableSize: '',
      windowHeight: '',
      search: '',
      headers: [
        {
          text: '',
          sortable: false,
          filterable: false,
          value: ''
        },
        {
          text: 'Sub Code',
          sortable: true,
          filterable: true,
          value: 'code'
        },
        {
          text: 'Description',
          sortable: true,
          filterable: true,
          value: 'description'
        },
        {
          text: 'Rebate Deduction',
          sortable: true,
          filterable: true,
          value: 'rebate_deduction_pct'
        },
        {
          text: 'Exclude from Meat Billing',
          sortable: true,
          filterable: true,
          value: 'meat_billing_exclude'
        },
        {
          text: 'Active',
          sortable: true,
          filterable: true,
          value: 'inactive'
        }
      ],
      deductions: [
        { label: '0.00 %', value: 0 },
        { label: '15.00 %', value: 0.15000000596046448 }
      ],
      subGroups: [],
      officeCodes: [
        { title: 'No Office', id: 0 },
        { title: 'Alliance - Nashville', id: 1 },
        { title: 'Alliance - Memphis', id: 2 },
        { title: 'Alliance - Ft. Worth', id: 3 },
        { title: 'Alliance - Kansas City', id: 4 }
      ]
    }
  },
  watch: {
    windowHeight: {
      handler () {
        this.tableSize = this.windowHeight - 225
      }
    }
  },
  created () {
    if (!store.state.userGroups.includes('AdMan_Admin')) {
      this.$router.push({ path: '/' })
    }
    this.tableSize = this.windowHeight - 225
    window.addEventListener('resize', this.resize)
    this.resize()
    this.getSubGroups()
  },
  methods: {
    resize () {
      this.windowHeight = window.innerHeight
    },
    close () {
      this.edit = false
      this.new = false
      this.subGroupModal = false
    },
    formatRebateDeduction (value) {
      return (Math.round(value * 100)).toFixed(2)
    },
    getSubGroups () {
      maintenance.getSubGroupMaintenance()
        .then(response => {
          this.subGroups = response
          this.loading = false
        })
    },
    newSubGroup () {
      this.new = true
      this.subGroupModal = true
    },
    editSubGroup (subGroup) {
      console.log(subGroup)
      this.edit = true
      this.subGroup = subGroup
      this.subGroupModal = true
    },
    saveSubGroup () {
      maintenance.updateSubGroup(this.subGroup).then(response => {
        this.text = response.status
        this.notification = true
        this.getSubGroups()
      }).catch(err => {
        console.log(err)
      })
      this.subGroupModal = false
      this.subGroup = {}
      this.new = false
      this.edit = false
    }
  }
}
</script>
