var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "ma-0",
      attrs: { value: _vm.value, persistent: "", width: "1300" }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(_vm._s(_vm.selectedAccrual.accrual_type) + " Accrual")
              ]),
              _c("v-spacer"),
              !_vm.edit
                ? _c(
                    "div",
                    { domProps: { innerHTML: _vm._s(_vm.accrualHTML) } },
                    [_vm._v(_vm._s(this.accrualHTML))]
                  )
                : _vm.edit && !_vm.finished
                ? _c("p", { staticClass: "pt-4" }, [
                    _c("strong", [_vm._v("Accrual ID:")]),
                    _vm._v(" " + _vm._s(_vm.accrual.id))
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "active ma-0 pa-6" },
            [
              _vm.selectedAccrual.accrual_type === "Non-Standard" ||
              _vm.selectedAccrual.accrual_type === "Non-standard"
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              items: _vm.suppliers,
                              "item-text": "description",
                              "item-value": "id",
                              label: "Supplier",
                              placeholder: "Select a Supplier",
                              "return-object": "",
                              autocomplete: "off"
                            },
                            model: {
                              value: _vm.accrual.supplier,
                              callback: function($$v) {
                                _vm.$set(_vm.accrual, "supplier", $$v)
                              },
                              expression: "accrual.supplier"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              value: _vm.formattedBeginDate,
                              label: "Begin Date",
                              placeholder: "Enter Begin Date",
                              "prepend-inner-icon": "mdi-calendar",
                              autocomplete: "off"
                            },
                            model: {
                              value: _vm.accrual.beginning_date,
                              callback: function($$v) {
                                _vm.$set(_vm.accrual, "beginning_date", $$v)
                              },
                              expression: "accrual.beginning_date"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              value: _vm.formattedEndDate,
                              label: "End Date",
                              placeholder: "Enter End Date",
                              "prepend-inner-icon": "mdi-calendar",
                              autocomplete: "off"
                            },
                            model: {
                              value: _vm.accrual.ending_date,
                              callback: function($$v) {
                                _vm.$set(_vm.accrual, "ending_date", $$v)
                              },
                              expression: "accrual.ending_date"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              value: _vm.formattedBillingDate,
                              label: "Billing Date",
                              placeholder: "Enter Billing Date",
                              "prepend-inner-icon": "mdi-calendar",
                              autocomplete: "off"
                            },
                            model: {
                              value: _vm.accrual.billing_date,
                              callback: function($$v) {
                                _vm.$set(_vm.accrual, "billing_date", $$v)
                              },
                              expression: "accrual.billing_date"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", align: "right" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                disabled: !Object.prototype.hasOwnProperty.call(
                                  _vm.accrual,
                                  "id"
                                ),
                                height: "40",
                                color: "#D32F2F"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.accrualDelete = true
                                }
                              }
                            },
                            [
                              _vm._v(" Delete"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-trash-can-outline")]
                              )
                            ],
                            1
                          ),
                          _vm.selectedAccrual.accrual_type !== "Standard"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "white--text ml-2",
                                  attrs: {
                                    disabled: !Object.prototype.hasOwnProperty.call(
                                      _vm.accrual,
                                      "id"
                                    ),
                                    height: "40",
                                    color: "#F9A825"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.accrualCopy = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(" Copy"),
                                  _c("v-icon", { attrs: { dense: "" } }, [
                                    _vm._v("mdi-content-duplicate")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text ml-2",
                              attrs: { height: "40", color: "#00B24A" },
                              on: { click: _vm.saveAccrual }
                            },
                            [
                              _vm._v(" Save"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-checkbox-marked-circle-outline")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              items: _vm.ads,
                              "item-text": "adName",
                              "item-value": "adId",
                              label: "Ad",
                              placeholder: "Select an Ad",
                              autocomplete: "off"
                            },
                            model: {
                              value: _vm.accrual.ad_id,
                              callback: function($$v) {
                                _vm.$set(_vm.accrual, "ad_id", $$v)
                              },
                              expression: "accrual.ad_id"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "7", align: "right" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                disabled: !Object.prototype.hasOwnProperty.call(
                                  _vm.accrual,
                                  "id"
                                ),
                                height: "40",
                                color: "#D32F2F"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.accrualDelete = true
                                }
                              }
                            },
                            [
                              _vm._v(" Delete"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-trash-can-outline")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text ml-2",
                              attrs: { height: "40", color: "#00B24A" },
                              on: { click: _vm.saveAccrual }
                            },
                            [
                              _vm._v(" Save"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-checkbox-marked-circle-outline")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c("v-divider"),
          _c("v-data-table", {
            staticClass: "table",
            attrs: {
              headers: _vm.itemHeaders,
              items: _vm.items,
              height: _vm.tableSize,
              "items-per-page": _vm.pageSize,
              loading: _vm.loading,
              search: _vm.search,
              "sort-by": "id",
              "show-select": "",
              dense: "",
              "hide-default-footer": "",
              "fixed-header": ""
            },
            on: {
              "update:items": function($event) {
                _vm.items = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.item.order_code",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(item.item.order_code) +
                          " - " +
                          _vm._s(item.item.description) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.accrual",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.formatCurrency(item.accrual)))
                      ])
                    ]
                  }
                },
                {
                  key: "item.case_BB",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.formatCurrency(item.case_BB)))
                      ])
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedItems,
              callback: function($$v) {
                _vm.selectedItems = $$v
              },
              expression: "selectedItems"
            }
          }),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "800" },
              model: {
                value: _vm.accrualItem,
                callback: function($$v) {
                  _vm.accrualItem = $$v
                },
                expression: "accrualItem"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", flat: "", color: "#1C3144" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.accrualItem = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _vm.type === "add"
                        ? _c("v-toolbar-title", [_vm._v("Add Accrual Item")])
                        : _vm.type === "edit"
                        ? _c("v-toolbar-title", [_vm._v("Edit Accrual Item")])
                        : _c("v-toolbar-title", [_vm._v("Copy Accrual Item")])
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "ma-0 py-6 px-6" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  "auto-select-first": "",
                                  outlined: "",
                                  dense: "",
                                  "background-color": "#fff",
                                  items: _vm.subGroups,
                                  "item-text": "description",
                                  "item-value": "id",
                                  label: "Sub-Group",
                                  placeholder: "Select Sub-Group",
                                  "menu-props": { overflowY: true },
                                  "return-object": ""
                                },
                                model: {
                                  value: _vm.item.sub_group,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "sub_group", $$v)
                                  },
                                  expression: "item.sub_group"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  "auto-select-first": "",
                                  outlined: "",
                                  dense: "",
                                  "background-color": "#fff",
                                  items: _vm.manufacturers,
                                  "item-text": "description",
                                  "item-value": "id",
                                  label: "Manufacturer",
                                  placeholder: "Select Manufacturer",
                                  "return-object": ""
                                },
                                model: {
                                  value: _vm.item.manufacturer,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "manufacturer", $$v)
                                  },
                                  expression: "item.manufacturer"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                ref: "order_code",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "background-color": "#fff",
                                  label: "Item",
                                  "item-value": "adId",
                                  hint: "Press Enter to View Linked Group Items"
                                },
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.getByOrderCode(_vm.itemDetail)
                                  }
                                },
                                model: {
                                  value: _vm.itemDetail,
                                  callback: function($$v) {
                                    _vm.itemDetail = $$v
                                  },
                                  expression: "itemDetail"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "background-color": "#fff",
                                  label: "Accrual Amt",
                                  placeholder: "0.00",
                                  prefix: "$"
                                },
                                on: { change: _vm.setAccrualAmt },
                                model: {
                                  value: _vm.item.accrual,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "accrual", $$v)
                                  },
                                  expression: "item.accrual"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "background-color": "#fff",
                                  label: "Case BB",
                                  placeholder: "0.00",
                                  prefix: "$",
                                  hint: "Press Enter to Save Item"
                                },
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.saveAccrualItem($event)
                                  }
                                },
                                model: {
                                  value: _vm.item.case_BB,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "case_BB", $$v)
                                  },
                                  expression: "item.case_BB"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-data-table", {
                    staticClass: "table",
                    attrs: {
                      headers: _vm.linkedItemHeaders,
                      items: _vm.linkedItems,
                      height: _vm.linkedGroupTable,
                      "items-per-page": _vm.pageSize,
                      "item-key": "orderCode",
                      "no-data-text": "No Linked Items Available",
                      "show-select": "",
                      dense: "",
                      "hide-default-footer": "",
                      "fixed-header": ""
                    },
                    on: {
                      "update:items": function($event) {
                        _vm.linkedItems = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function() {
                          return [
                            _c(
                              "v-toolbar",
                              { attrs: { flat: "" } },
                              [
                                _c("v-spacer"),
                                _c("strong", [
                                  _c("p", { staticClass: "pt-4" }, [
                                    _vm._v("Linked Group Items")
                                  ])
                                ]),
                                _c("v-spacer")
                              ],
                              1
                            ),
                            _c("v-divider")
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.selectedLinkedItems,
                      callback: function($$v) {
                        _vm.selectedLinkedItems = $$v
                      },
                      expression: "selectedLinkedItems"
                    }
                  }),
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "#fafafa" } },
                    [
                      _vm.saving
                        ? _c("v-toolbar-title", { staticClass: "operation" }, [
                            _vm._v(
                              _vm._s(_vm.action) +
                                " " +
                                _vm._s(_vm.count) +
                                " / " +
                                _vm._s(_vm.total)
                            )
                          ])
                        : _vm._e(),
                      _vm.saved
                        ? _c("v-toolbar-title", { staticClass: "operation" }, [
                            _vm._v(
                              _vm._s(_vm.action) +
                                " " +
                                _vm._s(_vm.complete) +
                                " / " +
                                _vm._s(_vm.total)
                            )
                          ])
                        : _vm._e(),
                      _vm.itemSaved
                        ? _c("v-toolbar-title", { staticClass: "operation" }, [
                            _vm._v("Item Saved")
                          ])
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selectedLinkedItems.length > 1,
                              expression: "selectedLinkedItems.length > 1"
                            }
                          ],
                          staticClass:
                            "ml-6 primary--text font-weight-bold font-italic"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.selectedLinkedItems.length) +
                              " Selected (Includes lookup item) "
                          )
                        ]
                      ),
                      _c("v-spacer"),
                      _vm.selectedLinkedItems.length > 0
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                disabled: _vm.saving,
                                height: "40",
                                color: "#00B24A"
                              },
                              on: { click: _vm.addLinkedItems }
                            },
                            [
                              _vm._v(" Add Linked Items"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-playlist-plus")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "400" },
              model: {
                value: _vm.editItems,
                callback: function($$v) {
                  _vm.editItems = $$v
                },
                expression: "editItems"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", flat: "", color: "#1C3144" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.editItems = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c("v-toolbar-title", [_vm._v("Edit Accrual Items")])
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "ma-0 py-4 px-6" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  "auto-select-first": "",
                                  outlined: "",
                                  dense: "",
                                  "background-color": "#fff",
                                  items: _vm.manufacturers,
                                  "item-text": "description",
                                  "menu-props": {
                                    overflowY: true,
                                    maxHeight: 140
                                  },
                                  "item-value": "id",
                                  label: "Manufacturer",
                                  placeholder: "Select Manufacturer",
                                  "return-object": ""
                                },
                                model: {
                                  value: _vm.item.manufacturer,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "manufacturer", $$v)
                                  },
                                  expression: "item.manufacturer"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "background-color": "#fff",
                                  label: "Accrual Amt",
                                  placeholder: "0.00",
                                  prefix: "$"
                                },
                                on: { change: _vm.setAccrualAmt },
                                model: {
                                  value: _vm.item.accrual,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "accrual", $$v)
                                  },
                                  expression: "item.accrual"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "background-color": "#fff",
                                  label: "Case BB",
                                  placeholder: "0.00",
                                  prefix: "$"
                                },
                                on: { change: _vm.setCaseBB },
                                model: {
                                  value: _vm.item.case_BB,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "case_BB", $$v)
                                  },
                                  expression: "item.case_BB"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "#fafafa" } },
                    [
                      _vm.updating
                        ? _c("v-toolbar-title", { staticClass: "operation" }, [
                            _vm._v(
                              "Updating " +
                                _vm._s(_vm.count) +
                                " / " +
                                _vm._s(_vm.total)
                            )
                          ])
                        : _vm._e(),
                      _vm.updated
                        ? _c("v-toolbar-title", { staticClass: "operation" }, [
                            _vm._v(
                              "Updated " +
                                _vm._s(_vm.complete) +
                                " / " +
                                _vm._s(_vm.total)
                            )
                          ])
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "#00B24A" },
                          on: { click: _vm.bulkEdit }
                        },
                        [
                          _vm._v(" Update"),
                          _c("v-icon", { staticClass: "ml-1" }, [
                            _vm._v("mdi-update")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { justify: "center", persistent: "", "max-width": "400" },
              model: {
                value: _vm.accrualItemCopy,
                callback: function($$v) {
                  _vm.accrualItemCopy = $$v
                },
                expression: "accrualItemCopy"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { color: "#fafafa" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", flat: "", color: "#1C3144" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.accrualItemCopy = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c("v-toolbar-title", [
                        _vm._v(
                          "Copy " +
                            _vm._s(_vm.selectedItems.length) +
                            " Items to Sub-Groups"
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "ma-0" },
                    [
                      _c("v-data-table", {
                        staticClass: "table",
                        attrs: {
                          headers: _vm.subGroupheaders,
                          items: _vm.subGroups,
                          "items-per-page": 200,
                          height: "353",
                          dense: "",
                          "hide-default-footer": "",
                          "show-select": "",
                          "fixed-header": "",
                          "item-key": "id"
                        },
                        model: {
                          value: _vm.selectedSubGroups,
                          callback: function($$v) {
                            _vm.selectedSubGroups = $$v
                          },
                          expression: "selectedSubGroups"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "#fafafa" } },
                    [
                      _c("v-spacer"),
                      _vm.copied
                        ? _c("v-toolbar-title", [
                            _vm._v(
                              "Copied " +
                                _vm._s(_vm.complete) +
                                " / " +
                                _vm._s(_vm.total)
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text ml-2",
                          attrs: {
                            disabled: _vm.copying,
                            height: "40",
                            color: "#00B24A"
                          },
                          on: { click: _vm.copyAccrualItems }
                        },
                        [
                          _vm._v(" Save"),
                          _c(
                            "v-icon",
                            { staticClass: "ml-1", attrs: { dense: "" } },
                            [_vm._v("mdi-checkbox-marked-circle-outline")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { justify: "center", persistent: "", "max-width": "700" },
              model: {
                value: _vm.accrualCopy,
                callback: function($$v) {
                  _vm.accrualCopy = $$v
                },
                expression: "accrualCopy"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { color: "#fafafa" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", flat: "", color: "#1C3144" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.copyingAccrual,
                            icon: "",
                            dark: ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.accrualCopy = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _vm.accrualCopy
                        ? _c("v-toolbar-title", [
                            _vm._v(
                              "Copy " +
                                _vm._s(_vm.accrual.id) +
                                " - " +
                                _vm._s(_vm.accrual.supplier.name)
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "active ma-0 pa-6" },
                    [
                      _vm.accrual.accrual_type === "Non-Standard"
                        ? _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "background-color": "#fff",
                                      label: "Begin Date",
                                      placeholder: "Enter Begin Date",
                                      autocomplete: "off"
                                    },
                                    on: { change: _vm.formatBeginDateCopy },
                                    model: {
                                      value: _vm.copy.beginning_date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.copy,
                                          "beginning_date",
                                          $$v
                                        )
                                      },
                                      expression: "copy.beginning_date"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "background-color": "#fff",
                                      label: "End Date",
                                      placeholder: "Enter End Date",
                                      autocomplete: "off"
                                    },
                                    on: { change: _vm.formatEndDateCopy },
                                    model: {
                                      value: _vm.copy.ending_date,
                                      callback: function($$v) {
                                        _vm.$set(_vm.copy, "ending_date", $$v)
                                      },
                                      expression: "copy.ending_date"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "background-color": "#fff",
                                      label: "Billing Date",
                                      placeholder: "Enter Billing Date",
                                      autocomplete: "off"
                                    },
                                    on: { change: _vm.formatBillingDateCopy },
                                    model: {
                                      value: _vm.copy.billing_date,
                                      callback: function($$v) {
                                        _vm.$set(_vm.copy, "billing_date", $$v)
                                      },
                                      expression: "copy.billing_date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "#fafafa" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            loading: _vm.copyingAccrual,
                            height: "40",
                            color: "#00B24A"
                          },
                          on: { click: _vm.copyAccrual }
                        },
                        [
                          _vm._v(" Save"),
                          _c(
                            "v-icon",
                            { staticClass: "ml-1", attrs: { dense: "" } },
                            [_vm._v("mdi-checkbox-marked-circle-outline")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "500" },
              model: {
                value: _vm.copyConfirm,
                callback: function($$v) {
                  _vm.copyConfirm = $$v
                },
                expression: "copyConfirm"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { color: "#fafafa" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", flat: "", color: "#1C3144" } },
                    [_c("v-toolbar-title", [_vm._v("Copy Complete")])],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-6" },
                    [
                      _c("v-row", { attrs: { justify: "center" } }, [
                        _c("h3", [
                          _vm._v("Accrual ID: " + _vm._s(_vm.accrualCopyID))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { height: "40", color: "#0c67a5" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.copyConfirm = false
                            }
                          }
                        },
                        [_vm._v(" Okay ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { justify: "center", persistent: "", "max-width": "300" },
              model: {
                value: _vm.accrualDelete,
                callback: function($$v) {
                  _vm.accrualDelete = $$v
                },
                expression: "accrualDelete"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { color: "#fafafa" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-6" },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { "x-large": "", color: "orange" } },
                            [_vm._v("mdi-alert-circle-outline")]
                          )
                        ],
                        1
                      ),
                      _c("v-row", { attrs: { justify: "center" } }, [
                        _c("h3", [_vm._v("Are You Sure?")])
                      ]),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "elevation-1",
                              attrs: { height: "40" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.accrualDelete = false
                                }
                              }
                            },
                            [_vm._v("No")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text elevation-1 ml-2",
                              attrs: { height: "40", color: "#0c67a5" },
                              on: { click: _vm.deleteAccrual }
                            },
                            [_vm._v("Yes")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "#fafafa" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          "max-width": "200",
                          outlined: "",
                          dense: "",
                          label: "Filter Accrual Items",
                          "prepend-inner-icon": "mdi-filter-outline",
                          "hide-details": "",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _vm.finished
                    ? _c("v-toolbar-title", { staticClass: "operation" }, [
                        _vm._v("Accrual " + _vm._s(_vm.action))
                      ])
                    : _vm._e(),
                  _vm.removed || _vm.copied
                    ? _c("v-toolbar-title", { staticClass: "operation" }, [
                        _vm._v(
                          _vm._s(_vm.action) +
                            " " +
                            _vm._s(_vm.complete) +
                            " / " +
                            _vm._s(_vm.total) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm.removing || _vm.copying
                    ? _c("v-toolbar-title", { staticClass: "operation" }, [
                        _vm._v(
                          _vm._s(_vm.action) +
                            " " +
                            _vm._s(_vm.count) +
                            " / " +
                            _vm._s(_vm.total)
                        )
                      ])
                    : _vm._e(),
                  _c("v-spacer"),
                  _vm.selectedItems.length > 0
                    ? _c(
                        "v-menu",
                        {
                          attrs: { top: "", "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            height: "40",
                                            color: "grey lighten-3"
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " Selected (" +
                                            _vm._s(_vm.selectedItems.length) +
                                            ") "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3604664593
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { color: "#F9A825 !important" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.accrualItemCopy = true
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v(" Copy"),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { color: "#F9A825" }
                                        },
                                        [_vm._v("mdi-playlist-plus")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { color: "#00B24A !important" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.editItems = true
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v(" Update"),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { color: "#00B24A" }
                                        },
                                        [_vm._v("mdi-update")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticStyle: { color: "#d32f2f !important" },
                                  on: { click: _vm.deleteAccrualItem }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v(" Remove"),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { color: "#d32f2f" }
                                        },
                                        [_vm._v("mdi-playlist-remove")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text ml-2",
                      attrs: {
                        disabled: !Object.prototype.hasOwnProperty.call(
                          _vm.accrual,
                          "id"
                        ),
                        height: "40",
                        color: "#0c67a5"
                      },
                      on: {
                        click: function($event) {
                          return _vm.openAccrualItem(null, "add")
                        }
                      }
                    },
                    [
                      _vm._v(" Add Item"),
                      _c("v-icon", { attrs: { dense: "" } }, [
                        _vm._v("mdi-plus")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ErrorModal", {
        attrs: { errors: _vm.errors },
        model: {
          value: _vm.errorModal,
          callback: function($$v) {
            _vm.errorModal = $$v
          },
          expression: "errorModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }