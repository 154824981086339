<template>
<v-container class="ma-0">
  <v-data-table
      :height="tableSize"
      :items.sync="owners"
      :headers="headers"
      :items-per-page="pageSize"
      :loading="loading"
      :search="search"
      loader-height="2"
      hide-default-footer
      fixed-header
      dense
      item-key="id"
      class="table"
      loading-text="Loading... Please wait"
      @click:row="editOwner"
    >

    <template v-slot:top>
      <v-toolbar flat color="#fafafa" class="toolbar">
      <v-toolbar-title>Owner Group Maintenance</v-toolbar-title>
      <v-divider class="mx-4" inset vertical/>
      <v-col cols="3" class="mt-6">
        <v-text-field dense outlined background-color="#fff" v-model="search"
          prepend-inner-icon="mdi-filter-outline" label ="Filter Owners" />
      </v-col>
      </v-toolbar>
      <v-divider/>
    </template>

    <template v-slot:footer>
      <v-divider></v-divider>
      <v-toolbar flat color="#fafafa">
      </v-toolbar>
    </template>

  </v-data-table>

  <v-dialog v-model="ownerModal" persistent width="1300">
      <v-card color="#fafafa">
        <v-toolbar color="#1C3144" dark flat>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="!edit">New Owner Setup</v-toolbar-title>
          <v-toolbar-title v-else>{{owner.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title v-show="edit">ID: {{owner.id}}</v-toolbar-title>
        </v-toolbar>
        <v-container class="ma-0 pa-6">
          <v-row>
            <v-col cols="4">
              <v-text-field v-model="owner.name" outlined dense background-color="#fff" label="Owner Name"/>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="owner.address1" outlined dense background-color="#fff" label="Address 1"/>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="owner.address2" outlined dense background-color="#fff" label="Address 2"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field v-model="owner.city" outlined dense background-color="#fff" label="City"/>
            </v-col>
            <v-col cols="2">
              <v-autocomplete v-model="owner.state" :items="states" item-text="state" item-value="state" outlined dense background-color="#fff" label="State"/>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="owner.zip_code" outlined dense background-color="#fff" label="Zip Code"/>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="owner.phone" outlined dense background-color="#fff" label="Work Phone" type="tel"/>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="owner.cell_phone" outlined dense background-color="#fff" label="Cell Phone" type="tel"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field v-model="owner.contact_first_name" outlined dense background-color="#fff" label="Contact First Name"/>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="owner.contact_last_name" outlined dense background-color="#fff" label="Contact Last Name"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="owner.comments" outlined dense background-color="#fff" label="Comments"/>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5">
              <v-text-field outlined dense background-color="#fff" rows="2" v-model="owner.email1" label="Email 1" @change="setEmail1"/>
            </v-col>
            <v-col cols="5">
              <v-text-field outlined dense background-color="#fff" rows="2" v-model="owner.email2" label="Email 2" @change="setEmail2"/>
            </v-col>
            <v-col cols="2">
              <v-checkbox class="ma-0" label="IPS Ineligible" v-model="owner.ips_ineligible"/>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-toolbar flat color="#fafafa">
          <small v-show="owner.update_date !== null && edit" class="ml-1">Last Updated: {{owner.update_by}} - {{formatDate(owner.update_date)}}</small>
          <v-spacer></v-spacer>
          <v-btn height="40" color="#00B24A" class="white--text" @click="saveOwnerGroup">
            <span v-if="!edit">Save<v-icon class="ml-1" dense>mdi-checkbox-marked-circle-outline</v-icon></span>
            <span v-else>Update<v-icon class="ml-1" dense>mdi-update</v-icon></span>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>

  <v-snackbar outlined v-model="notification" :timeout="timeout" color="#00B24A" top class="notification">
    {{ text }}<v-icon color="#fff">mdi-check-circle</v-icon>
  </v-snackbar>
  </v-container>
</template>

<script>
import maintenance from '../../axios/maintenance/endpoints'
import moment from 'moment'
import { store } from '@/store/index'

export default {
  name: 'Owners',
  data () {
    return {
      new: false,
      edit: false,
      offset: true,
      owner: {},
      ownerModal: false,
      pageSize: 500,
      loading: true,
      notification: false,
      text: '',
      timeout: 2000,
      tableSize: '',
      windowHeight: '',
      search: '',
      firstEmails: [],
      secondEmails: [],
      headers: [
        {
          text: '',
          sortable: false,
          filterable: false,
          value: ''
        },
        {
          text: 'Name',
          sortable: true,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Address1',
          sortable: true,
          filterable: true,
          value: 'address1'
        },
        {
          text: 'Address2',
          sortable: true,
          filterable: true,
          value: 'address2'
        },
        {
          text: 'City',
          sortable: true,
          filterable: true,
          value: 'city'
        },
        {
          text: 'State',
          sortable: true,
          filterable: true,
          value: 'state'
        },
        {
          text: 'Zip Code',
          sortable: true,
          filterable: true,
          value: 'zip_code'
        },
        {
          text: 'Phone',
          sortable: true,
          filterable: true,
          value: 'phone'
        },
        {
          text: 'Cell Phone',
          sortable: true,
          filterable: true,
          value: 'cell_phone'
        }
      ],
      owners: [],
      states: [
        { state: 'AL' },
        { state: 'AK' },
        { state: 'AZ' },
        { state: 'AR' },
        { state: 'CA' },
        { state: 'CO' },
        { state: 'CT' },
        { state: 'DE' },
        { state: 'FL' },
        { state: 'GA' },
        { state: 'HI' },
        { state: 'ID' },
        { state: 'IL' },
        { state: 'IN' },
        { state: 'IA' },
        { state: 'KS' },
        { state: 'KY' },
        { state: 'LA' },
        { state: 'ME' },
        { state: 'MD' },
        { state: 'MA' },
        { state: 'MI' },
        { state: 'MN' },
        { state: 'MS' },
        { state: 'MO' },
        { state: 'MT' },
        { state: 'NE' },
        { state: 'NV' },
        { state: 'NH' },
        { state: 'NJ' },
        { state: 'NM' },
        { state: 'NY' },
        { state: 'NC' },
        { state: 'ND' },
        { state: 'OH' },
        { state: 'OK' },
        { state: 'OR' },
        { state: 'PA' },
        { state: 'RI' },
        { state: 'SC' },
        { state: 'SD' },
        { state: 'TN' },
        { state: 'TX' },
        { state: 'UT' },
        { state: 'VT' },
        { state: 'VA' },
        { state: 'WA' },
        { state: 'WV' },
        { state: 'WI' },
        { state: 'WY' }
      ]
    }
  },
  watch: {
    windowHeight: {
      handler () {
        this.tableSize = this.windowHeight - 225
      }
    }
  },
  created () {
    if (!store.state.userGroups.includes('AdMan_Admin')) {
      this.$router.push({ path: '/' })
    }
    this.tableSize = this.windowHeight - 225
    window.addEventListener('resize', this.resize)
    this.resize()
    this.getOwnerGroups()
  },
  methods: {
    resize () {
      this.windowHeight = window.innerHeight
    },
    formatDate (date) {
      return moment(date).format('MM/DD/YYYY')
    },
    close () {
      this.edit = false
      this.new = false
      this.ownerModal = false
      this.owner = {}
    },
    setEmail1 (emails) {
      this.owner.email1 = emails.replace(/\n/, ' ')
    },
    setEmail2 (emails) {
      this.owner.email2 = emails.replace(/\n/, ' ')
    },
    getOwnerGroups () {
      maintenance.getOwnerGroups().then(response => {
        this.owners = response
        this.loading = false
      })
    },
    newOwner () {
      this.new = true
      this.ownerModal = true
    },
    editOwner (owner) {
      this.edit = true
      this.ownerModal = true
      this.owner = owner
    },
    saveOwnerGroup () {
      if (this.new === true) {
        this.owner = {
          address1: this.owner.address1,
          address2: this.owner.address2,
          cell_phone: this.owner.cell_phone,
          city: this.owner.city,
          comments: this.owner.comments,
          contact_first_name: this.owner.contact_first_name,
          contact_last_name: this.owner.contact_last_name,
          email1: this.owner.email1,
          email2: this.owner.email2,
          ips_ineligible: this.owner.ips_ineligible,
          name: this.owner.name,
          phone: this.owner.phone,
          state: this.owner.state,
          update_by: this.owner.update_by,
          update_date: this.owner.update_date,
          zip_code: this.owner.zip_code
        }
        maintenance.newOwnerGroup(this.owner).then(response => {
          this.text = response.status
          this.notification = true
        }).catch(err => {
          console.log(err)
        })
      } else {
        maintenance.updateOwnerGroup(this.owner).then(response => {
          this.text = response.status
          this.notification = true
        }).catch(err => {
          console.log(err)
        })
      }
      this.ownerModal = false
      this.edit = false
      this.new = false
    }
  }
}
</script>
