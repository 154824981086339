// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsCookieStorage = {
  cookieStorage: {
    domain: process.env.VUE_APP_AWS_COOKIE_LOCATION,
    path: '/',
    expires: 30,
    secure: false
  }
}

export default awsCookieStorage
