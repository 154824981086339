var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c("v-data-table", {
        staticClass: "table",
        attrs: {
          height: _vm.tableSize,
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.contracts,
          "items-per-page": _vm.pageSize,
          search: _vm.search,
          "loader-height": "2",
          color: "#fff",
          "loading-text": "Loading Contracts... Please Wait",
          "no-data-text": "No Matching Contracts Found",
          "no-results-text": "No Matching Contracts Found",
          "fixed-header": "",
          "hide-default-footer": "",
          dense: ""
        },
        on: {
          "update:items": function($event) {
            _vm.contracts = $event
          },
          "click:row": _vm.editContract
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { cursor: "default" },
                      attrs: { flat: "", color: "#fafafa" }
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        {
                          staticClass: "ml-2 title",
                          on: { click: _vm.getContracts }
                        },
                        [_vm._v("Contracts")]
                      ),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" }
                      }),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-7",
                            attrs: {
                              "background-color": "#fff",
                              "max-width": "200",
                              outlined: "",
                              dense: "",
                              label: "Contract ID",
                              "prepend-inner-icon": "mdi-magnify",
                              placeholder: "Find By Contract ID",
                              "single-lin": "",
                              autocomplete: "off"
                            },
                            on: {
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.getContract($event)
                              }
                            },
                            model: {
                              value: _vm.contractID,
                              callback: function($$v) {
                                _vm.contractID = _vm._n($$v)
                              },
                              expression: "contractID"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-7",
                            attrs: {
                              "background-color": "#fff",
                              label: "Contract Name",
                              placeholder: "Filter By Contract Name",
                              "prepend-inner-icon": "mdi-filter-outline",
                              outlined: "",
                              dense: "",
                              autocomplete: "off"
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" }
                      }),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { right: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "white--text ml-2",
                                        staticStyle: { background: "#0D68A5" },
                                        attrs: {
                                          to: { path: "/new-contract" },
                                          height: "40",
                                          color: "#0D68A5"
                                        }
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Create New Contract")])]
                      )
                    ],
                    1
                  ),
                  _c("v-divider")
                ]
              },
              proxy: true
            },
            {
              key: "item.AdDate",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [_vm._v(_vm._s(_vm.formatDates(item.AdDate)))])
                ]
              }
            },
            {
              key: "item.TPRBeginDate",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatDates(item.TPRBeginDate)) +
                        " - " +
                        _vm._s(_vm.formatDates(item.TPREndDate))
                    )
                  ])
                ]
              }
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "#fafafa" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            {
                              staticStyle: { "max-width": "150px" },
                              attrs: { cols: "2" }
                            },
                            [
                              _c("v-select", {
                                staticClass: "mt-6",
                                attrs: {
                                  dense: "",
                                  items: _vm.pageSizes,
                                  label: "Items Per Page",
                                  select: "",
                                  "menu-props": {
                                    top: true,
                                    offsetY: true,
                                    maxHeight: 500
                                  }
                                },
                                model: {
                                  value: _vm.pageSize,
                                  callback: function($$v) {
                                    _vm.pageSize = $$v
                                  },
                                  expression: "pageSize"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                icon: "",
                                large: "",
                                disabled: _vm.disablePrevious
                              },
                              on: {
                                click: function($event) {
                                  return _vm.previous()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                            1
                          ),
                          _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                icon: "",
                                large: "",
                                disabled: _vm.disableNext
                              },
                              on: {
                                click: function($event) {
                                  return _vm.next()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout, color: _vm.type, top: "" },
          model: {
            value: _vm.notification,
            callback: function($$v) {
              _vm.notification = $$v
            },
            expression: "notification"
          }
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [
            _vm._v(
              " " +
                _vm._s(
                  this.type === "success"
                    ? "mdi-checkbox-marked-circle-outline"
                    : "mdi-close-circle-outline"
                ) +
                " "
            )
          ]),
          _vm._v(_vm._s(_vm.text) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }