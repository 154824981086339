import { accruals } from './accruals'
// import { Auth } from 'aws-amplify'
import { store } from '@/store'

export default class endpoints {
  static async getAccruals (type, start, end) {
    accruals.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return accruals({
      url: '/meat_accruals/' + type + '/' + start + '/' + end,
      method: 'GET'
    })
  }

  static async getAccrualById (accrualId) {
    accruals.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return accruals({
      url: '/meat_accrual/' + accrualId,
      method: 'GET'
    })
  }

  static async getAccrualItems (accrualId) {
    accruals.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return accruals({
      url: '/meat_accrual_items/' + accrualId,
      method: 'GET'
    })
  }

  static async saveAccrual (accrual) {
    accruals.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return accruals({
      url: '/meat_accrual',
      method: 'POST',
      data: accrual
    })
  }

  static async updateAccrual (accrual) {
    accruals.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return accruals({
      url: '/meat_accrual',
      method: 'PUT',
      data: accrual
    })
  }

  static async deleteAccrual (accrual) {
    accruals.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return accruals({
      url: '/meat_accrual',
      method: 'DELETE',
      data: accrual
    })
  }

  static async saveAccrualItem (accrualItem) {
    accruals.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return accruals({
      url: '/meat_accrual_item',
      method: 'POST',
      data: accrualItem
    })
  }

  static async updateAccrualItem (accrualItem) {
    accruals.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return accruals({
      url: '/meat_accrual_item',
      method: 'PUT',
      data: accrualItem
    })
  }

  static async deleteAccrualItem (accrualItem) {
    accruals.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return accruals({
      url: '/meat_accrual_item',
      method: 'DELETE',
      data: accrualItem
    })
  }
}
