<template>
  <v-container class="ma-0">
    <v-data-table
      :height="tableSize"
      :items.sync="stores"
      :headers="headers"
      :items-per-page="pageSize"
      :loading="loading"
      :search="search"
      loader-height="2"
      hide-default-footer
      fixed-header
      dense
      item-key="id"
      class="table"
      loading-text="Loading... Please wait"
      @click:row="editStore"
    >

    <template v-slot:[`item.begin_date`]="{ item }">
      <span>{{format(item.begin_date)}}</span>
    </template>

    <template v-slot:[`item.end_date`]="{ item }">
      <span>{{format(item.end_date)}}</span>
    </template>

    <template v-slot:[`item.fee_amount`]="{ item }">
      <span>${{formatCurrency(item.fee_amount)}}</span>
    </template>

    <template v-slot:top>
      <v-toolbar flat color="#fafafa" class="toolbar">
      <v-toolbar-title>Stores Maintenance</v-toolbar-title>
      <v-divider class="mx-4" inset vertical/>
      <v-col cols="3" class="mt-7">
        <v-autocomplete :items="storesList" item-text="title" item-value="id" :value="storesList[0]" dense outlined
          background-color="#fff" label="Select Output Store Set" @change="changeStoreList"/>
      </v-col>
      <v-divider class="mx-4" inset vertical/>
      <v-col cols="3" class="mt-6">
        <v-text-field dense outlined background-color="#fff" v-model="search"
          prepend-inner-icon="mdi-filter-outline" label ="Filter Stores" />
      </v-col>
      </v-toolbar>
      <v-divider/>
    </template>

    <template v-slot:footer>
      <v-divider/>
      <v-toolbar flat color="#fafafa">
      </v-toolbar>
    </template>

    </v-data-table>

    <v-dialog v-model="storeModal" persistent width="1300">
      <v-card color="#fafafa">
        <v-toolbar color="#1C3144" dark flat>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="!edit">New Store Setup</v-toolbar-title>
          <v-toolbar-title v-else>{{store.name}}</v-toolbar-title>
        </v-toolbar>
        <v-container class="ma-0">
          <v-tabs background-color="#fafafa" grow :active-class="'active'">
            <v-tabs-slider color="#0c67a5"/>
            <v-tab v-for="tab in tabs" :key="tab.name" v-model="active_tab">
              {{tab.name}}
            </v-tab>
            <v-tab-item v-for="tab in tabs" :key="tab.content">
              <component :is="tab.content" :store="store"></component>
            </v-tab-item>
          </v-tabs>
        </v-container>
        <v-divider/>
        <v-toolbar flat color="#fafafa">
          <v-col cols="3">
            <small v-show="store.last_updated_by !== null">Last Updated: {{store.last_updated_by}} - {{format(store.last_updated_date)}}</small>
          </v-col>
          <v-spacer/>
          <v-btn height="40" color="#00B24A" class="white--text" @click="saveStore">
            <span v-if="!edit">Save<v-icon class="ml-1" dense>mdi-checkbox-marked-circle-outline</v-icon></span>
            <span v-else>Update<v-icon class="ml-1" dense>mdi-update</v-icon></span>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>

    <v-snackbar outlined v-model="notification" :timeout="timeout" color="#00B24A" top class="notification">
      {{ text }}<v-icon color="#fff">mdi-check-circle</v-icon>
    </v-snackbar>

  </v-container>
</template>

<script>
import BasicInfo from '../../components/maintenance/basicInfo'
import GroupAssignments from '../../components/maintenance/groupAssignments'
import ExclusionsInclusions from '../../components/maintenance/exclusionsInclusions'
import Comments from '../../components/maintenance/comments'
import TerminateAccount from '../../components/maintenance/terminateAccount'
import OutputSetManagement from '../../components/maintenance/outputSetMgmt'
import maintenance from '../../axios/maintenance/endpoints'
import moment from 'moment'
import { store } from '@/store/index'

export default {
  name: 'Stores',
  data () {
    return {
      new: false,
      edit: false,
      radioGroup: true,
      active_tab: 'Basic Info',
      radios: '1',
      pageSize: 2000,
      loading: true,
      notification: false,
      text: '',
      timeout: 2000,
      tableSize: '',
      windowHeight: '',
      search: '',
      store: {},
      storeSet: false,
      storeModal: false,
      storesList: [
        { title: 'All Stores', id: 0 },
        { title: 'Produce Reporting Fee', id: 1 },
        { title: 'Retailer Analytics Subscription', id: 2 },
        { title: 'Revionics Data Feed', id: 3 },
        { title: 'Cooke\'s Data Feed', id: 4 },
        { title: 'Category Management Fee', id: 5 },
        { title: 'G & W Bissel Report', id: 6 },
        { title: 'Park City Data Feed', id: 7 }
      ],
      tabs: [
        { name: 'Basic Info', content: 'BasicInfo' },
        { name: 'Group Assignments', content: 'GroupAssignments' },
        { name: 'Exclusions and Inclusions', content: 'ExclusionsInclusions' },
        { name: 'Comments', content: 'Comments' },
        { name: 'Terminate Account', content: 'TerminateAccount' },
        { name: 'Output Set Management', content: 'OutputSetManagement' }
      ],
      headers: [
        { text: '', sortable: false, filterable: false, value: '' },
        { text: 'Scan Data', sortable: true, filterable: true, value: 'exclusions.scan_data' },
        { text: 'Store Number', sortable: true, filterable: true, value: 'id' },
        { text: 'Store Name', sortable: true, filterable: true, value: 'name' },
        { text: 'Address 1', sortable: true, filterable: true, value: 'address1' },
        { text: 'Address 2', sortable: true, filterable: true, value: 'address2' },
        { text: 'City', sortable: true, filterable: true, value: 'city' },
        { text: 'State', sortable: true, filterable: true, value: 'state' },
        { text: 'Zip Code', sortable: true, filterable: true, value: 'zip_code' }
      ],
      stores: []
    }
  },
  components: {
    BasicInfo,
    GroupAssignments,
    ExclusionsInclusions,
    Comments,
    TerminateAccount,
    OutputSetManagement
  },
  watch: {
    windowHeight: {
      handler () {
        this.tableSize = this.windowHeight - 225
      }
    }
  },
  created () {
    if (!store.state.userGroups.includes('AdMan_Admin')) {
      this.$router.push({ path: '/' })
    }
    this.tableSize = this.windowHeight - 225
    window.addEventListener('resize', this.resize)
    this.resize()
    this.getStores()
  },
  methods: {
    resize () {
      this.windowHeight = window.innerHeight
    },
    format (value) {
      return moment(value).format('MM/DD/YYYY')
    },
    formatCurrency (value) {
      if (value === 0) {
        return value
      } else {
        return ((value * -1) * 100 / 100).toFixed(2)
      }
    },
    close () {
      this.edit = false
      this.storeModal = false
    },
    changeStoreList (value) {
      this.loading = true
      if (value === 0) {
        this.getStores()
      } else {
        this.stores = []
        maintenance.getStores().then(response => {
          response.forEach(storeObject => {
            if (value === storeObject.output_set.set_id && storeObject.output_set.set_id !== null) {
              this.stores.push(storeObject)
            }
          })
          this.loading = false
        })
      }
    },
    getStores () {
      this.stores = []
      maintenance.getStores().then(response => {
        this.stores = response
        this.loading = false
      })
    },
    editStore (value) {
      this.store = value
      this.edit = true
      this.storeModal = true
    },
    saveStore () {
      maintenance.updateStore(this.store).then(response => {
        this.text = response.status
        this.notification = true
        this.getStores()
      }).catch(err => {
        console.log(err)
      })
      this.storeModal = false
      this.store = {}
      this.new = false
      this.edit = false
    }
  }
}
</script>

<style scoped>
.active {
  background: #fafafa;
  color: #0c67a5 !important;
}
</style>
