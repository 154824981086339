import axios from 'axios'
// import _ from 'lodash'
import { Auth } from 'aws-amplify'

export const base = axios.create({
  baseURL: process.env.VUE_APP_API_GATEWAY,
  withCredentials: false
})

base.interceptors.request.use(function (config) {
  return new Promise((resolve) => {
    Auth.currentSession()
      .then((session) => {
        var idTokenExpire = session.getIdToken().getExpiration()
        var refreshToken = session.getRefreshToken()
        var currentTimeSeconds = Math.round(+new Date() / 1000)

        if (idTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser()
            .then((res) => {
              res.refreshSession(refreshToken, (err, data) => {
                if (err) {
                  Auth.signOut()
                } else {
                  config.headers.Authorization = data.getIdToken().getJwtToken()
                  resolve(config)
                }
              })
            })
        } else {
          config.headers.Authorization = session.getIdToken().getJwtToken()
          resolve(config)
        }
      }).catch(() => {
        // No logged-in user: don't set auth header
        Auth.signOut()
        resolve(config)
      })
  })
})

// base.interceptors.request.use(request => {
//   let data
//   if (_.has(request.data, 'body')) {
//     data = request.data.body
//     request.data = data
//   }
//   return request
// }, error => {
//   return Promise.reject(error)
// })

base.interceptors.response.use(response => {
  const data = response.data
  return data
}, error => {
  return Promise.reject(error)
})
