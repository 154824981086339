var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "#fafafa" } },
        [
          _c(
            "v-row",
            { staticClass: "pt-2", attrs: { dense: "" } },
            [
              _c("v-toolbar-title", { staticClass: "ml-3 mt-2 title" }, [
                _vm._v("Recap Additions")
              ]),
              _c("v-divider", {
                staticClass: "mx-4",
                attrs: { vertical: "", inset: "" }
              }),
              _c(
                "v-col",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "prepend-inner-icon": "mdi-filter-outline",
                      outlined: "",
                      dense: "",
                      "background-color": "#fff",
                      label: "Year",
                      items: _vm.years,
                      "item-text": "description",
                      "item-value": "id",
                      "menu-props": { maxHeight: 215 }
                    },
                    model: {
                      value: _vm.filters.year,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "year", $$v)
                      },
                      expression: "filters.year"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "prepend-inner-icon": "mdi-filter-outline",
                      outlined: "",
                      dense: "",
                      "background-color": "#fff",
                      label: "Period",
                      items: _vm.periods,
                      "item-text": "description",
                      "item-value": "id",
                      "menu-props": { maxHeight: 215 }
                    },
                    model: {
                      value: _vm.filters.period,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "period", $$v)
                      },
                      expression: "filters.period"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "prepend-inner-icon": "mdi-filter-outline",
                      outlined: "",
                      dense: "",
                      "background-color": "#fff",
                      label: "Component",
                      items: _vm.components,
                      "item-text": "description",
                      "item-value": "id",
                      "menu-props": { maxHeight: 215 }
                    },
                    model: {
                      value: _vm.filters.component,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "component", $$v)
                      },
                      expression: "filters.component"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-inner-icon": "mdi-filter-outline",
                      outlined: "",
                      dense: "",
                      "background-color": "#fff",
                      label: "Store #"
                    },
                    model: {
                      value: _vm.filters.store_id,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "store_id", $$v)
                      },
                      expression: "filters.store_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-inner-icon": "mdi-filter-outline",
                      outlined: "",
                      dense: "",
                      "background-color": "#fff",
                      label: "Comment"
                    },
                    model: {
                      value: _vm.filters.comment,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "comment", $$v)
                      },
                      expression: "filters.comment"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "white--text mx-2 mt-1",
                                attrs: {
                                  disabled: !_vm.billingUser,
                                  height: "40",
                                  color: "#0D68A5"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.modal = true
                                  }
                                }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Create Recap Addition")])]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        staticClass: "table",
        attrs: {
          items: _vm.items,
          headers: _vm.headers,
          height: _vm.tableSize,
          "items-per-page": _vm.pageSize,
          loading: _vm.loading,
          "loader-height": "2",
          search: _vm.search,
          "loading-text": "Loading Recap Additions...Please wait",
          dense: "",
          "hide-default-footer": "",
          "fixed-header": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.amount",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(_vm.formatCurrency(item.amount)) + " ")
                ]
              }
            },
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        disabled: !_vm.billingUser,
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          ;(_vm.selectedRecap = item),
                                            (_vm.modal = true)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { staticClass: "edit" }, [
                                      _vm._v("mdi-square-edit-outline")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v("Edit")])]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("v-divider"),
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "#fafafa" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-spacer"),
              _c("v-spacer"),
              _vm.filters
                ? _c("v-col", { attrs: { cols: "2" } }, [
                    _c("i", [
                      _c("p", { staticClass: "blue--text" }, [
                        _vm._v(_vm._s(_vm.items.length) + " Results")
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-col",
                { staticStyle: { "max-width": "150px" }, attrs: { cols: "2" } },
                [
                  _c("v-select", {
                    staticClass: "mt-6",
                    attrs: {
                      dense: "",
                      items: _vm.pageSizes,
                      label: "Items Per Page",
                      select: "",
                      "menu-props": { top: true, offsetY: true, maxHeight: 500 }
                    },
                    model: {
                      value: _vm.pageSize,
                      callback: function($$v) {
                        _vm.pageSize = $$v
                      },
                      expression: "pageSize"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { icon: "", large: "", disabled: _vm.disablePrevious },
                  on: {
                    click: function($event) {
                      return _vm.previous()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { icon: "", large: "", disabled: _vm.disableNext },
                  on: {
                    click: function($event) {
                      return _vm.next()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("RecapAdditionModal", {
        key: _vm.componentKey,
        attrs: {
          value: _vm.modal,
          selectedRecap: _vm.selectedRecap,
          components: _vm.components,
          scopes: _vm.scopes,
          periods: _vm.periods,
          years: _vm.years,
          stores: _vm.stores,
          adGroups: _vm.adGroups,
          getRecapAdditions: _vm.getRecapAdditions
        },
        on: {
          "update:value": function($event) {
            _vm.modal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }