<template>
  <v-container class="ma-0">
    <v-toolbar flat color="#fafafa">
      <v-row dense class="pt-2">
        <v-toolbar-title class="ml-3 mt-2 title">Period Closing</v-toolbar-title>
        <v-divider vertical inset class="mx-4" />
        <v-col style="max-width:200px;">
          <v-autocomplete prepend-inner-icon="mdi-filter-outline" outlined dense background-color="#fff" label="Year" :items="years" item-text="description"
            item-value="id" :menu-props="{ maxHeight: 215 }" v-model="year" />
        </v-col>
        <v-col style="max-width:200px;">
          <v-autocomplete prepend-inner-icon="mdi-filter-outline" outlined dense background-color="#fff" label="Period" :items="periods" item-text="description"
            item-value="id" :menu-props="{ maxHeight: 215 }" v-model="period"/>
        </v-col>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="!billingUser || !period || !year" v-on="on" class="white--text mx-2 mt-1" height="40" color="green" @click="getClosingSteps(period, year)">
              GO
            </v-btn>
          </template>
          <span>Start Closing</span>
        </v-tooltip>
      </v-row>
    </v-toolbar>
    <v-divider />
    <v-data-table :items.sync="items" item-key="step_id" :headers="headers" :items-per-page="pageSize" :height="tableSize" class="table" :loading="loading"
      loader-height="2" :search="search" loading-text="Loading Closing Steps...Please wait" no-data-text="Select a Period and Year" dense hide-default-footer
      fixed-header>

      <template v-slot:[`item.status`]="{ item }">
        <span v-if="item.status" :class="getStatusFont(item.status)">{{ item.status }}</span>
        <span v-else>Pending Execution</span>
      </template>

      <template v-slot:[`item.confirmed`]="{ item }">
        <span v-if="item.stage === 'Pre-check'">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn :disabled="!billingUser" v-on="on" icon
                @click="confirmStep(item)" v-show="!item.confirmed">
                <v-icon class="edit" color="red">mdi-close</v-icon>
              </v-btn>
              <span v-show="item.confirmed"><v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon></span>
            </template>
            <span>{{item.confirmed ? 'Step confirmed' : 'Click to confirm'}}</span>
          </v-tooltip>
        </span>
        <span v-else>&nbsp;</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="!billingUser || item.status !=='Failed'" v-on="on" icon
              @click="showConfirmation(item, 'restart')" v-show="item.stage==='Closing' || item.stage === 'Post'">
              <v-icon class="edit">mdi-restart</v-icon>
            </v-btn>
          </template>
          <span>Restart</span>
        </v-tooltip>
      </template>

    </v-data-table>
    <v-divider />
    <v-toolbar flat color="#fafafa">
      <v-btn :disabled="!canSubmit" height="40" color="#0c67a5" class="white--text" v-if="!canPost"
        @click="showConfirmation({}, 'submit')">
        Start Closing<v-icon color="#fff" class="ml-1">mdi-arrow-right-drop-circle-outline</v-icon>
      </v-btn>
      <v-btn height="40" color="#0c67a5" class="white--text" v-if="canPost"
        @click="showConfirmation({}, 'post')">
        Post Recaps<v-icon color="#fff" class="ml-1">mdi-arrow-right-drop-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <Component :is="component" :key="componentKey" :value.sync="modal" :period="period" :year="year" :selectedStep="selectedStep" 
        :action="action" :getClosingSteps="getClosingSteps" />

  </v-container>
</template>

<script>
import { notification } from '@/mixins/notification'
import { tableSettings } from '@/mixins/table'

export default {
  name: 'ClosingStepList',

  mixins: [tableSettings, notification],

  components: {
    ConfirmAction: () => import('@/components/closing/confirm')
  },

  data () {
    return {
      component: '',
      componentKey: 0,
      period: null,
      year: null,
      items: [],
      loading: false,
      modal: false,
      search: '',
      selectedStep: {},
      action: '',
      interval: false,
      refreshed: false,
      pageSize: 100,
      pageSizes: [100, 250, 500]
    }
  },

  computed: {
    billingUser () {
      return this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'].includes('AdMan_Billing')
    },

    headers () {
      return [
        { sortable: false, width: '0%' },
        { text: 'Step Name', align: 'start', class: 'black--text', value: 'step_description', width: '11%', groupable: false },
        { text: 'Stage', align: 'start', class: 'black--text', value: 'stage' },
        { text: 'Status', align: 'start', class: 'black--text', value: 'status' },
        { text: 'Confirmed', align: 'start', class: 'black--text', value: 'confirmed' },
        { text: 'Actions', align: 'start', class: 'black--text', value: 'actions' }
      ]
    },

    periods () {
      return [
        { description: 'Period 1', id: 1 },
        { description: 'Period 2', id: 2 },
        { description: 'Period 3', id: 3 },
        { description: 'Period 4', id: 4 },
        { description: 'Period 5', id: 5 },
        { description: 'Period 6', id: 6 },
        { description: 'Period 7', id: 7 },
        { description: 'Period 8', id: 8 },
        { description: 'Period 9', id: 9 },
        { description: 'Period 10', id: 10 },
        { description: 'Period 11', id: 11 },
        { description: 'Period 12', id: 12 },
        { description: 'Period 13', id: 13 }
      ]
    },

    years () {
      const years = []
      const dateStart = this.moment().subtract(3, 'y')
      const dateEnd = this.moment().add(3, 'y')
      while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'))
        dateStart.add(1, 'year')
      }
      return years
    },

    canSubmit() {
      if (this.items.length === 0) {
        return false
      }
      var result = true
      this.items.forEach(item => {
        if (item.stage === "Pre-check" && !item.confirmed) {
          result = false
        }
        if (item.stage === "Closing" && item.status) {
          result = false
        }
      })

      return result
    },

    canPost() {
      if (this.items.length === 0) {
        return false
      }
      var result = true
      this.items.forEach(item => {
        if (item.stage === "Closing" && item.status !== "Complete") {
          result = false
        }
        if (item.stage === "Post" && item.status) {
          result = false
        }
      })

      return result
    }

  },

  watch: {
    modal: {
      handler (value) {
        this.componentKey += 1
        if (!value) {
          this.selectedStep = {}
        }
      }
    }
  },

  created () {
    this.nonTableHeight = 245
    this.interval = setInterval(() => this.reload(), 15000)
  },

  methods: {

    async confirmStep(step) {
      this.selectedStep = step
      this.selectedStep.confirmed = true
      await this.$closing.updateStep(this.selectedStep)
        .then(() => {
          this.notify('success', `Data for ${this.selectedStep.step_description} confirmed`)
          this.getClosingSteps()
          this.selectedStep = {}
        })
    },

    showConfirmation(step, action) {
      this.action = action
      this.component = "ConfirmAction"
      this.selectedStep = step
      this.modal = true
    },

    reload() {
      console.log("calling reload")
      var reload_page = false

      if ((!this.period && !this.year) || this.loading) {
        return
      }

      this.items.some(item => {
        if (item.status === "Running") {
          reload_page = true
          return false
        }
      })

      if (!this.refreshed) {
        reload_page = true
      }
      console.log(reload_page)
      if (reload_page) {
        this.getClosingSteps()
        this.refreshed = true
      } else {
        clearInterval(this.interval)
        this.interval = false
      }
    },

    async getClosingSteps () {
      this.loading = true
      await this.$closing.getClosingSteps(this.period, this.year)
        .then(response => {
          this.items = response
          this.loading = false
        }).catch(err => {
          this.loading = false
          this.notify('error', err)
        })

        if (!this.interval) {
          this.interval = setInterval(() => this.reload(), 15000)
        }
    },

    submitClosing() {
      this.$closing.submitClosing(this.period, this.year)
        .then(() => {
          this.notify('success', `Closing steps submitted`)
          this.getClosingSteps()
        })
    },

    restartStep(step_id, stage) {
      this.$closing.restartStep(this.period, this.year, step_id, stage)
        .then(() => {

        })
    },

    postClosing() {
      this.$closing.postClosing(this.period, this.year)
        .then(() => {
          this.notify('success', `Recap posting submitted`)
          this.getClosingSteps()
        })
    },

    getStatusFont(status) {
      switch(status) {
        case 'Complete':
        case 'Passed':
          return 'green--text'
        case 'Failed':
          return 'font-weight-bold red--text'
        case 'Running':
          return 'font-weight-bold orange--text'
        default:
          return 'black--text'
      }
    }

  }

}
</script>
