import { base } from './base'
// import { store } from '@/store/index'

export default class contracts {
  static async getUploadLog () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/import_log',
      method: 'GET'
    })
  }

  static async getManufacturers () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return base({
      url: '/manufacturers',
      method: 'GET'
    })
  }
}
