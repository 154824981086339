var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            color: "#1C3144",
            "clipped-left": "",
            "clipped-right": "",
            dark: "",
            app: ""
          }
        },
        [
          _c(
            "v-app-bar-nav-icon",
            {
              staticClass: "mr-2",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.drawer = !_vm.drawer
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-menu")])],
            1
          ),
          _c("v-img", {
            attrs: {
              "max-height": "125",
              to: { path: "/contracts" },
              "max-width": "125",
              src: require("./assets/arg_logo_alt.png"),
              alt: "ARG Logo"
            }
          }),
          _c("v-spacer"),
          _vm.development
            ? _c(
                "h2",
                [
                  _c("v-icon", { attrs: { large: "", color: "orange" } }, [
                    _vm._v("mdi-alert-circle-outline")
                  ]),
                  _vm._v(" User Testing Environment ")
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _vm.admin
            ? _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", "offset-x": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mx-2",
                                  attrs: { icon: "", color: "white", dark: "" }
                                },
                                on
                              ),
                              [
                                _c("v-icon", [
                                  _vm._v("mdi-account-cog-outline")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    82275233
                  )
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Maintenance")]),
                      _c("v-divider"),
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        _vm._l(_vm.maintenanceChildren, function(link, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              attrs: {
                                link: "",
                                to: { path: "/maintenance/" + link.route }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { size: "15" } },
                                [_vm._v("mdi-cog")]
                              ),
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(link.text))
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "elevation-0",
          attrs: { color: "#1C3144", temporary: "", clipped: "", app: "" },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "pa-2", staticStyle: { height: "64px" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2",
                          attrs: { block: "", id: "logoutBtn" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.logout()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-logout")
                          ]),
                          _c("span", [_vm._v("Logout")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-list",
            [
              _vm._l(_vm.navLinks, function(parent, index) {
                return [
                  parent.children.length
                    ? _c(
                        "v-list-group",
                        {
                          key: index,
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "white--text" },
                                          [_vm._v(_vm._s(parent.icon))]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "white--text" },
                                      [_vm._v(_vm._s(parent.text))]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: { slot: "appendIcon", color: "white" },
                              slot: "appendIcon"
                            },
                            [_vm._v("mdi-menu-down")]
                          ),
                          _vm._l(parent.children, function(child, index) {
                            return _c(
                              "v-list-item",
                              {
                                key: index,
                                class: {
                                  active: _vm.$route.path === child.route
                                },
                                attrs: { link: "", to: { path: child.route } }
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  { staticClass: "pl-6" },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white" } },
                                      [_vm._v("mdi-menu-right")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "white--text" },
                                  [_vm._v(_vm._s(child.text))]
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    : _c(
                        "v-list-item",
                        {
                          key: index,
                          class: { active: _vm.$route.path === parent.route },
                          attrs: { link: "", to: { path: parent.route } }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v(_vm._s(parent.icon))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-title",
                            { staticClass: "white--text" },
                            [_vm._v(_vm._s(parent.text))]
                          )
                        ],
                        1
                      )
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c("Maintenance", {
            attrs: { value: _vm.maintenanceModal },
            on: {
              "update:value": function($event) {
                _vm.maintenanceModal = $event
              }
            }
          }),
          _c("Loader", {
            attrs: { value: _vm.loader, payload: _vm.payload },
            on: {
              "update:value": function($event) {
                _vm.loader = $event
              }
            }
          }),
          _c("ErrorDisplay", {
            attrs: { value: _vm.errorDisplay, errors: _vm.errors },
            on: {
              "update:value": function($event) {
                _vm.errorDisplay = $event
              }
            }
          }),
          _c(
            "v-snackbar",
            {
              attrs: {
                top: "",
                rounded: "md",
                height: "60",
                "content-class": "body-1",
                transition: "slide-y-transition",
                timeout: _vm.snackbar.timeout,
                color: _vm.snackbar.color
              },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: { icon: "", text: "" },
                            on: {
                              click: function($event) {
                                _vm.snackbar.showing = false
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.snackbar.showing,
                callback: function($$v) {
                  _vm.$set(_vm.snackbar, "showing", $$v)
                },
                expression: "snackbar.showing"
              }
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v(_vm._s(_vm.snackbar.icon))
              ]),
              _vm._v(" " + _vm._s(_vm.snackbar.text) + " ")
            ],
            1
          ),
          _c("router-view")
        ],
        1
      ),
      _c("v-footer", { attrs: { app: "", color: "#1C3144" } }, [
        _c("span", { staticClass: "white--text footer" }, [
          _vm._v("Alliance Retail Group © " + _vm._s(new Date().getFullYear()))
        ])
      ]),
      _c(
        "v-alert",
        {
          staticClass: "white--text",
          staticStyle: { bottom: "150px", left: "3px", "z-index": "10" },
          attrs: { icon: "mdi-cancel", type: "error", width: "248" },
          model: {
            value: _vm.alert,
            callback: function($$v) {
              _vm.alert = $$v
            },
            expression: "alert"
          }
        },
        [_vm._v(" Admin Access Only ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }