var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c("v-data-table", {
        staticClass: "table",
        attrs: {
          height: _vm.tableSize,
          items: _vm.stores,
          headers: _vm.headers,
          "items-per-page": _vm.pageSize,
          loading: _vm.loading,
          search: _vm.search,
          "loader-height": "2",
          "hide-default-footer": "",
          "fixed-header": "",
          dense: "",
          "item-key": "id",
          "loading-text": "Loading... Please wait"
        },
        on: {
          "update:items": function($event) {
            _vm.stores = $event
          },
          "click:row": _vm.editStore
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.begin_date",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [_vm._v(_vm._s(_vm.format(item.begin_date)))])
                ]
              }
            },
            {
              key: "item.end_date",
              fn: function(ref) {
                var item = ref.item
                return [_c("span", [_vm._v(_vm._s(_vm.format(item.end_date)))])]
              }
            },
            {
              key: "item.fee_amount",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v("$" + _vm._s(_vm.formatCurrency(item.fee_amount)))
                  ])
                ]
              }
            },
            {
              key: "top",
              fn: function() {
                return [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "toolbar",
                      attrs: { flat: "", color: "#fafafa" }
                    },
                    [
                      _c("v-toolbar-title", [_vm._v("Stores Maintenance")]),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" }
                      }),
                      _c(
                        "v-col",
                        { staticClass: "mt-7", attrs: { cols: "3" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.storesList,
                              "item-text": "title",
                              "item-value": "id",
                              value: _vm.storesList[0],
                              dense: "",
                              outlined: "",
                              "background-color": "#fff",
                              label: "Select Output Store Set"
                            },
                            on: { change: _vm.changeStoreList }
                          })
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" }
                      }),
                      _c(
                        "v-col",
                        { staticClass: "mt-6", attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "background-color": "#fff",
                              "prepend-inner-icon": "mdi-filter-outline",
                              label: "Filter Stores"
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider")
                ]
              },
              proxy: true
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c("v-divider"),
                  _c("v-toolbar", { attrs: { flat: "", color: "#fafafa" } })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "1300" },
          model: {
            value: _vm.storeModal,
            callback: function($$v) {
              _vm.storeModal = $$v
            },
            expression: "storeModal"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "#fafafa" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "#1C3144", dark: "", flat: "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  !_vm.edit
                    ? _c("v-toolbar-title", [_vm._v("New Store Setup")])
                    : _c("v-toolbar-title", [_vm._v(_vm._s(_vm.store.name))])
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "background-color": "#fafafa",
                        grow: "",
                        "active-class": "active"
                      }
                    },
                    [
                      _c("v-tabs-slider", { attrs: { color: "#0c67a5" } }),
                      _vm._l(_vm.tabs, function(tab) {
                        return _c(
                          "v-tab",
                          {
                            key: tab.name,
                            model: {
                              value: _vm.active_tab,
                              callback: function($$v) {
                                _vm.active_tab = $$v
                              },
                              expression: "active_tab"
                            }
                          },
                          [_vm._v(" " + _vm._s(tab.name) + " ")]
                        )
                      }),
                      _vm._l(_vm.tabs, function(tab) {
                        return _c(
                          "v-tab-item",
                          { key: tab.content },
                          [
                            _c(tab.content, {
                              tag: "component",
                              attrs: { store: _vm.store }
                            })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "#fafafa" } },
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c(
                      "small",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.store.last_updated_by !== null,
                            expression: "store.last_updated_by !== null"
                          }
                        ]
                      },
                      [
                        _vm._v(
                          "Last Updated: " +
                            _vm._s(_vm.store.last_updated_by) +
                            " - " +
                            _vm._s(_vm.format(_vm.store.last_updated_date))
                        )
                      ]
                    )
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { height: "40", color: "#00B24A" },
                      on: { click: _vm.saveStore }
                    },
                    [
                      !_vm.edit
                        ? _c(
                            "span",
                            [
                              _vm._v("Save"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-checkbox-marked-circle-outline")]
                              )
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _vm._v("Update"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-update")]
                              )
                            ],
                            1
                          )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          staticClass: "notification",
          attrs: {
            outlined: "",
            timeout: _vm.timeout,
            color: "#00B24A",
            top: ""
          },
          model: {
            value: _vm.notification,
            callback: function($$v) {
              _vm.notification = $$v
            },
            expression: "notification"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.text)),
          _c("v-icon", { attrs: { color: "#fff" } }, [
            _vm._v("mdi-check-circle")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }