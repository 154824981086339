import { base } from './base'
// import { store } from '@/store/index'

export default class tprs {
  static async getTPRs (start, end, params) {
    return base({
      // url: '/tprs/' + start + '/' + end + '?adgroup=' + tprGroup + '&contractid=' + contractID +
      // '&ordercode=' + orderCode + '&begindate=' + beginDate + '&enddate=' + endDate + '&manufacturer=' + manufacturer,
      url: `/tprs/${start}/${end}`,
      method: 'GET',
      params: params
    })
  }

  static async getTPRGroups () {
    return base({
      url: '/tprGroups',
      method: 'GET'
    })
  }

  static async getContractSelect () {
    return base({
      url: '/contractSelect',
      method: 'GET'
    })
  }

  static async saveTPR (saveTPR) {
    return base({
      url: '/tpr',
      method: 'POST',
      data: saveTPR
    })
  }

  static async copyTPR (copyTPR) {
    return base({
      url: '/tpr',
      method: 'POST',
      data: copyTPR
    })
  }

  static async updateTPR (updateTPR) {
    return base({
      url: '/tpr',
      method: 'PUT',
      data: updateTPR
    })
  }

  static async removeTPR (removeTPR) {
    return base({
      url: '/tpr',
      method: 'DELETE',
      data: removeTPR
    })
  }

  static async calculateSRP (SRP) {
    return base({
      url: '/srp/calculate',
      method: 'POST',
      data: SRP
    })
  }

  static async exportTPRs (adGroup, beginDate) {
    return base({
      url: '/tpr/export',
      method: 'GET',
      params: {
        adgroup: adGroup,
        begindate: beginDate
      },
      responseType: 'blob'
    })
  }
}
