import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import { store } from './store'

//  API
import accruals from '@/axios/meat-accrual-endpoints'
import ads from '@/axios/ad-planner-endpoints'
import contracts from '@/axios/contract-manager-endpoints'
import invoicing from '@/axios/invoice-endpoints'
import limiter from '@/axios/limiter-endpoints'
import maintenance from '@/axios/maintenance-endpoints'
import recaps from '@/axios/recap-addition-endpoints'
import reports from '@/axios/report-endpoints'
import shuttles from '@/axios/shuttle-endpoints'
import tprs from '@/axios/tpr-manager-endpoints'
import uploads from '@/axios/upload-endpoints'
import closing from '@/axios/closing-endpoints'

// Third Party Libraries
import vuetify from './plugins/vuetify'
import moment from 'moment'
import _ from 'lodash'

// Styles
import './assets/styles/global.css'

import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsExports from './config/aws-exports'
import awsCookieStorage from './config/aws-cookieStorage'

// Config
import config from './config/adman_config'

// Sentry
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

let appEnv = process.env.NODE_ENV

// local .env file is ignored by git in this project
if (appEnv === undefined) appEnv = 'local'

Sentry.init({
  Vue: Vue,
  environment: appEnv,
  dsn: 'https://3e944fbe54674c8eacea5e33d28c7ed6@o461793.ingest.sentry.io/5464112',
  integrations: [new Integrations.BrowserTracing()],
  tracingOptions: {
    trackComponents: true
  },
  logErrors: true,
  tracesSampleRate: 1.0
})

Amplify.configure({ ...awsExports, ...awsCookieStorage })
Vue.use(AmplifyPlugin, AmplifyModules)

// API
Vue.prototype.$accruals = accruals
Vue.prototype.$ads = ads
Vue.prototype.$contracts = contracts
Vue.prototype.$invoicing = invoicing
Vue.prototype.$limiter = limiter
Vue.prototype.$maintenance = maintenance
Vue.prototype.$recaps = recaps
Vue.prototype.$reports = reports
Vue.prototype.$shuttles = shuttles
Vue.prototype.$tprs = tprs
Vue.prototype.$uploads = uploads
Vue.prototype.$closing = closing

// Third-Party
Vue.prototype.moment = moment
Vue.prototype._ = _

// Config
Vue.prototype.$config = config

// Vuex
Vue.prototype.$store = store

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
