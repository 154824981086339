import axios from 'axios'
import { Auth } from 'aws-amplify'

export const reporting = axios.create({
  baseURL: process.env.VUE_APP_REPORT_API,
  withCredentials: false
})

reporting.interceptors.request.use(function (config) {
  return new Promise((resolve) => {
    Auth.currentSession()
      .then((session) => {
        var idTokenExpire = session.getIdToken().getExpiration()
        var refreshToken = session.getRefreshToken()
        var currentTimeSeconds = Math.round(+new Date() / 1000)

        if (idTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser()
            .then((res) => {
              res.refreshSession(refreshToken, (err, data) => {
                if (err) {
                  Auth.signOut()
                } else {
                  config.headers.Authorization = data.getIdToken().getJwtToken()
                  resolve(config)
                }
              })
            })
        } else {
          config.headers.Authorization = session.getIdToken().getJwtToken()
          resolve(config)
        }
      }).catch(() => {
        // No logged-in user: don't set auth header
        Auth.signOut()
        resolve(config)
      })
  })
})

reporting.interceptors.response.use(response => {
  const data = response.data
  return data
}, error => {
  console.log(error)
  return Promise.reject(error)
})
