<template>
<v-container class="ma-0">
  <v-data-table
      :height="tableSize"
      :items.sync="featureTypes"
      :headers="headers"
      :items-per-page="pageSize"
      :loading="loading"
      :search="search"
      loader-height="2"
      hide-default-footer
      fixed-header
      dense
      item-key="id"
      class="table"
      loading-text="Loading... Please wait"
      @click:row="editFeatureType"
    >

    <template v-slot:top>
      <v-toolbar flat color="#fafafa" class="toolbar">
      <v-toolbar-title>Feature Types Maintenance</v-toolbar-title>
      <v-divider class="mx-4" inset vertical/>
      <v-col cols="3" class="mt-6">
        <v-text-field dense outlined background-color="#fff" v-model="search"
          prepend-inner-icon="mdi-filter-outline" label ="Filter Feature Types" />
      </v-col>
      </v-toolbar>
      <v-divider/>
    </template>

    <template v-slot:[`item.inactive`]="{ item }">
      <span v-if="item.inactive === false" class="ml-1">Yes</span>
      <span v-else class="ml-1">No</span>
    </template>

    <template v-slot:footer>
      <v-divider></v-divider>
      <v-toolbar flat color="#fafafa">
      </v-toolbar>
    </template>

  </v-data-table>

  <v-dialog v-model="featureTypeModal" persistent width="800">
    <v-card color="#fafafa">
      <v-toolbar color="#1C3144" dark flat>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="!edit">New Feature Type Setup</v-toolbar-title>
        <v-toolbar-title v-else>{{featureType.name}}</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row class="mt-6">
          <v-col cols="7">
            <v-text-field outlined dense background-color="#fff" label="Page Position Name" v-model="featureType.name"/>
          </v-col>
          <v-col cols="3">
            <v-text-field outlined dense background-color="#fff" label="Sequence Number" v-model="featureType.sequence_number"/>
          </v-col>
          <v-col cols="2" class="mb-12">
            <v-text-field outlined dense background-color="#fff" label="Art Code" v-model="featureType.art_code"/>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="1" class="mb-12">
            <v-checkbox label="Inactive" v-model="featureType.inactive"/>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-toolbar flat color="#fafafa">
        <v-spacer></v-spacer>
        <v-btn height="40" color="#00B24A" class="white--text ml-2" @click="saveFeatureType">
          <span v-if="!edit">Save<v-icon class="ml-1" dense>mdi-checkbox-marked-circle-outline</v-icon></span>
          <span v-else>Update<v-icon class="ml-1" dense>mdi-update</v-icon></span>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="notification" :timeout="timeout" color="#00B24A" top class="notification">
    {{ text }}
  </v-snackbar>
  </v-container>
</template>

<script>
import maintenance from '../../axios/maintenance/endpoints'
import { store } from '@/store/index'

export default {
  name: 'FeatureTypes',
  data () {
    return {
      edit: false,
      new: false,
      featureType: {},
      featureTypeModal: false,
      pageSize: 5000,
      loading: true,
      notification: false,
      text: '',
      timeout: 2000,
      tableSize: '',
      windowHeight: '',
      search: '',
      headers: [
        {
          text: '',
          sortable: false,
          filterable: false,
          value: ''
        },
        {
          text: 'Page Position Name',
          sortable: true,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Sequencing Number',
          sortable: true,
          filterable: true,
          value: 'sequence_number'
        },
        {
          text: 'Art Code',
          sortable: true,
          filterable: true,
          value: 'art_code'
        },
        {
          text: 'Active',
          sortable: true,
          filterable: true,
          value: 'inactive'
        }
      ],
      featureTypes: []
    }
  },
  watch: {
    windowHeight: {
      handler () {
        this.tableSize = this.windowHeight - 225
      }
    }
  },
  created () {
    if (!store.state.userGroups.includes('AdMan_Admin')) {
      this.$router.push({ path: '/' })
    }
    this.tableSize = this.windowHeight - 225
    window.addEventListener('resize', this.resize)
    this.resize()
    this.getFeatureTypes()
  },
  methods: {
    resize () {
      this.windowHeight = window.innerHeight
    },
    close () {
      this.edit = false
      this.new = false
      this.featureTypeModal = false
      this.featureType = {}
    },
    getFeatureTypes () {
      maintenance.getFeatureTypes().then(response => {
        this.featureTypes = response
        this.loading = false
      })
    },
    newFeatureType () {
      this.new = true
      this.featureTypeModal = true
    },
    editFeatureType (featureType) {
      this.edit = true
      this.featureTypeModal = true
      this.featureType = featureType
    },
    saveFeatureType () {
      if (this.new === true) {
        this.featureType.inactive = false
        this.featureType = {
          name: this.featureType.name,
          sequence_number: this.featureType.sequence_number,
          art_code: this.featureType.art_code,
          inactive: this.featureType.inactive
        }
        maintenance.newFeatureType(this.featureType).then(response => {
          this.text = response.status
        })
      } else {
        maintenance.updateFeatureType(this.featureType).then(response => {
          this.text = response.status
        })
      }
      this.featureTypeModal = false
      this.notification = true
      this.featureType = {}
      this.new = false
      this.edit = false
      this.getFeatureTypes()
    }
  }
}
</script>
