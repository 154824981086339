<template>
  <div>
    <h1>404</h1>
    <p>Sorry, the page you were looking for could not be found.</p>
    <p>Please select an option from the menu or search for what you are looking for</p>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  data () {
    return {
    }
  }
}
</script>

<style scoped>
div {
  text-align: center;
}

h1 {
  font-size: 10rem;
}

P {
  margin-bottom: 0;
}
</style>
