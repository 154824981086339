var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c(
        "v-toolbar",
        {
          staticStyle: { cursor: "default" },
          attrs: { flat: "", color: "#fafafa" }
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-toolbar-title",
                {
                  staticClass: "ml-2 mt-4 title",
                  staticStyle: { position: "static" },
                  on: { click: _vm.getTPRs }
                },
                [_vm._v("TPRs")]
              ),
              _c("v-divider", {
                staticClass: "mx-4",
                attrs: { inset: "", vertical: "" }
              }),
              _c(
                "v-btn-toggle",
                {
                  staticClass: "mx-1 mt-3 pb-0",
                  staticStyle: { height: "40px" },
                  attrs: { mandatory: "" }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      color: !_vm.isExpanded ? "green" : "red",
                                      height: "40",
                                      disabled: !_vm.isExpanded
                                    },
                                    on: {
                                      click: function($event) {
                                        ;(_vm.isExpanded = false),
                                          _vm.collapseAll()
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v("mdi-collapse-all-outline")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Collapse Rows")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      color: _vm.isExpanded ? "red" : "green",
                                      height: "40",
                                      disabled: _vm.isExpanded
                                    },
                                    on: {
                                      click: function($event) {
                                        ;(_vm.isExpanded = true),
                                          _vm.expandAll()
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v("mdi-expand-all-outline")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Expand Rows")])]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-autocomplete", {
                    staticClass: "mt-2",
                    attrs: {
                      "auto-select-first": "",
                      dense: "",
                      outlined: "",
                      "background-color": "#fff",
                      placeholder: "Select Manufacturer",
                      label: "Manufacturer",
                      "prepend-inner-icon": "mdi-filter-outline",
                      items: _vm.manufacturers,
                      "item-text": "description",
                      "item-value": "id",
                      "open-on-clear": false,
                      clearable: ""
                    },
                    model: {
                      value: _vm.filters.manufacturer,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "manufacturer", $$v)
                      },
                      expression: "filters.manufacturer"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      "background-color": "#fff",
                      outlined: "",
                      dense: "",
                      label: "Order Code",
                      placeholder: "Enter Order Code",
                      "prepend-inner-icon": "mdi-filter-outline",
                      autocomplete: "off",
                      clearable: ""
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getTPRs(_vm.orderCode)
                      }
                    },
                    model: {
                      value: _vm.filters.ordercode,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "ordercode", $$v)
                      },
                      expression: "filters.ordercode"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-autocomplete", {
                    staticClass: "mt-2",
                    staticStyle: { "z-index": "0 !important" },
                    attrs: {
                      "auto-select-first": "",
                      "background-color": "#fff",
                      outlined: "",
                      dense: "",
                      label: "TPR Group",
                      "prepend-inner-icon": "mdi-filter-outline",
                      placeholder: "Select TPR Group",
                      items: _vm.tprGroups,
                      "item-text": "description",
                      "item-value": "id",
                      autocomplete: "off",
                      clearable: ""
                    },
                    model: {
                      value: _vm.filters.adgroup,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "adgroup", $$v)
                      },
                      expression: "filters.adgroup"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      "background-color": "#fff",
                      outlined: "",
                      dense: "",
                      label: "Contract ID",
                      placeholder: "Enter Contract ID",
                      "prepend-inner-icon": "mdi-filter-outline",
                      autocomplete: "off",
                      type: "number",
                      clearable: ""
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getTPRs(_vm.contractID)
                      }
                    },
                    model: {
                      value: _vm.filters.contractid,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "contractid", $$v)
                      },
                      expression: "filters.contractid"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      label: "Begin Date",
                      placeholder: "Enter Begin Date",
                      "prepend-inner-icon": "mdi-filter-outline",
                      autocomplete: "off",
                      clearable: ""
                    },
                    model: {
                      value: _vm.filters.begindate,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "begindate", $$v)
                      },
                      expression: "filters.begindate"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      label: "End Date",
                      placeholder: "Enter End Date",
                      "prepend-inner-icon": "mdi-filter-outline",
                      autocomplete: "off",
                      clearable: ""
                    },
                    model: {
                      value: _vm.filters.enddate,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "enddate", $$v)
                      },
                      expression: "filters.enddate"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        staticClass: "table",
        staticStyle: { cursor: "default" },
        attrs: {
          height: _vm.tableSize,
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.tprs,
          "items-per-page": _vm.pageSize,
          expanded: _vm.expanded,
          "show-select": "",
          search: _vm.search,
          "loader-height": "2",
          "item-key": "id",
          color: "#fff",
          "loading-text": "Loading TPRs... Please Wait",
          "no-data-text": "No Matching TPRs Found",
          "no-results-text": "No Matching TPRs Found",
          "fixed-header": "",
          "hide-default-footer": "",
          dense: "",
          "group-by": "supplier.description"
        },
        on: {
          "update:items": function($event) {
            _vm.tprs = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "group.header",
              fn: function(ref) {
                var group = ref.group
                var isOpen = ref.isOpen
                var toggle = ref.toggle
                var items = ref.items
                var headers = ref.headers
                return [
                  _c(
                    "td",
                    { attrs: { colspan: headers.length } },
                    [
                      _c(
                        "v-btn",
                        {
                          ref: group,
                          attrs: { small: "", icon: "", "data-open": isOpen },
                          on: { click: toggle }
                        },
                        [
                          _c("v-icon", [
                            _vm._v(
                              _vm._s(
                                isOpen ? "mdi-chevron-up" : "mdi-chevron-down"
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.tprs.length
                        ? _c(
                            "span",
                            [
                              !_vm.loading
                                ? _c(
                                    "b",
                                    { staticClass: "primary--text mr-2" },
                                    [_vm._v(_vm._s(group))]
                                  )
                                : _vm._e(),
                              _vm._v(" - "),
                              _c("span", { staticClass: "mx-2" }, [
                                _vm._v(
                                  "Results By Division: " + _vm._s(items.length)
                                )
                              ]),
                              _vm._v(" | "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mx-2",
                                                  attrs: {
                                                    color: "green",
                                                    small: "",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.selectGroup(
                                                        "All",
                                                        items
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "mdi-plus-box-multiple-outline"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v("Select All")])]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: "red",
                                                    small: "",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deselectGroup(
                                                        items
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "mdi-minus-box-multiple-outline"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v("Deselect All")])]
                              ),
                              _vm.isSelected(items)
                                ? _c("b", [
                                    _c(
                                      "span",
                                      { staticClass: "mx-2 primary--text" },
                                      [
                                        _vm._v(
                                          "(" +
                                            _vm._s(_vm.isSelected(items)) +
                                            " Selected)"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var headers = ref.headers
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticClass: "itemDetails",
                      attrs: { colspan: headers.length }
                    },
                    [
                      _c("strong", [_c("small", [_vm._v("Case Cost:")])]),
                      _c("small", [
                        _vm._v(_vm._s(_vm.formatCurrency(item.caseCost)))
                      ]),
                      _c("v-divider", {
                        staticClass: "ml-2 mr-2",
                        attrs: { vertical: "" }
                      }),
                      _c("strong", [_c("small", [_vm._v("AMAP:")])]),
                      _c("small", [
                        _vm._v(_vm._s(_vm.formatCurrency(item.AMAP)))
                      ]),
                      _c("v-divider", {
                        staticClass: "ml-2 mr-2",
                        attrs: { vertical: "" }
                      }),
                      _c("strong", [_c("small", [_vm._v("EBA:")])]),
                      _c("small", [
                        _vm._v(_vm._s(_vm.formatCurrency(item.EBA)))
                      ]),
                      _c("v-divider", {
                        staticClass: "ml-2 mr-2",
                        attrs: { vertical: "" }
                      }),
                      _c("strong", [_c("small", [_vm._v("TPR BB:")])]),
                      _c("small", [
                        _vm._v(_vm._s(_vm.formatCurrency(item.BB)))
                      ]),
                      _c("v-divider", {
                        staticClass: "ml-2 mr-2",
                        attrs: { vertical: "" }
                      }),
                      _c("strong", [_c("small", [_vm._v("TPR Scan:")])]),
                      _c("small", [
                        _vm._v(_vm._s(_vm.formatCurrency(item.scanAllow)))
                      ]),
                      _c("v-divider", {
                        staticClass: "ml-2 mr-2",
                        attrs: { vertical: "" }
                      }),
                      _c("strong", [_c("small", [_vm._v("ePay TPR:")])]),
                      _c("small", [
                        _vm._v(_vm._s(_vm.formatCurrency(item.ePay)))
                      ]),
                      _c("v-divider", {
                        staticClass: "ml-2 mr-2",
                        attrs: { vertical: "" }
                      }),
                      _c("strong", [_c("small", [_vm._v("Reg SRP:")])]),
                      _c("small", [
                        _vm._v(
                          _vm._s(item.regSRPCount) +
                            " @ " +
                            _vm._s(_vm.formatCurrency(item.regSRP))
                        )
                      ]),
                      _c("v-divider", {
                        staticClass: "ml-2 mr-2",
                        attrs: { vertical: "" }
                      }),
                      _c("strong", [_c("small", [_vm._v("TPR Retail:")])]),
                      _c("small", [
                        _vm._v(
                          _vm._s(item.specialSRPCount) +
                            " @ " +
                            _vm._s(_vm.formatCurrency(item.specialSRP))
                        )
                      ]),
                      _c("v-divider", {
                        staticClass: "ml-2 mr-2",
                        attrs: { vertical: "" }
                      }),
                      _c("strong", [
                        _c("small", { staticClass: "mr-2" }, [
                          _vm._v("Internal Comment:")
                        ])
                      ]),
                      _c("small", [
                        item.contract.internalComment
                          ? _c("span", [
                              _vm._v(_vm._s(item.contract.internalComment))
                            ])
                          : _c("span", [_vm._v("N/A")])
                      ])
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.dealBeginDate",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.status === "Active"
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "black--text",
                          attrs: { outlined: "", small: "", color: "#00b24a" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatDates(item.dealBeginDate)) +
                              " - " +
                              _vm._s(_vm.formatDates(item.dealEndDate))
                          )
                        ]
                      )
                    : item.status === "Upcoming"
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "black--text",
                          attrs: { outlined: "", small: "", color: "#f9a724" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatDates(item.dealBeginDate)) +
                              " - " +
                              _vm._s(_vm.formatDates(item.dealEndDate))
                          )
                        ]
                      )
                    : _c(
                        "v-chip",
                        {
                          staticClass: "black--text",
                          attrs: { outlined: "", small: "", color: "#d32f2f" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.formatDates(item.dealBeginDate)) +
                              " - " +
                              _vm._s(_vm.formatDates(item.dealEndDate))
                          )
                        ]
                      )
                ]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    { attrs: { small: "", icon: "" } },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.setComponent(
                                              "TprEdit",
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("mdi-square-edit-outline")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v("Edit")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    { attrs: { small: "", icon: "" } },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.setComponent(
                                              "TprCopy",
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("mdi-content-duplicate")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v("Copy")])]
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c("v-divider"),
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "#fafafa" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _vm.selected.length > 0
                    ? _c(
                        "v-menu",
                        {
                          staticClass: "pb-3",
                          attrs: { top: "", "offset-y": _vm.offset },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            height: "40",
                                            color: "#E0E0E0"
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " Selected (" +
                                            _vm._s(_vm.selected.length) +
                                            ") "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            894457539
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _vm.tprBulkEnabled
                                ? _c(
                                    "v-list-item",
                                    {
                                      staticClass: "ml-1",
                                      staticStyle: {
                                        color: "#00b24a !important"
                                      },
                                      on: {
                                        click: function($event) {
                                          ;(_vm.component = "TprBulkEdit"),
                                            (_vm.modal = true)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _vm._v(" Update"),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                right: "",
                                                color: "#00b24a"
                                              }
                                            },
                                            [_vm._v("mdi-update")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                {
                                  staticStyle: { color: "#d32f2f !important" },
                                  on: {
                                    click: function($event) {
                                      ;(_vm.component = "TprDeleteConfirm"),
                                        (_vm.modal = true)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v(" Remove"),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { right: "", color: "#d32f2f" }
                                        },
                                        [_vm._v("mdi-playlist-remove")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-spacer"),
              _vm.filterResults.length
                ? _c("v-col", { attrs: { cols: "2" } }, [
                    _c("p", { staticClass: "font-weight-light blue--text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.filterResults.length > 1
                            ? _vm.filterResults.length + " Results"
                            : _vm.filterResults.length + " Result"
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _c("v-spacer"),
              _vm.exportAvailable
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        loading: _vm.exporting,
                                        icon: ""
                                      },
                                      on: { click: _vm.exportTPRs }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-microsoft-excel")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2737675091
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.exporting ? "Exporting..." : "Export All")
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticStyle: { "max-width": "150px" }, attrs: { cols: "2" } },
                [
                  _c("v-select", {
                    staticClass: "mt-6",
                    attrs: {
                      dense: "",
                      items: _vm.pageSizes,
                      label: "Items Per Page",
                      select: "",
                      "menu-props": { top: true, offsetY: true, maxHeight: 500 }
                    },
                    model: {
                      value: _vm.pageSize,
                      callback: function($$v) {
                        _vm.pageSize = $$v
                      },
                      expression: "pageSize"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { icon: "", large: "", disabled: _vm.disablePrevious },
                  on: {
                    click: function($event) {
                      return _vm.previous()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { icon: "", large: "", disabled: _vm.disableNext },
                  on: {
                    click: function($event) {
                      return _vm.next()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            justify: "center",
            value: _vm.loader,
            "max-width": "600",
            persistent: ""
          }
        },
        [
          _c("v-card", { attrs: { color: "#fafafa" } }, [
            _c(
              "section",
              { staticClass: "ma-0 pa-6" },
              [
                _c("v-row", { attrs: { dense: "", justify: "center" } }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.operation) + " TPRs...Please Wait")
                  ])
                ]),
                _c(
                  "v-row",
                  { attrs: { dense: "", justify: "center" } },
                  [
                    _c("v-progress-linear", {
                      attrs: { rounded: "", height: "25", color: "primary" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var value = ref.value
                            return [
                              _c(
                                "strong",
                                {
                                  class:
                                    value > 50 ? "white--text" : "black--text"
                                },
                                [_vm._v(_vm._s(Math.ceil(value)) + "%")]
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.progress,
                        callback: function($$v) {
                          _vm.progress = $$v
                        },
                        expression: "progress"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(_vm.component, {
        tag: "Component",
        attrs: {
          getTPRs: _vm.getTPRs,
          selected: _vm.selected,
          selectedTPR: _vm.selectedTPR,
          value: _vm.modal
        },
        on: {
          "update:value": function($event) {
            _vm.modal = $event
          },
          delete: _vm.removeTPRs,
          update: _vm.bulkUpdateTPRs
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }