<template>
  <v-container class="ma-0">

    <v-toolbar flat color="#fafafa" style="cursor:default;">
      <v-row dense>
        <v-toolbar-title class="ml-2 mt-4 title" @click="getAds">Ads</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-col cols="3">
          <v-autocomplete auto-select-first @change="getAds" v-model="adGroup" :items="adGroups"
            item-text="description" item-value="id"
            class="mt-2" background-color="#fff" label="Ad Group Filter" placeholder="Select Ad Group"
            autocomplete="off" outlined dense clearable prepend-inner-icon="mdi-filter-outline" />
        </v-col>

        <v-col cols="2">
          <v-text-field class="mt-2" background-color="#fff" dense outlined label="Ad Date Filter"
            placeholder="Enter Ad Date" v-model="adDate" autocomplete="off" clearable prepend-inner-icon="mdi-filter-outline" />
        </v-col>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="#0D68A5" height="40" class="white--text mt-3 ml-2" @click="setComponent('AdEdit')">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Create New Ad</span>
        </v-tooltip>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>

    <v-data-table style="cursor:default" sort-by="adId" :height="tableSize" :loading="loading" :headers="headers"
      :items.sync="ads" :items-per-page="pageSize" loader-height="2" v-model="selected" show-select
      item-key="adId" color="#fff" class="table" fixed-header hide-default-footer dense
      loading-text="Loading Ads... Please Wait" no-results-text="No Matching Ads Found" no-data-text="No Matching Ads Found">

      <template v-slot:[`item.action`]="{ item }">
        <span class="noWrap">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon small @click="setComponent('AdEdit', item)">
                <v-icon class="edit">mdi-square-edit-outline</v-icon>
              </v-btn>
            </template>
            <span>Edit Ad</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon @click="setComponent('AdCopy', item)">
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>Copy Ad</span>
          </v-tooltip>
        </span>
      </template>

      <template v-slot:[`item.adDate`]="{ item }">
        <span>{{formatDates(item.adDate)}}</span>
      </template>
      <template v-slot:[`item.dateCreated`]="{ item }">
        <span>{{formatDates(item.dateCreated)}}</span>
      </template>

      <template v-slot:footer>
        <v-divider></v-divider>
      </template>

      <template v-slot:[`item.readyForUpload`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="setComponent('A2Ready', item)">
              <v-icon :color="item.readyForUpload ? 'green' : 'red'">
                {{item.readyForUpload ? 'mdi-checkbox-marked-circle-outline' : 'mdi-close'}}
              </v-icon>
            </v-btn>
          </template>
          <span>Change Status</span>
        </v-tooltip>
      </template>

    </v-data-table>

    <v-toolbar flat color="#fafafa">
      <v-row align="center">
        <v-col cols="2">
          <v-btn v-if="selected.length" height="40" color="#0c67a5" class="white--text"
            @click="setComponent('FeatureManagement')">
            Manage Features<v-icon color="#fff" class="ml-1">mdi-square-edit-outline</v-icon>
          </v-btn>
        </v-col>
        <v-spacer/>
        <v-col v-if="selected.length" cols="2" align="center">
          <i>
            <p class="blue--text">{{selected.length}} Selected</p>
          </i>
        </v-col>
        <v-spacer/>
        <v-col cols="2" style="max-width:150px;">
          <v-select class="mt-6" dense :items="pageSizes" label="Items Per Page" select v-model="pageSize"
            :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
          </v-select>
        </v-col>
        <v-btn icon large @click="previous()" :disabled="disablePrevious" class="mx-2">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <small>Page {{page}}</small>
        <v-btn icon large @click="next()" :disabled="disableNext" class="mx-2">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-toolbar>

    <Component :key="componentKey" :is="component" :value.sync="modal" :selectedAd="selectedAd"
      :selectedAds="selected" :getAds="getAds" :adGroup="adGroup" :adDate="adDate"
    />

  </v-container>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'
import { debounce } from 'lodash'

export default {
  name: 'AdList',

  mixins: [tableSettings, notification],

  components: {
    AdEdit: () => import('@/components/ads/ad-edit'),
    AdCopy: () => import('@/components/ads/ad-copy'),
    FeatureManagement: () => import('@/components/ads/feature-management'),
    A2Ready: () => import('@/components/ads/a2-ready')
  },

  data () {
    return {
      adDate: '',
      adGroup: '',
      adGroups: [],
      ads: [],
      component: '',
      componentKey: 0,
      disableNext: false,
      disablePrevious: true,
      loading: true,
      modal: false,
      notification: false,
      offset: true,
      page: 1,
      pageSize: 100,
      selected: [],
      selectedAd: {}
    }
  },

  computed: {
    headers () {
      return [
        { sortable: false },
        { text: 'Ad ID', align: 'left', sortable: true, value: 'adId', class: 'black--text' },
        { text: 'Ad Name', align: 'left', sortable: true, value: 'adName', class: 'black--text' },
        { text: 'Ad Date', align: 'left', sortable: true, value: 'adDate', class: 'black--text' },
        { text: 'Ad Group', align: 'left', sortable: true, value: 'adGroup.description', class: 'black--text' },
        { text: 'Date Created', align: 'left', sortable: true, value: 'dateCreated', class: 'black--text' },
        { text: 'A2 Ready', align: 'left', sortable: true, value: 'readyForUpload', class: 'black--text' },
        { text: 'Actions', align: 'center', sortable: true, value: 'action', class: 'black--text' }
      ]
    },

    formattedAdDate () {
      return this.adDate ? this.moment(this.adDate).format(this.$config.date_display) : ''
    }
  },

  watch: {
    modal: {
      handler (value) {
        this.componentKey += 1
        if (!value) {
          this.selectedAd = {}
        }
      }
    },

    adDate: debounce(function () {
      this.loading = true
      this.getAds()
    }, 1000),

    pageSize: {
      handler () {
        this.ads = []
        this.selected = []
        this.loading = true
        this.page = 1
        this.getAds()
      }
    },

    adGroup: {
      handler () {
        localStorage.setItem('adman_adGroup', JSON.stringify(this.adGroup))
      }
    }
  },

  created () {
    this.nonTableHeight = 245

    if (localStorage.getItem('adman_adGroup') && localStorage.getItem('adman_adDate')) {
      this.adGroup = JSON.parse(localStorage.getItem('adman_adGroup'))
      this.adDate = JSON.parse(localStorage.getItem('adman_adDate'))
    }

    this.getAdGroups()
    this.getAds()
  },

  methods: {
    setComponent (component, ad) {
      if (ad) { this.selectedAd = ad }
      this.component = component
      this.modal = true
    },

    getAdGroups () {
      this.$ads.getAdGroups()
        .then(response => {
          response.forEach(adGroup => {
            if (adGroup.id !== 'ALL') {
              this.adGroups.push(adGroup)
            }
          })
        })
    },

    selectAdDate (value) {
      if (value !== '') {
        this.getAds()
      } else {
        this.adDate = ''
      }
    },

    inputAdDate (date) {
      if (!date) return null
      this.adDate = this.moment(date, 'M/D/YYYY').format('YYYY-MM-DD')
      this.getAds()
    },

    async getAds () {
      this.selected = []
      const start = (this.page - 1) * this.pageSize
      const end = this.pageSize

      try {
        const response = await this.$ads.getAds(start, end, !this.adGroup ? '' : this.adGroup, !this.adDate ? '' : this.adDate)
        this.ads = response
        this.ads.length < this.pageSize
          ? this.disableNext = true
          : this.disableNext = false

        start === 0
          ? this.disablePrevious = true
          : this.disablePrevious = false
      } catch (error) {
        this.notify('error', 'Error Retrieving Ads')
      } finally {
        this.loading = false
      }
    },

    previous () {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      this.getAds()
    },

    next () {
      this.page = this.page + 1
      this.getAds()
    },

    formatDates (date) {
      return date ? this.moment(date).format(this.$config.date_display) : ''
    },

    allowedAdDates: val => ![1, 2, 3, 4, 5, 6].includes(new Date(val).getDay()),

    async deleteAds () {
      const promises = []
      const errors = []

      this.selected.forEach(ad => {
        promises.push(
          this.$ads.deleteAds()
            .then().catch(err => {
              try {
                errors.push(err.response.data)
              } catch {
                errors.push(`${ad.adName} Not Deleted`)
              }
            })
        )
      })
      await Promise.allSettled(promises)
      this.getAds()
      if (errors.length) {
        this.showErrors(errors)
      } else {
        this.notify('success', `${this.selected.length} Ads Successfully Deleted`)
      }
    }
  }
}
</script>

<style scoped>
@import '../assets/styles/global.css';
</style>
