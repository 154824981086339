export const polling = {
  data() {
    return {
      polling: null,
      pollingMethod: ''
    }
  },

  created() {
    this.pollData()
  },

  beforeDestroy () {
    clearInterval(this.polling)
  },

  methods: {
    pollData () {
      this.polling = setInterval(() => {
        this.pollingMethod()
      }, 5000)
    }
  }
}