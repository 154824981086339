
import { base } from './base'
// import { store } from '@/store/index'

export default class billing {
  static async getManualInvoice (invoiceID) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/invoice/manual_invoice/' + invoiceID,
      method: 'GET'
    })
  }

  static async getBatches (offset, limit, status, searchParams) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: `/invoice/batch/${status}/${offset}/${limit}`,
      method: 'GET',
      params: searchParams
    })
  }

  static async getInvoicesByBatch ( batchNumber, status) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: `/invoice/batch_invoices/${batchNumber}/${status}`,
      method: 'GET'
    })
  }

  static async createBatch (invoice) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/invoice/create_batch',
      method: 'POST',
      data: invoice
    })
  }

  static async restartBatch (batchNumber) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/invoice/reprocess_batch/' + batchNumber,
      method: 'POST'
    })
  }

  static async deleteBatch (batchNumber) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/invoice/batch/' + batchNumber,
      method: 'DELETE'
    })
  }

  static async deleteInvoice (invoiceNumber) {

    return base({
      url: '/invoice/' + invoiceNumber,
      method: 'DELETE'
    })
  }

  static async getBatchInvoices (batchNumber) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/invoice/batch_invoices/' + batchNumber,
      method: 'GET'
    })
  }

  static async getInvoiceItems (batchNumber, invoiceNumber) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/invoice/items/' + batchNumber + '/' + invoiceNumber,
      method: 'GET'
    })
  }

  static async getInvoicePayments (invoiceNumber) {
    return base({
      url: `/payments/${invoiceNumber}`,
      method: 'GET'
    })
  }

  static async saveInvoicePayment (payment) {
    return base({
      url: '/payments',
      method: 'POST',
      data: payment
    })
  }

  static async updateInvoicePayment (payment) {
    return base({
      url: '/payments',
      method: 'POST',
      data: payment
    })
  }

  static async deleteInvoicePayment (payment) {
    return base({
      url: '/payments',
      method: 'DELETE',
      data: payment
    })
  }

  static async processManualInvoice (manualInvoice) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/invoice/manual_invoice',
      method: 'POST',
      data: manualInvoice
    })
  }

  static async updateVendor (batchNumber, invoiceNumber, subVendorId) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: `/invoice/update_vendor/${batchNumber}/${invoiceNumber}/${subVendorId}`,
      method: 'PUT'
    })
  }

  static async postInvoice (batchNumber) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/invoice/post_batch/' + batchNumber,
      method: 'POST'
    })
  }

  static async emailInvoice (batchNumber) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/invoice/email_batch/' + batchNumber,
      method: 'POST'
    })
  }

}
