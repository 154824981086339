<template>
  <v-container class="ma-0">
    <v-toolbar flat color="#fafafa">
      <v-row dense class="pt-2">
        <v-toolbar-title class="ml-3 mt-2 title">File Uploads</v-toolbar-title>
        <v-divider vertical inset class="mx-4" />
        <v-col sm="4" md="3">
          <v-text-field outlined dense background-color="#fff" v-model="search" label="Filter Uploads"
            prepend-inner-icon="mdi-filter-outline" />
        </v-col>
        <v-spacer />
        <v-col sm="4" md="3">
          <v-autocomplete outlined dense background-color="#fff" prepend-inner-icon="mdi-file-upload-outline"
            label="Select Upload Type" @change="openModal" return-object v-model="uploadType"
            :items="uploadOptions" item-text="name" item-value="id" :menu-props="{ maxHeight: 215 }">
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider />
    <v-data-table :items="files" :headers="headers" :height="tableSize" class="table" :items-per-page="pageSize"
      :loading="loading" loader-height="2" :search="search" loading-text="Loading File Uploads...Please wait" dense
      no-data-text="No File Uploads to Display" hide-default-footer fixed-header>

      <template v-slot:[`item.file_name`]="{ item }">
        {{formattedFileName(item.file_name)}}
      </template>

      <template v-slot:[`item.upload_time`]="{ item }">
        {{moment.utc(item.upload_time).local().format('MMM D - h:mm a')}}
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip v-if="item.status !== 'Failed'" class="mr-4" small dark :color="getColor(item.status)">
          <span class="text-capitalize" style="display: inline-block; margin: 0 auto; width: 70px; text-align: center">
            {{ item.status === 'Complete' ? 'Uploaded' : item.status }}
          </span>
        </v-chip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip v-if="item.status === 'Failed'" v-on="on" class="mr-4" small dark :color="getColor(item.status)"
              @click="showErrors(item.errors)">
              <span class="text-capitalize"
                style="display: inline-block; margin: 0 auto; width: 70px; text-align: center">
                Errors
              </span>
            </v-chip>
          </template>
          <span>Click to View</span>
        </v-tooltip>
      </template>

    </v-data-table>
    <v-divider />
    <v-toolbar flat color="#fafafa">
      <v-row align="center">
        <v-spacer />
        <v-col cols="2" align="center" class="mt-4">
          <i>
            <p v-if="search" class="blue--text">{{items.length}} Results</p>
          </i>
        </v-col>
        <v-spacer />
        <v-col cols="2" align="right" style="max-width:150px;">
          <v-select :disabled="loading" class="mt-5" dense :items="pageSizes" label="Items Per Page" select
            v-model="pageSize" :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
          </v-select>
        </v-col>
        <v-btn icon large @click="previous()" :disabled="disablePreviousPage" class="mx-2">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        Page {{page}}
        <v-btn icon large @click="next()" :disabled="disableNextPage" class="mx-2">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-toolbar>

    <Component :is="component" :key="componentKey" @refresh="getLogs" :value.sync="modal" :uploadType="uploadType" />

  </v-container>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification' 

export default {
  name: 'FileUploadList',

  mixins: [tableSettings, notification],

  components: {
    FileUploader: () => import('@/components/uploads/file-uploader'),
    TprUpload: () => import('@/components/uploads/tpr-upload')
  },

  data () {
    return {
      component: '',
      componentKey: 0,
      errors: '',
      errorList: false,
      fileUploader: false,
      files: [],
      interval: false,
      modal: false,
      uploadType: {},
      items: [],
      loading: true,
      search: '',
      pageSize: 50,
      pageSizes: [50, 100, 250]
    }
  },

  computed: {
    headers () {
      return [
        {},
        { text: 'User', align: 'start', sortable: true, filterable: true, class: 'black--text', value: 'user' },
        { text: 'File Name', align: 'start', sortable: true, filterable: true, class: 'black--text', value: 'file_name' },
        { text: 'Upload Time', align: 'start', sortable: true, filterable: true, class: 'black--text', value: 'upload_time' },
        { text: 'Upload Status', align: 'center', sortable: true, filterable: true, class: 'black--text', value: 'status' }
      ]
    },

    uploadOptions () {
      return [
        { name: 'AWG Best Choice', directory: 'Best_Choice', info_required: false },
        { name: 'AWG Perishables', directory: 'Perishables', info_required: false },
        { name: 'AWG Shipper File', directory: 'Shipper', info_required: false },
        { name: 'DSD Items', directory: 'DSD_Items', info_required: false },
        { name: 'Invoice Payments', directory: 'Invoice_Payments', info_required: false },
        { name: 'Recap Additions', directory: 'Recap_Additions', info_required: true },
        { name: 'TPR Updates', directory: 'TPR_Updates', info_required: true },
        { name: 'Vendor Emails', directory: 'Vendor_Emails', info_required: false },
        { name: 'Manual Invoices', directory: 'Manual_Invoice', info_required: true }

      ]
    },

    disableNextPage () {
      return this.items.length < this.pageSize
    },

    disablePreviousPage () {
      return this.page === 1
    }
  },

  watch: {
    modal: {
      handler (newValue) {
        this.componentKey += 1
        if (newValue === false) {
          this.uploadType = {}
          setTimeout(() => {
            this.getLogs()
          }, 3000);
        }
      }
    }
  },

  created () {
    this.nonTableHeight = 245
    this.interval = setInterval(() => this.getLogs(), 15000)
    this.getLogs()
  },

  methods: {
    getColor (status) {
      switch (status) {
        case 'Processing':
          return 'amber darken-1'
        case 'Complete':
          return 'green'
        case 'Failed':
          return 'red'
      }
    },

    async getLogs () {
      await this.$uploads.getUploadLog()
        .then(response => {
          this.files = response
        }).catch(() => {
          clearInterval(this.interval)
        })
      this.loading = false
    },

    formattedFileName (fileName) {
      return fileName.split('/').pop()
    },

    getStatusIcon (status) {
      switch (status) {
        case 'Complete':
          return 'mdi-checkbox-marked-circle-outline'
        case 'Failed':
          return 'mdi-alert-outline'
      }
    },

    openModal (value) {
      if (value.directory === 'TPR_Updates') {
        this.component = 'TprUpload'
      } else {
        this.component = 'FileUploader'
      }
      this.modal = true
    },

    previous () {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
    },

    next () {
      this.page = this.page + 1
    }
  },

  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>
