var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c("v-data-table", {
        staticClass: "table",
        attrs: {
          height: _vm.tableSize,
          items: _vm.featureTypes,
          headers: _vm.headers,
          "items-per-page": _vm.pageSize,
          loading: _vm.loading,
          search: _vm.search,
          "loader-height": "2",
          "hide-default-footer": "",
          "fixed-header": "",
          dense: "",
          "item-key": "id",
          "loading-text": "Loading... Please wait"
        },
        on: {
          "update:items": function($event) {
            _vm.featureTypes = $event
          },
          "click:row": _vm.editFeatureType
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "toolbar",
                      attrs: { flat: "", color: "#fafafa" }
                    },
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Feature Types Maintenance")
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" }
                      }),
                      _c(
                        "v-col",
                        { staticClass: "mt-6", attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "background-color": "#fff",
                              "prepend-inner-icon": "mdi-filter-outline",
                              label: "Filter Feature Types"
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider")
                ]
              },
              proxy: true
            },
            {
              key: "item.inactive",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.inactive === false
                    ? _c("span", { staticClass: "ml-1" }, [_vm._v("Yes")])
                    : _c("span", { staticClass: "ml-1" }, [_vm._v("No")])
                ]
              }
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c("v-divider"),
                  _c("v-toolbar", { attrs: { flat: "", color: "#fafafa" } })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "800" },
          model: {
            value: _vm.featureTypeModal,
            callback: function($$v) {
              _vm.featureTypeModal = $$v
            },
            expression: "featureTypeModal"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "#fafafa" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "#1C3144", dark: "", flat: "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  !_vm.edit
                    ? _c("v-toolbar-title", [_vm._v("New Feature Type Setup")])
                    : _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.featureType.name))
                      ])
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "ma-0 pa-6" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "7" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Page Position Name"
                            },
                            model: {
                              value: _vm.featureType.name,
                              callback: function($$v) {
                                _vm.$set(_vm.featureType, "name", $$v)
                              },
                              expression: "featureType.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Sequence Number"
                            },
                            model: {
                              value: _vm.featureType.sequence_number,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.featureType,
                                  "sequence_number",
                                  $$v
                                )
                              },
                              expression: "featureType.sequence_number"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mb-12", attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Art Code"
                            },
                            model: {
                              value: _vm.featureType.art_code,
                              callback: function($$v) {
                                _vm.$set(_vm.featureType, "art_code", $$v)
                              },
                              expression: "featureType.art_code"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mb-12", attrs: { cols: "1" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Inactive" },
                            model: {
                              value: _vm.featureType.inactive,
                              callback: function($$v) {
                                _vm.$set(_vm.featureType, "inactive", $$v)
                              },
                              expression: "featureType.inactive"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "#fafafa" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text ml-2",
                      attrs: { height: "40", color: "#00B24A" },
                      on: { click: _vm.saveFeatureType }
                    },
                    [
                      !_vm.edit
                        ? _c(
                            "span",
                            [
                              _vm._v("Save"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-checkbox-marked-circle-outline")]
                              )
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _vm._v("Update"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-update")]
                              )
                            ],
                            1
                          )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          staticClass: "notification",
          attrs: { timeout: _vm.timeout, color: "#00B24A", top: "" },
          model: {
            value: _vm.notification,
            callback: function($$v) {
              _vm.notification = $$v
            },
            expression: "notification"
          }
        },
        [_vm._v(" " + _vm._s(_vm.text) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }