<template>
  <v-container class="ma-0">
    <v-data-table
      :height="tableSize"
      :items.sync="adGroups"
      :headers="headers"
      :items-per-page="pageSize"
      :loading="loading"
      loader-height="2"
      :search="search"
      hide-default-footer
      fixed-header
      dense
      item-key="id"
      class="table"
      loading-text="Loading... Please wait"
      @click:row="editAdGroup"
    >

    <template v-slot:top>
      <v-toolbar flat color="#fafafa" class="toolbar">
      <v-toolbar-title>Ad Group Maintenance</v-toolbar-title>
      <v-divider class="mx-4" inset vertical/>
      <v-col cols="3" class="mt-6">
        <v-text-field dense outlined background-color="#fff" v-model="search"
        prepend-inner-icon="mdi-filter-outline" label ="Filter Ad Groups" />
      </v-col>
      </v-toolbar>
      <v-divider/>
    </template>

    <template v-slot:footer>
      <v-divider></v-divider>
      <v-toolbar flat color="#fafafa">
      </v-toolbar>
    </template>

    <template v-slot:[`item.active`]="{ item }">
      <span v-if="item.active === true" class="ml-1">Yes</span>
      <span v-else class="ml-1">No</span>
    </template>

    <template v-slot:[`item.ad_begin_day`]="{ item }">
      <span>{{format(item.ad_begin_day)}}</span>
    </template>

    <template v-slot:[`item.tpr_begin_day`]="{ item }">
      <span>{{format(item.tpr_begin_day)}}</span>
    </template>

    <template v-slot:[`item.tpr_end_day`]="{ item }">
      <span>{{format(item.tpr_end_day)}}</span>
    </template>

    </v-data-table>

    <v-dialog v-model="adGroupModal" persistent width="1000">
      <v-card color="#fafafa">
        <v-toolbar color="#1C3144" dark flat>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="!edit">New Ad Group Setup</v-toolbar-title>
          <v-toolbar-title v-else>{{adGroup.ad_group_desc}}</v-toolbar-title>
        </v-toolbar>
        <v-container class="ma-0 pa-6">
          <v-row>
            <v-col cols="4">
              <v-autocomplete outlined dense background-color="#fff" autocomplete="off"
                label="Supplier" item-text="description" v-model="adGroup.sup_code" item-value="id" :items="suppliers"/>
            </v-col>
            <v-col cols="2">
              <v-text-field outlined dense background-color="#fff" autocomplete="off"
                label="Code" v-model="adGroup.ad_group_code"/>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined dense background-color="#fff" autocomplete="off"
                label="Ad Group Name" v-model="adGroup.ad_group_desc"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-autocomplete outlined dense background-color="#fff" item-text="day" item-value="number"
                autocomplete="off" :items="adDays" label="Ad Begin Day" v-model="adGroup.ad_begin_day"/>
            </v-col>
            <v-col cols="4">
              <v-autocomplete readonly outlined dense background-color="#fff" autocomplete="off" label="TPR Begin Day"
                :items="tprDays" item-text="day" item-value="number" v-model="adGroup.tpr_begin_day"/>
            </v-col>
            <v-col cols="4">
              <v-autocomplete readonly outlined dense background-color="#fff" autocomplete="off" label="TPR End Day"
                :items="tprDays" item-text="day" item-value="number" v-model="adGroup.tpr_end_day"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field outlined dense background-color="#fff" autocomplete="off"
                label="TPR Group" v-model="adGroup.tpr_group"/>
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense background-color="#fff" autocomplete="off"
                label="Index" v-model="adGroup.ad_group_index"/>
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense background-color="#fff" autocomplete="off"
                label="Sequence" v-model="adGroup.sequence"/>
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense background-color="#fff" autocomplete="off"
                label="Special Purpose" v-model="adGroup.special_purpose"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field outlined dense background-color="#fff" autocomplete="off" label="Freight Amount"
                v-model="adGroup.freight_amount" @change="setFreightAmount"/>
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense background-color="#fff" autocomplete="off" label="GP Calc Method"
                v-model="adGroup.gp_calc_method"/>
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense background-color="#fff" autocomplete="off" label="TPR SRP Calc Method"
                v-model="adGroup.tpr_srp_calc_method"/>
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense background-color="#fff" autocomplete="off" label="Weekly Admin Fee"
                prefix="$" v-model="adGroup.weekly_admin_fee" @change="setWeeklyAdminFee"/>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="3">
              <v-checkbox v-model="adGroup.receive_ads" label="Receive Ads"/>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="adGroup.receive_tprs" label="Receive TPRs"/>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="adGroup.tpr_billing" label="TPR Billing"/>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="3">
              <v-checkbox v-model="adGroup.tpr_maint" label="TPR Maintenance"/>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="adGroup.tpr_exclude_tpr_meat" label="Exclude TPR Meat"/>
            </v-col>
            <v-col cols="3">
              <v-checkbox v-model="adGroup.no_display" label="No Display"/>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-toolbar flat color="#fafafa">
          <v-spacer></v-spacer>
          <v-btn height="40" color="#00B24A" class="white--text" @click="saveAdGroup">
            <span v-if="!edit">Save<v-icon class="ml-1" dense>mdi-checkbox-marked-circle-outline</v-icon></span>
            <span v-else>Update<v-icon class="ml-1" dense>mdi-update</v-icon></span>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>

    <v-snackbar  v-model="notification" :timeout="timeout" color="#00B24A" top class="notification">
      {{ text }}
    </v-snackbar>

  </v-container>
</template>
<script>
import contracts from '../../axios/contractManager/endpoints'
import maintenance from '../../axios/maintenance/endpoints'
import { store } from '@/store/index'
import moment from 'moment'

export default {
  name: 'AdGroups',
  data () {
    return {
      adGroup: {},
      new: false,
      edit: false,
      adGroupModal: false,
      pageSize: 100,
      loading: true,
      notification: false,
      text: '',
      timeout: 2000,
      tableSize: '',
      windowHeight: '',
      search: '',
      suppliers: [],
      adDays: [
        { day: 'Monday', number: 2 },
        { day: 'Wednesday', number: 4 }
      ],
      tprDays: [
        { day: 'Sunday', number: 1 },
        { day: 'Saturday', number: 7 }
      ],
      adGroups: [],
      headers: [
        {
          text: '',
          align: 'center',
          sortable: false,
          filterable: false,
          value: ''
        },
        {
          text: 'Ad Group Code',
          sortable: true,
          filterable: true,
          value: 'ad_group_code'
        },
        {
          text: 'Ad Group Name',
          sortable: true,
          filterable: true,
          value: 'ad_group_desc'
        },
        {
          text: 'Ad Begin Day',
          sortable: true,
          filterable: true,
          value: 'ad_begin_day'
        },
        {
          text: 'TPR Begin Day',
          sortable: true,
          filterable: true,
          value: 'tpr_begin_day'
        },
        {
          text: 'TPR End Day',
          sortable: true,
          filterable: true,
          value: 'tpr_end_day'
        },
        {
          text: 'Active',
          sortable: true,
          filterable: true,
          value: 'active'
        }
      ]
    }
  },
  watch: {
    adGroupModal: {
      handler (value) {
        if (value === true) {
          this.setFreightAmount(this.adGroup.freight_amount)
          this.setWeeklyAdminFee(this.adGroup.weekly_admin_fee)
        }
      }
    },
    windowHeight: {
      handler () {
        this.tableSize = this.windowHeight - 225
      }
    }
  },
  async created () {
    if (!store.state.userGroups.includes('AdMan_Admin')) {
      this.$router.push({ path: '/' })
    }
    this.tableSize = this.windowHeight - 225
    window.addEventListener('resize', this.resize)
    this.resize()
    this.getSuppliers()
    this.getAdGroups()
  },
  methods: {
    resize () { this.windowHeight = window.innerHeight },
    close () {
      this.edit = false
      this.new = false
      this.adGroupModal = false
      this.adGroup = {}
    },
    setFreightAmount (value) { this.adGroup.freight_amount = (Math.round(value * 100) / 100).toFixed(2) },
    setWeeklyAdminFee (value) { this.adGroup.weekly_admin_fee = ((value * 100) / 100).toFixed(2) },
    format (value) {
      return moment().day(value - 1).format('dddd')
    },
    getSuppliers () {
      contracts.getSuppliers().then(response => {
        this.suppliers = response
      })
    },
    async getAdGroups () {
      const promises = []
      const arr = []
      await contracts.getAdGroups()
        .then(groups => {
          groups.forEach(adGroup => {
            promises.push(
              maintenance.getAdGroups(adGroup.id)
                .then(response => {
                  arr.push(response)
                })
            )
          })
        })
      await Promise.allSettled(promises)
      this.adGroups = arr
      this.loading = false
    },
    newAdGroup () {
      this.new = true
      this.adGroupModal = true
      this.adGroup = {
        active: true,
        gp_calc_method: 1,
        tpr_srp_calc_method: 5,
        tpr_group: 99,
        freight_amount: 0.019999999552965164,
        weekly_admin_fee: 55
      }
    },
    editAdGroup (adGroup) {
      this.edit = true
      this.adGroup = adGroup
      this.adGroupModal = true
    },
    saveAdGroup () {
      if (this.new === true) {
        this.adGroup = {
          active: true,
          ad_begin_day: this.adGroup.ad_begin_day,
          ad_group_code: this.adGroup.ad_group_code,
          ad_group_desc: this.adGroup.ad_group_desc,
          ad_group_index: this.adGroup.ad_group_index,
          exclude_tpr_meat: this.adGroup.exclude_tpr_meat,
          freight_amount: this.adGroup.freight_amount,
          gp_calc_method: this.adGroup.gp_calc_method,
          no_display: this.adGroup.no_display,
          receive_ads: this.adGroup.receive_ads,
          receive_tprs: this.adGroup.receive_tprs,
          sequence: this.adGroup.sequence,
          special_purpose: this.adGroup.special_purpose,
          sup_code: this.adGroup.sup_code,
          tpr_begin_day: this.adGroup.tpr_begin_day,
          tpr_billing: this.adGroup.tpr_billing,
          tpr_end_day: this.adGroup.tpr_end_day,
          tpr_group: this.adGroup.tpr_group,
          tpr_maint: this.adGroup.tpr_maint,
          tpr_srp_calc_method: this.adGroup.tpr_srp_calc_method,
          weekly_admin_fee: this.adGroup.weekly_admin_fee
        }
        maintenance.newAdGroup(this.adGroup).then(response => {
          this.text = response.status
        })
      } else {
        maintenance.updateAdGroup(this.adGroup).then(response => {
          this.text = response.status
        })
      }
      this.adGroupModal = false
      this.notification = true
      this.edit = false
      this.new = false
    }
  }
}
</script>
