import { ads } from './ads'
// import { Auth } from 'aws-amplify'
import { store } from '@/store/index'

export default class endpoints {
  static async getAds (start, end, adGroup, adDate) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/getAdList/' + start + '/' + end + '?adgroup=' + adGroup + '&addate=' + adDate,
      method: 'GET'
    })
  }

  static async getAdGroups () {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/adGroups',
      method: 'GET'
    })
  }

  static async getAdTypes () {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/adTypes',
      method: 'GET'
    })
  }

  static async getAdFeatures (adID) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/getAdFeatures/' + adID,
      method: 'GET'
    })
  }

  static async getAdFeatureTypes () {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/adFeatureTypes',
      method: 'GET'
    })
  }

  static async getFeatureItems (featureID) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/getAdItems/' + featureID,
      method: 'GET'
    })
  }

  static async getPages () {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/pages',
      method: 'GET'
    })
  }

  static async getDepartments (supplier) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/departments/' + supplier,
      method: 'GET'
    })
  }

  static async getManufacturers () {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/manufacturers',
      method: 'GET'
    })
  }

  static async getContract (contractID) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/contracts/contract/' + contractID,
      method: 'GET'
    })
  }

  static async saveAd (saveAd) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ad',
      method: 'POST',
      data: saveAd
    })
  }

  static async copyAd (srcAd, targetAd, adDate) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/copy/' + srcAd + '/' + targetAd + '/' + adDate,
      method: 'POST'
    })
  }

  static async deleteAd (deleteAd) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ad',
      method: 'DELETE',
      data: deleteAd
    })
  }

  static async saveAdFeature (saveAdFeature) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/feature',
      method: 'POST',
      data: saveAdFeature
    })
  }

  static async copyAdFeature (featureId, adId, featureType) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/feature/copy/' + featureId + '/' + adId + '/' + featureType,
      method: 'POST'
    })
  }

  static async moveAdFeature (featureId, adId, featureType) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/feature/move/' + featureId + '/' + adId + '/' + featureType,
      method: 'POST'
    })
  }

  static async removeAdFeature (removeAdFeature) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/feature',
      method: 'DELETE',
      data: removeAdFeature
    })
  }

  static async saveAdItem (saveAdItem) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/item',
      method: 'POST',
      data: saveAdItem
    })
  }

  static async deleteAdItem (deleteAdItem) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/item',
      method: 'DELETE',
      data: deleteAdItem
    })
  }

  static async updateAd (updateAd) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ad',
      method: 'PUT',
      data: updateAd
    })
  }

  static async updateAdFeature (updateAdFeature) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/feature',
      method: 'PUT',
      data: updateAdFeature
    })
  }

  static async updateAdItem (updateAdItem) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/item',
      method: 'PUT',
      data: updateAdItem
    })
  }

  static async getSRPList (item) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/srp_list',
      method: 'POST',
      data: item
    })
  }

  static async adShuttle (adShuttle) {
    ads.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return ads({
      url: '/ads/shuttle',
      method: 'POST',
      data: adShuttle
    })
  }
}
