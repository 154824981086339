import { base } from './base'
// import { store } from '@/store/index'

export default class maintenance {
  // Ad Group Maintenance Endpoints
  static async getAdGroups(groupCode) {
    return base({
      url: '/admin/ad_group/' + groupCode,
      method: 'GET'
    })
  }

  static async newAdGroup(adGroup) {
    return base({
      url: '/admin/ad_group',
      method: 'POST',
      data: adGroup
    })
  }

  static async updateAdGroup(adGroup) {
    return base({
      url: '/admin/ad_group',
      method: 'PUT',
      data: adGroup
    })
  }

  // Feature Type Maintenance Endpoints
  static async getFeatureTypes() {
    return base({
      url: '/admin/features',
      method: 'GET'
    })
  }

  static async newFeatureType(featureType) {
    return base({
      url: '/admin/features',
      method: 'POST',
      data: featureType
    })
  }

  static async updateFeatureType(featureType) {
    return base({
      url: '/admin/features',
      method: 'PUT',
      data: featureType
    })
  }

  // Owner Maintenance Endpoints
  static async getOwnerGroups() {
    return base({
      url: '/admin/owners',
      method: 'GET'
    })
  }

  static async newOwnerGroup(owner) {
    return base({
      url: '/admin/owners',
      method: 'POST',
      data: owner
    })
  }

  static async updateOwnerGroup(owner) {
    return base({
      url: '/admin/owners',
      method: 'PUT',
      data: owner
    })
  }

  // Store Maintenance Endpoints
  static async getStores() {
    return base({
      url: '/admin/store',
      method: 'GET'
    })
  }

  static async newStore(newStore) {
    return base({
      url: '/admin/store',
      method: 'POST',
      data: newStore
    })
  }

  static async updateStore(updateStore) {
    return base({
      url: '/admin/store',
      method: 'PUT',
      data: updateStore
    })
  }

  static async getOutputStoreSets() {
    return base({
      url: '/admin/outputstoresets',
      method: 'GET'
    })
  }

  // Sub-Group Maintenance Endpoints
  static async getSubGroups(supplierID) {
    return base({
      url: `/admin/subgroups/${supplierID}`,
      method: 'GET'
    })
  }

  static async newSubGroup(subGroup) {
    return base({
      url: '/admin/subgroups',
      method: 'POST',
      data: subGroup
    })
  }

  static async updateSubGroup(subGroup) {
    return base({
      url: '/admin/subgroups',
      method: 'PUT',
      data: subGroup
    })
  }

  // Vendor Code Mgmt Endpoints
  static async getVendorCodes() {
    return base({
      url: '/admin/vendorcodes',
      method: 'GET'
    })
  }

  // Price Book Endpoints
  static async getPriceBookItem(orderCode, supplierCode) {
    return base({
      url: supplierCode
        ? `/admin/item/${orderCode}/${supplierCode}`
        : `/admin/item/${orderCode}`,
      method: 'GET'
    })
  }

  static async createPriceBookItem(item) {
    return base({
      url: `/admin/item`,
      method: 'POST',
      data: item
    })
  }

  static async savePriceBookItem(item) {
    return base({
      url: `/admin/item`,
      method: 'PUT',
      data: item
    })
  }

  static async deletePriceBookItem(item) {
    return base({
      url: `/admin/item`,
      method: 'DELETE',
      data: item
    })
  }

  static async getDSDVendors() {
    return base({
      url: `/admin/dsd_vendor`,
      method: 'GET'
    })
  }
}
