var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-6" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("v-col", [
            _c("p", [
              _vm._v("Ad-Grp Pro Rata: " + _vm._s(_vm.store.adgroup_pro_rata))
            ])
          ]),
          _c("v-col", [
            _c("p", [
              _vm._v("Consolo Pro Rata: " + _vm._s(_vm.store.consol_pro_rata))
            ])
          ]),
          _c("v-col", [
            _c("p", [
              _vm._v("Total Pro Rata: " + _vm._s(_vm.store.total_pro_rata))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "Store Number",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.id,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "id", $$v)
                  },
                  expression: "store.id"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "Supplier",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.supplier,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "supplier", $$v)
                  },
                  expression: "store.supplier"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "Store Name",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.name,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "name", $$v)
                  },
                  expression: "store.name"
                }
              })
            ],
            1
          ),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                "nudge-left": 40,
                "nudge-top": 35,
                transition: "scale-transition",
                "offset-y": "",
                "max-width": "290px",
                "min-width": "290px"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "background-color": "#fff",
                              dense: "",
                              outlined: "",
                              label: "Effective Begin Date",
                              value: _vm.EffectiveBeginDate,
                              autocomplete: "off"
                            },
                            on: { change: _vm.setEffBeginDate },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-inner",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g({ staticClass: "calendar" }, on),
                                        [_vm._v("mdi-calendar")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("v-date-picker", {
                attrs: {
                  "no-title": "",
                  color: "#033f63",
                  "show-current": _vm.showCurrent,
                  autocomplete: "off"
                },
                model: {
                  value: _vm.store.eff_begin_date,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "eff_begin_date", $$v)
                  },
                  expression: "store.eff_begin_date"
                }
              })
            ],
            1
          ),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                "nudge-left": 40,
                "nudge-top": 35,
                transition: "scale-transition",
                "offset-y": "",
                "max-width": "290px",
                "min-width": "290px"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "background-color": "#fff",
                              dense: "",
                              outlined: "",
                              label: "Effective End Date",
                              value: _vm.EffectiveEndDate,
                              autocomplete: "off"
                            },
                            on: { change: _vm.setEffEndDate },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-inner",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g({ staticClass: "calendar" }, on),
                                        [_vm._v("mdi-calendar")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("v-date-picker", {
                attrs: {
                  "no-title": "",
                  color: "#033f63",
                  "show-current": _vm.showCurrent,
                  autocomplete: "off"
                },
                model: {
                  value: _vm.store.eff_end_date,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "eff_end_date", $$v)
                  },
                  expression: "store.eff_end_date"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "Address 1",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.address1,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "address1", $$v)
                  },
                  expression: "store.address1"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "Address 2",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.address2,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "address2", $$v)
                  },
                  expression: "store.address2"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "City",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.city,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "city", $$v)
                  },
                  expression: "store.city"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.states,
                  "item-text": "state",
                  "item-value": "state",
                  "background-color": "#fff",
                  label: "State",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.state,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "state", $$v)
                  },
                  expression: "store.state"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "Zip Code",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.zip_code,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "zip_code", $$v)
                  },
                  expression: "store.zip_code"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "Avg Weekly Sales",
                  prefix: "$",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.avg_weekly_sales,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "avg_weekly_sales", $$v)
                  },
                  expression: "store.avg_weekly_sales"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.days,
                  "item-text": "day",
                  "item-value": "number",
                  "background-color": "#fff",
                  label: "Ad-Break Day of Week",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.ad_break_dow,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "ad_break_dow", $$v)
                  },
                  expression: "store.ad_break_dow"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  outlined: "",
                  dense: "",
                  label: "Ad Length (Days)"
                },
                model: {
                  value: _vm.store.ad_length,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "ad_length", $$v)
                  },
                  expression: "store.ad_length"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "TD Linx Code",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.td_linx_code,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "td_linx_code", $$v)
                  },
                  expression: "store.td_linx_code"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "Store Internal Code",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.store_internal_code,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "store_internal_code", $$v)
                  },
                  expression: "store.store_internal_code"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "Email 1",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.email1,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "email1", $$v)
                  },
                  expression: "store.email1"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  "background-color": "#fff",
                  label: "Email 2",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.store.email2,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "email2", $$v)
                  },
                  expression: "store.email2"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c("v-checkbox", {
                staticClass: "mb-6",
                attrs: { label: "Cost Plus Store" },
                model: {
                  value: _vm.store.cost_plus,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "cost_plus", $$v)
                  },
                  expression: "store.cost_plus"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }