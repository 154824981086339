import axios from 'axios'
import _ from 'lodash'

export const contracts = axios.create({
  baseURL: process.env.VUE_APP_API_GATEWAY,
  withCredentials: false
})

contracts.interceptors.request.use(request => {
  let data
  if (_.has(request.data, 'body')) {
    data = request.data.body
    request.data = data
  }
  return request
}, error => {
  console.log(error.config)
  return Promise.reject(error)
})

contracts.interceptors.response.use(response => {
  const data = response.data
  return data
}, error => {
  if (error.message === 'The incoming token has expired') {
    alert('Your Session has expired please refresh your window.')
  }
  console.log('error: ', error.message)
  console.log('error.config: ', error.config)
  return Promise.reject(error)
})
