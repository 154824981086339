<template>
  <v-container class="ma-0 pl-6 pr-6 pb-6 pt-4">
    <v-toolbar color="#fafafa" flat>
      <v-toolbar-title>Exclusions</v-toolbar-title>
    </v-toolbar>
    <v-card flat style="border: 1px solid #ccc;">
      <v-row class="ml-6" justify="start">
        <v-checkbox readonly class="mr-12" label="Exclude New Items?" v-model="store.exclusions.new_items"/>
        <v-checkbox readonly class="mr-12" label="Exclude from ad scan billing?" v-model="store.exclusions.ad_scan_billing"/>
        <v-checkbox readonly class="mr-12" label="Exclude from ad-billback billing?" v-model="store.exclusions.ad_billback_billing"/>
      </v-row>
      <v-row class="ml-6" justify="start">
        <v-checkbox readonly class="mr-12" label="Exclude from Ad-Free Dist" v-model="store.exclusions.ad_fee_dist"/>
        <v-checkbox readonly class="mr-12" label="Is scan adata being provided?" v-model="store.exclusions.scan_data"/>
        <v-checkbox readonly class="mr-12" label="Non-Compliance Exclude" v-model="store.exclusions.non_compliance"/>
      </v-row>
    </v-card>
    <v-toolbar color="#fafafa" flat>
      <v-toolbar-title>Inclusions</v-toolbar-title>
    </v-toolbar>
    <v-card flat style="border: 1px solid #ccc;">
      <v-row class="ml-6" justify="start">
        <v-checkbox readonly class="mr-12" label="Include this store in billing?" v-model="store.exclusions.include_store_billing"/>
      </v-row>
    </v-card>
    <v-toolbar color="#fafafa" flat>
      <v-toolbar-title>Other Settings</v-toolbar-title>
    </v-toolbar>
    <v-card flat style="border: 1px solid #ccc;">
      <v-row class="ml-6" justify="start">
        <v-checkbox readonly class="mr-8" label="Exclude from admin fee?" true-value="Y" false-value="N" v-model="store.exclusions.admin_fee"/>
        <v-checkbox readonly class="mr-8" label="Is this store open 24 hours?" true-value="Y" false-value="N" v-model="store.exclusions.open24hours"/>
        <v-checkbox readonly class="mr-8" label="Closed on Sunday?" true-value="Y" false-value="N" v-model="store.exclusions.closed_sunday"/>
        <v-checkbox readonly class="mr-8"  true-value="Y" false-value="N" label="Does this store run a loyalty program?" v-model="store.exclusions.ad_fee_dist"/>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ExclusionsInclusions',
  data () {
    return {
      exclusions: [
        { label: 'Exclude New Items?', model: this.store.exclusions.new_items },
        { label: 'Exclude from ad scan billing?', model: this.store.exclusions.ad_scan_billing }
      ]
    }
  },
  props: [
    'store'
  ]
}
</script>
