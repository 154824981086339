var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c("v-data-table", {
        staticClass: "table",
        attrs: {
          height: _vm.tableSize,
          headers: _vm.batchHeaders,
          items: _vm.batches,
          expanded: _vm.expanded,
          "items-per-page": _vm.pageSize,
          search: _vm.filter,
          loading: _vm.loading,
          "loader-height": "2",
          "loading-text": "Loading Invoices...Please wait'",
          "no-data-text": "No Matching Invoice Batches",
          "item-key": "batchNumber",
          "single-expand": "",
          "show-expand": "",
          "hide-default-footer": "",
          "fixed-header": "",
          dense: ""
        },
        on: {
          "update:expanded": function($event) {
            _vm.expanded = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { cursor: "default" },
                      attrs: { flat: "", color: "#fafafa" }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "", align: "center" } },
                        [
                          _c("v-toolbar-title", { staticClass: "ml-2 title" }, [
                            _vm._v(" Invoices ")
                          ]),
                          _c("v-divider", {
                            staticClass: "mx-4",
                            attrs: { inset: "", vertical: "" }
                          }),
                          _c(
                            "v-col",
                            { attrs: { sm: "2", md: "2", lg: "2", xl: "2" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "background-color": "#fff",
                                  dense: "",
                                  "hide-details": "",
                                  outlined: "",
                                  label: "Filter Batches",
                                  "prepend-inner-icon": "mdi-filter-outline"
                                },
                                model: {
                                  value: _vm.filter,
                                  callback: function($$v) {
                                    _vm.filter = $$v
                                  },
                                  expression: "filter"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.toggle == "Posted"
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    value: _vm.searchMenu,
                                    bottom: "",
                                    "offset-y": "",
                                    "nudge-left": 100,
                                    "close-on-content-click": false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "text-capitalize pr-1 mx-2",
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.searchMenu = true
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [_vm._v("mdi-magnify")]
                                                ),
                                                _vm._v(" Search "),
                                                _c("v-badge", {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    inline: "",
                                                    color: "orange",
                                                    content: _vm.filterCount,
                                                    value: _vm.showFilterBadge
                                                  }
                                                }),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [_vm._v("mdi-chevron-down")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1913911732
                                  )
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "grey lighten-5",
                                      attrs: { width: "350" }
                                    },
                                    [
                                      _c("v-card-title", [_vm._v("Search By")]),
                                      _c(
                                        "v-card-text",
                                        _vm._l(_vm.searchFields, function(
                                          field,
                                          idx
                                        ) {
                                          return _c(
                                            "v-row",
                                            {
                                              key: idx,
                                              staticStyle: { height: "55px" },
                                              attrs: { justify: "center" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticStyle: {
                                                    width: "375px"
                                                  },
                                                  attrs: { align: "center" }
                                                },
                                                [
                                                  field.type === "text"
                                                    ? _c("v-text-field", {
                                                        attrs: {
                                                          clearable: "",
                                                          outlined: "",
                                                          solo: "",
                                                          dense: "",
                                                          "background-color":
                                                            "input",
                                                          label: field.text
                                                        },
                                                        on: {
                                                          "click:clear": function(
                                                            $event
                                                          ) {
                                                            _vm.searchParams[
                                                              "" + field.value
                                                            ] = ""
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.searchParams[
                                                              "" + field.value
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.searchParams,
                                                              "" + field.value,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "searchParams[`${field.value}`]"
                                                        }
                                                      })
                                                    : _c("v-autocomplete", {
                                                        attrs: {
                                                          outlined: "",
                                                          solo: "",
                                                          dense: "",
                                                          clearable: "",
                                                          "background-color":
                                                            "white",
                                                          label: field.text,
                                                          items:
                                                            _vm.batchTypeList,
                                                          "item-text":
                                                            "description",
                                                          "item-value": "id"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.searchParams[
                                                              "" + field.value
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.searchParams,
                                                              "" + field.value,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "searchParams[`${field.value}`]"
                                                        }
                                                      })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-capitalize",
                                              attrs: { text: "" },
                                              on: {
                                                click: _vm.clearFilterCache
                                              }
                                            },
                                            [_vm._v(" Clear ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "text-capitalize mr-2",
                                              attrs: {
                                                color: "primary",
                                                disabled: _vm.searchDisabled
                                              },
                                              on: { click: _vm.getBatches }
                                            },
                                            [_vm._v(" Search ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-divider", {
                            staticClass: "mx-4",
                            attrs: { inset: "", vertical: "" }
                          }),
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "", "nudge-left": 30 },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "white--text",
                                            attrs: {
                                              disabled: !_vm.billingUser,
                                              height: "40",
                                              color: "#0D68A5"
                                            }
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("mdi-plus")])],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          ;(_vm.component =
                                            "InvoiceBillingTypes"),
                                            (_vm.modal = true)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("All Types")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          ;(_vm.component = "InvoiceManual"),
                                            (_vm.modal = true)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Manual Invoice")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.getBatches(
                                                  _vm.toggle
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("mdi-refresh")])],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_c("span", [_vm._v("Refresh")])]
                          ),
                          _c(
                            "v-toolbar-title",
                            {
                              staticClass: "ml-2 title",
                              class: { active: _vm.toggle === "Pending" },
                              on: {
                                click: function($event) {
                                  _vm.toggle = "Pending"
                                }
                              }
                            },
                            [_vm._v(" Pending ")]
                          ),
                          _c("v-divider", {
                            staticClass: "mx-4",
                            attrs: { inset: "", vertical: "" }
                          }),
                          _c(
                            "v-toolbar-title",
                            {
                              staticClass: "title mr-4",
                              class: { active: _vm.toggle === "Posted" },
                              on: {
                                click: function($event) {
                                  _vm.toggle = "Posted"
                                }
                              }
                            },
                            [_vm._v(" Posted ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider")
                ]
              },
              proxy: true
            },
            {
              key: "item.run_date",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [_vm._v(_vm._s(_vm.formatDates(item.run_date)))])
                ]
              }
            },
            {
              key: "item.begin_date",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatDates(item.begin_date)) +
                        " - " +
                        _vm._s(_vm.formatDates(item.end_date)) +
                        " "
                    )
                  ])
                ]
              }
            },
            {
              key: "item.billing_type",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.billing_type === "ManInv"
                    ? _c("span", [_vm._v("Manual")])
                    : _vm._e(),
                  item.billing_type === "Grocery"
                    ? _c("span", [_vm._v(" Grocery, Frozen, Dairy ")])
                    : _vm._e(),
                  item.billing_type === "MeatAccrual"
                    ? _c("span", [_vm._v("Meat Accrual")])
                    : _vm._e(),
                  item.billing_type === "Meat"
                    ? _c("span", [_vm._v("Perishables")])
                    : _vm._e(),
                  item.billing_type === "AdScans"
                    ? _c("span", [_vm._v("Ad Scans")])
                    : _vm._e(),
                  item.billing_type === "TPRScans"
                    ? _c("span", [_vm._v("TPR Scans")])
                    : _vm._e(),
                  item.billing_type === "VMC_BB"
                    ? _c("span", [_vm._v("VMC Billbacks")])
                    : _vm._e(),
                  item.billing_type === "VMC_Scans"
                    ? _c("span", [_vm._v("VMC Scans")])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.invoice_total",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.billing_type === "ManInv"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.formatCurrency(item.invoice_total)))
                      ])
                    : _c("span", [
                        _vm.checkTotals(item.invoice_total, item.credit_total)
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(item.invoice_total)
                                  ) +
                                  "/" +
                                  _vm._s(
                                    _vm.formatCurrency(item.credit_total)
                                  ) +
                                  " "
                              )
                            ])
                          : _c(
                              "span",
                              { staticClass: "font-weight-bold red--text" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatCurrency(item.invoice_total)
                                    ) +
                                    "/" +
                                    _vm._s(
                                      _vm.formatCurrency(item.credit_total)
                                    ) +
                                    " "
                                )
                              ]
                            )
                      ])
                ]
              }
            },
            {
              key: "item.status",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.status !== "Posted"
                    ? _c(
                        "td",
                        [
                          item.status !== "Failed"
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ml-10",
                                  attrs: {
                                    small: "",
                                    dark: "",
                                    color: _vm.getColor(item.status)
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-capitalize",
                                      staticStyle: {
                                        display: "inline-block",
                                        margin: "0 auto",
                                        width: "70px",
                                        "text-align": "center"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.status) + " ")]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        item.status === "Failed"
                                          ? _c(
                                              "v-chip",
                                              _vm._g(
                                                {
                                                  staticClass: "ml-10",
                                                  attrs: {
                                                    small: "",
                                                    dark: "",
                                                    color: _vm.getColor(
                                                      item.status
                                                    )
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-capitalize",
                                                    staticStyle: {
                                                      display: "inline-block",
                                                      margin: "0 auto",
                                                      width: "70px",
                                                      "text-align": "center"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.status) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v(_vm._s(item.failure_reason))])]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.data-table-expand",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.status !== "Failed" && item.status !== "Running"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              !_vm.expanded.length
                                ? _vm.getInvoices(item)
                                : (_vm.expanded = [])
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-down")])],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.toggle === "Pending" || _vm.toggle === "Posted"
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            "close-on-content-click": false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g({ attrs: { icon: "" } }, on),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-dots-vertical")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              _vm._l(_vm.batchActions, function(action, index) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: index,
                                    attrs: {
                                      disabled: !_vm.billingUser,
                                      link: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        ;(_vm.type = action.value),
                                          (_vm.selectedBatch = item),
                                          (_vm.component = "InvoiceActions"),
                                          (_vm.modal = true)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(action.icon))
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(action.text))
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _vm.toggle === "Posted" &&
                              item.billing_type !== "ManInv"
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: { link: "" },
                                      on: {
                                        click: function($event) {
                                          ;(_vm.type = "send"),
                                            (_vm.selectedBatch = item),
                                            (_vm.component = "InvoiceActions"),
                                            (_vm.modal = true)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-email-outline")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v(" Send ")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              (_vm.toggle === "Pending") |
                              (item.billing_type == "ManInv")
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: { link: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.runReportByBatch(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-file-clock-outline")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v(" Invoice Batch Report ")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                {
                                  attrs: { link: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.runInvoiceSummaryReport(item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-file-clock-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v(" End Sheet Report ")
                                  ])
                                ],
                                1
                              ),
                              item.billing_type !== "ManInv"
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          ;(_vm.reportId = 163),
                                            (_vm.selectedBatch = item),
                                            (_vm.component =
                                              "RetailCreditStores"),
                                            (_vm.modal = true)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-file-clock-outline")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v(" Output Retail Credits ")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "#fafafa" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            {
                              staticStyle: { "max-width": "150px" },
                              attrs: { cols: "2" }
                            },
                            [
                              _c("v-select", {
                                staticClass: "mt-6",
                                attrs: {
                                  dense: "",
                                  items: _vm.pageSizes,
                                  label: "Items Per Page",
                                  select: "",
                                  "menu-props": {
                                    top: true,
                                    offsetY: true,
                                    maxHeight: 500
                                  }
                                },
                                model: {
                                  value: _vm.pageSize,
                                  callback: function($$v) {
                                    _vm.pageSize = $$v
                                  },
                                  expression: "pageSize"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                icon: "",
                                large: "",
                                disabled: _vm.disablePrevious
                              },
                              on: {
                                click: function($event) {
                                  return _vm.previous()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                            1
                          ),
                          _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                icon: "",
                                large: "",
                                disabled: _vm.disableNext
                              },
                              on: {
                                click: function($event) {
                                  return _vm.next()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticStyle: { padding: "0px" },
                      attrs: { colspan: "12" }
                    },
                    [
                      _c("v-divider"),
                      _c("v-data-table", {
                        staticClass: "table",
                        attrs: {
                          "items-per-page": 500,
                          items: item.invoices,
                          headers: _vm.invoiceHeaders,
                          height: "500",
                          "hide-default-footer": "",
                          "fixed-header": "",
                          dense: "",
                          search: _vm.invoiceFilter
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.manufacturer",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "span",
                                    { staticStyle: { "max-width": "400px" } },
                                    [_vm._v(_vm._s(item.manufacturer))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.email_address",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "span",
                                    {
                                      class: item.email_address
                                        ? ""
                                        : "font-weight-bold red--text"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.email_address
                                              ? item.email_address
                                              : "No Email Provided"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.total",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.formatCurrency(item.total))
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item.amount_paid",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.status === "Posted"
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatCurrency(
                                                item.amount_paid
                                              )
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "item.actions",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "parentMenuRef",
                                      attrs: {
                                        "offset-y": "",
                                        bottom: "",
                                        "nudge-left": 40,
                                        "close-on-content-click": false
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    { attrs: { icon: "" } },
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-dots-vertical"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        { attrs: { dense: "" } },
                                        [
                                          item.billing_type === "ManInv" ||
                                          _vm.toggle === "Posted"
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      ;(_vm.selectedBatch = item),
                                                        (_vm.component =
                                                          _vm.toggle ===
                                                            "Pending" &&
                                                          item.billing_type ===
                                                            "ManInv"
                                                            ? "InvoiceManual"
                                                            : "InvoiceVendorEdit"),
                                                        (_vm.modal = true),
                                                        (_vm.$refs.parentMenuRef.isActive = false)
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-square-edit-outline"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-title",
                                                    { staticClass: "mr-6" },
                                                    [_vm._v(" Edit ")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  ;(_vm.component =
                                                    "InvoicePreview"),
                                                    (_vm.selectedBatch = item),
                                                    (_vm.modal = true),
                                                    (_vm.$refs.parentMenuRef.isActive = false)
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-text-box-search-outline"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v(" View ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  ;(_vm.type = "delete"),
                                                    (_vm.component =
                                                      "InvoiceActions"),
                                                    (_vm.selectedBatch = item),
                                                    (_vm.modal = true),
                                                    (_vm.$refs.parentMenuRef.isActive = false)
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-trash-can-outline"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v(" Delete ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm.toggle === "Posted"
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      ;(_vm.component =
                                                        "InvoicePayments"),
                                                        (_vm.selectedBatch = item),
                                                        (_vm.modal = true),
                                                        (_vm.$refs.parentMenuRef.isActive = false)
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-cash-multiple"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-title", [
                                                    _vm._v(" Payments ")
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.toggle === "Posted"
                                            ? _c(
                                                "v-list-group",
                                                {
                                                  attrs: {
                                                    value: false,
                                                    "prepend-icon":
                                                      "mdi-file-clock-outline"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  "Reports"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          " Invoice Report "
                                                        )
                                                      ]),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            loading:
                                                              _vm.loadingPDF
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.exportFile(
                                                                "pdf",
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-file-pdf-box"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            loading:
                                                              _vm.loadingExcel
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.exportFile(
                                                                "xlsx",
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-microsoft-excel"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  item.billing_type !== "ManInv"
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.runReportByInvoice(
                                                                item,
                                                                164
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                " Retail Credit "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("v-divider")
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c(_vm.component, {
        key: _vm.componentKey,
        tag: "Component",
        attrs: {
          getBatches: _vm.getBatches,
          reportId: _vm.reportId,
          selectedBatch: _vm.selectedBatch,
          toggle: _vm.toggle,
          type: _vm.type,
          value: _vm.modal
        },
        on: {
          "update:value": function($event) {
            _vm.modal = $event
          },
          deleteBatch: _vm.deleteInvoiceBatch,
          deleteInvoice: _vm.deleteInvoice,
          dialog: function($event) {
            _vm.invoiceReportAlert = true
          },
          post: _vm.postInvoiceBatch,
          send: _vm.emailInvoiceBatch,
          refresh: _vm.getBatches,
          restart: _vm.restartInvoiceBatch
        }
      }),
      _c("InvoiceReportAlert", {
        attrs: { value: _vm.invoiceReportAlert },
        on: {
          "update:value": function($event) {
            _vm.invoiceReportAlert = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }