const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:ca2206d4-0f71-44ae-9639-fbc77ed89d2f',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_YrDNHadxd',
  aws_user_pools_web_client_id: '388t24e5qmgphlkvu279n9a6e6',
  oauth: {}
}

export default awsmobile
