<template>
  <v-container class="ma-0 pa-6">
    <v-row justify="center">
      <v-col>
        <p>Ad-Grp Pro Rata: {{store.adgroup_pro_rata}}</p>
      </v-col>
      <v-col>
        <p>Consolo Pro Rata: {{store.consol_pro_rata}}</p>
      </v-col>
      <v-col>
        <p>Total Pro Rata: {{store.total_pro_rata}}</p>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="2">
        <v-text-field background-color="#fff" label="Store Number" outlined dense v-model="store.id" />
      </v-col>
      <v-col cols="1">
        <v-text-field background-color="#fff" label="Supplier" outlined dense v-model="store.supplier" />
      </v-col>
      <v-col cols="5">
        <v-text-field background-color="#fff" label="Store Name" outlined dense v-model="store.name" />
      </v-col>

      <v-menu :close-on-content-click="false" :nudge-left="40" :nudge-top="35" transition="scale-transition"
        offset-y max-width="290px" min-width="290px">
        <template v-slot:activator="{ on }">
          <v-col cols="2">
            <v-text-field background-color="#fff" dense outlined label="Effective Begin Date"
              :value="EffectiveBeginDate"  @change="setEffBeginDate" autocomplete="off">
              <template v-slot:prepend-inner>
                <v-icon v-on="on" class="calendar">mdi-calendar</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </template>
        <v-date-picker no-title color="#033f63" :show-current="showCurrent" v-model="store.eff_begin_date" autocomplete="off">
        </v-date-picker>
      </v-menu>

      <v-menu :close-on-content-click="false" :nudge-left="40" :nudge-top="35" transition="scale-transition"
        offset-y max-width="290px" min-width="290px">
        <template v-slot:activator="{ on }">
          <v-col cols="2">
            <v-text-field background-color="#fff" dense outlined label="Effective End Date"
              :value="EffectiveEndDate" @change="setEffEndDate" autocomplete="off">
              <template v-slot:prepend-inner>
                <v-icon v-on="on" class="calendar">mdi-calendar</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </template>
        <v-date-picker no-title color="#033f63" :show-current="showCurrent" v-model="store.eff_end_date" autocomplete="off">
        </v-date-picker>
      </v-menu>

    </v-row>
    <v-row align="center">
      <v-col cols="3">
        <v-text-field background-color="#fff" label="Address 1" outlined dense v-model="store.address1" />
      </v-col>
      <v-col cols="3">
        <v-text-field background-color="#fff" label="Address 2" outlined dense v-model="store.address2" />
      </v-col>
      <v-col cols="3">
        <v-text-field background-color="#fff" label="City" outlined dense v-model="store.city" />
      </v-col>
      <v-col cols="2">
        <v-autocomplete :items="states" item-text="state" item-value="state" background-color="#fff" label="State"
          outlined dense v-model="store.state" />
      </v-col>
      <v-col cols="1">
        <v-text-field background-color="#fff" label="Zip Code" outlined dense v-model="store.zip_code" />
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="2">
        <v-text-field background-color="#fff" label="Avg Weekly Sales" prefix="$" outlined dense
          v-model="store.avg_weekly_sales" />
      </v-col>
      <v-col cols="3">
        <v-autocomplete :items="days" item-text="day" item-value="number" background-color="#fff"
          label="Ad-Break Day of Week" outlined dense v-model="store.ad_break_dow" />
      </v-col>
      <v-col cols="2">
        <v-text-field background-color="#fff" outlined dense label="Ad Length (Days)" v-model="store.ad_length" />
      </v-col>
      <v-col cols="2">
        <v-text-field background-color="#fff" label="TD Linx Code" outlined dense v-model="store.td_linx_code" />
      </v-col>
      <v-col cols="3">
        <v-text-field background-color="#fff" label="Store Internal Code" outlined dense
          v-model="store.store_internal_code" />
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="4">
        <v-text-field background-color="#fff" label="Email 1" outlined dense
          v-model="store.email1" />
      </v-col>
      <v-col cols="4">
        <v-text-field background-color="#fff" label="Email 2" outlined dense
          v-model="store.email2" />
      </v-col>
      <v-col cols="2">
        <v-checkbox class="mb-6" label="Cost Plus Store" v-model="store.cost_plus" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  name: 'BasicInfo',
  data () {
    return {
      showCurrent: true,
      states: [
        { state: 'AL' },
        { state: 'AK' },
        { state: 'AZ' },
        { state: 'AR' },
        { state: 'CA' },
        { state: 'CO' },
        { state: 'CT' },
        { state: 'DE' },
        { state: 'FL' },
        { state: 'GA' },
        { state: 'HI' },
        { state: 'ID' },
        { state: 'IL' },
        { state: 'IN' },
        { state: 'IA' },
        { state: 'KS' },
        { state: 'KY' },
        { state: 'LA' },
        { state: 'ME' },
        { state: 'MD' },
        { state: 'MA' },
        { state: 'MI' },
        { state: 'MN' },
        { state: 'MS' },
        { state: 'MO' },
        { state: 'MT' },
        { state: 'NE' },
        { state: 'NV' },
        { state: 'NH' },
        { state: 'NJ' },
        { state: 'NM' },
        { state: 'NY' },
        { state: 'NC' },
        { state: 'ND' },
        { state: 'OH' },
        { state: 'OK' },
        { state: 'OR' },
        { state: 'PA' },
        { state: 'RI' },
        { state: 'SC' },
        { state: 'SD' },
        { state: 'TN' },
        { state: 'TX' },
        { state: 'UT' },
        { state: 'VT' },
        { state: 'VA' },
        { state: 'WA' },
        { state: 'WV' },
        { state: 'WI' },
        { state: 'WY' }
      ],
      days: [
        { day: 'Monday', number: 2 },
        { day: 'Wednesday', number: 4 }
      ]
    }
  },
  props: [
    'store'
  ],
  computed: {
    EffectiveBeginDate () {
      return this.store.eff_begin_date ? moment(this.store.eff_begin_date).format('MM/DD/YYYY') : ''
    },
    EffectiveEndDate () {
      return this.store.eff_end_date ? moment(this.store.eff_end_date).format('MM/DD/YYYY') : ''
    }
  },
  methods: {
    setEffBeginDate (value) {
      if (value !== '') {
        this.store.eff_begin_date = moment(value).format('YYYY-MM-DD')
      } else {
        this.store.eff_begin_date = null
      }
    },
    setEffEndDate (value) {
      if (value !== '') {
        this.store.eff_end_date = moment(value).format('YYYY-MM-DD')
      } else {
        this.store.eff_end_date = null
      }
    }
  }
}
</script>
