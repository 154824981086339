<template>
  <v-dialog :value="value" class="ma-0" persistent width="1300">
    <v-card color="#fafafa">
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{selectedAccrual.accrual_type}} Accrual</v-toolbar-title>
        <v-spacer />
        <div v-if="!edit" v-html="accrualHTML">{{this.accrualHTML}}</div>
        <p class="pt-4" v-else-if="edit && !finished"><strong>Accrual ID:</strong> {{accrual.id}}</p>
      </v-toolbar>
      <v-container class="active ma-0 pa-6">
        <v-row v-if="selectedAccrual.accrual_type === 'Non-Standard' || selectedAccrual.accrual_type === 'Non-standard'">
          <v-col cols="2">
            <v-autocomplete outlined dense background-color="#fff" :items="suppliers" item-text="description"
              item-value="id" label="Supplier" placeholder="Select a Supplier" v-model="accrual.supplier" return-object
              autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field outlined dense background-color="#fff" :value="formattedBeginDate" label="Begin Date"
              placeholder="Enter Begin Date" v-model="accrual.beginning_date" prepend-inner-icon="mdi-calendar" autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field outlined dense background-color="#fff" :value="formattedEndDate" label="End Date"
              placeholder="Enter End Date" v-model="accrual.ending_date" prepend-inner-icon="mdi-calendar" autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field outlined dense background-color="#fff" :value="formattedBillingDate" label="Billing Date"
              placeholder="Enter Billing Date" v-model="accrual.billing_date" prepend-inner-icon="mdi-calendar" autocomplete="off" />
          </v-col>
          <v-col cols="4" align="right">
            <v-btn :disabled="!Object.prototype.hasOwnProperty.call(accrual, 'id')" height="40" color="#D32F2F"
              class="white--text" @click.stop="accrualDelete=true">
              Delete<v-icon class="ml-1" dense>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-btn :disabled="!Object.prototype.hasOwnProperty.call(accrual,'id')"
              v-if="selectedAccrual.accrual_type !== 'Standard'" height="40" color="#F9A825" class="white--text ml-2"
              @click.stop="accrualCopy=true">
              Copy<v-icon dense>mdi-content-duplicate</v-icon>
            </v-btn>
            <v-btn height="40" color="#00B24A" class="white--text ml-2" @click="saveAccrual">
              Save<v-icon class="ml-1" dense>mdi-checkbox-marked-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="5">
            <v-autocomplete outlined dense background-color="#fff" :items="ads" item-text="adName" item-value="adId"
              label="Ad" placeholder="Select an Ad" v-model="accrual.ad_id" autocomplete="off" />
          </v-col>
          <v-col cols="7" align="right">
            <v-btn :disabled="!Object.prototype.hasOwnProperty.call(accrual, 'id')" height="40" color="#D32F2F"
              class="white--text" @click.stop="accrualDelete=true">
              Delete<v-icon class="ml-1" dense>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-btn height="40" color="#00B24A" class="white--text ml-2" @click="saveAccrual">
              Save<v-icon class="ml-1" dense>mdi-checkbox-marked-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />

      <v-data-table :headers="itemHeaders" :items.sync="items" :height="tableSize" :items-per-page="pageSize"
        :loading="loading" :search="search" sort-by="id" show-select v-model="selectedItems" class="table" dense
        hide-default-footer fixed-header>

        <template v-slot:[`item.item.order_code`]="{ item }">
          {{item.item.order_code}} - {{item.item.description}}
        </template>

        <template v-slot:[`item.accrual`]="{ item }">
          <span>{{formatCurrency(item.accrual)}}</span>
        </template>

        <template v-slot:[`item.case_BB`]="{ item }">
          <span>{{formatCurrency(item.case_BB)}}</span>
        </template>

      </v-data-table>

      <!--------------------------------------- Add Items --------------------------------------->
      <v-dialog v-model="accrualItem" persistent width="800">
        <v-card>
          <v-toolbar dark flat color="#1C3144">
            <v-btn icon dark @click.stop="accrualItem=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="type === 'add'">Add Accrual Item</v-toolbar-title>
            <v-toolbar-title v-else-if="type === 'edit'">Edit Accrual Item</v-toolbar-title>
            <v-toolbar-title v-else>Copy Accrual Item</v-toolbar-title>
          </v-toolbar>
          <v-container class="ma-0 py-6 px-6">
            <v-row>
              <v-col cols="5">
                <v-autocomplete auto-select-first outlined dense background-color="#fff" :items="subGroups"
                  item-text="description" item-value="id" label="Sub-Group" placeholder="Select Sub-Group"
                  v-model="item.sub_group" :menu-props="{ overflowY: true }" return-object />
              </v-col>
              <v-col cols="7">
                <v-autocomplete auto-select-first outlined dense background-color="#fff" :items="manufacturers"
                  item-text="description" item-value="id" label="Manufacturer" placeholder="Select Manufacturer"
                  v-model="item.manufacturer" return-object />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field ref="order_code" outlined dense background-color="#fff" label="Item" item-value="adId"
                  v-model="itemDetail" @keyup.enter="getByOrderCode(itemDetail)"
                  hint="Press Enter to View Linked Group Items">
                  <!-- <template v-slot:append>
                    <v-icon @click="getByOrderCode(itemDetail)">mdi-magnify</v-icon>
                  </template> -->
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field outlined dense background-color="#fff" label="Accrual Amt" placeholder="0.00" prefix="$"
                  v-model="item.accrual" @change="setAccrualAmt" />
              </v-col>
              <v-col cols="3">
                <v-text-field outlined dense background-color="#fff" label="Case BB" placeholder="0.00" prefix="$"
                  v-model="item.case_BB" @keyup.enter="saveAccrualItem" hint="Press Enter to Save Item" />
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
          <v-data-table :headers="linkedItemHeaders" :items.sync="linkedItems" :height="linkedGroupTable"
            :items-per-page="pageSize" item-key="orderCode" class="table" no-data-text="No Linked Items Available"
            show-select v-model="selectedLinkedItems" dense hide-default-footer fixed-header>

            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer />
                <strong>
                  <p class="pt-4">Linked Group Items</p>
                </strong>
                <v-spacer />
              </v-toolbar>
              <v-divider />
            </template>

          </v-data-table>
          <v-divider />
          <v-toolbar flat color="#fafafa">
            <v-toolbar-title v-if="saving" class="operation">{{action}} {{count}} / {{total}}</v-toolbar-title>
            <v-toolbar-title v-if="saved" class="operation">{{action}} {{complete}} / {{total}}</v-toolbar-title>
            <v-toolbar-title v-if="itemSaved" class="operation">Item Saved</v-toolbar-title>
            <v-spacer/>
            <span class="ml-6 primary--text font-weight-bold font-italic" v-show="selectedLinkedItems.length > 1">
              {{selectedLinkedItems.length}} Selected (Includes lookup item)
            </span>
            <v-spacer />
            <!-- <v-btn v-if="type === 'copy'" class="white--text" height="40" color="#00B24A" @click="saveAccrualItem">
              Add<v-icon class="ml-1" dense>mdi-plus</v-icon>
            </v-btn> -->
            <v-btn :disabled="saving" v-if="selectedLinkedItems.length > 0" class="white--text" height="40"
              color="#00B24A" @click="addLinkedItems">
              Add Linked Items<v-icon class="ml-1" dense>mdi-playlist-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-dialog>

      <!--------------------------------------- Edit Items --------------------------------------->
      <v-dialog v-model="editItems" persistent width="400">
        <v-card>
          <v-toolbar dark flat color="#1C3144">
            <v-btn icon dark @click.stop="editItems=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Edit Accrual Items</v-toolbar-title>
          </v-toolbar>
          <v-container class="ma-0 py-4 px-6">
            <v-row>
              <v-col cols="12">
                <v-autocomplete auto-select-first outlined dense background-color="#fff" :items="manufacturers"
                  item-text="description" :menu-props="{ overflowY: true, maxHeight: 140 }" item-value="id"
                  label="Manufacturer" placeholder="Select Manufacturer" v-model="item.manufacturer" return-object />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field outlined dense background-color="#fff" label="Accrual Amt" placeholder="0.00" prefix="$"
                  v-model="item.accrual" @change="setAccrualAmt" />
              </v-col>
              <v-col cols="6">
                <v-text-field outlined dense background-color="#fff" label="Case BB" placeholder="0.00" prefix="$"
                  v-model="item.case_BB" @change="setCaseBB" />
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
          <v-divider />
          <v-toolbar flat color="#fafafa">
            <v-toolbar-title v-if="updating" class="operation">Updating {{count}} / {{total}}</v-toolbar-title>
            <v-toolbar-title v-if="updated" class="operation">Updated {{complete}} / {{total}}</v-toolbar-title>
            <v-spacer />
            <v-btn color="#00B24A" class="white--text" @click="bulkEdit">
              Update<v-icon class="ml-1">mdi-update</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-dialog>

      <!--------------------------------------- Copy Items ------------------------------------------>
      <v-dialog justify="center" v-model="accrualItemCopy" persistent max-width="400">
        <v-card color=#fafafa>
          <v-toolbar dark flat color="#1C3144">
            <v-btn icon dark @click.stop="accrualItemCopy=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Copy {{selectedItems.length}} Items to Sub-Groups</v-toolbar-title>
          </v-toolbar>
          <v-container class="ma-0">
            <v-data-table :headers="subGroupheaders" :items="subGroups" :items-per-page="200" height="353" class="table"
              dense v-model="selectedSubGroups" hide-default-footer show-select fixed-header item-key="id">
            </v-data-table>
          </v-container>
          <v-toolbar flat color="#fafafa">
            <v-spacer />
            <v-toolbar-title v-if="copied">Copied {{complete}} / {{total}}</v-toolbar-title>
            <v-btn :disabled="copying" height="40" color="#00B24A" class="white--text ml-2" @click="copyAccrualItems">
              Save<v-icon class="ml-1" dense>mdi-checkbox-marked-circle-outline</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-dialog>

      <!--------------------------------------- Copy Accrual ---------------------------------------->
      <v-dialog justify="center" v-model="accrualCopy" persistent max-width="700">
        <v-card color=#fafafa>
          <v-toolbar dark flat color="#1C3144">
            <v-btn :disabled="copyingAccrual" icon dark @click.stop="accrualCopy=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="accrualCopy">Copy {{accrual.id}} - {{accrual.supplier.name}}</v-toolbar-title>
          </v-toolbar>
          <v-container class="active ma-0 pa-6">
            <v-row v-if="accrual.accrual_type === 'Non-Standard'" justify="center">
              <v-col cols="4">
                <v-text-field outlined dense background-color="#fff" label="Begin Date" placeholder="Enter Begin Date"
                  @change="formatBeginDateCopy" autocomplete="off" v-model="copy.beginning_date" />
              </v-col>
              <v-col cols="4">
                <v-text-field outlined dense background-color="#fff" label="End Date" placeholder="Enter End Date"
                  @change="formatEndDateCopy" autocomplete="off" v-model="copy.ending_date" />
              </v-col>
              <v-col cols="4">
                <v-text-field outlined dense background-color="#fff" label="Billing Date"
                  placeholder="Enter Billing Date" @change="formatBillingDateCopy" autocomplete="off"
                  v-model="copy.billing_date" />
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
          <v-toolbar flat color="#fafafa">
            <v-spacer />
            <v-btn :loading="copyingAccrual" height="40" color="#00B24A" class="white--text" @click="copyAccrual">
              Save<v-icon class="ml-1" dense>mdi-checkbox-marked-circle-outline</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-dialog>

      <!------------------------------- Accrual Copy Id Notification ------------------------>
      <v-dialog v-model="copyConfirm" persistent width="500">
        <v-card color=#fafafa>
          <v-toolbar dark flat color="#1C3144">
            <v-toolbar-title>Copy Complete</v-toolbar-title>
          </v-toolbar>
          <v-container class="ma-0 pa-6">
            <v-row justify="center">
              <h3>Accrual ID: {{accrualCopyID}}</h3>
            </v-row>
          </v-container>
          <v-divider />
          <v-toolbar flat>
            <v-spacer />
            <v-btn height="40" color="#0c67a5" class="white--text" @click.stop="copyConfirm=false">
              Okay
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-dialog>

      <!--------------------------------------- Delete Accrual -------------------------------------->
      <v-dialog justify="center" v-model="accrualDelete" persistent max-width="300">
        <v-card color=#fafafa>
          <v-container class="ma-0 pa-6">
            <v-row justify="center">
              <v-icon x-large color="orange">mdi-alert-circle-outline</v-icon>
            </v-row>
            <v-row justify="center">
              <h3>Are You Sure?</h3>
            </v-row>
            <v-row justify="center">
              <v-btn height="40" class="elevation-1" @click.stop="accrualDelete=false">No</v-btn>
              <v-btn class="white--text elevation-1 ml-2" height="40" color="#0c67a5" @click="deleteAccrual">Yes</v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <v-divider />
      <v-toolbar flat color="#fafafa">
        <v-row dense align="center">
        <v-col cols="3">
          <v-text-field background-color="#fff" max-width="200" outlined dense label="Filter Accrual Items"
            prepend-inner-icon="mdi-filter-outline" hide-details v-model="search" autocomplete="off">
          </v-text-field>
        </v-col>
        <v-spacer />
        <v-toolbar-title v-if="finished" class="operation">Accrual {{action}}</v-toolbar-title>
        <v-toolbar-title v-if="removed || copied" class="operation">{{action}} {{complete}} / {{total}}
        </v-toolbar-title>
        <v-toolbar-title v-if="removing || copying" class="operation">{{action}} {{count}} / {{total}}</v-toolbar-title>
        <v-spacer />
        <v-menu v-if="selectedItems.length > 0" top offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" height="40" color="grey lighten-3">
              Selected ({{selectedItems.length}})
            </v-btn>
          </template>
          <v-list>
            <v-list-item style="color:#F9A825 !important" class="ml-1" @click.stop="accrualItemCopy=true">
              <v-list-item-title>
                Copy<v-icon color="#F9A825" class="ml-1">mdi-playlist-plus</v-icon>
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="color:#00B24A !important" class="ml-1" @click.stop="editItems=true">
              <v-list-item-title>
                Update<v-icon color="#00B24A" class="ml-1">mdi-update</v-icon>
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="color:#d32f2f !important;" @click="deleteAccrualItem">
              <v-list-item-title>
                Remove<v-icon color="#d32f2f" class="ml-1">mdi-playlist-remove</v-icon>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn :disabled="!Object.prototype.hasOwnProperty.call(accrual, 'id')" class="white--text ml-2" height="40"
          color="#0c67a5" @click="openAccrualItem(null, 'add')">
          Add Item<v-icon dense>mdi-plus</v-icon>
        </v-btn>
        </v-row>
      </v-toolbar>
    </v-card>
    <ErrorModal v-model="errorModal" :errors="errors" />
  </v-dialog>
</template>

<script>
import contracts from '../../axios/contractManager/endpoints'
import accruals from '../../axios/accruals/endpoints'
import maintenance from '../../axios/maintenance/endpoints'

import ErrorModal from '../errors/errorModal'

import { cloneDeep } from 'lodash'
import moment from 'moment'

export default {
  name: 'AccrualDetail',
  data () {
    return {
      accrualCopy: false,
      accrualCopyID: '',
      accrualDelete: false,
      accrualHTML: '',
      accrualItem: false,
      accrualItemCopy: false,
      accrualSupplier: {},
      action: '',
      complete: 0,
      copied: false,
      copy: {},
      copying: false,
      copyingAccrual: false,
      copyConfirm: false,
      count: 0,
      editItems: false,
      errors: [],
      finished: false,
      item: {},
      itemDetail: '',
      items: [],
      errorModal: false,
      itemSaved: false,
      lgLookup: false,
      linkedGroupTable: '',
      linkedItems: [],
      loading: null,
      notification: false,
      pageSize: 1500,
      removed: false,
      removing: false,
      saved: false,
      saving: false,
      updating: false,
      updated: false,
      search: '',
      selectedItems: [],
      selectedLinkedItems: [],
      selectedSubGroups: [],
      subGroups: [],
      tableSize: '',
      total: 0,
      type: '',
      windowHeight: '',
      itemHeaders: [
        {
          text: '',
          sortable: false,
          filterable: false,
          value: ''
        },
        {
          text: 'Sub-Group',
          sortable: true,
          filterable: true,
          value: 'sub_group.name',
          class: 'black--text'
        },
        {
          text: 'Manufacturer',
          sortable: true,
          filterable: true,
          value: 'manufacturer.name',
          class: 'black--text'
        },
        {
          text: 'Item',
          sortable: true,
          filterable: true,
          value: 'item.order_code',
          class: 'black--text'
        },
        {
          text: 'Accrual Amt',
          sortable: true,
          filterable: true,
          value: 'accrual',
          class: 'black--text'
        },
        {
          text: 'Case BB',
          sortable: true,
          filterable: true,
          value: 'case_BB',
          class: 'black--text'
        }
      ],
      linkedItemHeaders: [
        {
          text: '',
          sortable: false,
          value: ''
        },
        {
          text: 'Order Code',
          sortable: true,
          value: 'orderCode',
          class: 'black--text'
        },
        {
          text: 'Item Description',
          sortable: true,
          value: 'description',
          class: 'black--text'
        }
      ],
      subGroupheaders: [
        {
          text: 'Sub-Group',
          sortable: true,
          value: 'description',
          class: 'black--text'
        }
      ]
    }
  },
  components: {
    ErrorModal
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.resize()
        } else {
          this.items = []
          this.selectedItems = []
          this.itemDetail = ''
          this.accrualHTML = ''
        }
      }
    },
    edit: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getSubGroups()
          this.getAccrualItems()
        }
      }
    },
    type: {
      handler (value) {
        if (value === 'edit') {
          this.itemDetail = this.item.item.order_code + ' - ' + this.item.item.description
          this.getByOrderCode(this.item.item.order_code)
        }
      }
    },
    itemDetail: {
      handler (value) {
        if (!value) {
          this.linkedItems = []
        }
      }
    },
    accrualItem: {
      handler (value) {
        if (value) {
          this.item.accrual = '0.00'
        } else {
          this.item = {}
          this.itemDetail = ''
        }
      }
    },
    accrualItemCopy: {
      handler (value) {
        if (value) {
          this.selectedSubGroups = []
        }
      }
    },
    accrualCopy: {
      handler (value) {
        if (!value) {
          this.copy = {}
        }
      }
    },
    windowHeight: {
      handler (value) {
        this.tableSize = value - 412
        this.linkedGroupTable = value - 540
      }
    }
  },
  props: [
    'value',
    'edit',
    'selectedAccrual',
    'toggle',
    'ads',
    'manufacturers',
    'suppliers',
    'getAccruals',
    'formatCurrency'
  ],
  computed: {
    isExistingAccrual () {
      return 'id' in this.accrual
    },
    accrual () {
      return cloneDeep(this.selectedAccrual)
    },
    minDate () {
      return this.accrual.beginning_date
    },
    formattedBeginDate () { return this.accrual.beginning_date ? moment(this.accrual.beginning_date).format('M/D/YYYY') : '' },
    formattedEndDate () { return this.accrual.ending_date ? moment(this.accrual.ending_date).format('M/D/YYYY') : '' },
    formattedBillingDate () { return this.accrual.billing_date ? moment(this.accrual.billing_date).format('M/D/YYYY') : '' }
  },
  methods: {
    resize () { this.windowHeight = window.innerHeight },
    getAccrualItems () {
      this.loading = true
      accruals.getAccrualItems(this.accrual.id).then(response => {
        this.items = []
        this.items = response
        this.loading = false
      })
    },
    formatBeginDate (date) {
      if (!date) return null
      this.accrual.beginning_date = moment(date, ['M/D/YY', 'M/D/YYYY']).format('M/D/YYYY')
    },
    formatEndDate (date) {
      if (!date) return null
      this.accrual.ending_date = moment(date, ['M/D/YY', 'M/D/YYYY']).format('M/D/YYYY')
    },
    formatBillingDate (date) {
      if (!date) return null
      this.accrual.billing_date = moment(date, ['M/D/YY', 'M/D/YYYY']).format('M/D/YYYY')
    },
    formatBeginDateCopy (date) {
      if (!date) return null
      this.copy.beginning_date = moment(date, ['M/D/YY', 'M/D/YYYY']).format('M/D/YYYY')
    },
    formatEndDateCopy (date) {
      if (!date) return null
      this.copy.ending_date = moment(date, ['M/D/YY', 'M/D/YYYY']).format('M/D/YYYY')
    },
    formatBillingDateCopy (date) {
      if (!date) return null
      this.copy.billing_date = moment(date, ['M/D/YY', 'M/D/YYYY']).format('M/D/YYYY')
    },
    focusInput () {
      this.$refs.order_code.focus()
    },
    setAccrualAmt (value) {
      this.item.accrual = ((value * 100) / 100).toFixed(2)
      // if (!value) {
      //   return ''
      // } else {
      // }
    },
    setCaseBB (value) {
      if (value) {
        this.item.case_BB = ((value * 100) / 100).toFixed(2)
      } else {
        return ''
      }
    },
    getSubGroups () {
      maintenance.getSubGroups(this.accrual.supplier.id)
        .then(response => {
          this.subGroups = response
        })
    },
    openAccrualItem (item, type) {
      this.type = type
      if (this.type === 'add') {
        this.accrualItem = true
      } else {
        this.editItem = true
        this.item = item
        this.itemDetail = this.item.item.order_code + ' - ' + this.item.item.description
        this.accrualItem = true
      }
    },
    async saveAccrual () {
      this.errors = []
      if (!this.isExistingAccrual) {
        var accrualObj = {}
        if (this.selectedAccrual.accrual_type === 'Standard') {
          accrualObj = {
            accrual_type: this.selectedAccrual.accrual_type,
            ad_id: this.accrual.ad_id
          }
        } else {
          accrualObj = {
            accrual_type: this.selectedAccrual.accrual_type,
            ad_id: 109,
            beginning_date: moment(this.accrual.beginning_date).format('YYYY-MM-DD'),
            billing_date: moment(this.accrual.billing_date).format('YYYY-MM-DD'),
            ending_date: moment(this.accrual.ending_date).format('YYYY-MM-DD'),
            supplier: this.accrual.supplier
          }
        }
        await accruals.saveAccrual(accrualObj)
          .then(response => {
            this.accrual.id = response.id
            this.accrual.supplier = response.supplier
            this.getSubGroups()
            this.action = 'Saved'
            this.finished = true
            setTimeout(() => { this.finished = false }, 2000)
            setTimeout(() => { this.accrualHTML = '<p class="mt-4"><strong>Accrual ID</strong>: ' + `${this.accrual.id}` + '</p>' }, 2100)
          }).catch(err => {
            try {
              this.errors.push(err.response.data.failure_reason)
            } catch (err) {
              this.errors.push('Accrual Not Created')
            }
            this.errorModal = true
          })
      } else {
        await accruals.updateAccrual(this.accrual)
          .then(() => {
            this.action = 'Updated'
            this.finished = true
            setTimeout(() => { this.finished = false }, 2000)
          }).catch(err => {
            try {
              this.errors.push(err.response.data.failure_reason)
            } catch (err) {
              this.errors.push('Accrual Not Created')
            }
            this.errorModal = true
          })
      }
      this.getAccruals(this.toggle)
    },
    async copyAccrual () {
      this.copyingAccrual = true
      var accrualCopy = {
        accrual_type: this.accrual.accrual_type,
        ad_name: null,
        ad_id: this.accrual.ad_id,
        beginning_date: moment(this.copy.beginning_date).format('YYYY-MM-DD'),
        ending_date: moment(this.copy.ending_date).format('YYYY-MM-DD'),
        billing_date: moment(this.copy.billing_date).format('YYYY-MM-DD'),
        supplier: this.accrual.supplier
      }
      await accruals.saveAccrual(accrualCopy)
        .then(response => {
          accrualCopy.id = response.id
          this.accrualCopyID = response.id
        })
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].accrual_id = accrualCopy.id
        await accruals.saveAccrualItem(this.items[i])
          .then(() => {
          }).catch(err => {
            console.log(err)
          })
      }
      this.getAccruals(this.toggle)
      this.copyingAccrual = false
      this.accrualCopy = false
      this.copyConfirm = true
      // this.action = 'Copied'
      // this.finished = true
      // setTimeout(() => { this.finished = false }, 2000)
    },
    async deleteAccrual () {
      var accrualId = this.accrual.id
      await accruals.deleteAccrual(this.accrual)
        .then(() => {
          this.getAccruals(this.toggle)
          this.accrualDelete = false
          this.$emit('update:value', false)
          this.$emit('action', `Accrual ${accrualId} Deleted`)
        })
    },
    async getByOrderCode (orderCode) {
      this.errors = []
      this.linkedItems = []
      this.selectedLinkedItems = []
      await contracts.getByItemCode(orderCode, this.accrual.supplier.id)
        .then(response => {
          this.item.item = {
            order_code: response[0].orderCode,
            item_code: response[0].itemCode,
            description: response[0].description
          }
          this.itemDetail = this.item.item.order_code + ' - ' + this.item.item.description
          contracts.getProductLinkGroup(orderCode, this.accrual.supplier.id)
            .then(response => {
              response.forEach(item => {
                if (this.item.item.order_code !== item.orderCode) {
                  this.linkedItems.push(item)
                } else {
                  this.selectedLinkedItems.push(item)
                }
              })
            })
        }).catch(err => {
          this.errors.push(err.response.data.msg)
          this.errorModal = true
        })
    },
    async saveAccrualItem () {
      var accrualItem = {}
      this.errors = []
      accrualItem = {
        accrual: this.item.accrual,
        accrual_id: this.accrual.id,
        case_BB: this.item.case_BB,
        item: this.item.item,
        manufacturer: this.item.manufacturer,
        sub_group: this.item.sub_group
      }
      if ('case_BB' in this.item && this.item.case_BB) {
        accrualItem.case_BB = this.item.case_BB
      } else {
        accrualItem.case_BB = 0
      }
      await accruals.saveAccrualItem(accrualItem)
        .then(() => {
          this.complete++
          this.items = []
          this.itemDetail = ''
          this.item.accrual = '0.00'
          this.item.case_BB = ''
          this.action = 'Saved'
          this.itemSaved = true
          this.focusInput()
        }).catch(err => {
          try {
            this.errors.push(`${accrualItem.item.order_code} - ${err.response.data.message}`)
          } catch (err) {
            this.errors.push(`${accrualItem.item.order_code} Not Saved`)
          }
          this.errorModal = true
        })
      setTimeout(() => { this.itemSaved = false }, 2000)
      this.getAccrualItems()
    },
    async addLinkedItems () {
      this.complete = 0
      this.action = 'Saving'
      this.saving = true
      this.complete = 0
      this.errors = []
      this.total = this.selectedLinkedItems.length
      for (let i = 0; i < this.selectedLinkedItems.length; i++) {
        this.count++
        var linkedItem = {
          accrual: this.item.accrual,
          accrual_id: this.accrual.id,
          case_BB: this.item.case_BB,
          item: {
            order_code: this.selectedLinkedItems[i].orderCode,
            item_code: this.selectedLinkedItems[i].itemCode,
            description: this.selectedLinkedItems[i].description
          },
          manufacturer: this.item.manufacturer,
          sub_group: this.item.sub_group
        }
        await accruals.saveAccrualItem(linkedItem)
          .then(() => {
            this.complete++
          }).catch(err => {
            this.errors.push(`${linkedItem.item.order_code} - ${err.response.data.message}`)
          })
      }
      this.saving = false
      if (this.errors.length > 0) {
        this.errorModal = true
        this.getAccrualItems()
      } else {
        this.getAccrualItems()
        this.action = 'Saved'
        this.saved = true
        setTimeout(() => { this.saved = false }, 2000)
      }
      this.selectedLinkedItems = []
      this.linkedItems = []
      this.item.accrual = '0.00'
      this.item.case_BB = ''
      this.itemDetail = ''
      this.count = 0
    },
    async bulkEdit () {
      this.updating = true
      this.complete = 0
      this.total = this.selectedItems.length
      for (let i = 0; i < this.selectedItems.length; i++) {
        this.count++
        if (Object.prototype.hasOwnProperty.call(this.item, 'manufacturer') && this.item.manufacturer !== '') {
          this.selectedItems[i].manufacturer = this.item.manufacturer
        } else { delete this.item.manufacturer }
        if (Object.prototype.hasOwnProperty.call(this.item, 'accrual') && this.item.accrual !== '') {
          this.selectedItems[i].accrual = this.item.accrual
        } else { delete this.item.accrual }
        if (Object.prototype.hasOwnProperty.call(this.item, 'case_BB') && this.item.case_BB !== '') {
          this.selectedItems[i].case_BB = this.item.case_BB
        } else { delete this.item.case_BB }
        await accruals.updateAccrualItem(this.selectedItems[i])
          .then(() => {
            this.complete++
          }).catch(err => {
            console.log('Updating Accrual Items:', err)
          })
      }
      this.count = 0
      this.updating = false
      this.updated = true
      setTimeout(() => { this.updated = false }, 2000)
      this.item = {}
      this.selectedItems = []
      this.getAccrualItems()
    },
    async copyAccrualItems () {
      this.accrualItemCopy = false
      this.action = 'Copying'
      this.copying = true
      this.errors = []
      this.complete = 0
      this.total = this.selectedSubGroups.length * this.selectedItems.length
      for (let s = 0; s < this.selectedSubGroups.length; s++) {
        for (let i = 0; i < this.selectedItems.length; i++) {
          this.count++
          this.selectedItems[i].sub_group = {
            id: this.selectedSubGroups[s].id,
            name: this.selectedSubGroups[s].description
          }
          await accruals.saveAccrualItem(this.selectedItems[i])
            .then(() => {
              this.complete++
            }).catch(err => {
              this.errors.push(`${this.selectedItems[i].item.order_code} - ${err.response.data.message}`)
            })
        }
      }
      this.copying = false
      if (this.errors.length > 0) {
        this.errorModal = true
        this.getAccrualItems()
      } else {
        this.getAccrualItems()
        this.action = 'Copied'
        this.copied = true
        setTimeout(() => { this.copied = false }, 2000)
      }
      this.selectedSubGroups = []
      this.selectedItems = []
      this.count = 0
    },
    async deleteAccrualItem () {
      this.complete = 0
      this.removing = true
      this.action = 'Removing'
      this.total = this.selectedItems.length
      for (let i = 0; i < this.selectedItems.length; i++) {
        this.count++
        await accruals.deleteAccrualItem(this.selectedItems[i])
          .then(() => {
            this.complete++
            var itemIndex = this.items.indexOf(this.selectedItems[i], 0)
            this.items.splice(itemIndex, 1)
          }).catch(() => {
            this.errors.push(`${this.selectedItems[i].item.order_code} - ${this.selectedItems[i].item.description} Not Deleted`)
          })
      }
      this.count = 0
      this.removing = false
      this.action = 'Removed'
      this.removed = true
      setTimeout(() => { this.removed = false }, 2000)
      this.selectedItems = []
      this.count = 0
    }
  }
}
</script>

<style scoped>
.active {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); */
  background: #fafafa;
  color: #133C55 !important;
}

</style>
