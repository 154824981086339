import { base } from './base'
// import { store } from '@/store/index'

export default class accruals {
  static async getAccruals (type, start, end) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/meat_accruals/' + type + '/' + start + '/' + end,
      method: 'GET'
    })
  }

  static async getAccrualById (accrualId) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/meat_accrual/' + accrualId,
      method: 'GET'
    })
  }

  static async getAccrualItems (accrualId) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/meat_accrual_items/' + accrualId,
      method: 'GET'
    })
  }

  static async saveAccrual (accrual) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/meat_accrual',
      method: 'POST',
      data: accrual
    })
  }

  static async updateAccrual (accrual) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/meat_accrual',
      method: 'PUT',
      data: accrual
    })
  }

  static async deleteAccrual (accrual) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/meat_accrual',
      method: 'DELETE',
      data: accrual
    })
  }

  static async saveAccrualItem (accrualItem) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/meat_accrual_item',
      method: 'POST',
      data: accrualItem
    })
  }

  static async updateAccrualItem (accrualItem) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/meat_accrual_item',
      method: 'PUT',
      data: accrualItem
    })
  }

  static async deleteAccrualItem (accrualItem) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/meat_accrual_item',
      method: 'DELETE',
      data: accrualItem
    })
  }
}
