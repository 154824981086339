import { maintenance } from './maintenance'
// import { Auth } from 'aws-amplify'
import { store } from '@/store/index'

export default class endpoints {
  // Ad Group Maintenance Endpoints
  static async getAdGroups (groupCode) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/ad_group/' + groupCode,
      method: 'GET'
    })
  }

  static async newAdGroup (adGroup) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/ad_group',
      method: 'POST',
      data: adGroup
    })
  }

  static async updateAdGroup (adGroup) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/ad_group',
      method: 'PUT',
      data: adGroup
    })
  }

  // Feature Type Maintenance Endpoints
  static async getFeatureTypes () {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/features',
      method: 'GET'
    })
  }

  static async newFeatureType (featureType) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/features',
      method: 'POST',
      data: featureType
    })
  }

  static async updateFeatureType (featureType) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/features',
      method: 'PUT',
      data: featureType
    })
  }

  // Owner Maintenance Endpoints
  static async getOwnerGroups () {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/owners',
      method: 'GET'
    })
  }

  static async newOwnerGroup (owner) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/owners',
      method: 'POST',
      data: owner
    })
  }

  static async updateOwnerGroup (owner) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/owners',
      method: 'PUT',
      data: owner
    })
  }

  // Store Maintenance Endpoints
  static async getStores () {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/store',
      method: 'GET'
    })
  }

  static async newStore (newStore) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/store',
      method: 'POST',
      data: newStore
    })
  }

  static async updateStore (updateStore) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/store',
      method: 'PUT',
      data: updateStore
    })
  }

  static async getOutputStoreSets () {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/outputstoresets',
      method: 'GET'
    })
  }

  // Sub-Group Maintenance Endpoints
  static async getSubGroupMaintenance () {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/subgroups',
      method: 'GET'
    })
  }

  // Accruals
  static async getSubGroups (supplierID) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: `/admin/subgroups/${supplierID}`,
      method: 'GET'
    })
  }

  static async newSubGroup (subGroup) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/subgroups',
      method: 'POST',
      data: subGroup
    })
  }

  static async updateSubGroup (subGroup) {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/subgroups',
      method: 'PUT',
      data: subGroup
    })
  }

  // Vendor Code Mgmt Endpoints
  static async getVendorCodes () {
    maintenance.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return maintenance({
      url: '/admin/vendorcodes',
      method: 'GET'
    })
  }
}
