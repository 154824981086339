<template>
  <v-container class="ma-0">

    <v-toolbar flat color="#fafafa" style="cursor: default;">
      <v-row dense>
        <v-toolbar-title class="ml-2 mt-4 title" @click="getTPRs" style="position:static;">TPRs</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn-toggle mandatory class="mx-1 mt-3 pb-0" style="height:40px">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn :color="!isExpanded ? 'green' : 'red'" class="white--text" v-on="on" height="40"
                :disabled="!isExpanded" @click="isExpanded=false, collapseAll()">
                <v-icon color="white">mdi-collapse-all-outline</v-icon>
              </v-btn>
            </template>
            <span>Collapse Rows</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn :color="isExpanded ? 'red' : 'green'" class="white--text" v-on="on" height="40"
                :disabled="isExpanded" @click="isExpanded=true, expandAll()">
                <v-icon color="white">mdi-expand-all-outline</v-icon>
              </v-btn>
            </template>
            <span>Expand Rows</span>
          </v-tooltip>
        </v-btn-toggle>
        <v-col cols="2">
          <v-autocomplete class="mt-2" auto-select-first dense outlined background-color="#fff"
            placeholder="Select Manufacturer" label="Manufacturer" prepend-inner-icon="mdi-filter-outline"
            :items="manufacturers" item-text="description" item-value="id" v-model="filters.manufacturer"
            :open-on-clear="false" clearable>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field class="mt-2" background-color="#fff" outlined dense label="Order Code"
            placeholder="Enter Order Code" prepend-inner-icon="mdi-filter-outline" @keydown.enter="getTPRs(orderCode)"
            v-model="filters.ordercode" autocomplete="off" clearable>
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-autocomplete class="mt-2" auto-select-first background-color="#fff" outlined dense label="TPR Group"
            style="z-index:0 !important;" prepend-inner-icon="mdi-filter-outline" placeholder="Select TPR Group"
            :items="tprGroups" item-text="description" item-value="id" v-model="filters.adgroup" autocomplete="off" clearable>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field class="mt-2" background-color="#fff" outlined dense label="Contract ID"
            placeholder="Enter Contract ID" prepend-inner-icon="mdi-filter-outline" @keydown.enter="getTPRs(contractID)"
            v-model="filters.contractid" autocomplete="off" type="number" clearable>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field class="mt-2" background-color="#fff" dense outlined label="Begin Date"
            placeholder="Enter Begin Date" prepend-inner-icon="mdi-filter-outline" v-model="filters.begindate"
            autocomplete="off" clearable/>
        </v-col>
        <v-col>
          <v-text-field class="mt-2" background-color="#fff" dense outlined label="End Date"
            placeholder="Enter End Date" prepend-inner-icon="mdi-filter-outline" v-model="filters.enddate"
            autocomplete="off" clearable/>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider />
    <v-data-table style="cursor:default;" :height="tableSize" :loading="loading" :headers="headers" :items.sync="tprs"
      :items-per-page="pageSize" :expanded="expanded" show-select :search="search" loader-height="2" v-model="selected"
      item-key="id" color="#fff" class="table" loading-text="Loading TPRs... Please Wait"
      no-data-text="No Matching TPRs Found" no-results-text="No Matching TPRs Found" fixed-header hide-default-footer
      dense group-by="supplier.description">

      <template v-slot:[`group.header`]="{ group, isOpen, toggle, items, headers }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon>{{isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
          </v-btn>
          <span v-if="tprs.length">
            <b v-if="!loading" class="primary--text mr-2">{{group}}</b> -
            <span class="mx-2">Results By Division: {{items.length}}</span> |

            <!-- <span v-for="(tpr, index) in tprTypes" :key="index" link @click="selectGroup(tpr, items)">
              <a class="mx-2">{{ tpr }}</a>
            </span> | -->

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="green" small icon class="mx-2" @click="selectGroup('All', items)">
                  <v-icon small>mdi-plus-box-multiple-outline</v-icon>
                </v-btn>
              </template>
              <span>Select All</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="red" small icon @click="deselectGroup(items)" class="mr-2">
                  <v-icon small>mdi-minus-box-multiple-outline</v-icon>
                </v-btn>
              </template>
              <span>Deselect All</span>
            </v-tooltip>
            <!-- <span v-if="isSelected(items)">|</span> -->
            <b v-if="isSelected(items)"><span class="mx-2 primary--text">({{isSelected(items)}} Selected)</span></b>
          </span>

        </td>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td class="itemDetails" :colspan="headers.length">
          <strong><small>Case Cost:</small></strong><small>{{formatCurrency(item.caseCost)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <strong><small>AMAP:</small></strong><small>{{formatCurrency(item.AMAP)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <strong><small>EBA:</small></strong><small>{{formatCurrency(item.EBA)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <strong><small>TPR BB:</small></strong><small>{{formatCurrency(item.BB)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <strong><small>TPR Scan:</small></strong><small>{{formatCurrency(item.scanAllow)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <strong><small>ePay TPR:</small></strong><small>{{formatCurrency(item.ePay)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <strong><small>Reg SRP:</small></strong><small>{{item.regSRPCount}} @ {{formatCurrency(item.regSRP)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <strong><small>TPR Retail:</small></strong><small>{{item.specialSRPCount}} @
            {{formatCurrency(item.specialSRP)}}</small>
          <v-divider class="ml-2 mr-2" vertical />
          <strong><small class="mr-2">Internal Comment:</small></strong>
          <small><span v-if="item.contract.internalComment">{{item.contract.internalComment}}</span><span
              v-else>N/A</span></small>
        </td>
      </template>

      <template v-slot:[`item.dealBeginDate`]="{ item }">
        <v-chip v-if="item.status === 'Active'" outlined small class="black--text" color="#00b24a">
          {{formatDates(item.dealBeginDate)}} - {{formatDates(item.dealEndDate)}}</v-chip>
        <v-chip v-else-if="item.status === 'Upcoming'" outlined small class="black--text" color="#f9a724">
          {{formatDates(item.dealBeginDate)}} - {{formatDates(item.dealEndDate)}}</v-chip>
        <v-chip v-else outlined small class="black--text" color="#d32f2f">{{formatDates(item.dealBeginDate)}} -
          {{formatDates(item.dealEndDate)}}</v-chip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small icon>
              <v-icon @click="setComponent('TprEdit', item)">mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small icon>
              <v-icon @click="setComponent('TprCopy', item)">mdi-content-duplicate</v-icon>
            </v-btn>
          </template>
          <span>Copy</span>
        </v-tooltip>
      </template>

    </v-data-table>

    <v-divider />
    <v-toolbar flat color="#fafafa">
      <v-row align="center">
        <v-col cols="2">
          <v-menu v-if="selected.length > 0" top :offset-y="offset" class="pb-3">
            <template v-slot:activator="{ on }">
              <v-btn height="40" color="#E0E0E0" v-on="on">
                Selected ({{selected.length}})
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="tprBulkEnabled" class="ml-1" style="color:#00b24a !important;"
                @click="component='TprBulkEdit', modal=true">
                <v-list-item-title>
                  Update<v-icon right color="#00b24a">mdi-update</v-icon>
                </v-list-item-title>
              </v-list-item>
              <v-list-item style="color:#d32f2f !important;" @click="component='TprDeleteConfirm', modal=true">
                <v-list-item-title>
                  Remove<v-icon right color="#d32f2f">mdi-playlist-remove</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-spacer />
        <v-col v-if="filterResults.length" cols="2">
          <p class="font-weight-light blue--text">{{filterResults.length > 1 ? `${filterResults.length} Results` : `${filterResults.length} Result`}}</p>
        </v-col>
        <v-spacer />
        <v-tooltip v-if="exportAvailable" top>
          <template v-slot:activator="{ on }">
            <v-btn :loading="exporting" v-on="on" icon class="mx-2" @click="exportTPRs">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>{{exporting ? 'Exporting...' : 'Export All'}}</span>
        </v-tooltip>
        <v-col cols="2" style="max-width:150px;">
          <v-select class="mt-6" dense :items="pageSizes" label="Items Per Page" select v-model="pageSize"
            :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
          </v-select>
        </v-col>
        <v-btn icon large @click="previous()" :disabled="disablePrevious" class="mx-2">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <small>Page {{page}}</small>
        <v-btn icon large @click="next()" :disabled="disableNext" class="mx-2">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-toolbar>

    <v-dialog justify="center" :value="loader" max-width="600" persistent>
      <v-card color=#fafafa>
        <section class="ma-0 pa-6">
          <v-row dense justify="center">
            <b>{{operation}} TPRs...Please Wait</b>
          </v-row>
          <v-row dense justify="center">
            <v-progress-linear rounded v-model="progress" height="25" color="primary">
              <template v-slot:default="{ value }">
                <strong :class="value > 50 ? 'white--text' : 'black--text'">{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </v-row>
        </section>
      </v-card>
    </v-dialog>

    <Component :getTPRs="getTPRs" :is="component" :selected="selected" :selectedTPR="selectedTPR" :value.sync="modal"
      @delete="removeTPRs" @update="bulkUpdateTPRs" />

  </v-container>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'
import { store } from '@/store/index'
import { debounce, difference, uniqBy } from 'lodash'
import Bottleneck from 'bottleneck'

export default {
  name: 'TprList',

  mixins: [tableSettings, notification],

  components: {
    TprBulkEdit: () => import('@/components/tprs/tpr-bulk-edit'),
    TprEdit: () => import('@/components/tprs/tpr-edit'),
    TprCopy: () => import('@/components/tprs/tpr-copy'),
    TprDeleteConfirm: () => import('@/components/tprs/tpr-delete-confirm')
  },

  data () {
    return {
      progress: 0,
      timeRemaining: '',
      component: '',
      tprDelete: false,
      disableNext: false,
      disablePrevious: true,
      division: '',
      divisions: [],
      errorDisplay: false,
      errors: [],
      expanded: [],
      exporting: false,
      filters: {},
      filterResults: [],
      isExpanded: true,
      loading: true,
      loader: false,
      manufacturers: [],
      message: '',
      modal: false,
      offset: true,
      operation: '',
      page: 1,
      pageSize: 250,
      pageSizes: [250, 500, 1000],
      results: '',
      search: '',
      selected: [],
      selectedGroupItems: [],
      showCurrent: false,
      selectedGroup: [],
      selectedTPR: {},
      suppliers: [],
      tprGroups: [],
      tprs: []
    }
  },

  computed: {
    tprBulkEnabled () {
      return store.state.user.signInUserSession.accessToken.payload['cognito:groups'].includes('AdMan_TPR_Bulk')
    },

    headers () {
      return [
        { text: 'Order Code', align: 'left', sortable: true, filterable: true, value: 'item.orderCode', class: 'black--text' },
        { text: 'Manufacturer', sortable: false, filterable: true, align: 'center', value: 'manufacturer.name', class: 'black--text' },
        { text: 'TPR Group', sortable: true, filterable: true, align: 'left', value: 'adGroup.description', class: 'black--text' },
        { text: 'Contract ID', sortable: true, filterable: true, align: 'left', value: 'contract.id', class: 'black--text' },
        { text: 'Item Description', align: 'left', sortable: true, filterable: true, value: 'item.description', class: 'black--text' },
        { text: 'Pack', align: 'left', sortable: true, filterable: true, value: 'item.pack', class: 'black--text' },
        { text: 'Size', align: 'left', sortable: true, filterable: true, value: 'item.Size', class: 'black--text' },
        { text: 'UPC', align: 'left', sortable: true, filterable: true, value: 'item.upc', class: 'black--text' },
        { text: 'Begin Date - End Date', align: 'center', sortable: true, filterable: true, value: 'dealBeginDate', class: 'black--text' },
        { text: 'Actions', sortable: true, filterable: true, align: 'center', value: 'action', class: 'black--text' },
      ]
    },

    tprTypes () {
      return [
        'Upcoming',
        'Active',
        'Expired'
      ]
    },

    currentDate () {
      return this.moment().format(this.$config.date_format)
    },

    formattedBeginDate () {
      return this.filters.begindate ? this.moment(this.filters.begindate).format(this.$config.date_format) : ''
    },

    formattedEndDate () {
      return this.filters.enddate ? this.moment(this.filters.enddate).format(this.$config.date_format) : ''
    },

    exportAvailable () {
      return !this._.isEmpty(this.filters.adgroup) && !this._.isEmpty(this.filters.begindate)
    }
  },

  watch: {
    filters: {
      deep: true,
      handler: debounce(function () {
        for (const key in this.filters) {
          if (!this.filters[key]) {
            delete this.filters[key]
          }
        }
        this.getTPRs()
      }, 250)
    },

    pageSize: {
      deep: true,
      handler () {
        this.loading = true
        this.page = 1
        this.getTPRs()
      }
    }
  },

  created () {
    this.nonTableHeight = 245
    this.getTPROptions()

    this.getTPRs()

    this.$root.$on('delete-parent', this.deleteTPR)
  },

  methods: {
    collapseAll () {
      Object.keys(this.$refs).forEach(k => {
        if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
          this.$refs[k].$el.click()
        }
      })
    },

    expandAll () {
      Object.keys(this.$refs).forEach(k => {
        if (this.$refs[k] && !this.$refs[k].$attrs['data-open']) {
          this.$refs[k].$el.click()
        }
      })
    },

    setComponent (component, tpr) {
      if (tpr) { this.selectedTPR = tpr }
      this.component = component
      this.modal = true
    },

    getStatus () {
      this.tprs.forEach(tpr => {
        if (this.currentDate >= tpr.dealBeginDate && this.currentDate <= tpr.dealEndDate) {
          tpr.status = 'Active'
        } else if (this.currentDate < tpr.dealBeginDate) {
          tpr.status = 'Upcoming'
        } else if (this.currentDate > tpr.dealEndDate) {
          tpr.status = 'Expired'
        }
      })
    },

    isSelected (items) {
      const arr = []
      this.selected.forEach(tpr => {
        items.forEach(item => {
          if (tpr.id === item.id) {
            arr.push(item)
          }
        })
      })
      return arr.length
    },

    selectGroup (tpr, items) {
      items.forEach(item => {
        if (tpr === item.status) {
          this.selected.push(item)
        } else if (tpr === 'All') {
          this.selected.push(item)
        }
      })

      this.selected = uniqBy(this.selected, function (tpr) {
        return tpr.id
      })
    },

    deselectGroup (items) {
      this.selected = difference(this.selected, items)
    },

    formatCurrency (value) {
      return '$' + parseFloat(value).toFixed(2)
    },

    formatDates (date) {
      return date ? this.moment(date).format(this.$config.date_display) : ''
    },

    async getTPROptions () {
      const manufacturers = this.$contracts.getManufacturers()
      const suppliers = this.$contracts.getSuppliers()
      const tprGroups = this.$tprs.getTPRGroups()

      await Promise.all([manufacturers, suppliers, tprGroups])
        .then(response => {
          this.manufacturers = response[0]
          this.suppliers = response[1]
          this.tprGroups = response[2]
        })
    },

    async getTPRs () {
      this.selected = []
      this.loading = true

      const start = (this.page - 1) * this.pageSize
      const end = this.pageSize
      const params = new URLSearchParams()
      const filters = this._.cloneDeep(this.filters)

      for (const key in filters) {
        params.append(key, filters[key] ? filters[key] : '')
      }

      await this.$tprs.getTPRs(start, end, params)
        .then(response => {
          this.tprs = response
          if (Object.keys(this.filters).length)  {
            this.filterResults = response
          } else {
            this.filterResults = []
          }
          this.expanded = this.tprs
          this.getStatus()
        }).catch(() => {
          this.notify('error', 'Invalid search parameters')
        })
      this.loading = false
      if (this.tprs.length < this.pageSize) {
        this.disableNext = true
      } else {
        this.disableNext = false
      }
      if (start === 0) {
        this.disablePrevious = true
      } else {
        this.disablePrevious = false
      }
    },

    previous () {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      this.getTPRs()
    },

    next () {
      this.page = this.page + 1
      this.getTPRs()
    },

    async exportTPRs () {
      this.showLoader('Exporting', true)
      const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      await this.$tprs.exportTPRs(this.filters.adgroup, this.formattedBeginDate)
        .then(response => {
          var blob = new Blob([response], { type: contentType })
          var objectUrl = URL.createObjectURL(blob)
          var anchor = document.createElement("a")
          anchor.download = `${this.filters.adgroup}_${this.formattedBeginDate}.xlsx`
          anchor.href = objectUrl
          anchor.click()
        }).catch(() => {
          this.notify('error', 'Failed to export TPRs')
        })
        this.showLoader('', false)
    },

    async updateMyData(tpr) {
      return this.$tprs.updateTPR(tpr)
        .catch(err => {
          try {
            this.errors.push(`${tpr.item.orderCode} - ${tpr.adGroup.description} - ${err.response.data.failure_reason}`)
          } catch {
            this.errors.push(`${tpr.item.orderCode} - ${tpr.adGroup.description} Failed to Update`)
          }
        })
    },

    async bulkUpdateTPRs (tpr) {
      const config = await this.$limiter.getLimiterConfig('TPR_UPDATE')

      this.errors = []
      this.operation = 'Updating'
      this.modal = false
      this.loader = true

      let interval

      if (this.selected.length > config.reservoir) {
        let counter = config.reservoir
        this.progress = (counter / this.selected.length) * 100
        interval = setInterval(() => {
          if (this.selected.length > config.reservoir) counter = counter + config.reservoir
          if (counter < this.selected.length) this.progress = (counter / this.selected.length) * 100
        }, 5000)
      } else {
        this.progress = 100
      }


      const limiter = new Bottleneck({
        reservoir: config.reservoir || 25,
        reservoirRefreshAmount: config.reservoir_refresh_amount || 25,
        reservoirRefreshInterval: config.reservoir_refresh_interval || 5000,
        maxConcurrent: config.max_concurrent || 25,
        minTime: config.min_time || 0
      })

      const throttledGetMyData = limiter.wrap(this.updateMyData)

      const allThePromises = this.selected.map(obj => {
        for (const a in obj) {
          for (const b in tpr) {
            if (b === 'dealBeginDate') {
              tpr.dealBeginDate = this.moment(tpr.dealBeginDate).format(this.$config.date_format)
            }
            if (b === 'dealEndDate') {
              tpr.dealEndDate = this.moment(tpr.dealEndDate).format(this.$config.date_format)
            }
            if (a === b) {
              obj[a] = tpr[b]
            }
          }
        }
        return throttledGetMyData(obj)
      })

      await Promise.all(allThePromises)
      clearInterval(interval)
      if (this.errors.length) {
        this.showErrors(this.errors)
      } else {
        this.notify('success', 'TPRs Successfully Updated')
      }
      this.loader = false
      this.getTPRs()
    },

    async removeMyData(tpr) {
      return this.$tprs.removeTPR(tpr)
        .catch(err => {
          try {
            this.errors.push(`${tpr.item.orderCode} - (${tpr.adGroup.description}) - ${err.response.data.failure_reason}`)
          } catch {
            this.errors.push(`${tpr.item.orderCode} Not Deleted`)
          }
        })
    },

    async removeTPRs (notificationText) {
      const config = await this.$limiter.getLimiterConfig('TPR_UPDATE')

      this.errors = []
      this.operation = 'Removing'
      this.loader = true

      let interval

      if (this.selected.length > config.reservoir) {
        let counter = config.reservoir
        this.progress = (counter / this.selected.length) * 100
        interval = setInterval(() => {
          if (this.selected.length > config.reservoir) counter = counter + config.reservoir
          if (counter < this.selected.length) this.progress = (counter / this.selected.length) * 100
        }, 5000)
      } else {
        this.progress = 100
      }

      const limiter = new Bottleneck({
        reservoir: config.reservoir || 25,
        reservoirRefreshAmount: config.reservoir_refresh_amount || 25,
        reservoirRefreshInterval: config.reservoir_refresh_interval || 5000,
        maxConcurrent: config.max_concurrent || 25,
        minTime: config.min_time || 0
      })

      const throttledGetMyData = limiter.wrap(this.removeMyData)

      const allThePromises = this.selected.map(tpr => {
        return throttledGetMyData(tpr)
      })

      await Promise.all(allThePromises)

      clearInterval(interval)

      if (this.errors.length) {
        this.showErrors(this.errors)
      } else {
        this.notify('success', notificationText)
      }
      this.selected = []
      this.loader = false
      this.getTPRs()
    },

    allowedTPRBegin: val => ![0, 1, 2, 3, 4, 5].includes(new Date(val).getDay()),

    allowedTPREnd: val => ![0, 1, 2, 3, 4, 6].includes(new Date(val).getDay())
  }
}
</script>

<style scoped>
.table {
  cursor: default;
}

.table >>> tbody tr.v-data-table__expanded__content {
  background: #eeeeee;
  box-shadow: none;
  cursor: default;
}

.itemsPerPage {
  margin-top: 30px;
}

small {
  font-size: 12px;
}

</style>
