<template>
  <v-dialog v-model="show" persistent max-width="700">
    <v-card>
      <v-toolbar dark flat color="#1C3144">
        <v-toolbar-title>Errors</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6" style="max-height:200px; overflow:auto;">
        <ul v-for="error in errors" :key="error">
          <li style="color:red;">{{error}}</li>
        </ul>
      </v-container>
      <v-divider />
      <v-toolbar flat color="#fafafa">
        <v-spacer />
        <v-btn color="#0c67a5" text @click="show=false">Okay</v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorModal',
  data () {
    return {}
  },
  props: [
    'value',
    'errors'
  ],
  computed: {
    show: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  }
}
</script>
