var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-6" },
    [
      _c(
        "v-row",
        { staticClass: "mb-4", attrs: { justify: "center" } },
        [
          _c("v-col", { attrs: { cols: "3" } }, [
            _c("small", [
              _vm._v(
                " The date the account closed. Entering a date here will close this store to all further activity, including the importing of movement data. "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                "nudge-right": 8,
                "nudge-top": 35,
                transition: "scale-transition",
                "offset-y": "",
                "max-width": "290px",
                "min-width": "290px"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "background-color": "#fff",
                              dense: "",
                              outlined: "",
                              label: "Termination Date",
                              value: _vm.terminationDate,
                              autocomplete: "off"
                            },
                            on: { change: _vm.setTerminationDate },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-inner",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g({ staticClass: "calendar" }, on),
                                        [_vm._v("mdi-calendar")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                _vm.store.account_closed_date !== null
                                  ? {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "clear",
                                              attrs: { tabindex: "-1" },
                                              on: {
                                                click: _vm.clearTerminationDate
                                              }
                                            },
                                            [_vm._v("mdi-close")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  : null
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("v-date-picker", {
                attrs: {
                  color: "#033f63",
                  "no-title": "",
                  "show-current": "false",
                  autocomplete: "off"
                },
                model: {
                  value: _vm.store.account_closed_date,
                  callback: function($$v) {
                    _vm.$set(_vm.store, "account_closed_date", $$v)
                  },
                  expression: "store.account_closed_date"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }