var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-6" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  readonly: "",
                  items: _vm.adGroups,
                  "item-text": "description",
                  "item-value": "id",
                  "background-color": "#fff",
                  dense: "",
                  outlined: "",
                  label: "Ad Group"
                },
                model: {
                  value: _vm.store.groups.ad_group,
                  callback: function($$v) {
                    _vm.$set(_vm.store.groups, "ad_group", $$v)
                  },
                  expression: "store.groups.ad_group"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  readonly: "",
                  items: _vm.subGroups,
                  "item-text": "description",
                  "item-value": "id",
                  "background-color": "#fff",
                  dense: "",
                  outlined: "",
                  label: "Sub-Group"
                },
                model: {
                  value: _vm.store.groups.sub_group,
                  callback: function($$v) {
                    _vm.$set(_vm.store.groups, "sub_group", $$v)
                  },
                  expression: "store.groups.sub_group"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  readonly: "",
                  items: _vm.ownerGroups,
                  "item-text": "name",
                  "item-value": "id",
                  "background-color": "#fff",
                  dense: "",
                  outlined: "",
                  label: "Owner Group"
                },
                model: {
                  value: _vm.store.groups.owner_group,
                  callback: function($$v) {
                    _vm.$set(_vm.store.groups, "owner_group", $$v)
                  },
                  expression: "store.groups.owner_group"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  readonly: "",
                  items: _vm.catMgmtGroups,
                  "item-text": "description",
                  "item-value": "group",
                  "background-color": "#fff",
                  dense: "",
                  outlined: "",
                  label: "Category Management Group"
                },
                model: {
                  value: _vm.store.groups.category_management_group,
                  callback: function($$v) {
                    _vm.$set(_vm.store.groups, "category_management_group", $$v)
                  },
                  expression: "store.groups.category_management_group"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  readonly: "",
                  items: _vm.vmcAdGroups,
                  "item-text": "description",
                  "item-value": "index",
                  "background-color": "#fff",
                  dense: "",
                  outlined: "",
                  label: "VMC Ad Group"
                },
                model: {
                  value: _vm.store.groups.vmc_ad_group,
                  callback: function($$v) {
                    _vm.$set(_vm.store.groups, "vmc_ad_group", $$v)
                  },
                  expression: "store.groups.vmc_ad_group"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }