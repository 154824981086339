import { base } from './base'
// import { store } from '@/store/index'
export default class ads {
  /* ********* GET Calls for Ad Planner ********* */

  static async getAds (start, end, adGroup, adDate) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: `/ads/getAdList/${start}/${end}?adgroup=${adGroup}&addate=${adDate}`,
      method: 'GET'
    })
  }

  static async getAdGroups () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/adGroups',
      method: 'GET'
    })
  }

  static async getAdTypes () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/adTypes',
      method: 'GET'
    })
  }

  static async getAdFeatures (adID) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: `/ads/getAdFeatures/${adID}`,
      method: 'GET'
    })
  }

  static async getAdFeatureTypes () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/adFeatureTypes',
      method: 'GET'
    })
  }

  static async getFeatureItems (featureID) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: `/ads/getAdItems/${featureID}`,
      method: 'GET'
    })
  }

  static async getPages () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/pages',
      method: 'GET'
    })
  }

  static async getDepartments (supplier) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: `/departments/${supplier}`,
      method: 'GET'
    })
  }

  static async getManufacturers () {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/manufacturers',
      method: 'GET'
    })
  }

  /* ********* Ads ********* */

  static async saveAd (ad) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/ad',
      method: 'POST',
      data: ad
    })
  }

  static async updateAd (ad) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/ad',
      method: 'PUT',
      data: ad
    })
  }

  static async copyAd (srcAd, targetAd, adDate) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: `/ads/copy/${srcAd}/${targetAd}/${adDate}`,
      method: 'POST'
    })
  }

  static async deleteAd (deleteAd) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/ad',
      method: 'DELETE',
      data: deleteAd
    })
  }

  /* ********* Ad Features ********* */

  static async saveAdFeature (saveAdFeature) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/ads/feature',
      method: 'POST',
      data: saveAdFeature
    })
  }

  static async updateAdFeature (adFeature) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/ads/feature',
      method: 'PUT',
      data: adFeature
    })
  }

  static async copyAdFeature (featureId, adId, featureType) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: `/ads/feature/copy/${featureId}/${adId}/${featureType}`,
      method: 'POST'
    })
  }

  static async moveAdFeature (featureId, adId, featureType) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: `/ads/feature/move/${featureId}/${adId}/${featureType}`,
      method: 'POST'
    })
  }

  static async deleteAdFeature (adFeature) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/ads/feature',
      method: 'DELETE',
      data: adFeature
    })
  }

  /* ********* Ad Feature Items ********* */

  static async saveFeatureItem (featureItem) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/ads/item',
      method: 'POST',
      data: featureItem
    })
  }

  static async updateFeatureItem (featureItem) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/ads/item',
      method: 'PUT',
      data: featureItem
    })
  }

  static async deleteFeatureItem (featureItem) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/ads/item',
      method: 'DELETE',
      data: featureItem
    })
  }

  static async getSRPList (item) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/ads/srp_list',
      method: 'POST',
      data: item
    })
  }

  static async adShuttle (adShuttle) {
    // base.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken

    return base({
      url: '/ads/shuttle',
      method: 'POST',
      data: adShuttle
    })
  }
}
