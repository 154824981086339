var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c("v-data-table", {
        staticClass: "table",
        attrs: {
          height: _vm.tableSize,
          items: _vm.subGroups,
          headers: _vm.headers,
          "items-per-page": _vm.pageSize,
          loading: _vm.loading,
          search: _vm.search,
          "loader-height": "2",
          "hide-default-footer": "",
          "fixed-header": "",
          dense: "",
          "item-key": "id",
          "loading-text": "Loading... Please wait"
        },
        on: {
          "update:items": function($event) {
            _vm.subGroups = $event
          },
          "click:row": _vm.editSubGroup
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  {
                    staticClass: "toolbar",
                    attrs: { flat: "", color: "#fafafa" }
                  },
                  [
                    _c("v-toolbar-title", [_vm._v("Sub-Group Maintenance")]),
                    _c("v-divider", {
                      staticClass: "mx-4",
                      attrs: { inset: "", vertical: "" }
                    }),
                    _c(
                      "v-col",
                      { staticClass: "mt-6", attrs: { cols: "3" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "background-color": "#fff",
                            "prepend-inner-icon": "mdi-filter-outline",
                            label: "Filter Sub-Groups"
                          },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-divider")
              ]
            },
            proxy: true
          },
          {
            key: "item.meat_billing_exclude",
            fn: function(ref) {
              var item = ref.item
              return [
                item.meat_billing_exclude === false
                  ? _c("span", { staticClass: "ml-12" }, [_vm._v("No")])
                  : _c("span", { staticClass: "ml-12" }, [_vm._v("Yes")])
              ]
            }
          },
          {
            key: "item.inactive",
            fn: function(ref) {
              var item = ref.item
              return [
                item.inactive === false
                  ? _c("span", { staticClass: "ml-1" }, [_vm._v("Yes")])
                  : _c("span", { staticClass: "ml-1" }, [_vm._v("No")])
              ]
            }
          },
          {
            key: "item.rebate_deduction_pct",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.formatRebateDeduction(item.rebate_deduction_pct)
                    ) +
                    " % "
                )
              ]
            }
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("v-divider"),
                _c("v-toolbar", { attrs: { flat: "", color: "#fafafa" } })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "600" },
          model: {
            value: _vm.subGroupModal,
            callback: function($$v) {
              _vm.subGroupModal = $$v
            },
            expression: "subGroupModal"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "#fafafa" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "#1C3144", dark: "", flat: "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  !_vm.edit
                    ? _c("v-toolbar-title", [_vm._v("New Sub-Group Setup")])
                    : _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.subGroup.description))
                      ])
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "ma-0 pa-6" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Sub Code"
                            },
                            model: {
                              value: _vm.subGroup.code,
                              callback: function($$v) {
                                _vm.$set(_vm.subGroup, "code", $$v)
                              },
                              expression: "subGroup.code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Description"
                            },
                            model: {
                              value: _vm.subGroup.description,
                              callback: function($$v) {
                                _vm.$set(_vm.subGroup, "description", $$v)
                              },
                              expression: "subGroup.description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Rebate Deduction",
                              items: _vm.deductions,
                              "item-text": "label",
                              "item-value": "value"
                            },
                            model: {
                              value: _vm.subGroup.rebate_deduction_pct,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.subGroup,
                                  "rebate_deduction_pct",
                                  $$v
                                )
                              },
                              expression: "subGroup.rebate_deduction_pct"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Managing Office",
                              items: _vm.officeCodes,
                              "item-text": "title",
                              "item-value": "id"
                            },
                            model: {
                              value: _vm.subGroup.office_code,
                              callback: function($$v) {
                                _vm.$set(_vm.subGroup, "office_code", $$v)
                              },
                              expression: "subGroup.office_code"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Exclude from Meat Billing" },
                            model: {
                              value: _vm.subGroup.exclude_from_meat_billing,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.subGroup,
                                  "exclude_from_meat_billing",
                                  $$v
                                )
                              },
                              expression: "subGroup.exclude_from_meat_billing"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Inactive" },
                            model: {
                              value: _vm.subGroup.inactive,
                              callback: function($$v) {
                                _vm.$set(_vm.subGroup, "inactive", $$v)
                              },
                              expression: "subGroup.inactive"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "#fafafa" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { height: "40", color: "#00B24A" },
                      on: { click: _vm.saveSubGroup }
                    },
                    [
                      !_vm.edit
                        ? _c(
                            "span",
                            [
                              _vm._v("Save"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-checkbox-marked-circle-outline")]
                              )
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _vm._v("Update"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-update")]
                              )
                            ],
                            1
                          )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          staticClass: "notification",
          attrs: {
            outlined: "",
            timeout: _vm.timeout,
            color: "#00B24A",
            top: ""
          },
          model: {
            value: _vm.notification,
            callback: function($$v) {
              _vm.notification = $$v
            },
            expression: "notification"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.text)),
          _c("v-icon", { attrs: { color: "#fff" } }, [
            _vm._v("mdi-check-circle")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }