import { base } from './base'
// import { store } from '@/store/index'

export default class shuttles {
  static async shuttleToAd (shuttle) {
    return base({
      url: '/ads/shuttle',
      method: 'POST',
      data: shuttle
    })
  }

  static async shuttleToTPR (shuttle) {
    return base({
      url: '/tprs/shuttle',
      method: 'POST',
      data: shuttle
    })
  }

  static async getShuttlesByUser (email, date) {
    return base({
      url: `/shuttleStatus/${email}/${date}`,
      method: 'GET'
    })
  }

  static async getShuttleDetails (shuttleId) {
    return base({
      url: '/shuttle_contract/' + shuttleId,
      method: 'GET'
    })
  }

  static async getShuttleUsers () {
    return base({
      url: '/shuttle_users',
      method: 'GET'
    })
  }

  static async removeShuttle (uuid) {
    return base({
      url: '/shuttleStatus/' + uuid,
      method: 'DELETE'
    })
  }

  static async getAdGroupSRPs (item) {
    return base({
      url: '/ads/srp_list',
      method: 'POST',
      data: item
    })
  }

  static async getTPRGroupSRPs (item) {
    return base({
      url: '/tprs/srp_list',
      method: 'POST',
      data: item
    })
  }
}
