var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c(
        "section",
        { staticClass: "px-4 mb-0" },
        [
          _c(
            "v-toolbar",
            {
              staticStyle: { cursor: "default" },
              attrs: { flat: "", color: "#fafafa" }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    to: { path: "/contracts" },
                    text: "",
                    color: "#0c67a5"
                  }
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { large: "", left: "", color: "#0c67a5" } },
                    [_vm._v("mdi-chevron-left")]
                  ),
                  _vm._v("Contracts ")
                ],
                1
              ),
              _c("v-spacer"),
              _vm.hasContractChanged
                ? _c(
                    "span",
                    [
                      _c("v-icon", { attrs: { color: "orange", left: "" } }, [
                        _vm._v("mdi-alert-circle-outline")
                      ]),
                      _c("small", { staticClass: "orange--text" }, [
                        _vm._v(
                          "Contract information has been changed. Please update."
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-radio-group",
                {
                  staticClass: "mt-6",
                  attrs: { row: "" },
                  model: {
                    value: _vm.contract.ContractScope,
                    callback: function($$v) {
                      _vm.$set(_vm.contract, "ContractScope", $$v)
                    },
                    expression: "contract.ContractScope"
                  }
                },
                _vm._l(_vm.scopes, function(s) {
                  return _c("v-radio", {
                    key: s.value,
                    attrs: {
                      color: "#0c67a5",
                      label: "" + s.label,
                      value: s.value
                    }
                  })
                }),
                1
              ),
              _c("v-divider", {
                staticClass: "mx-4",
                attrs: { vertical: "", inset: "" }
              }),
              _c("b", [
                _c("p", { staticClass: "mt-4" }, [
                  _vm._v(
                    _vm._s(
                      _vm.isExistingContract
                        ? "ID: " + _vm.contract.ContractID
                        : "New Contract"
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: [
                        function(v) {
                          return !!v || "Contract Name is required"
                        }
                      ],
                      "background-color": "#fff",
                      label: "Contract Name",
                      autocomplete: "off",
                      dense: "",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.contract.Name,
                      callback: function($$v) {
                        _vm.$set(_vm.contract, "Name", $$v)
                      },
                      expression: "contract.Name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "auto-select-first": "",
                      rules: [
                        function(v) {
                          return !!v || "Contract Type is required"
                        }
                      ],
                      items: _vm.contractTypes,
                      "background-color": "#fff",
                      label: "Contract Type",
                      "item-text": "description",
                      "item-value": "id",
                      autocomplete: "off",
                      dense: "",
                      outlined: ""
                    },
                    on: { change: _vm.contractTypeChange },
                    model: {
                      value: _vm.contract.ContractType.ID,
                      callback: function($$v) {
                        _vm.$set(_vm.contract.ContractType, "ID", $$v)
                      },
                      expression: "contract.ContractType.ID"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "auto-select-first": "",
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      label: "Ad Group",
                      items: _vm.adGroups,
                      rules: [
                        function(v) {
                          return !!v || "Ad Group is required"
                        }
                      ],
                      "item-text": "description",
                      "item-value": "id",
                      autocomplete: "off"
                    },
                    on: { change: _vm.adGroupSelect },
                    model: {
                      value: _vm.contract.AdGroup.ID,
                      callback: function($$v) {
                        _vm.$set(_vm.contract.AdGroup, "ID", $$v)
                      },
                      expression: "contract.AdGroup.ID"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "auto-select-first": "",
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      label: "Base Supplier",
                      items: _vm.suppliers,
                      rules: [
                        function(v) {
                          return !!v || "Base Supplier Name is required"
                        }
                      ],
                      "item-text": "description",
                      "item-value": "id",
                      autocomplete: "off"
                    },
                    on: { change: _vm.supplierSelect },
                    model: {
                      value: _vm.contract.Supplier.ID,
                      callback: function($$v) {
                        _vm.$set(_vm.contract.Supplier, "ID", $$v)
                      },
                      expression: "contract.Supplier.ID"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "auto-select-first": "",
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      label: "Department",
                      "item-text": "name",
                      "item-value": "id",
                      rules: [
                        function(v) {
                          return !!v || "Department is required"
                        }
                      ],
                      items: _vm.departments,
                      autocomplete: "off"
                    },
                    on: { change: _vm.departmentSelect },
                    model: {
                      value: _vm.contract.Department.ID,
                      callback: function($$v) {
                        _vm.$set(_vm.contract.Department, "ID", $$v)
                      },
                      expression: "contract.Department.ID"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "auto-select-first": "",
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      label: "Manufacturer",
                      "item-text": "description",
                      rules: [
                        function(v) {
                          return !!v || "Manufacturer is required"
                        }
                      ],
                      items: _vm.manufacturers,
                      "item-value": "id",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.contract.Manufacturer.ID,
                      callback: function($$v) {
                        _vm.$set(_vm.contract.Manufacturer, "ID", $$v)
                      },
                      expression: "contract.Manufacturer.ID"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticStyle: { "max-width": "15%" }, attrs: { cols: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "auto-select-first": "",
                      value: _vm.formattedAdDate,
                      "background-color": "#fff",
                      "prepend-inner-icon": "mdi-calendar",
                      label: "Ad Date",
                      autocomplete: "off",
                      dense: "",
                      outlined: "",
                      items: _vm.adDates
                    },
                    on: { change: _vm.selectAdDate },
                    scopedSlots: _vm._u(
                      [
                        _vm.contract.AdDate !== null
                          ? {
                              key: "append",
                              fn: function() {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "clear",
                                      attrs: { tabindex: "-1" },
                                      on: {
                                        click: function($event) {
                                          return _vm.clear("adDate")
                                        }
                                      }
                                    },
                                    [_vm._v("mdi-close")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticStyle: { "max-width": "15%" }, attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      label: "TPR Begin Date",
                      hint: "M/D/YYYY format",
                      "prepend-inner-icon": "mdi-calendar",
                      value: _vm.formattedTPRBeginDate,
                      autocomplete: "off"
                    },
                    on: { change: _vm.setTPRBeginDate },
                    scopedSlots: _vm._u(
                      [
                        _vm.contract.TPRBeginDate !== null
                          ? {
                              key: "append",
                              fn: function() {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "clear",
                                      attrs: { tabindex: "-1" },
                                      on: {
                                        click: function($event) {
                                          return _vm.clear("beginDate")
                                        }
                                      }
                                    },
                                    [_vm._v("mdi-close")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticStyle: { "max-width": "15%" }, attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      value: _vm.formattedTPREndDate,
                      "background-color": "#fff",
                      label: "TPR End Date",
                      hint: "M/D/YYYY format",
                      "prepend-inner-icon": "mdi-calendar",
                      dense: "",
                      outlined: "",
                      autocomplete: "off"
                    },
                    on: { change: _vm.setTPREndDate },
                    scopedSlots: _vm._u(
                      [
                        _vm.contract.TPREndDate !== null
                          ? {
                              key: "append",
                              fn: function() {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "clear",
                                      attrs: { tabindex: "-1" },
                                      on: {
                                        click: function($event) {
                                          return _vm.clear("endDate")
                                        }
                                      }
                                    },
                                    [_vm._v("mdi-close")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "pr-0",
                                  attrs: {
                                    disabled: _vm.disabledMultiDates,
                                    large: "",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setComponent(
                                        "AdditionalTprDates"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-calendar-multiple")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-badge",
                                _vm._g(
                                  {
                                    staticClass: "pl-0 mb-2",
                                    attrs: {
                                      content: _vm.contract.TPRDates.length,
                                      value: _vm.contract.TPRDates.length,
                                      color: "#0c67a5"
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Additional Dates")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    staticClass: "input",
                    attrs: {
                      "background-color": "#fff",
                      label: "TPR Lump",
                      placeholder: "0.00",
                      type: "number",
                      min: "0",
                      step: "0.01",
                      prefix: "$",
                      autocomplete: "off",
                      dense: "",
                      outlined: ""
                    },
                    on: { change: _vm.setTPRLump },
                    model: {
                      value: _vm.contract.TPRLump,
                      callback: function($$v) {
                        _vm.$set(_vm.contract, "TPRLump", $$v)
                      },
                      expression: "contract.TPRLump"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("v-text-field", {
                    staticClass: "input",
                    attrs: {
                      label: "Internal Notes",
                      autocomplete: "off",
                      rows: "1",
                      "background-color": "#fff",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.contract.InternalComment,
                      callback: function($$v) {
                        _vm.$set(_vm.contract, "InternalComment", $$v)
                      },
                      expression: "contract.InternalComment"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6", align: "right" } },
                [
                  _vm.isExistingContract
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "white--text ml-2",
                          attrs: { height: "40", color: "orange" },
                          on: {
                            click: function($event) {
                              return _vm.confirm("Copy", "Contract")
                            }
                          }
                        },
                        [
                          _vm._v(" Copy "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-content-duplicate")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text ml-2",
                      class: _vm.hasContractChanged
                        ? "updateRequired ml-2"
                        : "white--text ml-2",
                      attrs: {
                        disabled: _vm.disabled,
                        height: "40",
                        color: "green",
                        elevation: _vm.hasContractChanged ? "12" : "2"
                      },
                      on: { click: _vm.saveContract }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.isExistingContract ? "Update" : "Save") +
                          " "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.isExistingContract
                              ? "mdi-update"
                              : "mdi-checkbox-marked-circle-outline"
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.contractItems.length
                    ? _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": _vm.offsetY },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "white--text ml-2",
                                          attrs: {
                                            disabled: _vm.hasContractChanged,
                                            height: "40",
                                            color: "#D8542F"
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(" Shuttle "),
                                        _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("mdi-transfer-right")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            759701271
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _vm.contract.AdDate
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.setComponent("ShuttleToAd")
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Items to Ad")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.contract.TPRBeginDate &&
                              _vm.contract.TPRBeginDate
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.setComponent(
                                            "ShuttleToTpr"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Items to TPR")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        staticClass: "table",
        staticStyle: { cursor: "default" },
        attrs: {
          expanded: _vm.expanded,
          headers: _vm.headers,
          height: _vm.tableSize,
          "items-per-page": _vm.pageSize,
          items: _vm.contractItems,
          loading: _vm.loading,
          "loading-text": "Loading Contract Items...Please Wait",
          search: _vm.search,
          "sort-by": _vm.tableSort,
          "show-select": "",
          dense: "",
          "fixed-header": "",
          "hide-default-footer": "",
          "item-key": "Item.OrderCode",
          "no-results-text": "No Matching Items Found"
        },
        on: {
          "update:expanded": function($event) {
            _vm.expanded = $event
          },
          "update:items": function($event) {
            _vm.contractItems = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.Item.CaseCost",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(_vm._s(_vm.formatCurrency(item.Item.CaseCost)))
                  ])
                ]
              }
            },
            {
              key: "item.Item.Status",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "span",
                    {
                      class:
                        item.Item.Status === "Active"
                          ? "green--text"
                          : "red--text"
                    },
                    [_vm._v(" " + _vm._s(item.Item.Status) + " ")]
                  )
                ]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { small: "", icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.setComponent(
                                            "ContractItemEdit",
                                            item
                                          )
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "edit",
                                        attrs: { medium: "" }
                                      },
                                      [_vm._v("mdi-square-edit-outline")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v("Edit")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    { attrs: { small: "", icon: "" } },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "copy",
                                        on: {
                                          click: function($event) {
                                            return _vm.setComponent(
                                              "ContractItemCopy",
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("mdi-content-duplicate")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v("Copy")])]
                  )
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var headers = ref.headers
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticClass: "pl-12",
                      attrs: { colspan: headers.length }
                    },
                    [
                      _c("small", [
                        _c("strong", [_vm._v("Case Cost:")]),
                        _vm._v(
                          " " + _vm._s(_vm.formatCurrency(item.Item.CaseCost))
                        )
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-3",
                        attrs: { vertical: "" }
                      }),
                      _c("small", [
                        _c("strong", [_vm._v("AMAP:")]),
                        _vm._v(" " + _vm._s(_vm.formatCurrency(item.AMAP)))
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-3",
                        attrs: { vertical: "" }
                      }),
                      _c("small", [
                        _c("strong", [_vm._v("EBA:")]),
                        _vm._v(" " + _vm._s(_vm.formatCurrency(item.EBA)))
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-3",
                        attrs: { vertical: "" }
                      }),
                      _c("small", [
                        _c("strong", [_vm._v("TPR BB:")]),
                        _vm._v(" " + _vm._s(_vm.formatCurrency(item.TPRBB)))
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-3",
                        attrs: { vertical: "" }
                      }),
                      _c("small", [
                        _c("strong", [_vm._v("Ad BB:")]),
                        _vm._v(" " + _vm._s(_vm.formatCurrency(item.AdBB)))
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-3",
                        attrs: { vertical: "" }
                      }),
                      _c("small", [
                        _c("strong", [_vm._v("Ad Scan:")]),
                        _vm._v(" " + _vm._s(_vm.formatCurrency(item.AdScan)))
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-3",
                        attrs: { vertical: "" }
                      }),
                      _c("small", [
                        _c("strong", [_vm._v("TPR Scan:")]),
                        _vm._v(" " + _vm._s(_vm.formatCurrency(item.TPRScan)))
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-3",
                        attrs: { vertical: "" }
                      }),
                      _c("small", [
                        _c("strong", [_vm._v("ePay Ad:")]),
                        _vm._v(" " + _vm._s(_vm.formatCurrency(item.ePayAd)))
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-3",
                        attrs: { vertical: "" }
                      }),
                      _c("small", [
                        _c("strong", [_vm._v("ePay TPR:")]),
                        _vm._v(" " + _vm._s(_vm.formatCurrency(item.ePayTPR)))
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-3",
                        attrs: { vertical: "" }
                      }),
                      _c("small", [
                        _c("strong", [_vm._v("Compete:")]),
                        _vm._v(" " + _vm._s(_vm.formatCurrency(item.Compete)))
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-3",
                        attrs: { vertical: "" }
                      }),
                      _c("small", [
                        _c("strong", [_vm._v("PCAF:")]),
                        _vm._v(" " + _vm._s(_vm.formatCurrency(item.PCAF)))
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-3",
                        attrs: { vertical: "" }
                      }),
                      _c("small", [
                        _c("strong", [_vm._v("Deal ID:")]),
                        _vm._v(" " + _vm._s(item.DealID))
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c("v-divider"),
      _c(
        "v-toolbar",
        { staticClass: "pt-3", attrs: { flat: "", color: "#fafafa" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "background-color": "#fff",
                      label: "Sort By Asc",
                      items: _vm.sortOptions,
                      "menu-props": {
                        top: true,
                        offsetY: true,
                        maxHeight: 500
                      },
                      "item-text": "label",
                      "item-value": "value",
                      "prepend-inner-icon": "mdi-sort-ascending"
                    },
                    on: { change: _vm.changeSort }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3", align: "left" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "background-color": "#fff",
                      label: "Search for Contract Item",
                      "prepend-inner-icon": "mdi-magnify",
                      autocomplete: "off",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { cols: "6", align: "right" } },
                [
                  _vm.selected.length
                    ? _c(
                        "v-menu",
                        {
                          attrs: { top: "", "offset-y": _vm.offset },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            height: "40",
                                            color: "#E0E0E0"
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "Selected (" +
                                            _vm._s(_vm.selected.length) +
                                            ")"
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3461265091
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { color: "green !important" },
                                  on: {
                                    click: function($event) {
                                      return _vm.setComponent(
                                        "ContractItemEdit"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v(" Update "),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { right: "", color: "green" }
                                        },
                                        [_vm._v("mdi-update")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticStyle: { color: "red !important" },
                                  on: {
                                    click: function($event) {
                                      return _vm.confirm("Delete", "Item")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v(" Remove "),
                                      _c(
                                        "v-icon",
                                        { attrs: { right: "", color: "red" } },
                                        [_vm._v("mdi-playlist-remove")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text ml-2",
                      attrs: {
                        disabled: !_vm.isExistingContract,
                        height: "40",
                        color: "#0c67a5"
                      },
                      on: {
                        click: function($event) {
                          return _vm.setComponent("ContractItemAdd")
                        }
                      }
                    },
                    [
                      _vm._v(" Add Items"),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-plus")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(_vm.component, {
        tag: "Component",
        attrs: {
          contract: _vm.contract,
          getContract: _vm.getContract,
          isBulkEdit: _vm.isBulkEdit,
          saveContract: _vm.saveContract,
          selected: _vm.selected,
          selectedItem: _vm.selectedItem,
          suppliers: _vm.suppliers,
          value: _vm.modal
        },
        on: {
          "update:value": function($event) {
            _vm.modal = $event
          },
          "clear-dates": _vm.clearTPRDates
        }
      }),
      _c("ConfirmDialog", {
        attrs: {
          action: _vm.action,
          copy: _vm.copyContract,
          remove: _vm.remove,
          selected: _vm.selected,
          selectedItem: _vm.selectedItem,
          type: _vm.type,
          value: _vm.confirmDialog
        },
        on: {
          "update:value": function($event) {
            _vm.confirmDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }