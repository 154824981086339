import { reporting } from './reporting'
// import { store } from '@/store/index'

export default class reports {
  static async getReports () {
    return reporting({
      url: '/reports/40',
      method: 'GET'
    })
  }

  static async getParameters (reportId) {
    return reporting({
      url: `/report/${reportId}`,
      method: 'GET'
    })
  }

  static async getDimensions (fieldId) {
    return reporting({
      url: `/value_list/${fieldId}`,
      method: 'GET'
    })
  }

  static async submitReport(reportId, params) {
    return reporting({
      url: `/submit_report/${reportId}`,
      method: 'POST',
      params: params
    })
  }

  static async exportFile (fileType, reportId, invoiceNumber) {
    return reporting({
      url: `/run_report/${reportId}/${fileType}`,
      method: 'GET',
      params: { invoice_number: invoiceNumber },
      responseType: 'blob'
    })
  }

  static async getQueue () {
    return reporting({
      url: '/report_log',
      method: 'GET'
    })
  }
}
