var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h1", [_vm._v("404")]),
      _c("p", [
        _vm._v("Sorry, the page you were looking for could not be found.")
      ]),
      _c("p", [
        _vm._v(
          "Please select an option from the menu or search for what you are looking for"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }