<template>
  <v-container class="ma-0 pa-6">
    <v-row justify="center" class="mb-12">
      <v-col cols="6">
        <v-textarea align="center" outlined dense background-color="#fff" label="Comments" rows="3" v-model="store.comments"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Comments',
  data () {
    return {
    }
  },
  props: [
    'store'
  ]
}
</script>
