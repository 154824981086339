import { base } from './base'

export default class closing {
  static async getClosingSteps (period, year) {
    return base({
      url: `/closing/steps/${period}/${year}`,
      method: 'GET'
    })
  }

  static async updateStep (payload) {
    return base({
      url: '/closing/step',
      method: 'PUT',
      data: payload
    })
  }

  static async submitClosing (period, year) {
    return base({
      url: `/closing/submit/${period}/${year}`,
      method: 'POST'
    })
  }
  
  static async restartStep (period, year, stepID, stage) {
    let url = `/closing/submit/${period}/${year}/${stepID}`
  
    if (stage === "Post") {
      url = `/closing/post/${period}/${year}/${stepID}`
    }
    return base({
      url: url,
      method: 'POST'
    })
  }

  static async postClosing (period, year) {
    return base({
      url: `/closing/post/${period}/${year}`,
      method: 'POST'
    })
  }
}
