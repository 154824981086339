import { base } from './base'

export default class recaps {
  static async getRecapAdditions (start, end, filters) {
    return base({
      url: `/recap_additions/${start}/${end}?${filters}`,
      method: 'GET'
    })
  }

  static async getRecapComponents () {
    return base({
      url: '/recap_components',
      method: 'GET'
    })
  }

  static async saveRecapAddition (payload) {
    return base({
      url: '/recap_additions',
      method: 'POST',
      data: payload
    })
  }

  static async updateRecapAddition (payload) {
    return base({
      url: '/recap_additions',
      method: 'PUT',
      data: payload
    })
  }

  static async deleteRecapAddition (payload) {
    return base({
      url: '/recap_additions',
      method: 'DELETE',
      data: payload
    })
  }
  
  static async getStores () {
    return base({
      url: '/stores',
      method: 'GET'
    })
  }
}
