<template>
  <v-app id="inspire">

    <v-app-bar color="#1C3144" clipped-left clipped-right dark app>
      <v-app-bar-nav-icon class="mr-2" @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-img max-height="125" :to="{ path: '/contracts'}" max-width="125" src="./assets/arg_logo_alt.png"
        alt="ARG Logo"></v-img>
      <v-spacer></v-spacer>
      <h2 v-if="development">
        <v-icon large color="orange">mdi-alert-circle-outline</v-icon>
        User Testing Environment
      </h2>
      <v-spacer></v-spacer>
      <v-menu v-if="admin" offset-y offset-x>
        <template v-slot:activator="{ on }">
          <v-btn class="mx-2" v-on="on" icon color="white" dark>
            <v-icon>mdi-account-cog-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Maintenance</v-card-title>
          <v-divider />
          <v-list dense>
            <v-list-item v-for="(link, i) in maintenanceChildren" :key="i" link
              :to="{ path: `/maintenance/${link.route}` }">
              <v-icon size="15" class="mr-2">mdi-cog</v-icon>
              <v-list-item-title>{{link.text}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer color="#1C3144" class="elevation-0" temporary v-model="drawer" clipped app>
      <v-list>
        <template v-for="(parent, index) in navLinks">
          <v-list-group v-if="parent.children.length" :key="index">
            <v-icon slot="appendIcon" color="white">mdi-menu-down</v-icon>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon class="white--text">{{parent.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="white--text">{{parent.text}}</v-list-item-title>
            </template>
            <v-list-item v-for="(child, index) in parent.children" :key="index"
              :class="{ active: $route.path === child.route }" link :to="{ path: child.route }">
              <v-list-item-icon class="pl-6">
                <v-icon color="white">mdi-menu-right</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="white--text">{{child.text}}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="index" :class="{ active: $route.path === parent.route }" link
            :to="{ path: parent.route }">
            <v-list-item-icon>
              <v-icon color="white">{{parent.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text">{{parent.text}}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <div class="pa-2" style="height:64px;">
          <v-btn block id="logoutBtn" class="mt-2" @click.prevent="logout()">
            <v-icon left>mdi-logout</v-icon>
            <span>Logout</span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <Maintenance :value.sync="maintenanceModal" />
      <Loader :value.sync="loader" :payload="payload" />
      <ErrorDisplay :value.sync="errorDisplay" :errors="errors" />
      <v-snackbar top rounded="md" height="60" content-class="body-1" transition="slide-y-transition"
        v-model="snackbar.showing" :timeout="snackbar.timeout" :color="snackbar.color">
        <v-icon left>{{snackbar.icon}}</v-icon> {{snackbar.text}}
        <template v-slot:action="{ attrs }">
          <v-btn icon text v-bind="attrs" @click="snackbar.showing=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <router-view />
    </v-main>

    <v-footer app color="#1C3144">
      <span class="white--text footer">Alliance Retail Group &copy; {{ new Date().getFullYear() }}</span>
    </v-footer>

    <v-alert class="white--text" v-model="alert" icon="mdi-cancel" type="error" width="248"
      style="bottom:150px; left:3px; z-index:10;">
      Admin Access Only
    </v-alert>

  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',

  components: {
    Maintenance: () => import('@/components/maintenanceModal'),
    Loader: () => import('@/components/shared/loader'),
    ErrorDisplay: () => import('@/components/shared/error-display')
  },

  data () {
    return {
      alert: false,
      environment: '',
      errorDisplay: false,
      errors: [],
      drawer: null,
      loader: false,
      maintenanceModal: false,
      notification: false,
      payload: {},
      timer: '',
      footer: { inset: true }
    }
  },

  computed: {
    ...mapState(['admin', 'user', 'userGroups', 'snackbar']),

    development () {
      return process.env.NODE_ENV === 'development'
    },
    navLinks () {
      return [
        {
          text: 'Reporting',
          icon: 'mdi-file-chart-outline',
          route: '/',
          children: []
        },
        {
          text: 'Ad/TPR',
          icon: 'mdi-newspaper-variant-outline',
          children: [
            {
              text: 'Contract Manager',
              route: '/contracts'
            },
            {
              text: 'Ad Planner',
              route: '/ads'
            },
            {
              text: 'TPR Manager',
              route: '/tprs'
            },
            {
              text: 'Shuttle Status',
              route: '/shuttle-status'
            },
            {
              text: 'Price Book',
              route: '/maintenance/price-book'
            },
          ]
        },
        {
          text: 'Meat Accruals',
          icon: 'mdi-food-drumstick',
          route: '/meat-accruals',
          children: []
        },
        {
          text: 'Billing',
          icon: 'mdi-currency-usd',
          children: [
            {
              text: 'Invoices',
              route: '/invoices'
            }, {
              text: 'Recap Additions',
              route: '/recap-additions'
            },
            {
              text: 'Period Close',
              route: '/closing'
            }
          ]
        },
        {
          text: 'Uploads',
          icon: 'mdi-file-upload-outline',
          route: 'file-uploads',
          children: []
        },
      ]
    },

    maintenanceChildren () {
      return [
        { text: 'Ad Groups', icon: 'mdi-circle-small', route: 'ad-groups'},
        { text: 'AWG Vendor Codes', icon: 'mdi-circle-small', route: 'vendor-codes'},
        { text: 'Customer/Store Info', icon: 'mdi-circle-small', route: 'stores'},
        { text: 'Feature Types', icon: 'mdi-circle-small', route: 'feature-types'},
        { text: 'Owner Groups', icon: 'mdi-circle-small', route: 'owners'},
        { text: 'Price Book', icon: 'mdi-circle-small', route: 'price-book'},
        { text: 'Sub-Groups', icon: 'mdi-circle-small', route: 'sub-groups'}
      ]
    }
  },

  created () {
    this.$root.$on('notification', (payload) => {
      this.loader = false
      this.payload = payload
      this.notification = true
    })

    this.$root.$on('loader', (payload) => {
      this.payload = payload
      this.loader = payload.show
    })

    this.$root.$on('show-errors', (errors) => {
      this.loader = false
      this.errors = errors
      this.errorDisplay = true
    })

    this.$store.dispatch('getAllAdGroups')
  },

  methods: {
    checkAdminStatus () {
      if (this.userGroups.includes('AdMan_Admin')) {
        this.maintenanceModal = true
      } else {
        this.alert = true
        setTimeout(() => { this.alert = false }, 500)
      }
    },

    logout () {
      window.location.href = process.env.VUE_APP_SIGNOUT_LOCATION
    }
  }
}
</script>
<style scoped>
.theme--light.v-application {
  background: #fafafa;
}

.active {
  background: #2AACE3;
}

.footer {
  font-size: small;
}
</style>
