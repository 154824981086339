var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0" },
    [
      _c("v-data-table", {
        staticClass: "table",
        attrs: {
          height: _vm.tableSize,
          items: _vm.vendorCodes,
          headers: _vm.headers,
          "items-per-page": _vm.pageSize,
          loading: _vm.loading,
          search: _vm.search,
          "loader-height": "2",
          "hide-default-footer": "",
          "fixed-header": "",
          dense: "",
          "item-key": "id",
          "loading-text": "Loading... Please wait"
        },
        on: {
          "update:items": function($event) {
            _vm.vendorCodes = $event
          },
          "click:row": _vm.editVendorCode
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  {
                    staticClass: "toolbar",
                    attrs: { flat: "", color: "#fafafa" }
                  },
                  [
                    _c("v-toolbar-title", [
                      _vm._v("AWG Vendor Code Maintenance")
                    ]),
                    _c("v-divider", {
                      staticClass: "mx-4",
                      attrs: { inset: "", vertical: "" }
                    }),
                    _c(
                      "v-col",
                      { staticClass: "mt-6", attrs: { cols: "3" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "background-color": "#fff",
                            "prepend-inner-icon": "mdi-filter-outline",
                            label: "Filter Vendor Codes"
                          },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-divider")
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("v-divider"),
                _c("v-toolbar", { attrs: { flat: "", color: "#fafafa" } })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "600" },
          model: {
            value: _vm.vendorCodeModal,
            callback: function($$v) {
              _vm.vendorCodeModal = $$v
            },
            expression: "vendorCodeModal"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "#fafafa" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "#1C3144", dark: "", flat: "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  !_vm.edit
                    ? _c("v-toolbar-title", [_vm._v("New Vendor Code Setup")])
                    : _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.vendorCode.name))
                      ]),
                  _c("v-spacer"),
                  _c("v-toolbar-title", [
                    _vm._v("ID: " + _vm._s(_vm.vendorCode.id))
                  ])
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "ma-0 pa-6" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mb-12", attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              readonly: "",
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Code"
                            },
                            model: {
                              value: _vm.vendorCode.code,
                              callback: function($$v) {
                                _vm.$set(_vm.vendorCode, "code", $$v)
                              },
                              expression: "vendorCode.code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              readonly: "",
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              label: "Name"
                            },
                            model: {
                              value: _vm.vendorCode.name,
                              callback: function($$v) {
                                _vm.$set(_vm.vendorCode, "name", $$v)
                              },
                              expression: "vendorCode.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mb-12", attrs: { cols: "1" } },
                        [
                          _c("v-checkbox", {
                            attrs: { readonly: "", label: "Hide" },
                            model: {
                              value: _vm.vendorCode.hide,
                              callback: function($$v) {
                                _vm.$set(_vm.vendorCode, "hide", $$v)
                              },
                              expression: "vendorCode.hide"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "#fafafa" } },
                [_c("v-spacer")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          staticClass: "notification",
          attrs: { timeout: _vm.timeout, color: "#00B24A", top: "" },
          model: {
            value: _vm.notification,
            callback: function($$v) {
              _vm.notification = $$v
            },
            expression: "notification"
          }
        },
        [_vm._v(" " + _vm._s(_vm.text) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }