import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/views/dashboard.vue'
import ContractList from '@/views/contract-list'
import ContractView from '@/views/contract-view'
import AdList from '@/views/ad-list'
import TPRList from '@/views/tpr-list'
import Invoices from '@/views/invoices'
import RecapAdditionList from '@/views/recap-addition-list'
import MeatAccruals from '@/views/accruals'
import FileUploadList from '@/views/file-uploads'
import PeriodClose from '@/views/closing.vue'
import Shuttles from '@/views/shuttle'
import Error404 from '@/components/errors/404'

// Admin Templates
import AdGroups from '@/views/maintenance/adGroups'
import Owners from '@/views/maintenance/owners'
import Stores from '@/views/maintenance/stores'
import SubGroups from '@/views/maintenance/subGroups'
import FeatureTypes from '@/views/maintenance/featureTypes'
import VendorCodes from '@/views/maintenance/vendorCodes'
import PriceBook from '@/views/maintenance/priceBook'

// Vuex
import { store } from '../store/index'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/contracts',
      name: 'ContractList',
      component: ContractList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/new-contract',
      name: 'ContractNew',
      component: ContractView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/edit-contract',
      name: 'ContractEdit',
      component: ContractView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/copy-contract',
      name: 'ContractCopy',
      component: ContractView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/ads',
      name: 'AdList',
      component: AdList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/tprs',
      name: 'TPRList',
      component: TPRList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/invoices',
      name: 'Invoices',
      component: Invoices,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/meat-accruals',
      name: 'MeatAccruals',
      component: MeatAccruals,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shuttle-status',
      name: 'Shuttles',
      component: Shuttles,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/file-uploads',
      name: 'FileUploadList',
      component: FileUploadList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/maintenance/ad-groups',
      name: 'AdGroups',
      component: AdGroups,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/maintenance/owners',
      name: 'Owners',
      component: Owners,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/maintenance/stores',
      name: 'Stores',
      component: Stores,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/maintenance/sub-groups',
      name: 'SubGroups',
      component: SubGroups,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/maintenance/feature-types',
      name: 'FeatureTypes',
      component: FeatureTypes,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/maintenance/vendor-codes',
      name: 'VendorCodes',
      component: VendorCodes,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/maintenance/price-book',
      name: 'PriceBook',
      component: PriceBook,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/recap-additions',
      name: 'RecapAdditionList',
      component: RecapAdditionList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/closing',
      name: 'PeriodClose',
      component: PeriodClose,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '*',
      name: '404',
      component: Error404,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeResolve(async (to, from, next) => {
  if (Object.prototype.hasOwnProperty.call(to, 'meta')) {
    // Use the route's "meta.title" value to assign the page's title
    if (Object.prototype.hasOwnProperty.call(to.meta, 'title') && to.meta.title) document.title = to.meta.title
    // For routes requiring authentication ( has "meta.requiresAuth" property )
    if (to.meta.requiresAuth === true) {
      const validSession = await Promise.resolve(store.dispatch('valid'))
      if (validSession === true) {
        return next()
      } else {
        // return next('/')
        window.location.replace(process.env.VUE_APP_ACCOUNT_LOC)
      }
    }
    return next()
  }
  return next()
})
