import { contracts } from './contracts'
// import { Auth } from 'aws-amplify'
import { store } from '@/store/index'

export default class endpoints {
  static async shuttleStatus (email, date) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: `/shuttleStatus/${email}/${date}`,
      method: 'GET'
    })
  }

  static async getShuttleDetails (shuttleId) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/shuttle_contract/' + shuttleId,
      method: 'GET'
    })
  }

  static async getShuttleUsers () {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/shuttle_users',
      method: 'GET'
    })
  }

  static async removeShuttle (uuid) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/shuttleStatus/' + uuid,
      method: 'DELETE'
    })
  }

  static async getContracts (start, end) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/contracts/getContractList/' + start + '/' + end,
      method: 'GET'
    })
  }

  static async getContract (contractID) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/contracts/contract/' + contractID,
      method: 'GET'
    })
  }

  static async getContractTypes () {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/contractTypes',
      method: 'GET'
    })
  }

  static async saveContract (saveContract) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/contract',
      method: 'POST',
      data: saveContract
    })
  }

  static async updateContract (updateContract) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/contract',
      method: 'PUT',
      data: updateContract
    })
  }

  static async deleteContract (deleteContract) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/contract',
      method: 'DELETE',
      data: deleteContract
    })
  }

  static async getByItemCode (itemCode, supplierCode) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/items/' + itemCode + '/' + supplierCode,
      method: 'GET'
    })
  }

  static async getProductLinkGroup (itemCode, supplierCode) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/productLinkGroup/' + itemCode + '/' + supplierCode,
      method: 'GET'
    })
  }

  static async saveItem (saveItem) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/contracts/item',
      method: 'POST',
      data: saveItem
    })
  }

  static async updateItem (updateItem) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/contracts/item',
      method: 'PUT',
      data: updateItem
    })
  }

  static async deleteItem (deleteItem) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/contracts/item',
      method: 'DELETE',
      data: deleteItem
    })
  }

  static async getSuppliers () {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/suppliers',
      method: 'GET'
    })
  }

  static async getAdGroups () {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/adGroups',
      method: 'GET'
    })
  }

  static async getDepartments (supID) {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/departments/' + supID,
      method: 'GET'
    })
  }

  static async getManufacturers () {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/manufacturers',
      method: 'GET'
    })
  }

  static async getAdDates () {
    contracts.defaults.headers.common.Authorization = store.state.user.signInUserSession.idToken.jwtToken
    return contracts({
      url: '/ad_dates',
      method: 'GET'
    })
  }
}
